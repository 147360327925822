import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Common from "../based/Common";
import { CARD_COLOR } from "../based/Constants";

const CardCollection = (props) => {
    const { project, autoProject } = props;
    if (props.addNew) {
        return (
            <CardWrapper>
                <div className="button__control">
                    <ButtonAdd to="/creator/project-editor">
                        <i className="fa fa-plus" aria-hidden="true">
                            {" "}
                        </i>
                        <span> Tạo mới </span>
                    </ButtonAdd>
                </div>
            </CardWrapper>
        );
    }
    if (props.addNewAuto) {
        return (
            <CardWrapper>
                <div className="button__control">
                    <ButtonAdd to="/creator/project-editor-auto">
                        <i className="fa fa-plus" aria-hidden="true">
                            {" "}
                        </i>
                        <span> Tạo mới Bộ sưu tập tự động </span>
                    </ButtonAdd>
                </div>
            </CardWrapper>
        );
    }
    if (project)
        return (
            <CardWrapper coverUrl={project.coverUrl ? project.coverUrl : null}>
                <div className="button__control">
                    {" "}
                    {props.isDone ? (
                        <React.Fragment>
                            <CardButtonLink to={`/creator/project-editor/${project.id}`} color={CARD_COLOR.Green}>
                                <span className="">
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                    <p> Xem </p>
                                </span>
                            </CardButtonLink>
                            <CardButton color={CARD_COLOR.Red} onClick={props.onDelete}>
                                <span>
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                    <p> Xóa </p>
                                </span>
                            </CardButton>
                        </React.Fragment>
                    ) : (
                        <div>
                            <CardButtonLink to={`/manage-profile/projects`}>
                                <span>
                                    <i className="fa fa-pencil-square-o" aria-hidden="true">
                                        {" "}
                                    </i>
                                    <p> Sửa </p>
                                </span>
                            </CardButtonLink>
                            <CardButton>
                                <span>
                                    <i className="fa fa-trash" aria-hidden="true">
                                        {" "}
                                    </i>
                                    <p> Xóa </p>
                                </span>
                            </CardButton>
                        </div>
                    )}
                </div>
                <div className="card__footer">
                    <span className="card__name"> Tên bộ sưu tập: {project.name ? project.name : ""} </span>
                    <span> {project.createAt ? Common.formatDate(new Date(project.createAt)) : ""} </span>
                    <span> {project.statusName ? project.statusName : ""} </span>
                </div>
            </CardWrapper>
        );
    if (autoProject) {
        return (
            <CardWrapper coverUrl={autoProject.url ? autoProject.url : null}>
                <div className="button__control">
                    {props.isDone ? (
                        <React.Fragment>
                            <CardButtonLink to={`/creator/project-editor-auto/${autoProject.id}`} color={CARD_COLOR.Green}>
                                <span className="">
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                    <p> Xem </p>
                                </span>
                            </CardButtonLink>
                            <CardButtonLink to={`/creator/project-editor-auto/${autoProject.id}`} color={CARD_COLOR.Green}>
                                <span className="">
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                    <p> Sửa </p>
                                </span>
                            </CardButtonLink>
                            <CardButton color={CARD_COLOR.Red} onClick={props.onDelete}>
                                <span>
                                    <i className="fa fa-trash" aria-hidden="true">
                                        {" "}
                                    </i>
                                    <p> Xóa </p>
                                </span>
                            </CardButton>
                        </React.Fragment>
                    ) : (
                        <div>
                            <CardButtonLink to={`/manage-profile/projects`}>
                                <span>
                                    <i className="fa fa-pencil-square-o" aria-hidden="true">
                                        {" "}
                                    </i>
                                    <p> Sửa </p>
                                </span>
                            </CardButtonLink>
                            <CardButton>
                                <span>
                                    <i className="fa fa-trash" aria-hidden="true">
                                        {" "}
                                    </i>
                                    <p> Xóa </p>
                                </span>
                            </CardButton>
                        </div>
                    )}
                </div>
                <div className="card__footer">
                    <span className="card__name"> Tên bộ sưu tập: {autoProject.name ? autoProject.name : ""} </span>
                    <span style={{ color: "white" }}> {autoProject.createdDate ? Common.formatDate(new Date(autoProject.createdDate)) : ""} </span>
                    <span style={{ color: "white" }}> {autoProject.statusName ? autoProject.statusName : ""} </span>
                </div>
            </CardWrapper>
        );
    }
};

export const CardWrapper = styled.div`
    background: ${({ coverUrl }) => (coverUrl ? `url(${coverUrl})` : "#F2F2F2")};
    background-size: cover;
    width: 23%;
    position: relative;
    height: 290px;
    margin: 7px;
    over-flow: hidden;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    .card__footer {
        position: absolute;
        bottom: 10px;
        padding: 10px;
        left: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        .card__name {
            font-size: 18px;
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
        }
    }
    div.button__control {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        height: fit-content;
        width: fit-content;
        display: flex;
        flex-direction: column;
        display: block;
        z-index: 5;
    }
`;
export const CardButton = styled.div`
    padding: 5px 17px;
    background: ${({ color }) => (color ? color : "transparent")};
    margin: 4px;
    border-radius: 17px;
    transition: all 0.5s ease;
    border: 2px solid transparent;
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    will-change: transform;
    cursor: pointer;
    display: none;
    ${CardWrapper}:hover & {
        display: block;
    }
    span.card__add {
        display: flex;
        flex-direction: column;
    }
    span {
        display: flex;
        align-items: center;
        color: #fff;
        i {
            margin-right: 10px;
        }
        p {
            margin: 0;
        }
    }
    &:hover {
        background: #fff;
        border: 2px solid ${({ color }) => (color ? color : "#000")};
        color: #fff;
        box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
        transform: translateY(-2px);
        span {
            i,
            p {
                color: #000;
                font-weight: 500;
            }
        }
    }
`;
export const CardButtonLink = styled(Link)`
    white-space: nowrap;
    display: flex;
    padding: 5px 17px;
    background: ${({ color }) => (color ? color : "transparent")};
    margin: 4px;
    border-radius: 17px;
    transition: all 0.5s ease;
    border: 2px solid transparent;
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    will-change: transform;
    display: none;
    ${CardWrapper}:hover & {
        display: block;
    }
    span.card__add {
        display: flex;
        flex-direction: column;
    }
    span {
        display: flex;
        align-items: center;
        color: #fff;
        white-space: nowrap;
        i {
            margin-right: 10px;
        }
        p {
            margin: 0;
        }
    }
    &:hover {
        background: transparent;
        border: 2px solid ${({ color }) => (color ? color : "#000")};
        color: #fff;
        box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
        transform: translateY(-2px);
        span {
            i,
            p {
                color: #000;
                font-weight: 500;
            }
        }
    }
`;
const ButtonAdd = styled(Link)`
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export default CardCollection;
