import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Common from "../based/Common";
import { ARTWORK_STATUS, ARTWORK_STATUS_OPTIONS, NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import TFUSelect from "../based/inputs/TFUSelect";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import ArtworkServices from "../based/services/ArtworkServices";
import BrandServices from "../based/services/BrandServices";
import Breadcum from "../layout/refactor/Breadcum/Breadcum";
import TableFooter from "../based/content/TableFooter";
const Tab_Initializing = 999;

const ManageArtwork = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState({ ...Common.Paging, status: ARTWORK_STATUS.All, orderBy: "id", orderDirection: "desc" });
    const [datas, setDatas] = useState([]);
    const [artworkQuota, setArtworkQuota] = useState({});
    const [tabIndex, setTabIndex] = useState(ARTWORK_STATUS.All);
    const [brandOptions, setBrandOptions] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getDatas(paging, tabIndex);
        getArtworkQuota();
        getBrandApproved();
    }, []);

    //lấy thông tin artwork
    const getArtworkQuota = async () => {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await ArtworkServices.GetArtworkQuota();
        if (!error && data) {
            setArtworkQuota(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setArtworkQuota({});
            setIsLoading(false);
            setMsg(null);
        }
    };

    async function getBrandApproved() {
        let [err, data] = await BrandServices.GetDropdownApprovedBrand(Common.Paging);
        if (!err && data) setBrandOptions(data);
        else setBrandOptions([]);
    }

    /** Lấy dữ liệu của set */
    const getDatas = async (newPaging, tab) => {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await ArtworkServices.GetSetPaging(newPaging, newPaging.status, tab);
        if (!error && data) {
            let pagingUp = { ...data.paging, status: newPaging.status };
            setDatas(data.listObjects);
            setPaging(pagingUp);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    };

    //thay đổi tab
    const _handleChangeTab = async (tab) => {
        if (tabIndex != tab) {
            let pagingUp = { ...paging, status: tab == Tab_Initializing ? ARTWORK_STATUS.All : tab };
            setTabIndex(tab);
            getDatas(pagingUp, tab);
        }
    };

    /** Ẩn set */
    function handleConfigOffline(item) {
        let id = item.id;
        let status = item.status;

        //Nếu set ở trạng thái cancel return
        if (status == ARTWORK_STATUS.Cancelled) return;
        if (status == ARTWORK_STATUS.Waiting) return;

        //Nếu set đang online thì bật offline, nếu đang offline thì bật online
        let updateStatus = status == ARTWORK_STATUS.Offline ? ARTWORK_STATUS.Online : ARTWORK_STATUS.Offline;
        let msg = status == ARTWORK_STATUS.Offline ? "hiện" : "ẩn";

        ConfirmDialog("Xác nhận ẩn?", `Xin vui lòng xác nhận để ${msg} set sản phẩm này`, () => {
            return new Promise(async (resolve, reject) => {
                setIsLoading(true);
                setMsg("Đang xóa dữ liệu...");
                let [err, data] = await ArtworkServices.ConfigSetStatus(id, updateStatus);
                if (!err && data) {
                    resolve({ title: "Thành công", msg: "Ẩn thành công." });
                    getDatas(paging, tabIndex);
                } else {
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                }
                setIsLoading(false);
                setMsg(null);
            });
        });
    }

    /** Xoá set */
    const handleDelete = (item) => {
        let id = item.id;
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa set sản phẩm này", () => {
            return new Promise(async (resolve, reject) => {
                setIsLoading(true);
                setMsg("Đang xóa dữ liệu...");
                let [error, data] = await ArtworkServices.DeleteSet(id);
                if (!error && data) {
                    setIsLoading(false);
                    setMsg(null);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    getDatas(paging, tabIndex);
                    getArtworkQuota();
                } else {
                    setIsLoading(false);
                    setMsg(null);
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                }
            });
        });
    };

    return (
        <React.Fragment>
            <Breadcum menu={["Trang chủ", "Quản lý thiết kế"]} title="Quản lý thiết kế" />
            <Wrapper>
                <div className="header-page">
                    <div className="header-top">
                        <div className="box__header">
                            <div className={tabIndex === ARTWORK_STATUS.All ? "btn-tab active" : "btn-tab"} onClick={() => _handleChangeTab(ARTWORK_STATUS.All)}>
                                <span>Tất cả</span>
                            </div>
                            <div className={tabIndex === Tab_Initializing ? "btn-tab active" : "btn-tab"} onClick={() => _handleChangeTab(Tab_Initializing)}>
                                <span>Đang tạo</span>
                            </div>
                            <div className={tabIndex === ARTWORK_STATUS.Online ? "btn-tab active" : "btn-tab"} onClick={() => _handleChangeTab(ARTWORK_STATUS.Online)}>
                                <span>Đang hoạt động</span>
                            </div>
                            <div className={tabIndex === ARTWORK_STATUS.Offline ? "btn-tab active" : "btn-tab"} onClick={() => _handleChangeTab(ARTWORK_STATUS.Offline)}>
                                <span>Đang ẩn</span>
                            </div>
                        </div>
                        <div className="add-new">
                            <div
                                className="btn-add-new"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (brandOptions && brandOptions.length > 0) {
                                        window.location.href = "/create-artwork";
                                    } else Notify(NOTIFY.WARNING, "Thông báo", "Xin vui lòng tạo thương hiệu của bạn trước khi tải thiết kế lên");
                                }}
                            >
                                Thêm thiết kế mới
                                <div className="icon">
                                    <AddNewIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="header-overview">
                        {artworkQuota && (
                            <div className="overview-left">
                                <div className="overview-area">
                                    <label htmlFor="">Mức bán hàng (Tier)</label>
                                    <div className="overview-value">{artworkQuota.tierName}</div>
                                </div>
                                <div className="overview-area">
                                    <label htmlFor="">Đã bán</label>
                                    <div className="overview-value">{artworkQuota.totalSell}</div>
                                </div>

                                <div className="overview-area">
                                    <label htmlFor="">{`Số thiết kế < 25mb`}</label>
                                    <div className="overview-value">
                                        {artworkQuota.totalArtworkUnder25Used}/{artworkQuota.totalArtworkUnder25}
                                    </div>
                                </div>
                                <div className="overview-area">
                                    <label htmlFor="">{`Số thiết kế < 50mb`}</label>
                                    <div className="overview-value">
                                        {artworkQuota.totalArtworkUnder50Used}/{artworkQuota.totalArtworkUnder50}
                                    </div>
                                </div>
                                <div className="overview-area">
                                    <label htmlFor="">{`Số thiết kế < 100mb`}</label>
                                    <div className="overview-value">
                                        {artworkQuota.totalArtworkUnder100Used}/{artworkQuota.totalArtworkUnder100}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="overview-right">
                            <div className="search-area">
                                <input
                                    type="text"
                                    value={paging.keyword}
                                    placeholder="Tìm kiếm thiết kế"
                                    onChange={(e) => {
                                        setPaging({ ...paging, keyword: e.target.value });
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            getDatas({ ...paging }, tabIndex);
                                        }
                                    }}
                                />
                                <div className="icon-subfix">
                                    <div className="icon">
                                        <SearchIcon />
                                    </div>
                                </div>
                            </div>

                            <div className="status-options">
                                <TFUSelect options={[...ARTWORK_STATUS_OPTIONS]} value={paging.status} onChanged={(value) => setPaging({ ...paging, status: value ? value : -2 })} placeholder="Trạng thái" />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="table-artwork-header">
                        <div className="list-item">
                            <div
                                className="item"
                                style={{
                                    width: "5%",
                                }}
                            >
                                <input type="checkbox" />
                            </div>
                            <div
                                className="item"
                                style={{
                                    width: "5%",
                                }}
                            >
                                <span>STT</span>
                            </div>
                            <div
                                className="item"
                                style={{
                                    width: "calc(90% / 6)",
                                }}
                            >
                                <span>Tên thiết kế</span>
                            </div>
                            <div
                                className="item"
                                style={{
                                    width: "calc(90% / 6)",
                                }}
                            >
                                <span>Thiết kế</span>
                            </div>
                            <div
                                className="item"
                                style={{
                                    width: "calc(90% / 6)",
                                }}
                            >
                                <span>Tiền bản quyền</span>
                            </div>
                            <div
                                className="item"
                                style={{
                                    width: "calc(90% / 6)",
                                }}
                            >
                                <span>Đã bán</span>
                            </div>
                            <div
                                className="item"
                                style={{
                                    width: "calc(90% / 6)",
                                }}
                            >
                                <span>Trạng thái</span>
                            </div>
                            <div
                                className="item"
                                style={{
                                    width: "calc(90% / 6)",
                                }}
                            >
                                <span>Thao tác</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-artwork-body">
                    {datas && datas.length > 0 ? (
                        datas.map((item, index) => {
                            let disableBtn = item.status != ARTWORK_STATUS.Online && item.status != ARTWORK_STATUS.Offline;
                            return (
                                <div className="list-item" key={index}>
                                    <div
                                        className="item"
                                        style={{
                                            width: "5%",
                                        }}
                                    >
                                        <input type="checkbox" />
                                    </div>
                                    <div
                                        className="item"
                                        style={{
                                            width: "5%",
                                        }}
                                    >
                                        <span>{index + 1}</span>
                                    </div>
                                    <div
                                        className="item"
                                        style={{
                                            width: "calc(90% / 6)",
                                        }}
                                    >
                                        <span>{item.title}</span>
                                    </div>
                                    <div
                                        className="item"
                                        style={{
                                            width: "calc(90% / 6)",
                                        }}
                                    >
                                        <div className="list-thumbnail">
                                            {item.artworks &&
                                                item.artworks.map((art, a) => {
                                                    return (
                                                        <div className="thumbnail" key={a}>
                                                            <img src={art.thumbnail} alt="Thumbnail thiết kế" />
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                    <div
                                        className="item"
                                        style={{
                                            width: "calc(90% / 6)",
                                        }}
                                    >
                                        <span>{Common.formatCurrency(item.loyaltyAmount)}</span>
                                    </div>
                                    <div
                                        className="item"
                                        style={{
                                            width: "calc(90% / 6)",
                                        }}
                                    >
                                        <span>{item.totalSell}</span>
                                    </div>
                                    <div
                                        className="item"
                                        style={{
                                            width: "calc(90% / 6)",
                                        }}
                                    >
                                        <span className="status">{item.statusName}</span>
                                    </div>
                                    <div
                                        className="item"
                                        style={{
                                            width: "calc(90% / 6)",
                                        }}
                                    >
                                        <div className="list-item-btn">
                                            <div
                                                className={`item-btn ${disableBtn ? "disable" : ""}`}
                                                onClick={() => {
                                                    if (!disableBtn) history.push(`/create-artwork/${item.id}`);
                                                }}
                                            >
                                                <div className="icon">
                                                    <EditIcon />
                                                </div>
                                                <span>Chỉnh sửa</span>
                                            </div>
                                            <div
                                                className={`item-btn ${item.id > 0 && item.suggestedVariantId > 0 ? "" : "disable"}`}
                                                onClick={(e) => {
                                                    window.open(`${Request.HOME_URL}/product-detail-page/${item.id}/${item.suggestedVariantId}`, "_blank");
                                                }}
                                            >
                                                <div className="icon">
                                                    <PreviewIcon />
                                                </div>
                                                <span>Xem trước</span>
                                            </div>
                                            <div
                                                className={`item-btn ${disableBtn ? "disable" : ""}`}
                                                onClick={() => {
                                                    if (!disableBtn) handleConfigOffline(item);
                                                }}
                                            >
                                                <div className="icon">
                                                    <HideIcon />
                                                </div>
                                                <span>Ẩn sản phẩm</span>
                                            </div>

                                            <div
                                                className="item-btn"
                                                onClick={(e) => {
                                                    handleDelete(item);
                                                }}
                                            >
                                                <div className="icon">
                                                    <RemoveIcon />
                                                </div>
                                                <span>Xóa sản phẩm</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="text-center no-data">Không có dữ liệu</div>
                    )}

                    <TableFooter itemToDisplay={2} onFilter={(paging) => getDatas({ ...paging }, tabIndex)} paging={paging} />
                </div>
            </Wrapper>
        </React.Fragment>
    );
};

export default ManageArtwork;

const Wrapper = styled.div`
    width: 90%;

    .list-thumbnail {
        height: 100%;
        width: 100%;
        padding: 10px;
    }

    .no-data {
        background: white;
        margin: 12px 0;
        padding: 12px 24px;
        border-radius: 12px;
        font-weight: 500;
        font-family: Lexend;
    }

    .list-item-btn {
        width: 100%;
        height: 100%;
    }

    .item-btn {
        display: flex;
        align-items: center;
        margin: 6px 0;
        cursor: pointer;
    }

    .item-btn span {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 6px 0;
        color: #13aaff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        margin-left: 4px;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .item-btn span .icon {
        margin: 0 12px;
    }

    span.status {
        color: #28b259;
        text-align: center;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 20px;
        background: #ecf8f0;
        padding: 10px 12px;
    }

    .list-thumbnail {
        display: flex;
    }

    .thumbnail {
        width: 50%;
        height: 100%;
        display: flex;
    }

    .thumbnail img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .table-artwork-body .list-item .item {
        height: 100%;
        border-right: 1px solid #f1f1f1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .table-artwork-body .list-item .item:last-child {
        border: none;
    }
    .table-artwork-body .list-item {
        padding: 0px 16px;
        background: white;
        margin: 12px 0;
        border-radius: 12px;
        height: 170px;
    }
    .list-item {
        display: flex;
        align-items: center;
    }

    .item {
        text-align: center;
        color: #303030;
        text-align: center;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .header-overview {
        display: flex;
        align-items: center;
    }

    .overview-right {
        margin-left: auto;
        display: flex;
    }

    .search-area {
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #e5e5e5;
        background: #fff;
        padding: 12px 16px 12px 20px;
        margin-right: 12px;
    }

    .search-area input[type="text"] {
        border: none;
        width: 100%;
    }

    .overview-right {
        flex: 1;
    }

    .search-area {
        width: 100%;
    }

    .status-options {
        width: 200px;
        display: flex;
        align-items: center;
    }

    .status-options > div {
        width: 100%;
    }

    .react-select__control {
        border-radius: 8px;
        border: 1px solid #e5e5e5;
        background: #f3f3f3;
        padding: 11px 13px;
    }

    hr {
        border: 1px solid #f3f3f3 !important;
        width: 100%;
    }

    .overview-left {
        display: flex;
        width: 60%;
        flex-wrap: wrap;
    }

    .overview-area {
        border-radius: 10px;
        background: #f8f8f8;
        padding: 6px 16px;
        margin: 6px;
    }

    .overview-area label {
        color: #a0a0a0;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .overview-value {
        color: #303030;
        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .header-top {
        display: flex;
        flex: 1;
    }

    .btn-add-new {
        border-radius: 40px;
        background: #ff9027;
        display: flex;
        align-items: center;
        color: #fff;
        text-align: center;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 13px 18px;
        align-items: center;
        cursor: pointer;
    }

    .icon {
        display: flex;
        margin-left: 14px;
    }

    .add-new {
        margin-left: auto;
    }

    .header-page {
        display: flex;
        border-radius: 12px;
        border: 1px solid #f3f3f3;
        background: #fff;
        padding: 10px 16px;
        flex-direction: column;
    }

    .box__header {
        display: flex;
        align-items: center;
    }

    .btn-tab {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 6px;
        cursor: pointer;
        padding: 12px 18px;
    }

    .btn-tab.active {
        border-radius: 8px;
        background: #fff5ec;
        color: #ff9027;
        text-align: center;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`;

const AddNewIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
            <path d="M7.5 1V13" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.5 7L1.5 7" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const SearchIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
            <path
                d="M8.67414 16.1886C12.9125 16.1886 16.3483 12.7885 16.3483 8.5943C16.3483 4.40008 12.9125 1 8.67414 1C4.43583 1 1 4.40008 1 8.5943C1 12.7885 4.43583 16.1886 8.67414 16.1886Z"
                stroke="#303030"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M14.312 14.1736L18.0861 17.9084" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const EditIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 19H19" stroke="#13AAFF" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M14.5 2.49998C14.8978 2.10216 15.4374 1.87866 16 1.87866C16.2786 1.87866 16.5544 1.93353 16.8118 2.04014C17.0692 2.14674 17.303 2.303 17.5 2.49998C17.697 2.69697 17.8532 2.93082 17.9598 3.18819C18.0665 3.44556 18.1213 3.72141 18.1213 3.99998C18.1213 4.27856 18.0665 4.55441 17.9598 4.81178C17.8532 5.06915 17.697 5.303 17.5 5.49998L5 18L1 19L2 15L14.5 2.49998Z"
                stroke="#13AAFF"
                stroke-width="1.8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

const PreviewIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 12C2 12 5.63636 5 12 5C18.3636 5 22 12 22 12C22 12 18.3636 19 12 19C5.63636 19 2 12 2 12Z" stroke="#13AAFF" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#13AAFF" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const HideIcon = () => {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.6 15.4C7.15401 16.5846 9.04627 17.2408 11 17.2728C17.3636 17.2728 21 10 21 10C19.8692 7.89268 18.3008 6.05151 16.4 4.60005M12.9091 2.9455C12.2833 2.79903 11.6427 2.72581 11 2.72732C4.63636 2.72732 0.999999 10 0.999999 10C1.55184 11.0324 2.20995 12.0043 2.96364 12.9M9.07273 11.9273C9.32241 12.1953 9.6235 12.4102 9.95804 12.5592C10.2926 12.7083 10.6537 12.7885 11.0199 12.7949C11.3861 12.8014 11.7499 12.734 12.0894 12.5969C12.429 12.4597 12.7375 12.2555 12.9965 11.9965C13.2555 11.7376 13.4596 11.4291 13.5968 11.0895C13.734 10.7499 13.8013 10.3862 13.7949 10.02C13.7884 9.65377 13.7083 9.29263 13.5592 8.95809C13.4101 8.62355 13.1952 8.32245 12.9273 8.07277"
                stroke="#13AAFF"
                stroke-width="1.8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M19.1821 1.81836L2.81849 18.182" stroke="#13AAFF" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const RemoveIcon = () => {
    return (
        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.2209 20H5.69325C4.4908 20 3.54601 19.1515 3.37423 17.9636L2 6H16L14.5399 17.9636C14.3681 19.1515 13.4233 20 12.2209 20Z"
                stroke="#13AAFF"
                stroke-width="1.8"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M1 3.5H17" stroke="#13AAFF" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" />
            <path d="M6.41699 1H11.7504" stroke="#13AAFF" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" />
            <path d="M7 9.6665L7.36356 15.3333" stroke="#13AAFF" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.9997 9.6665L10.5635 15.3333" stroke="#13AAFF" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
