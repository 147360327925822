import React, { useEffect, useContext, useRef } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { ApplicationContext } from "../../context/ApplicationContext";
import { INITPAGING } from "../../based/Constants";
// Redux
import { clearImages, selectMockupImage, fetchMockupImages, fetchArtworks, closeMockupImageModal } from "../../redux/project-editor/actions";
import { selectImageMockupLoading, selectImageMockupModal, selectMockupModal } from "../../redux/project-editor/selector";
import BaseModal from "../../based/refactor/BaseModal";

const ImageMockupModal = ({ isLoading, clearImages, mockupModal, imageMockupModal, selectMockupImage, fetchMockupImages, fetchArtworks, closeMockupImageModal }) => {
    const { isShow, hasMore, pickedImageId, paging, items } = imageMockupModal;
    const { mainImage } = mockupModal;

    const observer = useRef(null);
    const appCtx = useContext(ApplicationContext);

    useEffect(() => {
        if (isShow && hasMore && !pickedImageId) {
            getArtworksHandler(paging);
        }
    }, [isShow, pickedImageId]);

    useEffect(() => {
        if (isShow && pickedImageId) getMockupImagesHandler({ ...INITPAGING }, mainImage.objectId);
    }, [isShow, mainImage.objectId]);

    const getArtworksHandler = (paging) => {
        fetchArtworks(paging);
    };

    const getMockupImagesHandler = (paging, artworkId) => {
        if (!artworkId) return;
        fetchMockupImages(paging, artworkId);
    };

    const setLastImageHandler = (element, idx) => {
        if (isLoading || idx !== items.length - 1 || !hasMore) return;

        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                const newPaging = {
                    ...paging,
                    pageNumber: paging.pageNumber + 1,
                };

                if (pickedImageId) getMockupImagesHandler(newPaging);
                else getArtworksHandler(newPaging);
            }
        });

        if (element) observer.current.observe(element);
    };

    const closeOpenMockupModalHandler = () => {
        closeMockupImageModal();
    };

    const selectImageHandler = (imgUrl, objectId) => {
        selectMockupImage(imgUrl, objectId);
        clearImages();
    };

    const renderSpinnerHandler = () => {
        return (
            <div className="col-12 d-flex align-items-center justify-content-center">
                <div className="spinner-border text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    };

    const renderImagesHandler = () => {
        return (
            <React.Fragment>
                {items.map((item, idx) => {
                    let id = item.id;

                    // If Existed ObjectId ? Image : Artwork
                    if (item.objectId) id = item.objectId;

                    return (
                        <div
                            onClick={() => selectImageHandler(item.url, id)}
                            ref={(el) => setLastImageHandler(el, idx)}
                            key={`${item.id}-${idx}`}
                            className={`col-lg-3 col-md-4 col-sm-4 col-12 border cursor-pointer ${items.length - 1 === idx ? "align-self-start" : ""}`}
                        >
                            <img className="w-100" alt="image" src={item.url} />
                        </div>
                    );
                })}
                {isLoading && renderSpinnerHandler()}
            </React.Fragment>
        );
    };

    return (
        <BaseModal title="Danh sách sản phẩm" isShow={isShow} onClose={closeOpenMockupModalHandler}>
            <div className="row justify-content-center p-5 ">{renderImagesHandler()}</div>
        </BaseModal>
    );
};

const mapStateToProps = createStructuredSelector({
    isLoading: selectImageMockupLoading,
    imageMockupModal: selectImageMockupModal,
    mockupModal: selectMockupModal,
});

const mapDispatchToProps = (dispatch) => ({
    clearImages: () => {
        return dispatch(clearImages());
    },
    selectMockupImage: (imgUrl, objectId) => {
        return dispatch(selectMockupImage(imgUrl, objectId));
    },
    closeMockupImageModal: () => {
        return dispatch(closeMockupImageModal());
    },
    fetchMockupImages: (paging, artworkId) => {
        return dispatch(fetchMockupImages(paging, artworkId));
    },
    fetchArtworks: (paging) => {
        return dispatch(fetchArtworks(paging));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageMockupModal);
