import React, { useState, useEffect, useRef } from "react";
import { createStructuredSelector } from "reselect";
// import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { PROJECT_EDITOR_ITEM_TYPE as ITEM_TYPE, PROJECT_EDITOR_MODAL_TYPE as MODAL_TYPE } from "../based/Constants";
import ProjectImage from "./components/ProjectImage";
import ProjectText from "./components/ProjectText";
import ProjectEmbed from "./components/ProjectEmbeb";
import ProjectMockup from "./components/ProjectMockup";
import ProjectModal from "./components/ProjectModal";
import EmbedModal from "./components/EmbedModal";
import InsertBar from "./components/InsertBar";
import ImagePicker from "./components/ImagePicker";

// Redux
import { addImage, addText, openEmbedModal, openMockupModal, fetchProject, clearProject } from "../redux/project-editor/actions";
import { selectItems, selectProjectModel } from "../redux/project-editor/selector";

import ProjectMenu from "./components/ProjectMenu";
import MockupModal from "./components/MockupModal";
import LayoutLight from "../layout/refactor/Layout/Layout";
import Breadcum from "../layout/refactor/Breadcum/Breadcum";
import { useHistory } from "react-router-dom";

const ProjectEditor = ({ match, items, project, addImage, addText, openEmbedModal, openMockupModal, fetchProject, clearProject }) => {
    const imgRef = useRef(null);
    const contentRef = useRef(null);
    const history = useHistory();
    const [isShowProjectModal, setIsShowProjectModal] = useState(false);

    useEffect(() => {
        const projectId = match.params.id;
        if (projectId) fetchProject(projectId);
        else {
            clearProject();
        }
    }, [match.params.id]);
    const pickImageHandler = () => {
        if (imgRef.current) imgRef.current.click();
    };

    const addImageHandler = (imgFile) => {
        addImage(imgFile);
    };

    const addTextHandler = () => {
        addText();
    };

    const openEmbedModalHandler = () => {
        openEmbedModal({ itemId: "", type: MODAL_TYPE.ADD });
    };

    const openMockupModalHandler = () => {
        openMockupModal({ itemId: "", type: MODAL_TYPE.ADD });
    };

    const openProjectModalHandler = () => {
        if (items.length !== 0) setIsShowProjectModal(true);
    };

    const saveProjectModalHandler = () => {
        setIsShowProjectModal(false);
        history.push("/projects");
    };

    const closeProjectModalHandler = () => {
        setIsShowProjectModal(false);
    };
    const renderProjectImageHandler = ({ id, data }, index) => {
        const { url, isFullWidth } = data;
        const isLastItem = items.length - 1 === index;

        let contentWidth = "";
        if (contentRef.current) {
            const contentRect = contentRef.current.getBoundingClientRect();
            contentWidth = contentRect.width;
        }

        return (
            <React.Fragment key={id}>
                <InsertBar itemId={id} />
                <div className='project-editor__item'>
                    <ProjectImage id={id} url={url} isFullWidth={isFullWidth} isLast={isLastItem} contentWidth={contentWidth} />
                </div>
            </React.Fragment>
        );
    };

    const renderProjectTextHandler = ({ id, data }, index) => {
        const { value, align } = data;
        const isLastItem = items.length - 1 === index;

        return (
            <React.Fragment key={id}>
                <InsertBar itemId={id} />
                <div className={`project-editor__item`}>
                    <ProjectText id={id} value={value} align={align} isLast={isLastItem} />
                </div>
            </React.Fragment>
        );
    };

    const renderProjectEmbedHandler = ({ id, data }, index) => {
        const { content, isFullWidth } = data;
        const isLastItem = items.length - 1 === index;
        return (
            <React.Fragment key={id}>
                <InsertBar itemId={id} />
                <div className={`project-editor__item`}>
                    <ProjectEmbed id={id} content={content} isFullWidth={isFullWidth} isLast={isLastItem} />
                </div>
            </React.Fragment>
        );
    };

    const renderProjectMockupHandler = ({ id, data }, index) => {
        const { isFullWidth, mainImage, subImages } = data;
        const isLastItem = items.length - 1 === index;

        return (
            <React.Fragment key={id}>
                <InsertBar itemId={id} />
                <div className={`project-editor__item`}>
                    <ProjectMockup id={id} isLast={isLastItem} isFullWidth={isFullWidth} mainImage={mainImage} subImages={subImages} />
                </div>
            </React.Fragment>
        );
    };

    const renderEmptyProjectHandler = () => {
        return (
            <h3 className='project-editor_empty text-muted p-5'>
                <p className='mb-4 text-center'>Bắt đầu tạo bộ sưu tập.</p>
                <div className='d-flex align-items-center justify-content-center'>
                    <i className='fa fa-picture-o project-editor__empty-item rounded-circle cursor-pointer mx-2' onClick={pickImageHandler} />
                    <i className='fa fa-font project-editor__empty-item rounded-circle cursor-pointer mx-2' onClick={addTextHandler} />
                    <i className='fa fa-code project-editor__empty-item rounded-circle cursor-pointer mx-2' onClick={openEmbedModalHandler} />
                    <i className='fa fa-window-maximize project-editor__empty-item rounded-circle cursor-pointer mx-2' onClick={openMockupModalHandler} />
                </div>
            </h3>
        );
    };
    const renderProjectEditorItemsHandler = () => {
        if (items.length <= 0) return renderEmptyProjectHandler();
        else
            return items.map((item, idx) => {
                switch (item.type) {
                    case ITEM_TYPE.IMAGE:
                        return renderProjectImageHandler(item, idx);
                    case ITEM_TYPE.TEXT:
                        return renderProjectTextHandler(item, idx);
                    case ITEM_TYPE.EMBED:
                        return renderProjectEmbedHandler(item, idx);
                    case ITEM_TYPE.MOCKUP:
                        return renderProjectMockupHandler(item, idx);
                    default:
                        return;
                }
            });
    };
    return (
        <React.Fragment>
            <Breadcum menu={["Trang Chủ", "Quản lý bộ sưu tập"]} title='Quản lý bộ sưu tập' />
            {/* Embed Modal */}
            <EmbedModal />

            {/* Mockup Modal */}
            <MockupModal />

            {/* Project Modal */}
            <ProjectModal isShow={isShowProjectModal} onSave={saveProjectModalHandler} onClose={closeProjectModalHandler} />
            <div className='row w-100 pl-5'>
                {/* Content */}
                <div className='project-editor__content w-80 px-0 bg-white shadow' ref={contentRef}>
                    {/* Items */}
                    {renderProjectEditorItemsHandler()}
                </div>
            </div>
            {/* Menu */}
            <ProjectMenu items={items} onAddText={addTextHandler} onPickImage={pickImageHandler} onOpenEmbedModal={openEmbedModalHandler} onOpenMockupModal={openMockupModalHandler} onOpenProjectModal={openProjectModalHandler} project={project} />

            {/* Image Picker */}
            <ImagePicker imgRef={imgRef} onChange={addImageHandler} disablePreview />
        </React.Fragment>
    );
};

const mapStateToProps = createStructuredSelector({
    items: selectItems,
    project: selectProjectModel,
});

const mapDispatchToProps = (dispatch) => ({
    addImage: (imgFile) => {
        return dispatch(addImage(imgFile));
    },
    addText: () => {
        return dispatch(addText());
    },
    openEmbedModal: ({ itemId, type }) => {
        return dispatch(openEmbedModal({ itemId, type }));
    },
    openMockupModal: ({ itemId, type }) => {
        return dispatch(openMockupModal({ itemId, type }));
    },
    fetchProject: (projectId) => {
        return dispatch(fetchProject(projectId));
    },
    clearProject: () => {
        return dispatch(clearProject());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEditor);
