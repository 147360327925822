import React, { useState } from "react";
import styled from "styled-components";
import { CONSTANTS, NOTIFY } from "../based/Constants";
import Textbox from "../based/inputs/Textbox";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import useFormValidate from "../hooks/useFormValidate";
import BackgroundLogin from "./assets/BackgroundLogin.png";
import RanusCreatorLogo from "./assets/RanusCreatorLogo.png";
export default function ForgetPassword(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const { addRef, displayError, isValid } = useFormValidate();
    const [email, setEmail] = useState("");
    const [isShowFlashNote, setIsShowFlashNote] = useState(false);

    function _handleForgetPass(e) {
        e.preventDefault();
        if (isValid()) {
            setIsBusy(true);
            setMsg("Đang gửi dữ liệu...");
            Request.Post("/api/accounts/forgot-password", { email })
                .then((res) => {
                    if (res.success) {
                        setIsBusy(false);
                        setMsg(null);
                        setIsShowFlashNote(true);
                    } else {
                        Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                        setIsBusy(false);
                        setMsg(null);
                    }
                })
                .catch((err) => {
                    setIsBusy(false);
                    setMsg(null);
                    if (err.errors) displayError(err.errors);
                });
        }
    }

    return (
        <Wrapper>
            <section className="creator-background-account">
                <div className="account-content-body">
                    <div className="content-body">
                        <div className="body-left">
                            <img className="background-body" atl="lotus" src={BackgroundLogin} />
                            <div className="header-creator cursor-pointer">
                                <img
                                    alt="ranus"
                                    src={RanusCreatorLogo}
                                    onClick={() => {
                                        window.location.href = "/";
                                    }}
                                />
                            </div>
                        </div>
                        <div className="body-right">
                            <div className="account-content">
                                <div className="col-right">
                                    <div className="main-account">
                                        <h4 className="login-header">Quên mật khẩu</h4>
                                        <div className="form-group-section position-relative">
                                            {isShowFlashNote ? (
                                                <div className="form-input-action">
                                                    <div className="col-12">
                                                        <div className="alert alert-icon text-info alert-dismissible flash-notice">
                                                            <button type="button" className="close" onClick={() => setIsShowFlashNote(false)}>
                                                                <span>×</span>
                                                            </button>
                                                            <i className="mdi mdi-information mr-2"></i>
                                                            Nếu địa chỉ email của bạn tồn tại trong hệ thống của chúng tôi, bạn sẽ nhận được liên kết khôi phục mật khẩu tại địa chỉ email của bạn sau vài phút!
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className="form-input-action">
                                                <div className="col-12">
                                                    <label htmlFor="email" className="required">
                                                        Nhập email đăng ký để thực hiện
                                                    </label>
                                                    <Textbox id="email" name="email" value={email} required email ref={addRef} maxLength={255} className="form-control" onChanged={(name, value) => setEmail(value)} placeholder="Nhập email" />
                                                </div>
                                            </div>
                                            <div className="form-input-action">
                                                <div className="btn-action">
                                                    <a className="btn-reset" href="#" onClick={(e) => _handleForgetPass(e)}>
                                                        Đặt lại mật khẩu
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row pt-2">
                                            <div className="col-sm-12 text-center">
                                                <p className="text-muted mb-0">
                                                    Bạn đã có tài khoản?{" "}
                                                    <a
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            props.history.push("/login");
                                                        }}
                                                        className="text-dark ml-1"
                                                    >
                                                        <b>Đăng nhập</b>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    .content-body {
        display: flex;
    }

    .body-left {
        width: 50%;
        height: 100vh;
        position: relative;
        background: #fff5ec;
    }

    img.background-body {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .header-creator.cursor-pointer {
        position: absolute;
        z-index: 1;
        top: 61px;
        left: 55px;
    }

    .body-right {
        flex: 1;
        background: white;
        position: relative;
    }

    .main-account {
        width: 450px;
        display: flex;
        flex-direction: column;
        height: auto;
        position: absolute;
        top: calc(40% - 225px);
        margin-left: 80px;
    }

    h4.login-header {
        color: #303030;
        font-family: Lexend;
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
    }

    .form-group {
        margin-bottom: 24px;
        position: relative;
    }

    .form-group input {
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
        color: #303030 !important;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        padding: 11px 16px;
        height: auto;
        position: relative;
    }

    .login-input {
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
        width: 100%;
        padding: 11px 16px;
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .form-group-login {
        margin: 12px 0;
    }

    .icon {
        display: flex;
        position: absolute;
        top: 11px;
        right: 16px;
        width: 24px;
        height: 24px;
        align-items: center;
    }

    .form-area {
        position: relative;
    }

    button.btn-ranus {
        border-radius: 12px;
        background: var(--Cam-Thng-hiu, #ff9027);
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        border: none;
        padding: 12px;
    }

    .login-section {
        margin: 12px 0;
    }

    button.btn-forgot {
        border: none;
        background: none;
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 12px;
    }

    span.register-section {
        text-align: center;
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 12px 0;
    }

    button.btn-ranus-register {
        border: none;
        background: none;
        color: var(--Cam-Thng-hiu, #ff9027);
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 8px;
    }

    h4.login-header {
        padding: 0 10px;
    }

    .form-input-action input#email {
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
        padding: 11px 16px;
        height: auto;
    }

    .form-input-action {
        margin: 12px 0;
    }

    .btn-action {
        margin: 0 10px;
        border-radius: 12px;
        background: #ff9027;
        color: #fff !important;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        padding: 12px;
    }

    a.btn-reset {
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    p.text-muted {
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .p.text-muted a.text-dark {
        color: var(--Cam-Thng-hiu, #ff9027);
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .p.text-muted a {
    }

    p.text-muted b {
        color: #ff9027;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .form-input-action label.required {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;
