import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BreadcumWrapper, Title } from "./bc.elm";

export default function Breadcum(props) {
    return (
        <BreadcumWrapper>
            <Wrapper>
                <div className="breadcum">
                    {props.title ? (
                        <React.Fragment>
                            <span className="breadcum-item">
                                <Link to={"/"} style={{ color: "#797979" }}>
                                    &nbsp;Trang chủ&nbsp;
                                </Link>
                                <div className="icon">
                                    <GoRight />
                                </div>
                            </span>
                            <span className="breadcum-item">
                                <Link to={props.hrefParent ? props.hrefParent : "#"} style={{ color: "#797979" }}>
                                    &nbsp;{props.title}&nbsp;
                                </Link>
                            </span>
                        </React.Fragment>
                    ) : (
                        <span className="breadcum-item">
                            <Link to={"/"} style={{ color: "#797979" }}>
                                &nbsp;Trang chủ&nbsp;
                            </Link>
                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                        </span>
                    )}
                </div>
                <Title>{props.title ? <h4>{props.title}</h4> : ""}</Title>
            </Wrapper>
        </BreadcumWrapper>
    );
}

const GoRight = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
            <path d="M1 1L5 5L1 9" stroke="#A5A5A5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const Wrapper = styled.div`
    .icon {
        display: flex;
        margin: 0 5px;
    }

    span.breadcum-item {
        display: flex;
        align-items: center;
    }
`;
