export class AffiliateSiteModel {
    constructor() {
        this.id = 0;
        this.hashCode = "";
        this.siteUrl = "";
        this.thirdPartyId = 0;
        this.createdDate = new Date();
        this.expiredDays = 0;
        this.note = "";
        this.layout = 0;
        this.keyword = "";
    }
}
