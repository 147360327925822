import { actionTypes } from '../types';

// Embed Modal
export const openEmbedModal = ({ itemId, type }) => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_OPEN_EMBED_MODAL,
      payload: { itemId, type },
    };
    dispatch(action);
  };
};

export const closeEmbedModal = () => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_CLOSE_EMBED_MODAL,
      payload: null,
    };
    dispatch(action);
  };
};

// Embed Item
export const addEmbed = (content) => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_ADD_EMBED,
      payload: content,
    };
    dispatch(action);
  };
};

export const removeEmbed = (itemId) => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_REMOVE_EMBED,
      payload: itemId,
    };
    dispatch(action);
  };
};

export const editEmbed = (newContent) => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_EDIT_EMBED,
      payload: newContent,
    };
    dispatch(action);
  };
};

export const insertEmbed = (newContent) => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_INSERT_EMBED,
      payload: newContent,
    };
    dispatch(action);
  };
};
