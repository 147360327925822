import React, { useEffect, useState } from "react";
import OrderCommissionContext from "../affiliate/affiliate-commission/OrderCommissionContext";
import { Box } from "../based/content/ct.elm";
import LoadingR from "../based/LoadingR";
import Breadcum from "../layout/refactor/Breadcum/Breadcum";
import CompletedOrders from "./components/CompletedOrders";
import InCompletedOrders from "./components/InCompletedOrders";
import Payments from "./components/Payments";
const INIT_ORDER_COUNT = {
    completedOrderCount: 0,
    inCompletedOrderCount: 0
}
const TAB_INDEX = {
    COMPLETE_ORDER: 1,
    IN_COMPLETE_ORDER: 2,
    PAYMENT: 3
}
const menu = ['Trang chủ', 'Thông kê và thanh toán']
function FinanceCreatorOverview(props) {
    const [busy, setBusy] = useState(false)
    const [orderCount, setOrderCount] = useState(INIT_ORDER_COUNT);
    const [tabIndex, setTabIndex] = useState(TAB_INDEX.COMPLETE_ORDER);
    useEffect(() => {
        _fetchData();
    }, [])

    async function _fetchData() {

    }

    function _handleChangeTab(idx) {
        setTabIndex(idx);
    }

    function _renderTabContent() {
        switch (tabIndex) {
            case TAB_INDEX.COMPLETE_ORDER:
                return (
                    <CompletedOrders />
                );
            case TAB_INDEX.IN_COMPLETE_ORDER:
                return (
                    <InCompletedOrders />
                );
            case TAB_INDEX.PAYMENT:
                return (
                    <Payments />
                );
        }
    }

    return (
        <OrderCommissionContext.Provider value={{ orderCount: orderCount, setOrderCount: setOrderCount }}>
            <Breadcum menu={menu} title='Thông kê và thanh toán' />
            <LoadingR fullScreen isShow={busy} />
            <Box>
                <ul className='nav nav-tabs tabs-bordered' style={{ alignSelf: 'flex-start' }}>
                    <li className='nav-item'>
                        <a className={tabIndex == TAB_INDEX.COMPLETE_ORDER ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangeTab(TAB_INDEX.COMPLETE_ORDER)}>
                            <span>Hoàn thành ({orderCount.completedOrderCount})</span>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a className={tabIndex == TAB_INDEX.IN_COMPLETE_ORDER ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangeTab(TAB_INDEX.IN_COMPLETE_ORDER)}>
                            <span>Chưa hoàn thành ({orderCount.inCompletedOrderCount})</span>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a className={tabIndex == TAB_INDEX.PAYMENT ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangeTab(TAB_INDEX.PAYMENT)}>
                            <span>Thanh toán</span>
                        </a>
                    </li>
                </ul>
            </Box>
            {
                _renderTabContent()
            }
        </OrderCommissionContext.Provider>
    );
}
export default FinanceCreatorOverview;