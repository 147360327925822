import React, { useEffect, useState } from 'react';
import Common from '../based/Common';
import CommonTable from '../based/CommonTable';
import { INITPAGING } from '../based/Constants';
import Loading from '../based/Loading';
import Textbox from "../based/inputs/Textbox";
import TFUDatetime from "../based/inputs/TFUDatetime";

function FeedManagement() {
    const [isBusy, setIsBusy] = useState(false)
    const [data, setData] = useState([])
    const [paging, setPaging] = useState(INITPAGING)
    useEffect(() => {
        _fetchData();
    }, [])

    const _fetchData = async () => {
        setIsBusy(true);
        let [err, data] = [];
        setIsBusy(false);

    }



    const _renderHeaderTable = () => {
        return (
            <thead>
                <tr>
                    <th>Feed ID</th>
                    <th>Sản phẩm</th>
                    <th>Voucher</th>
                    <th>Hashtag</th>
                    <th>Ngày đăng</th>
                </tr>
            </thead>
        )
    }
    const _renderBodyTable = () => {
        return (
            <tbody>
                {
                    data ? data.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td>{item.feedId}</td>
                                <td>{item.productList}</td>
                                <td>{item.voucherList}</td>
                                <td>{item.hashtagList}</td>
                                <td>{item.publishDate}</td>
                            </tr>
                        )
                    })
                        :
                        <tr>
                            <td colSpan={10}>Không có dữ liệu</td>
                        </tr>
                }
            </tbody>
        )
    }
    const _renderAction = () => {
        return (
            <div className='box__actions'>
                <div className='form-group full-width'>
                    <div className='box__search form-row'>
                        <div className='form-group col-md-3'>
                            <label htmlFor='code'>Sản phẩm:</label>
                            <Textbox className='form-control' id='code' name='code' onChanged={(name, value) => setPaging({ ...paging, code: value })} value={paging.code} />
                        </div>
                        <div className='form-group col-md-3'>
                            <label htmlFor='code'>Mã Voucher:</label>
                            <Textbox className='form-control' id='code' name='code' onChanged={(name, value) => setPaging({ ...paging, code: value })} value={paging.code} />
                        </div>
                        <div className='form-group col-md-3'>
                            <label htmlFor='name'>Hashtag:</label>
                            <Textbox className='form-control' name='name' onChanged={(name, value) => setPaging({ ...paging, name: value })} value={paging.name} />
                        </div>
                        <div className='form-group col-md-3'>
                            <label htmlFor='time'>Thời gian áp dụng:</label>
                            <div className='input-group form-row'>
                                <div className='input-daterange input-group' id='date-range'>
                                    <TFUDatetime dateFormat='dd/MM/yyyy' name='startDate' maxDate={paging.endDate} value={paging.startDate} placeholder='Từ ngày' onChanged={(newValue) => setPaging({ ...paging, startDate: newValue })} />
                                    <TFUDatetime dateFormat='dd/MM/yyyy' name='endDate' minDate={paging.startDate} value={paging.endDate} placeholder='Đến ngày' onChanged={(newValue) => setPaging({ ...paging, endDate: newValue })} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-12'>
                            <button className='btn btn-custom btn-sm m-r-5' >
                                <i className='fa fa-search m-r-5' />
                                Tìm kiếm
                            </button>
                            {Common.IsCreator() && (
                                <React.Fragment>
                                    <button className='btn btn-custom btn-sm m-r-5' >
                                        <i className='fa fa-plus m-r-5' /> Thêm mới
                                    </button>
                                    <button className="btn btn-custom btn-sm m-r-5 disable">
                                        <i className='fa fa-edit m-r-5' /> Sửa
                                    </button>
                                    <button className="btn btn-danger btn-sm m-r-5 disable" >
                                        <i className='fa fa-remove m-r-5' /> Xóa
                                    </button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="table-responsive h-100vh">
                <Loading show={isBusy} msg='Đang tải dữ liệu' />
                <CommonTable
                    paging={paging}
                    onFilter={_fetchData}
                    renderHeader={_renderHeaderTable}
                    renderBody={_renderBodyTable}
                    renderAction={_renderAction}
                    searchBasic
                    tableGuid={Common.generateGuid()}
                    searchBasic={false}
                />
            </div>
        </React.Fragment>
    );
}

export default FeedManagement;