import React, { useContext, useEffect, useMemo, useState } from "react";
import TagsInput from "react-tagsinput";
import styled from "styled-components";
import Common from "../../based/Common";
import { ARTWORK_TYPE, CONSTANTS, IMAGE_TYPE, INITPAGING, NOTIFY, PRINT_TYPE_NAME } from "../../based/Constants";
import TextEditor from "../../based/inputs/TextEditor";
import { Notify } from "../../based/Notify";
import TextArea from "../../based/refactor/TextArea";
import TFUSelect from "../../based/refactor/TFUSelect";
import { CreateArtworkContext, PRINT_SIDE } from "../context/CreateArtworkContext";
import { DesignType } from "./DesignType";
import ProductVer2Modal from "./ProductVer2Modal";
import { ArtworkModel, MockupDataModel } from "../model/PreAddArtworkModel";
import ArtworkServices from "../../based/services/ArtworkServices";
import Request from "../../based/Request";
import { Loading } from "./Loading";
import VerticalPositionScoll from "../../based/VerticalPositionScoll";
import MockupOppositeModalVer2 from "./MockupOppositeModalVer2";
import TopicServices from "../../based/services/TopicServices";
import PrintServices from "../../based/services/PrintServices";
import BrandServices from "../../based/services/BrandServices";
const MockupDTO = {
    artworkType: 0, //mặt trước/mặt sau
    imageType: 0, //loại ảnh
    mockup: new MockupDataModel(), //thông tin ảnh và kích thước
};

const LoyaltyMethodOptions = [
    {
        value: 1,
        text: "Tính giá bán theo tiền bản quyền",
        label: "Tính giá bán theo tiền bản quyền",
    },
    {
        value: 2,
        text: "Tính giá bán theo tiền bản quyền",
        label: "Tính giá bán theo tiền bản quyền",
    },
];

function DetailVer2({ onBack, onSave }) {
    const { frame, setFrame, detail, setDetail, configModel, step, setStep, setModel, setSetModel, addRef, setArtworkModels, printSide, artworkModels } = useContext(CreateArtworkContext);
    const [topTopics, setTopTopics] = useState([]);
    const [tagTrending, setTagTrending] = useState([]);
    const [designType, setDesignType] = useState(false);
    const [showProductType, setShowProductType] = useState(false);
    const isReadOnly = setModel.id > 0;
    const [productColorCode, setProductColorCode] = useState([]);
    const [mockupData, setMockupData] = useState([MockupDTO]);
    const [loadingIndex, setLoadingIndex] = useState([]);
    const [showOppositeModal, setShowOppositeModal] = useState(false);
    const [loyaltyMethodOptions, setLoyaltyMethodOptions] = useState(LoyaltyMethodOptions);
    const [readRateCard, setReadRateCard] = useState([]);

    console.log("mockupData", mockupData);

    useEffect(() => {
        getTopTopic();
        getTagTrending();
        getReadRateCard();
        getBrand();
    }, []);

    useEffect(() => {
        if (!isReadOnly) handlePrintSide(printSide);
    }, [printSide, frame, detail]);

    useEffect(() => {
        if (setModel && setModel.appSetId > 0 && detail) getAppProductColorCode(setModel.appSetId, setModel.id);
    }, [setModel.appSetId, setModel.id, detail]);

    useEffect(() => {
        if (readRateCard && readRateCard.length > 0) {
            handleAmount(setModel.loyaltyAmount);
        }
    }, [readRateCard]);

    const ListPrintTypeName = useMemo(() => {
        let listPrintTypeName = [];
        if (configModel && configModel.listPrintType) {
            configModel.listPrintType.map((item) => {
                listPrintTypeName.push(PRINT_TYPE_NAME[item]);
            });
        }
        return listPrintTypeName;
    }, [configModel]);

    //màu sắc hiển thị
    const listProductColor = useMemo(() => {
        let lst = [];
        if (productColorCode && productColorCode.length > 0) {
            for (var element of productColorCode) {
                const lstAppProductCodes = element.appProductCodes;
                if (lstAppProductCodes && lstAppProductCodes.length > 0) {
                    for (var item of lstAppProductCodes) {
                        var any = lst.some((x) => x.colorCode == item.colorCode);
                        if (!any)
                            lst.push({
                                colorCode: item.colorCode,
                                colorName: item.colorName,
                            });
                    }
                }
            }
        }
        return lst;
    }, [productColorCode]);

    //dòng áo hiển thị
    const listProduct = useMemo(() => {
        let lst = getListProduct(productColorCode, setModel.colorCode);
        return lst;
    }, [productColorCode, setModel.colorCode]);

    const isShowOpposite = useMemo(() => {
        let isShow = false;
        if (artworkModels && artworkModels.length > 0) {
            let allThumbnail = artworkModels.every((x) => x.thumbnail);
            if (allThumbnail) isShow = false;
            else isShow = true;
        }
        return isShow;
    }, [artworkModels]);

    function getListProduct(listProductCode, colorCode) {
        let lst = [];
        if (listProductCode && listProductCode.length > 0) {
            for (var element of listProductCode) {
                const lstAppProductCodes = element.appProductCodes;
                let any = lstAppProductCodes.some((x) => x.colorCode == colorCode);
                if (any) lst.push(element);
            }
        }
        return lst;
    }

    async function getBrand() {
        let [err, data] = await BrandServices.GetDropdownApprovedBrand({ ...INITPAGING });
        if (!err && data && data.length > 0) {
            setSetModel((prev) => ({ ...prev, brandId: prev.brandId > 0 ? prev.brandId : +data[0].value }));
        }
    }

    async function getTopTopic() {
        let [err, data] = await TopicServices.GetTop10Topic();
        if (!err && data && data.length > 0) setTopTopics(data);
        else setTopTopics([]);
    }

    async function getTagTrending() {
        let [err, data] = await ArtworkServices.GetTagTrending();
        if (!err && data && data.length > 0) setTagTrending(data);
        else setTagTrending([]);
    }

    async function getReadRateCard() {
        let [error, data] = await PrintServices.ReadRateCard();
        if (!error && data) {
            setReadRateCard(data);
        } else {
            setReadRateCard([]);
        }
    }

    function handleAmount(amount) {
        if (amount > 0 && readRateCard && readRateCard.length > 0) {
            let total = 0;
            let tmp = 0;
            for (let index = 0; index < readRateCard.length; index++) {
                if (amount >= readRateCard[index].loyaltyAmount) {
                    total += (readRateCard[index].loyaltyAmount - tmp) * (1 - readRateCard[index].lotusChargePercent * 0.01);
                    tmp = readRateCard[index].loyaltyAmount;
                } else {
                    total += (amount - tmp) * (1 - readRateCard[index].lotusChargePercent * 0.01);
                    break;
                }
            }
            setSetModel({ ...setModel, totalLoyaltyPrice: total, lotusChargeAmount: amount - total, loyaltyAmount: amount });
        }
    }

    //thay đổi thông tin set
    const handleSet = (name, value) => {
        let set = { ...setModel };
        if (name == "tag") {
            let length = value.length;
            if (length >= 10) {
                Notify(NOTIFY.INFO, NOTIFY.INFO, "Chọn tối đa 10 Tag");
                return;
            }
        }
        set[name] = value;
        setSetModel(set);
    };

    const handleInput = (index, name, value) => {
        let artworks = [...artworkModels];
        let item = artworks[index];

        if (name == "isDefaultAvatar") {
            if (artworks.length == 1) return;
            artworks.map((item) => (item.isDefaultAvatar = 0));
        }
        if (name == "optionTop" && value >= item.min && value <= item.max) {
            let artworkType = item.artworkType;
            let mck = [...mockupData];
            let itemMck = mck.find((x) => x.artworkType == artworkType);
            itemMck.optionTop = value;
            setMockupData(mck);
        }

        item[name] = value;
        setArtworkModels(artworks);
    };

    //xử lý thông tin màu sắc/ảnh biến thể
    async function getAppProductColorCode(setId, ageSetId) {
        let [err, data] = await ArtworkServices.GetAppProductCodeModels(setId, ageSetId);
        if (!err && data && data.length > 0) {
            if (ageSetId == 0) {
                let item = data[0];
                let appProductCodes = item.appProductCodes;
                let first = appProductCodes[0];
                item.checked = true;
                setProductColorCode(data);
                setSetModel((prev) => {
                    return { ...prev, appProductVariantId: first.appProductVariantId, colorCode: first.colorCode };
                });
                handleDataMockup(first.appProductId, first.appProductVariantId, data);
            } else {
                let appProductVariantId = setModel.appProductVariantId;
                let item = data.find((x) => x.appProductCodes.some((y) => y.appProductVariantId == appProductVariantId));
                if (!item) return;
                let appProductCodes = item.appProductCodes;
                let first = appProductCodes.find((x) => x.appProductVariantId == appProductVariantId);
                item.checked = true;
                setProductColorCode(data);
                handleDataMockup(first.appProductId, first.appProductVariantId, data);
            }
        } else setProductColorCode([]);
    }

    const handleDataMockup = (productId, productVariantId, productColorCodes = []) => {
        let productColor = productColorCodes && productColorCodes.length > 0 ? [...productColorCodes] : [...productColorCode];
        let pc = productColor.find((x) => x.id == productId);
        let variantCode = pc.appProductCodes.find((x) => x.appProductVariantId == productVariantId);
        let color = variantCode.colorCode;

        let dt = [...detail];
        if (!dt || dt.length == 0) return;
        let product = dt.find((x) => x.id == productId);
        if (!product) return;
        let dtColor = product.colors.find((x) => x.value == color);
        let img = dtColor.images;
        const optiontop = configModel.imageTypeValidate == IMAGE_TYPE.ArtworkCase ? 0 : 30; //vị trí mặc định
        let arr = [];
        let front = {
            artworkType: PRINT_SIDE.Front,
            optionTop: optiontop,
            imageType: 19,
        };
        let back = {
            artworkType: PRINT_SIDE.Back,
            optionTop: optiontop,
            imageType: 20,
        };

        switch (printSide) {
            case PRINT_SIDE.Front:
                arr.push(front);
                break;
            case PRINT_SIDE.Back:
                arr.push(back);
                break;
            case PRINT_SIDE.Both:
                arr.push(front);
                arr.push(back);
                break;
            default:
                break;
        }
        for (var art of arr) {
            //main
            let selectImg = img.find((x) => x.imageType == art.imageType);
            if (selectImg) {
                var mockup = new MockupDataModel();
                mockup.image = selectImg;
                mockup.frame = selectImg.frame;
                art.mockup = mockup;
                art.optionTop = selectImg.frame.frontMin;
            }

            //opposite
            var imageO = art.imageType == 19 ? 20 : 19;
            var selectImgO = img.find((x) => x.imageType == imageO);
            if (selectImgO) {
                var mockupO = new MockupDataModel();
                mockupO.image = selectImgO;
                mockupO.frame = selectImgO.frame;
                art.mockupOpposite = mockupO;
                art.optionTop = selectImgO.frame.backMin;
            }
        }
        arr = arr.filter((x) => x.mockup);
        setMockupData(arr);
    };

    const handlePrintSide = (side) => {
        let arr = [];
        var front = new ArtworkModel();
        front.artworkType = PRINT_SIDE.Front;
        var back = new ArtworkModel();
        back.artworkType = PRINT_SIDE.Back;

        if (frame) {
            front.min = frame.frontMin;
            front.max = frame.frontMax;
            back.min = frame.backMin;
            back.max = frame.frontMax;
        }

        switch (side) {
            case PRINT_SIDE.Front:
                front.isDefaultAvatar = 1;
                front.optionTop = frame.frontMin;
                arr.push(front);
                break;
            case PRINT_SIDE.Back:
                back.isDefaultAvatar = 1;
                back.optionTop = frame.backMin;
                arr.push(back);
                break;
            case PRINT_SIDE.Both:
                front.isDefaultAvatar = 1;
                front.optionTop = frame.frontMin;
                back.isDefaultAvatar = 0;
                back.optionTop = frame.backMin;
                arr = [...arr, ...[front, back]];
                break;
            default:
                break;
        }
        setArtworkModels(arr);
    };

    const handleArtwork = (index, e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (!file) return;
        let maximumSize = 50;
        if (file.size / 1024 / 1024 > maximumSize) {
            let artworks = [...artworkModels];
            let item = artworks[index];
            item.msgInvalid = `*Ảnh vượt kích thước cho phép ${maximumSize}mb`;
            setArtworkModels(artworks);
        }
        // setLoading(true);
        setLoadingIndex((prev) => [...prev, index]);
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            Request.UploadImage(file, configModel.imageTypeValidate)
                .then((res) => {
                    let artworks = [...artworkModels];
                    let item = artworks[index];
                    if (res && res.data) {
                        let { imageUrl, thumbnailUrl, tinyThumbnailUrl } = res.data;
                        item.url = imageUrl;
                        item.thumbnail = thumbnailUrl;
                        item.tinyThumbnail = tinyThumbnailUrl;
                        item.msgInvalid = "";
                    } else {
                        let errMsg =
                            res.errors && res.errors.length > 0
                                ? res.errors.reduce((prev, item, idx) => {
                                      return `${prev}${item.value}<br/>`;
                                  }, "")
                                : CONSTANTS.MSG_IMAGE_INVALID;
                        item.msgInvalid = `*${errMsg}`;
                    }
                    setArtworkModels(artworks);
                    setLoadingIndex((prev) => {
                        let item = [...prev];
                        let idx = item.indexOf(index);
                        item.splice(idx, 1);
                        return item;
                    });
                })
                .catch((err) => {
                    setLoadingIndex((prev) => {
                        let item = [...prev];
                        let idx = item.indexOf(index);
                        item.splice(idx, 1);
                        return item;
                    });
                });
        };
    };

    if (!setModel) return <div className="no-data">Không có dữ liệu</div>;
    if (!mockupData) return <div className="no-data">Không có dữ liệu</div>;
    return (
        <Wrapper>
            <div className="detail-section">
                <section className="product-base">
                    <section className={`set_base ${isReadOnly ? "disabled" : ""}`}>
                        <div className="set_base-left">
                            <div className="set_base-avatar">
                                <img src={setModel.appSetAvatar} alt="set avatar" />
                            </div>
                            <div className="set_base-combo">
                                <div className="set_base-combo-main">{setModel.appSetName}</div>
                                <div className="set_base-combo-total">{detail && detail.length} dòng sản phẩm</div>
                                <div
                                    className="set_base-change"
                                    onClick={(e) => {
                                        setShowProductType(true);
                                    }}
                                >
                                    <button>Đổi dòng sản phẩm khác</button>
                                </div>
                            </div>
                        </div>
                        <div className="set_base-right">
                            <div className="set_base-require">
                                <div className="set_base-require-main">Yêu cầu thiết kế không có Opacity (Độ mờ)</div>
                                {configModel && (
                                    <ul className="set_base-require-list">
                                        <li>Định dạng: {configModel.format.join(", ")}</li>
                                        <li>
                                            Kích thước thiết kế: {configModel.widthArtwork}x{configModel.heightArtwork}cm, {configModel.resolution}dpi
                                        </li>
                                        <li>Tối đa: {configModel.maxMb}Mb</li>
                                        {ListPrintTypeName && ListPrintTypeName.length > 0 && <li>Loại in: {ListPrintTypeName.join(", ")}</li>}
                                    </ul>
                                )}
                            </div>
                            <div className="set_base-template">
                                <button>
                                    Tải file mẫu
                                    <div className="icon">
                                        <DownloadIcon />
                                    </div>
                                </button>
                            </div>
                        </div>
                    </section>

                    <section className="product_base">
                        <div className={`product_base-printside ${isReadOnly ? "disabled" : ""}`}>
                            {artworkModels &&
                                artworkModels.length > 0 &&
                                artworkModels.map((item, idx) => {
                                    const artworkTypeName = item.artworkType == ARTWORK_TYPE.Front ? "Mặt trước" : "Mặt sau";
                                    const frameData = mockupData.find((x) => x.artworkType == item.artworkType);
                                    const imageData = frameData ? frameData.mockup.image : {};
                                    //xử lý thông tin vị trí khung in
                                    const frameDetail = handlePositionMockup(frameData, 300);
                                    console.log("frameData", frameData);
                                    console.log("frameDetail", frameDetail);
                                    const thumbnail = item.thumbnail;

                                    //thông tin artwork mặt đối diện
                                    var oppositeArtwork = artworkModels.find((x) => x.artworkType != item.artworkType);

                                    return (
                                        <div className="product_base-front" key={idx}>
                                            <label htmlFor="">{artworkTypeName}</label>
                                            <div className="product_base-front-content">
                                                <div
                                                    className="product_base-front-avatar"
                                                    onMouseMove={(e) => {
                                                        const { clientX, clientY, target } = e;
                                                        const rect = target.getBoundingClientRect();
                                                        const centerX = rect.left + rect.width / 2;
                                                        const centerY = rect.top + rect.height / 2;

                                                        let lstArtwork = [...artworkModels];
                                                        // Calculate the distance from the center
                                                        const distance = Math.sqrt(Math.pow(clientX - centerX, 2) + Math.pow(clientY - centerY, 2));
                                                        if (distance < 75) {
                                                            lstArtwork[idx].isHover = true;
                                                        } else {
                                                            lstArtwork[idx].isHover = false;
                                                        }
                                                        setArtworkModels(lstArtwork);
                                                    }}
                                                >
                                                    <img src={imageData.url} />
                                                    {loadingIndex.includes(idx) ? (
                                                        <div className="show-loading">
                                                            <Loading />
                                                        </div>
                                                    ) : (
                                                        thumbnail && (
                                                            <React.Fragment>
                                                                <div
                                                                    className="thumbnail"
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: `${frameDetail.y}px`,
                                                                        left: `${frameDetail.x}px`,
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={thumbnail}
                                                                        style={{
                                                                            width: `${frameDetail.width}px`,
                                                                            height: `${frameDetail.height}px`,
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div
                                                                    className="position-scroll"
                                                                    onMouseMove={(e) => {
                                                                        e.stopPropagation();
                                                                        let lstArtwork = [...artworkModels];
                                                                        lstArtwork[idx].isHover = false;
                                                                        setArtworkModels(lstArtwork);
                                                                    }}
                                                                    style={{
                                                                        zIndex: 2,
                                                                    }}
                                                                >
                                                                    <VerticalPositionScoll value={item.optionTop} min={item.min} max={item.max} onChange={(value) => handleInput(idx, "optionTop", value)} />
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    )}

                                                    {imageData.topLayer && (
                                                        <div className="ignore-pixel">
                                                            <img src={imageData.topLayer} alt="" />
                                                        </div>
                                                    )}

                                                    {item.isHover && !loadingIndex.includes(idx) && (
                                                        <div className="hover-btn">
                                                            <div className="list-hover-btn">
                                                                {!thumbnail ? (
                                                                    <div className="hover-btn-upload">
                                                                        <button
                                                                            onClick={(e) => {
                                                                                let input = document.getElementById(`detail-upload-default-button-${idx}`);
                                                                                input.click();
                                                                            }}
                                                                            className="cursor-pointer"
                                                                        >
                                                                            <div className="icon">
                                                                                <UploadIcon />
                                                                            </div>
                                                                            Tải thiết kế lên
                                                                        </button>

                                                                        <div className="upload-img">
                                                                            <input
                                                                                id={`detail-upload-default-button-${idx}`}
                                                                                className="detail-upload-default-button d-none"
                                                                                type={"file"}
                                                                                multiple={false}
                                                                                accept="image/*"
                                                                                onChange={(e) => {
                                                                                    handleArtwork(idx, e);

                                                                                    //tắt hover
                                                                                    let lstArtwork = [...artworkModels];
                                                                                    lstArtwork[idx].isHover = false;
                                                                                    setArtworkModels(lstArtwork);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <div className="hover-btn-change">
                                                                            <button
                                                                                onClick={(e) => {
                                                                                    let input = document.getElementById(`detail-upload-default-button-${idx}`);
                                                                                    input.click();
                                                                                }}
                                                                                className="cursor-pointer"
                                                                            >
                                                                                <div className="icon">
                                                                                    <UploadIcon />
                                                                                </div>
                                                                                Đổi thiết kế
                                                                            </button>
                                                                            <div className="upload-img">
                                                                                <input
                                                                                    id={`detail-upload-default-button-${idx}`}
                                                                                    className="detail-upload-default-button d-none"
                                                                                    type={"file"}
                                                                                    multiple={false}
                                                                                    accept="image/*"
                                                                                    onChange={(e) => {
                                                                                        handleArtwork(idx, e);
                                                                                    }}
                                                                                />
                                                                                <div className="detail-upload-invalid" dangerouslySetInnerHTML={{ __html: item.msgInvalid }}></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="hover-btn-remove">
                                                                            <button
                                                                                onClick={(e) => {
                                                                                    let lstArtwork = [...artworkModels];
                                                                                    lstArtwork[idx].url = "";
                                                                                    lstArtwork[idx].thumbnail = "";
                                                                                    lstArtwork[idx].tinyThumbnail = "";
                                                                                    lstArtwork[idx].msgInvalid = "";
                                                                                    setArtworkModels(lstArtwork);
                                                                                }}
                                                                                className="cursor-pointer"
                                                                            >
                                                                                <div className="icon">
                                                                                    <TrashIcon />
                                                                                </div>
                                                                                Xóa thiết kế
                                                                            </button>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {!thumbnail ? (
                                                    <div className="product_base-require">
                                                        <div className="product_base-require-main">Yêu cầu thiết kế không có Opacity (Độ mờ)</div>
                                                        {configModel && (
                                                            <ul className="product_base-require-list">
                                                                <li>Định dạng: {configModel.format.join(", ")}</li>
                                                                <li>
                                                                    Kích thước thiết kế: {configModel.widthArtwork}x{configModel.heightArtwork}cm, {configModel.resolution}dpi
                                                                </li>
                                                                <li>Tối đa: {configModel.maxMb}Mb</li>
                                                            </ul>
                                                        )}
                                                        <div className="product_base-require-list detail-upload-invalid" dangerouslySetInnerHTML={{ __html: item.msgInvalid }}></div>
                                                    </div>
                                                ) : (
                                                    <div className="product_base-require">
                                                        <div className="product_base-default">
                                                            <div className="product_base-button-default">
                                                                <input
                                                                    id={`checkbox-${idx}`}
                                                                    type={"checkbox"}
                                                                    checked={item.isDefaultAvatar}
                                                                    onChange={(e) => {
                                                                        e.stopPropagation();
                                                                        let checked = e.target.checked;
                                                                        if (item.isDefaultAvatar != checked) handleInput(idx, "isDefaultAvatar", checked);
                                                                    }}
                                                                />
                                                                <label htmlFor={`checkbox-${idx}`}>Dùng làm ảnh đại diện</label>
                                                            </div>
                                                            <div className="product_base-button-default-instruct">
                                                                <span>Mặt sẽ được hiển thị đầu tiên</span>
                                                            </div>
                                                        </div>

                                                        {isShowOpposite && (
                                                            <React.Fragment>
                                                                <div className="product_base-opposite">
                                                                    <label htmlFor="" className="opposite-main">
                                                                        Vị trí mặt đối diện
                                                                    </label>
                                                                    <p className="opposite-sub">Chức năng giúp khách hàng có thể in thiết kế ở mặt đối diện.</p>
                                                                    <div
                                                                        className="opposite-button"
                                                                        onClick={(e) => {
                                                                            setShowOppositeModal(true);
                                                                        }}
                                                                    >
                                                                        <button>Chỉnh sửa</button>
                                                                    </div>
                                                                </div>
                                                                {showOppositeModal && (
                                                                    <MockupOppositeModalVer2
                                                                        show={showOppositeModal}
                                                                        onClose={() => {
                                                                            setShowOppositeModal(false);
                                                                        }}
                                                                        thumbnail={thumbnail}
                                                                        frameDetail={{ ...frameData, optionTop: item.optionTopOpposite, mockup: frameData.mockupOpposite, min: oppositeArtwork.min, max: oppositeArtwork.max }}
                                                                        onScroll={(value) => {
                                                                            handleInput(idx, "optionTopOpposite", value);
                                                                        }}
                                                                    />
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        <hr />
                        <div className="product_base-color">
                            <div className="instruct">
                                <label htmlFor="">Lựa chọn màu sắc</label>
                            </div>
                            <div className="color-select">
                                <div className="list-color">
                                    {listProductColor &&
                                        listProductColor.map((item, idx) => {
                                            let isActive = item.colorCode == setModel.colorCode;
                                            return (
                                                <div
                                                    className={`cursor-pointer color-item-product ${isActive ? "active" : ""}`}
                                                    key={idx}
                                                    onClick={(e) => {
                                                        //config màu sắc
                                                        let set = { ...setModel };
                                                        set.colorCode = item.colorCode;
                                                        setSetModel(set);

                                                        //lấy ra ds dòng sản phẩm có màu sắc này
                                                        let lstColorCode = [...productColorCode].map((item) => {
                                                            item.checked = false;
                                                            return item;
                                                        });
                                                        if (lstColorCode && lstColorCode.length > 0) {
                                                            for (var element of lstColorCode) {
                                                                const lstAppProductCodes = element.appProductCodes;
                                                                let find = lstAppProductCodes.find((x) => x.colorCode == item.colorCode);
                                                                if (find) {
                                                                    element.checked = true;
                                                                    handleDataMockup(element.id, find.appProductVariantId, lstColorCode);
                                                                    break;
                                                                }
                                                            }
                                                        }

                                                        setProductColorCode(lstColorCode);
                                                    }}
                                                >
                                                    <div
                                                        className="color-item-product-code"
                                                        style={{
                                                            backgroundColor: `${item.colorCode}`,
                                                        }}
                                                    ></div>
                                                    <div className="color-item-product-name">{item.colorName}</div>
                                                    {isActive && (
                                                        <div className="active-selection">
                                                            <ItemSelection />
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="product_base-product-type">
                            <div className="instruct">
                                <label htmlFor="">Dòng sản phẩm hiển thị</label>
                            </div>
                            <div className="product-type-select">
                                <div className="list-product-type">
                                    {listProduct &&
                                        listProduct.map((item, idx) => {
                                            let isActive = item.checked;
                                            return (
                                                <div
                                                    className={`cursor-pointer product-type-item ${isActive ? "active" : ""}`}
                                                    onClick={(e) => {
                                                        let lstColorCode = [...productColorCode].map((item) => {
                                                            item.checked = false;
                                                            return item;
                                                        });
                                                        let find = lstColorCode.find((x) => x.id == item.id);
                                                        if (find) {
                                                            find.checked = true;
                                                            //lấy ra biến thể có cùng màu
                                                            let variant = find.appProductCodes.find((x) => x.colorCode == setModel.colorCode);
                                                            if (variant) {
                                                                handleDataMockup(find.id, variant.appProductVariantId, lstColorCode);
                                                            }
                                                        }

                                                        setProductColorCode(lstColorCode);
                                                    }}
                                                >
                                                    <div className="product-type-item-name">{item.productName}</div>
                                                    {isActive && (
                                                        <div className="active-selection">
                                                            <ItemSelection />
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="detail_base">
                        <div className="detail-info">
                            <div className="left">
                                <div className="info">
                                    <h6 className="title">Thông tin thiết kế</h6>
                                    <div className="name">
                                        <div className="vietnamese">
                                            <div className="vietnamese-title">
                                                <div className="title required">Tên thiết kế (Tiếng Việt)</div>
                                                <span className="keyword-count">{setModel.title.length}/120 kí tự</span>
                                            </div>
                                            <TextArea
                                                value={setModel.title}
                                                name={"title"}
                                                onChanged={(name, value) => {
                                                    if (value.length <= 120) handleSet(name, value);
                                                }}
                                                rows={4}
                                                placeholder="Nhập tên thiết kế"
                                                ref={addRef}
                                                required
                                            />
                                        </div>
                                        <div className="english">
                                            <div className="english-title">
                                                <div className="title required">Tên thiết kế (Tiếng Anh)</div>
                                                <span className="keyword-count">{setModel.titleEN ? setModel.titleEN.length : 0}/120 kí tự</span>
                                            </div>
                                            <TextArea
                                                value={setModel.titleEN}
                                                name={"titleEN"}
                                                onChanged={(name, value) => {
                                                    if (value.length <= 120) handleSet(name, value);
                                                }}
                                                rows={4}
                                                placeholder="Nhập tên thiết kế"
                                                ref={addRef}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="design-style">
                                        <div className="design-style-header">
                                            <label className="title required">Phong cách thiết kế (Chọn tối đa 2)</label>
                                            {setModel.topic && setModel.topic.length > 0 && <div className="checkbox-result">Đã chọn {setModel.topic.join(", ")}</div>}
                                        </div>
                                        <div className="checkbox-range">
                                            {topTopics &&
                                                topTopics.map((item, idx) => {
                                                    return (
                                                        <div className="select" key={idx}>
                                                            <input
                                                                id={`checkbox-topic-${idx}`}
                                                                type={"checkbox"}
                                                                checked={setModel && setModel.topic && setModel.topic.includes(item.topicName)}
                                                                onChange={(e) => {
                                                                    let value = e.target.checked;
                                                                    let set = { ...setModel };
                                                                    let topic = set.topic || [];
                                                                    if (value) {
                                                                        let lengthTopic = topic.length;
                                                                        if (lengthTopic >= 2) Notify(NOTIFY.INFO, NOTIFY.INFO, "Chọn tối đa 2 phong cách thiết kế");
                                                                        else {
                                                                            topic.push(item.topicName);
                                                                            handleSet("topic", topic);
                                                                        }
                                                                    } else {
                                                                        let indexOf = topic.indexOf(item.topicName);
                                                                        if (indexOf >= 0) topic.splice(indexOf, 1);
                                                                        handleSet("topic", topic);
                                                                    }
                                                                }}
                                                            />
                                                            <label htmlFor={`checkbox-topic-${idx}`}>{item.topicName}</label>
                                                        </div>
                                                    );
                                                })}
                                            <div className="select select-more cursor-pointer" onClick={(e) => setDesignType(true)}>
                                                Xem thêm phong cách
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-vietnamese">
                                        <div className="title required">Câu chuyện thiết kế (Tiếng Việt)</div>
                                        <TextEditor html={setModel.content} name="content" onChanged={(name, value) => handleSet(name, value)} required ref={addRef} />
                                    </div>
                                    <div className="content-english">
                                        <div className="title">Câu chuyện thiết kế (Tiếng Anh)</div>
                                        <TextEditor html={setModel.contentEN} name="contentEN" onChanged={(name, value) => handleSet(name, value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="seo">
                        <div className="tag">
                            <div className="tag-title">
                                Thêm tag
                                <span>(Mỗi tag kết thúc bằng phím Enter, tối đa 10 tag)</span>
                            </div>
                            <div className="tag-input">
                                <div className="tag-area">
                                    <TagsInput
                                        addKeys={[13]}
                                        addOnPaste
                                        maxTags={10}
                                        inputProps={{ placeholder: "" }}
                                        value={setModel.tag || []}
                                        onChange={(tags) => {
                                            let newTags = tags.reduce((prev, val, idx) => {
                                                let value = Common.removeVietnameseFromString(val);
                                                value = `#${value.replace(/ /g, "")}`;
                                                return prev.indexOf(value) == -1 ? [...prev, value] : [...prev];
                                            }, []);
                                            handleSet("tag", newTags);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="tag-suggest">
                            <div className="title">
                                Gợi ý xu hướng<span>(Nhấp vào tag để chọn)</span>
                            </div>
                            <div className="suggest">
                                {tagTrending &&
                                    tagTrending.length > 0 &&
                                    tagTrending.map((item, idx) => {
                                        return (
                                            <span
                                                className="suggest-item cursor-pointer"
                                                key={idx}
                                                onClick={(e) => {
                                                    let newTags = [...setModel.tag];
                                                    if (!newTags.includes(item)) newTags.push(item);
                                                    handleSet("tag", newTags);
                                                }}
                                            >
                                                {item}
                                            </span>
                                        );
                                    })}
                            </div>
                        </div>
                    </section>
                </section>
                <section className="loyalty-base">
                    <h5>Thông tin tiền bản quyền</h5>
                    <div className="calculate-type">
                        <TFUSelect options={loyaltyMethodOptions} value={setModel.loyaltyMethod} onChanged={(value) => handleSet("loyaltyMethod", +value)} isDisabled />
                    </div>
                    <div className="loyalty-method">
                        <label htmlFor="" className="required">
                            Tiền bản quyền
                        </label>
                        <div className="loyalty-amount">
                            <input
                                type="number"
                                placeholder="Nhập số tiền"
                                value={setModel.loyaltyAmount}
                                onChange={(e) => {
                                    handleSet("loyaltyAmount", e.target.value);
                                    handleAmount(e.target.value);
                                }}
                            />
                            <div className="subfix">Đồng</div>
                        </div>
                    </div>
                    <div className="price-calculate">
                        <div className="platform-fee">
                            <label htmlFor="">Phí dịch vụ và thuế VAT</label>
                            <div className="readonly-fee">{Common.formatCurrency(setModel.lotusChargeAmount)}</div>
                        </div>
                        <div className="amount-receive">
                            <label htmlFor="">Tiền bản quyền thực lĩnh</label>
                            <div className="readonly-fee">{Common.formatCurrency(setModel.totalLoyaltyPrice)}</div>
                        </div>
                        <div className="product-calculate">
                            <label htmlFor="">Dự tính giá bán theo dòng sản phẩm</label>
                            <div className="product-price-table">
                                <table className="table table-bordered">
                                    <tbody>
                                        {productColorCode &&
                                            productColorCode.length > 0 &&
                                            productColorCode.map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>
                                                            <span>{item.productName}</span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                {Common.formatCurrency(item.minPrice + +setModel.loyaltyAmount)} - {Common.formatCurrency(item.maxPrice + +setModel.loyaltyAmount)}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="button-bottom">
                    <button
                        className="btn btn-back"
                        onClick={() => {
                            onBack();
                        }}
                    >
                        Trở về
                    </button>
                    <button
                        className="btn btn-save"
                        onClick={() => {
                            onSave();
                        }}
                    >
                        Hoàn thành
                    </button>
                </div>
            </div>
            <DesignType
                isModal={designType}
                onClose={() => setDesignType(false)}
                topics={setModel.topic}
                setTopics={(topics) => {
                    let set = { ...setModel };
                    set.topic = topics;
                    setSetModel(set);
                }}
            />
            <ProductVer2Modal
                show={showProductType}
                onClose={(e) => {
                    setShowProductType(false);
                }}
            />
        </Wrapper>
    );
}

export default DetailVer2;

const Wrapper = styled.div`
    .list-thumbnail {
        height: 100%;
        width: 100%;
    }
    table.table.table-bordered span {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }
    .ignore-pixel {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .select.select-more.cursor-pointer {
        color: #13aaff;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    .button-bottom {
        width: 100%;
        background: #fff;
        padding: 17px 32px;
        display: flex;
    }

    .detail-section {
        flex-wrap: wrap;
    }

    button.btn.btn-back {
        margin: 0 12px 0 auto;
    }

    button.btn.btn-back {
        border-radius: 40px;
        background: #f3f3f3;
        color: #303030;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    button.btn.btn-save {
        border-radius: 40px;
        background: var(--Cam-Thng-hiu, #ff9027);
        color: #fff;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .suggest {
        display: flex;
        flex-wrap: wrap;
    }

    span.suggest-item {
        border-radius: 30px;
        border: 1px solid var(--Cam-Thng-hiu, #ff9027);
        padding: 5px 12px;
        margin: 6px;
        color: var(--Cam-Thng-hiu, #ff9027);
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    span.react-tagsinput-tag {
        border-radius: 30px;
        background: var(--Cam-Thng-hiu, #ff9027);
        padding: 10px;
        border: none;
    }

    .design-style-header {
        display: flex;
        align-items: center;
    }

    .design-style-header label.title.required {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        margin: 0;
    }

    .design-style-header .checkbox-result {
        color: #13aaff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-left: 12px;
    }

    .checkbox-range {
        display: flex;
        flex-wrap: wrap;
        margin: 12px 0;
    }

    .checkbox-range .select {
        display: flex;
        align-items: center;
        width: 25%;
        margin: 6px 0;
    }

    .checkbox-range .select label {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-left: 10px;
        margin-bottom: 0;
    }

    .product_base-front:last-child {
        border-right: none;
    }
    .show-loading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .product_base-front-avatar {
        position: relative;
    }

    .hover-btn {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(34, 34, 34, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hover-btn-upload button {
        border-radius: 40px;
        background: #ff9027;
        display: flex;
        align-items: center;
        border: none;
        padding: 10px 12px;
        color: #fff;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        width: max-content;
    }

    button {
    }

    .hover-btn-upload button .icon {
        display: flex;
        margin-right: 12px;
    }

    .hover-btn-change button {
        border-radius: 40px;
        background: #ff9027;
        display: flex;
        align-items: center;
        border: none;
        padding: 10px 12px;
        color: #fff;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        width: max-content;
    }

    .list-hover-btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .position-scroll {
        position: absolute;
        left: -105px;
        top: 135px;
    }

    .list-hover-btn {
        z-index: 1;
    }

    .hover-btn-change button .icon {
        display: flex;
        margin-right: 12px;
    }

    .hover-btn-upload {
        margin: 6px 0;
    }

    .hover-btn-change {
        margin: 6px 0;
    }

    .hover-btn-remove button {
        border-radius: 40px;
        background: #f3f3f3;
        border: none;
        display: flex;
        align-items: center;
        padding: 10px 12px;
        margin: 6px 0;
    }

    .hover-btn-remove button .icon {
        display: flex;
        margin-right: 12px;
    }

    /* section loyalty */
    section.loyalty-base {
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        background: #fff;
        margin-left: 24px;
        padding: 24px;
        height: fit-content;
        top: 5px;
        position: sticky;
    }

    .loyalty-base h5 {
        color: #303030;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .loyalty-method label.required {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    .loyalty-amount {
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 11px 14px;
    }

    .loyalty-amount .subfix {
        color: #303030;
        text-align: right;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-left: auto;
    }

    .loyalty-amount input[type="number"] {
        border: none;
        width: 100%;
    }

    .loyalty-method {
        margin: 12px 0;
    }

    .price-calculate {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .platform-fee {
        flex-basis: calc(50% - 10px);
        margin-right: 20px;
    }

    .amount-receive {
        flex-basis: calc(50% - 10px);
    }

    .platform-fee label {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .amount-receive label {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .readonly-fee {
        border-radius: 8px;
        border: 1px solid #d9d9d9;
        background: #f3f3f3;
        padding: 7px 10px;
    }

    .amount-receive .readonly-fee {
        color: #ff9027;
        background: #fff5ec;
    }

    .product-calculate {
        margin-top: 24px;
        width: 100%;
    }

    .product-calculate label {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    .product-price-table {
        width: 100%;
    }

    .product-price-table table {
        width: 100%;
    }

    /* section sản phẩm */
    .detail-section {
        display: flex;
        padding: 0 20px;
    }

    section.product-base {
        flex-basis: 70%;
    }

    section.loyalty-base {
        flex: 1;
    }

    section.set_base {
        border-radius: 12px;
        background: #fff;
        padding: 16px 24px;
        display: flex;
    }

    .set_base-avatar {
        width: 112px;
        height: 112px;
        display: flex;
    }

    .set_base-avatar img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .set_base-left {
        display: flex;
    }

    .set_base-combo {
        margin-left: 24px;
        display: flex;
        flex-direction: column;
    }

    .set_base-combo-main {
        color: #303030;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .set_base-combo-total {
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 6px 0;
    }

    .set_base-change {
        margin-top: auto;
    }

    .set_base-change button {
        cursor: pointer;
        border-radius: 40px;
        background: #ff9027;
        border: none;
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 13px 12px;
    }

    .set_base-right {
        margin-left: auto;
        display: flex;
        border-radius: 8px;
        background: #f1faff;
        padding: 16px 24px;
        align-items: center;
    }

    .set_base-require-main {
        color: #13aaff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    ul.set_base-require-list {
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        list-style: none;
        padding: 0;
        margin: 6px 0;
    }

    .set_base-template button {
        display: flex;
        align-items: center;
        border: none;
        border-radius: 30px;
        background: #13aaff;
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        padding: 9px 12px;
    }

    .set_base-template {
        margin-left: 60px;
    }

    .set_base-template button .icon {
        display: flex;
        margin-left: 8px;
    }

    section.product_base {
        border-radius: 12px;
        border: 1px solid #f3f3f3;
        background: #fff;
        margin: 12px 0;
        padding: 24px;
    }

    .product_base-printside {
        display: flex;
    }

    .product_base-front {
        min-width: 50%;
        border-right: 1px solid #f3f3f3;
        padding: 0 12px;
    }

    .product_base-back {
        min-width: 50%;
        padding: 0 12px;
    }

    .product_base-front label {
        color: #303030;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .product_base-back label {
        color: #303030;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .product_base-front-avatar {
        width: 300px;
        height: 300px;
        display: block;
        flex-shrink: 0;
        flex-grow: 0;
    }

    .product_base-front-avatar img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .product_base-front-content {
        display: flex;
    }

    .product_base-require-main {
        color: #13aaff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    ul.product_base-require-list {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        list-style: none;
        padding: 0;
        margin: 6px 0;
    }

    .product_base-back-content {
        display: flex;
    }

    .product_base-back-avatar {
        width: 50%;
    }

    .product_base-back-avatar img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .product_base-require {
        margin-left: 24px;
    }

    .product_base-button-default label {
        color: #303030;
        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        margin: 0 0 0 6px !important;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .product_base-button-default {
        display: flex;
        align-items: center;
    }

    .product_base-button-default-instruct {
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin: 10px 0;
    }

    .product_base-opposite {
        border-radius: 8px;
        border: 1px solid #f3f3f3;
        background: #fff;
        padding: 12px;
    }

    label.opposite-main {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    p.opposite-sub {
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin: 6px 0;
    }

    button {
    }

    .opposite-button button {
        border-radius: 8px;
        background: #f1faff;
        border: none;
        width: 100%;
        color: #13aaff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        padding: 9px;
        margin: 12px 0 0 0;
    }

    .instruct label {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .color-select {
    }

    .list-color {
        display: flex;
        flex-wrap: wrap;
    }

    .color-item-product {
        border-radius: 8px;
        background: #f3f3f3;
        display: flex;
        align-items: center;
        padding: 6px 12px;
        margin: 6px 6px;
        position: relative;
    }

    .color-item-product-code {
        width: 28px;
        height: 28px;
        border-radius: 100%;
    }

    .color-item-product-name {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 10px;
    }

    .active-selection {
        position: absolute;
        left: 0;
        top: 0;
    }

    .color-item-product.active {
        border: 1px solid var(--Cam-Thng-hiu, #ff9027);
    }

    .product_base-product-type .instruct {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .list-product-type {
        display: flex;
        flex-wrap: wrap;
    }

    .product-type-item {
        border-radius: 8px;
        background: #f3f3f3;
        padding: 10px 12px;
        margin: 6px;
        position: relative;
    }

    .product-type-item-name {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .product-type-item.active {
        border: 1px solid var(--Cam-Thng-hiu, #ff9027);
    }

    section.detail_base {
        border-radius: 12px;
        background: #fff;
        padding: 24px;
    }

    h6.title {
        color: #303030;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .name {
        display: flex;
    }

    .vietnamese {
        width: calc(50% - 20px);
    }

    .english {
        width: calc(50% - 20px);
    }

    .vietnamese,
    .english .title {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        margin-right: 20px;
    }

    span.keyword-count {
        margin-left: auto;
        color: #a5a5a5;
        text-align: right;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    d {
    }

    .vietnamese-title {
        display: flex;
        align-items: center;
        margin: 6px 0;
    }

    .english-title {
        display: flex;
        align-items: center;
        margin: 6px 0;
    }

    .design-style h6.title.required {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    .design-style {
        margin: 24px 0;
    }

    .content-vietnamese .title.required {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        margin: 12px 0;
    }

    .content-english .title {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        margin: 12px 0;
    }

    section.seo {
        display: flex;
        border-radius: 12px;
        background: #fff;
        margin: 12px 0;
        padding: 24px;
    }

    .tag-suggest {
        flex: 1;
        margin-left: 40px;
    }

    .tag {
        width: 50%;
    }

    .tag-title {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 14px;
    }

    .tag-title span {
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-left: 12px;
    }

    .tag-suggest .title {
        color: var(--Cam-Thng-hiu, #ff9027);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .tag-suggest .title span {
        color: #707070;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-left: 9px;
    }
`;

const DownloadIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M7 10L12 15L17 10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 15V3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const ItemSelection = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 2.86197e-07L0 24L1.90798e-07 8C2.43486e-07 3.58172 3.58172 4.27116e-08 8 9.5399e-08L24 2.86197e-07Z" fill="#FF9027" />
            <path d="M3.99951 6.6665L6.22173 8.6665L10.6662 4.6665" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
export const handlePositionMockup = (mockupData, renderSizeSquare) => {
    if (!mockupData) return null;

    var mockup = mockupData.mockup;
    if (!mockup || !mockup.frame || !mockup.image) return null;
    var frame = mockup.frame;
    var variantFrame = mockup.image.variantFrame;

    //khoảng cách từ khung đến cổ
    var yDistance = variantFrame.frameMinY - variantFrame.collarY;
    var distanceCm = variantFrame.digitalScale;
    var pixelPerCentimet = yDistance / distanceCm;

    //chiều ngang tính theo tỉ lệ hiện tại
    var widthCm = frame.widthFrame;
    var pixelWidth = widthCm * pixelPerCentimet;

    //chiều dọc tính theo tỉ lệ hiện tại
    var heightCm = frame.heightFrame;
    var pixelHeight = heightCm * pixelPerCentimet;

    //tọa độ góc X và góc Y, trên tỉ lệ 1000x1000
    var x = variantFrame.axisX - pixelWidth / 2;
    var y = variantFrame.collarY + mockupData.optionTop * pixelPerCentimet * frame.scale;
    var heightPx = pixelHeight;
    var widthPx = pixelWidth;
    var result1000 = {
        width: widthPx,
        height: heightPx,
        x: x,
        y: y,
    };

    //cấu hình lại theo tỉ lệ renderSizeSquare
    var ratio = renderSizeSquare / 1000;
    var resultRender = {
        width: widthPx * ratio,
        height: heightPx * ratio,
        x: x * ratio,
        y: y * ratio,
    };

    return resultRender;
};

const UploadIcon = () => {
    return (
        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 3C1 1.89543 1.89543 1 3 1H10L17 8V18.8462C17 19.9507 16.1046 20.8462 15 20.8462H3C1.89543 20.8462 1 19.9507 1 18.8462V3Z" stroke="white" stroke-width="1.8" stroke-linejoin="round" />
            <path d="M8.59977 11.3599L6.67977 9.43994L4.75977 11.3599" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.67969 9.43994V15.1999" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3.7998 17.1201H9.5598" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10 8V1L17 8H10Z" stroke="white" stroke-width="1.8" stroke-linejoin="round" />
        </svg>
    );
};

const TrashIcon = () => {
    return (
        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.2209 20H5.69325C4.4908 20 3.54601 19.1515 3.37423 17.9636L2 6H16L14.5399 17.9636C14.3681 19.1515 13.4233 20 12.2209 20Z"
                stroke="#303030"
                stroke-width="1.8"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M1 3.5H17" stroke="#303030" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" />
            <path d="M6.41699 1H11.7504" stroke="#303030" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" />
            <path d="M7 9.6665L7.36356 15.3333" stroke="#303030" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.9997 9.6665L10.5635 15.3333" stroke="#303030" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
