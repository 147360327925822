import { actionTypes } from "./types";

// Text
export { addText, removeText, insertText, setText } from "./actions/text";

// Image
export {
  addImage,
  removeImage,
  replaceImage,
  insertImage,
} from "./actions/image";

// Embed
export {
  // Modal
  openEmbedModal,
  closeEmbedModal,
  // Item
  addEmbed,
  removeEmbed,
  editEmbed,
  insertEmbed,
} from "./actions/embed";

// Mockup
export {
  // Modal
  openMockupModal,
  closeMockupModal,
  addSubImageMockup,
  // Item
  addMockup,
  editMockup,
  insertMockup,
  removeMockup,
} from "./actions/mockup";

// Image Modal
export {
  // Image
  clearImages,
  selectMockupImage,
  openMockupImageModal,
  closeMockupImageModal,
  fetchMockupImages,
  fetchArtworks,
} from "./actions/mockupImage";

// Project
export {
  clearProject,
  fetchProject,
  createProject,
  uploadCoverImage,
} from "./actions/project";

// ToggleFull Width (Image, Embed, Mockup)
export const toggleFullWidth = (itemId) => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_TOGGLE_FULL_WIDTH,
      payload: itemId,
    };
    dispatch(action);
  };
};
