import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcum from "../layout/refactor/Breadcum/Breadcum";

function CreateProfileInfo(props) {
    const history = useHistory();
    return (
        <React.Fragment>
            <Breadcum title="Thông tin nhà sáng tạo" />
            <Wrapper>
                <BrandWrapper>
                    <div className="main">Cảm ơn bạn đã cập nhật thông tin Nhà sáng tạo</div>
                    <div className="sub">
                        <p>
                            Thông tin sẽ được <span>Ranus xác thực (CMND/ CCCD, Tài khoản ngân hàng) trong vòng 24h</span>
                            kể từ lúc cập nhật thông tin thành công, sau đó sẽ sử dụng được đầy đủ chức năng của Nhà sáng tạo
                        </p>
                        <p>
                            <span>Bạn hãy tạo thương hiệu cá nhân</span> trong quá trình chờ xác thực nhé.
                        </p>
                    </div>
                    <div>
                        <button className="button" onClick={(e) => history.push("/create-brand")}>
                            Tạo thương hiệu
                        </button>
                    </div>
                </BrandWrapper>
            </Wrapper>
        </React.Fragment>
    );
}

export default CreateProfileInfo;

const Wrapper = styled.div`
    width: 100%;
`;
const BrandWrapper = styled.div`
    margin: 0 20px;
    background: white;
    border-radius: 10px;
    padding: 20px;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    text-align: center;
    .main {
        font-weight: 700;
        font-size: 25px;
        line-height: 20px;
        /* identical to box height, or 80% */

        text-align: center;

        color: #444444;
        margin: 20px 0;
    }
    .sub {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #444444;
        span {
            font-weight: 700;
            color: #28b259;
        }
        margin: 20px 0;
    }
    .button {
        border: none;
        background: #28b259;
        color: white;
        padding: 10px 20px;
        border-radius: 10px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #f3f3f3;
        margin: 20px 0;
    }
`;
