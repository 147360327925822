import React, { useEffect, useState } from "react";
import { NOTIFY } from "../../based/Constants";
import { Notify } from "../../based/Notify";
const ACCEPTED_EXTENSIONS = ["image/png", "image/jpg", "image/jpeg"];
const IMAGE_TYPE = {
    BANNER: "BANNER",
    AVATAR: "AVATAR",
};
const ImagePicker = ({ imgRef, onChange, onClickChangeImage, imgSrc, type, disablePreview }) => {
    const [imgSrcPrev, setImgSrcPrev] = useState("");
    useEffect(() => {
        let imgPrev = !imgSrc ? (type === IMAGE_TYPE.BANNER ? "" : "") : imgSrc;
        setImgSrcPrev(imgPrev);
    }, [imgSrc]);
    const checkImageValidHandler = (files) => {
        let fileValid = true;

        if (files.length > 1) {
            Notify(NOTIFY.WARNING, "Cảnh báo", "Chọn tối đa 1 hình ảnh.");
            fileValid = false;
        }
        if (!ACCEPTED_EXTENSIONS.includes(files[0].type)) {
            Notify(NOTIFY.WARNING, "Cảnh báo", "Hình ảnh không hợp lệ.");
            fileValid = false;
        }

        return fileValid;
    };

    const imageChangeHandler = (event) => {
        const files = event.target.files;
        if (!files || files.length <= 0) return;
        const isValidFile = checkImageValidHandler(files);
        if (isValidFile) {
            onChange(files[0]);
            let imageFile = files[0];
            const reader = new FileReader();
            reader.onload = (x) => {
                setImgSrcPrev(x.target.result);
            };
            reader.readAsDataURL(imageFile);
        } else {
            setImgSrcPrev(imgSrc);
        }
    };

    return (
        <div className="align-items-center justify-content-center">
            <input type="file" accept={ACCEPTED_EXTENSIONS.join(",")} style={{ display: "none" }} ref={imgRef} onChange={imageChangeHandler} />
            {imgSrcPrev && <img className={disablePreview ? "d-none" : "w-100 rounded"} src={imgSrcPrev} alt="image" onClick={onClickChangeImage} />}
        </div>
    );
};

export default ImagePicker;
