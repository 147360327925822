import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { CreateArtworkContext, PRINT_SIDE } from "../context/CreateArtworkContext";
import { INITPAGING, PACKAGE_ORDER_STATUS, PACKAGE_TYPE, PRINT_TYPE_NAME } from "../../based/Constants";
import SetProductServices from "../../based/services/SetProductServices";

function ProductVer2({ setId }) {
    const { step, setStep, setModel, setSetModel, setPrintSide } = useContext(CreateArtworkContext);
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING, pageSize: 12 });
    const [keyword, setKeyword] = useState("");
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        _getData({ ...paging });
    }, []);

    //Lấy thông tin app set theo paging
    async function _getData(paging) {
        const [err, data] = await SetProductServices.GetPagingCreatorArtwork(paging);
        if (!err && data && data.listObjects) {
            let item = data.listObjects[0];
            setDatas(data.listObjects);
            setPaging(data.paging);
            if (setId == 0) setSetModel({ ...setModel, appSetId: item.id, appSetName: item.title });
        } else {
            setDatas([]);
            setPaging(paging);
        }
    }

    const handleKeyword = (keyword) => {
        setKeyword(keyword);
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
                _getData({ ...paging, keyword: keyword });
            }, 1000)
        );
    };

    return (
        <Wrapper>
            <div className="box-product">
                <div className="box-product-header">
                    <div className="product-header-left">
                        <label htmlFor="">Chọn dòng sản phẩm</label>
                        <div className="instruction">
                            <span>Tùy dòng sản phẩm mà Ranus sẽ có những kiểu in khác nhau.</span>
                        </div>
                    </div>
                    <div className="product-header-right">
                        <div className="search">
                            <input
                                type="text"
                                placeholder="Tìm kiếm dòng sản phẩm sản phẩm"
                                value={keyword}
                                onChange={(e) => {
                                    setKeyword(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleKeyword(keyword);
                                    }
                                }}
                            />
                            <div className="icon">
                                <SearchIcon />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box-product-body">
                    <div className="list-item">
                        {datas &&
                            datas.length > 0 &&
                            datas.map((item, idx) => {
                                let listPrintTypeName = [];
                                if (item.setConfigModel && item.setConfigModel.listPrintType) {
                                    item.setConfigModel.listPrintType.map((item) => {
                                        listPrintTypeName.push(PRINT_TYPE_NAME[item]);
                                    });
                                }
                                return (
                                    <div className="item" key={idx}>
                                        <div className="item-avatar">
                                            <img src={item.setAvatar} alt="" />
                                        </div>
                                        <div className="item-info">
                                            <div className="item-name">{item.title}</div>
                                            <div className="total-item">{item.countProductName} dòng sản phẩm</div>
                                            <div className="upload-info">
                                                <div className="main-upload-info">Yêu cầu thiết kế không có Opacity (Độ mờ)</div>
                                                {item.setConfigModel && (
                                                    <ul className="list-require">
                                                        <li>Định dạng: {item.setConfigModel.format.join(", ")}</li>
                                                        <li>
                                                            Kích thước thiết kế: {item.setConfigModel.widthArtwork}x{item.setConfigModel.heightArtwork}cm, {item.setConfigModel.resolution}dpi
                                                        </li>
                                                        <li>Tối đa: {item.setConfigModel.maxMb}Mb</li>
                                                        {listPrintTypeName.length > 0 && <li>Loại in: {listPrintTypeName.join(", ")}</li>}
                                                    </ul>
                                                )}
                                            </div>
                                            <div className="item-action">
                                                <button
                                                    className="btn-select"
                                                    onClick={(e) => {
                                                        let model = { ...setModel };
                                                        model.appSetId = item.id;
                                                        model.appSetName = item.title;
                                                        model.appSetAvatar = item.setAvatar;
                                                        if (item.setConfigModel) {
                                                            model.printType = item.setConfigModel.printType;
                                                        }
                                                        setSetModel(model);

                                                        //nhảy qua bước tiếp theo
                                                        let st = [...step];
                                                        st.map((item, idx) => {
                                                            if (item.value == 2) item.isActive = true;
                                                            else item.isActive = false;
                                                        });
                                                        setStep(st);
                                                    }}
                                                >
                                                    Chọn
                                                </button>
                                                <button className="btn-detail">Chi tiết</button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

export default ProductVer2;

const Wrapper = styled.div`
    margin: 0 20px;
    overflow-y: auto;
    border-radius: 12px 12px 0px 0px;
    border: 1px solid #f3f3f3;
    background: #fff;
    padding: 13px 30px;

    .product-header-left label {
        color: #333;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .product-header-left .instruction {
        color: #5e5e5e;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 12px 0;
        span {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
        }
    }

    .box-product-header {
        display: flex;
        align-items: center;
    }

    .product-header-right {
        margin-left: auto;
    }

    .search {
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #e5e5e5;
        background: #fff;
        padding: 11px 15px;
        min-width: 420px;
    }

    .search input[type="text"] {
        border: none;
        width: 100%;
    }

    .search .icon {
        display: flex;
        margin-left: 12px;
    }

    .list-item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .item {
        width: calc(100% / 3 - 24px);
        margin: 12px;
        display: flex;
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
        padding: 12px;
        min-width: 520px;
    }

    .item-avatar {
        width: 160px;
        height: 160px;
        display: flex;
    }

    .item-avatar img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .item-info {
        margin-left: 12px;
    }

    .item-name {
        color: #333;
        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .total-item {
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 6px 0;
    }

    .upload-info {
        border-radius: 12px;
        background: #f1faff;
        padding: 11px 15px;
    }

    .main-upload-info {
        color: #13aaff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin: 6px 0;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    ul.list-require {
        padding: 0;
        color: #5d5d5d;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        list-style: none;
        margin: 6px 0;
    }

    .item-action {
        margin: 12px 0;
        display: flex;
        flex-wrap: wrap;
    }

    button.btn-select {
        margin: 6px;
        border: none;
        border-radius: 40px;
        background: #ff9027;
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 10px 30.5px;
        cursor: pointer;
    }

    button.btn-detail {
        margin: 6px;
        border: none;
        border-radius: 40px;
        background: #f8f8f8;
        color: black;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 10px 30.5px;
    }

    button.btn-select:focus {
        border: none;
    }

    .row {
        margin-right: 0;
        margin-left: 0;
    }
    .required:after {
        content: "*";
        color: #e62e04;
        display: inline-block;
        margin: 0 4px;
    }
`;

const SearchIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
            <path
                d="M8.67414 16.1886C12.9125 16.1886 16.3483 12.7885 16.3483 8.5943C16.3483 4.40008 12.9125 1 8.67414 1C4.43583 1 1 4.40008 1 8.5943C1 12.7885 4.43583 16.1886 8.67414 16.1886Z"
                stroke="#303030"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M14.312 14.1736L18.0861 17.9084" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
