import React, { useEffect, useState } from 'react';
import { CARD_COLOR } from '../../based/Constants';
import Textbox from '../../based/inputs/Textbox';
import LoadingR from '../../based/LoadingR';
import Button, { ButtonType } from '../../based/refactor/Button';
import CommonTable from '../../based/CommonTable';
import BaseServices from '../../based/services/BaseServices';
import RESOURCE_TYPE from './ResourceType';

const ORDER_BY = {
    DownloadTime: "DownloadTime",
    ResourceUrl: "ResourceUrl",
    ResourceType: "ResourceType",
    DownloadStatus: "DownloadStatus"
}
const INIT_PAGING = {
    pageNumber: 1,
    pageSize: 20,
    orderBy: ORDER_BY.DownloadTime,
    orderDirection: 'DESC',
    keyword: ''
}

function DownloadHistory(props) {
    const [data, setData] = useState(null);
    const [paging, setPaging] = useState(INIT_PAGING);
    const [busy, setBusy] = useState(false);


    useEffect(() => {
        if (props.fetchData)
            _fetchData(paging);
    }, [props.fetchData])

    async function _fetchData(pagingModel) {
        setBusy(true);
        const [err, resData] = await BaseServices.Post('/api/downloadresource/get-download-history', pagingModel);
        setBusy(false);
        if (!err && resData) {
            setData(resData.listObjects);
            setPaging(resData.paging);
        } else {
        }
    }

    function _handleFilter() {
        _fetchData(paging);
    }

    function _sortItems(sortBy) {
        let page = { ...paging };
        if (page.orderBy == sortBy) {
            if (page.orderDirection == 'DESC')
                page.orderDirection = 'ASC'
            else
                page.orderDirection = 'DESC'
        } else {
            page.orderBy = sortBy;
            page.orderDirection = 'DESC';
        }
        setPaging(page);
        _fetchData(page);
    }
    function _renderHeader() {
        const icon1 = paging.orderBy == ORDER_BY.ResourceType ? paging.orderDirection == 'DESC' ? <i className="fa fa-sort-desc fa-sort-thead"></i> : <i className="fa fa-sort-asc fa-sort-thead"></i> : null;
        const icon2 = paging.orderBy == ORDER_BY.ResourceUrl ? paging.orderDirection == 'DESC' ? <i className="fa fa-sort-desc fa-sort-thead"></i> : <i className="fa fa-sort-asc fa-sort-thead"></i> : null;
        const icon3 = paging.orderBy == ORDER_BY.DownloadTime ? paging.orderDirection == 'DESC' ? <i className="fa fa-sort-desc fa-sort-thead"></i> : <i className="fa fa-sort-asc fa-sort-thead"></i> : null;
        const icon4 = paging.orderBy == ORDER_BY.DownloadStatus ? paging.orderDirection == 'DESC' ? <i className="fa fa-sort-desc fa-sort-thead"></i> : <i className="fa fa-sort-asc fa-sort-thead"></i> : null;
        return <thead>
            <tr>
                <th>#</th>
                <th className='cursor-pointer' onClick={() => _sortItems(ORDER_BY.ResourceType)}>Nguồn download {icon1}</th>
                <th className='cursor-pointer' onClick={() => _sortItems(ORDER_BY.ResourceUrl)}>Link download {icon2}</th>
                <th className='cursor-pointer' onClick={() => _sortItems(ORDER_BY.DownloadTime)}>Ngày download {icon3}</th>
                <th className='cursor-pointer' onClick={() => _sortItems(ORDER_BY.DownloadStatus)}>Trạng thái {icon4}</th>
            </tr>
        </thead>
    }
    function _renderBody() {
        return <tbody>
            {data && data.length > 0 ? data.map((item, i) => {
                return <tr key={i}>
                    <td className='text-center'>{i + 1}</td>
                    <td className='text-center'>
                        {RESOURCE_TYPE.filter(m => m.resourceType == item.resourceType)[0].name}
                    </td>
                    <td>
                        <a href={item.url} target='_blank'>{item.url}</a>
                    </td>
                    <td className='text-center'>
                        {
                            item.downloadTimeText
                        }
                    </td>
                    <td>
                        {item.downloadStatus == 1 ? 'Thành công' : 'Thât bại'}
                    </td>
                </tr>
            })
                :
                <tr>
                    <td colSpan={5}>Không có dữ liệu</td>
                </tr>
            }
        </tbody>
    }
    function _renderAction() {
        return <div className='box__header d-flex justify-content-between' style={{ alignItems: 'baseline' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Số lượt tải còn lại trong ngày: <strong>{paging.remainingDownload}</strong></label>
            </div>
            <div className='form-group form-inline'>
                <div style={{ width: 200 }}>
                    <Textbox value={paging.keyword} name='keyword' id='keyword'
                        onChanged={(name, value) => setPaging({ ...paging, keyword: value })} placeholder='Nhập từ khóa tìm kiếm...' />
                </div>
                <Button btnType={ButtonType.Rounded} className='ml-4' title='Lọc dữ liệu' color={CARD_COLOR.Green} onClick={_handleFilter} />
            </div>
        </div>
    }

    return (
        <React.Fragment>
            <LoadingR fullScreen isShow={busy} />
            <label>Danh sách ảnh đã download:</label>
            <CommonTable
                paging={paging}
                renderHeader={_renderHeader}
                renderBody={_renderBody}
                onFilter={(paging) => _fetchData(paging)}
                renderAction={_renderAction}
            />
        </React.Fragment>
    );
}

export default DownloadHistory;