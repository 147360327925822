import { actionTypes } from '../types';

// Mockup Modal
export const openMockupModal = ({ itemId, type }) => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_OPEN_MOCKUP_MODAL,
      payload: { itemId, type },
    };
    dispatch(action);
  };
};

export const closeMockupModal = () => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_CLOSE_MOCKUP_MODAL,
      payload: null,
    };
    dispatch(action);
  };
};

export const addSubImageMockup = () => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_ADD_SUB_IMAGE_MOCKUP,
      payload: null,
    };
    dispatch(action);
  };
};

// Mockup Item
export const addMockup = () => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_ADD_MOCKUP,
      payload: null,
    };
    dispatch(action);
  };
};

export const editMockup = (itemId) => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_EDIT_MOCKUP,
      payload: itemId,
    };
    dispatch(action);
  };
};

export const insertMockup = (insertItemId) => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_INSERT_MOCKUP,
      payload: insertItemId,
    };
    dispatch(action);
  };
};

export const removeMockup = (itemId) => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_REMOVE_MOCKUP,
      payload: itemId,
    };
    dispatch(action);
  };
};
