import React from "react";
import { ArtworkModel, SetConfigModel, SetModel } from "../model/PreAddArtworkModel";
export const PRINT_SIDE = {
    Front: 1,
    Back: 2,
    Both: 3,
};
export class CreateArtworkContextModel {
    constructor() {
        this.artworkModels = [new ArtworkModel()];
        this.setArtworkModels = () => {};
        this.setModel = new SetModel();
        this.setSetModel = () => {};
        this.printSide = PRINT_SIDE.Both;
        this.setPrintSide = () => {};
        this.frame = {};
        this.setFrame = () => {};
        this.detail = {};
        this.setDetail = () => {};
        this.configModel = new SetConfigModel();
        this.setConfigModel = () => {};
        this.step = [];
        this.setStep = () => {};
        this.addRef = () => {};
        this.displayError = () => {};
        this.isValid = () => {};
    }
}

export const CreateArtworkContext = React.createContext(new CreateArtworkContextModel());
