export default class PreAddArtworkModel {
    constructor() {
        this.artworkModels = [new ArtworkModel()];
        this.setModel = new SetModel();
    }
}

export class ArtworkModel {
    constructor() {
        this.id = 0;
        this.url = "";
        this.thumbnail = "";
        this.artworkType = 0;
        this.optionTop = 30;
        this.optionTopOpposite = 30;
        this.isDefaultAvatar = 0;
        this.appSetId = 0;
        this.msgInvalid = "";
        this.isHover = false;
        this.tinyThumbnail = "";
        this.min = 0;
        this.max = 0;
    }
}

export class MockupDataModel {
    constructor() {
        this.image = {};
        this.frame = {};
    }
}

export class SetModel {
    constructor() {
        this.id = 0;
        this.title = "";
        this.titleEN = "";
        this.totalVariants = 0;
        this.setAvatar = "";
        this.status = 0;
        this.brandId = -2;
        this.topic = [];
        this.advantages = "";
        this.content = "";
        this.contentEN = "";
        this.tag = [];
        this.loyaltyAmount = 20000; //tiền bản quyền thực lĩnh
        this.lotusChargeAmount = 0; //ranus charge phí
        this.totalLoyaltyPrice = 0; //tổng số tiền
        this.seoMetaTitle = "";
        this.seoMetaKeywords = "";
        this.seoMetaDescription = "";
        this.manualReviewStatus = 0;
        this.remark = "";
        this.artworks = [];
        this.appProductVariantId = 0;
        this.appSetId = 0; //id của set sản phẩm phôi
        this.appSetName = ""; //tên của set sản phẩm phôi
        this.appSetAvatar = ""; //avatar của set sản phẩm phôi
        this.colorCode = ""; //màu sắc của sản phẩm
        this.loyaltyMethod = 1;
        this.printType = 0;
    }
}

export class SetConfigModel {
    constructor() {
        {
            this.format = [];
            this.widthArtwork = 0;
            this.heightArtwork = 0;
            this.resolution = 0;
            this.maxMb = 0;
            this.printType = 0;
            this.listPrintType = [];
            this.imageTypeValidate = 31;
            this.content = "";
            this.isShowCreator = true;
        }
    }
}
