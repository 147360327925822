import BaseServices from "./BaseServices";

const ReviewProductsService = {
    GetAllReviewProduct: async (paging) => {
        return await BaseServices.Post(`/api/ProductReviewCreator/get-review-by-creator`, paging);
    },
    GetProductReviewReplyByReviewId: async (id) => {
        return await BaseServices.Get(`/api/ProductReviewCreator/get-review-reply-by-previewId/${id}`);
    },
    AddOrUpdateReplyProductReview: async (model) => {
        return await BaseServices.Post(`/api/ProductReviewCreator/create-update-review`, model);
    },
};
export default ReviewProductsService;
