import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-currency-format';
import InputValidation from './InputValidation';

class TextNumber extends InputValidation {
  constructor(props) {
    super(props);
  }
  static defaultProps = {
    required: false,
    valid: true,
    className: '',
    min: 0,
    max: Number.MAX_SAFE_INTEGER,
    value: '',
    readOnly: false,
    displayType: 'input',
  };

  static propTypes = {
    id: PropTypes.string,
    required: PropTypes.bool,
    requiredMsg: PropTypes.string,
    valid: PropTypes.bool,
    invalidMessage: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    onChanged: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.number,
    readOnly: PropTypes.bool,
    suffix: PropTypes.string,
    price: PropTypes.bool,
    displayType: PropTypes.string,
    text: PropTypes.bool
  };

  handleChanged(value) {
    let val = value.value;
    if (!val) val = 0;
    let msgError = this.validateInput(val);
    if (this.state.isValid || (val >= this.props.min && val <= this.props.max)) this.props.onChanged(this.props.name, val);
    if (msgError !== '') {
      this.setState({
        isValid: false,
        msgInvalid: msgError,
      });
      this.props.onChanged(this.props.name, 0);
    } else {
      this.setState({
        isValid: true,
        msgInvalid: '',
      });
    }
  }
  handleBlur(e) {
    if (this.props.onBlur) {
      let currentVal = e.target.value;
      if (currentVal && currentVal.length > 0) {
        currentVal = currentVal.replace(/,/g, '');
        let val = parseFloat(currentVal);
        this.props.onBlur(this.props.name, val);
      } else {
        this.props.onBlur(this.props.name, 0);
      }
    }
  }
  render() {
    let displayType = this.props.displayType;
    let isText = displayType === 'text' || this.props.text;
    if (isText)
      displayType = 'text';
    let classInput = this.props.className;
    if (displayType === 'input') classInput += ' form-control';
    if (!this.state.isValid) classInput += ' alert-danger';
    let suffix = this.props.suffix ? this.props.suffix : this.props.price ? 'đ' : '';
    let nummberFormat = (
      <NumberFormat
        thousandSeparator=","
        className={classInput}
        decimalSeparator="."
        decimalScale={this.props.decimal ? 2 : 0}
        name={this.props.name}
        value={this.props.value}
        displayType={displayType}
        readOnly={this.props.readOnly}
        onValueChange={this.handleChanged}
        onFocus={(event) => event.target.select()}
        onBlur={this.handleBlur.bind(this)}
        suffix={isText ? suffix : ''}
        onKeyDown={this.handleEnter}
      />
    );
    return (
      <React.Fragment>
        {displayType === 'input' ? (
          <div className="input-group">
            {nummberFormat}
            {suffix ? (
              <div className="input-group-append">
                <span className="input-group-text">{suffix}</span>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          nummberFormat
        )}
        {this.state.isValid ? null : (
          <label className={'text-danger ' + this.props.invalidBoxClassName} htmlFor={this.props.id}>
            {this.state.msgInvalid}
          </label>
        )}
      </React.Fragment>
    );
  }
}
export default TextNumber;
