import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { CONSTANTS, NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import TextArea from "../based/refactor/TextArea";
import Textbox from "../based/refactor/Textbox";
import Request from "../based/Request";
import BrandServices from "../based/services/BrandServices";
import useFormValidate from "../hooks/useFormValidate";
import Breadcum from "../layout/refactor/Breadcum/Breadcum";

function CreateBrand(props) {
    const [brand, setBrand] = useState({});
    const { addRef, displayError, isValid } = useFormValidate();
    const [loading, setLoading] = useState(false);
    const [mainloading, setMainLoading] = useState(false);
    const { brandId } = props.match.params;

    const IMAGES = [".jpg", ".jpeg", ".png"];
    const history = useHistory();

    useEffect(() => {
        if (brandId > 0) _getBrand(brandId);
    }, [brandId]);

    const handleImageChange = (e) => {
        setLoading(true);
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let imageType = "brand";
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file, imageType)
                    .then((res) => {
                        if (res && res.data) {
                            setBrand({ ...brand, image: res.data.imageUrl });
                        } else {
                            let errMsg =
                                res.errors && res.errors.length > 0
                                    ? res.errors.reduce((prev, item, idx) => {
                                          return `${prev}${item.value}<br/>`;
                                      }, "")
                                    : "" || CONSTANTS.MSG_ERROR;
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
                        }
                        setLoading(false);
                    })
                    .catch((err) => {
                        setLoading(false);
                    });
            };

            reader.readAsDataURL(file);
        }
    };

    async function _handleSave() {
        if (isValid()) {
            setMainLoading(true);
            let [err, data] = await BrandServices.SaveBrand(brand);
            if (!err) {
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                history.push("/create-brand-info");
            } else {
                if (err.errors) {
                    let outOfConfig = err.errors.find((x) => x.key == "outOfConfig");
                    if (outOfConfig) {
                        Notify(NOTIFY.ERROR, "Lỗi", outOfConfig.value);
                    }
                    displayError(err.errors);
                } else Notify(NOTIFY.ERROR, "Lỗi", err.data);
            }
            setMainLoading(false);
        }
    }

    async function _getBrand(brandId) {
        setMainLoading(true);
        const [err, data] = await BrandServices.GetById(brandId);
        if (!err && data) {
            setBrand(data);
        } else {
            setBrand({});
        }
        setMainLoading(false);
    }

    async function _handleClose() {
        history.push("/brand");
    }

    function detectOfficeIcon(extension) {
        if (extension) {
            switch (extension) {
                case ".pdf":
                    return `${Request.CdnURL()}/images_system/icon_pdf.jpg`;
                case ".docx":
                    return `${Request.CdnURL()}/images_system/icon_word.png`;
                case ".xlsx":
                    return `${Request.CdnURL()}/images_system/icon_excel.jpg`;
                case ".ppt":
                    return `${Request.CdnURL()}/images_system/icon_powerpoint.png`;
                default:
                    return `${Request.CdnURL()}/images_system/icon-file.png`;
            }
        } else return `${Request.CdnURL()}/images_system/icon-file.png`;
    }

    function _handleChangeUpload(e) {
        e.preventDefault();
        let files = e.target.files;
        if (files && files.length > 0) {
            let documentType = "CertificateBrandOwnership";
            let sizeLimit = 5; //default nếu không truyền size thì lấy 5MB
            //nếu upload nhiều file
            let documents = [];
            let fileLimit = 5;
            if (brand.certificatesBrand && brand.certificatesBrand.length > 0) {
                brand.certificatesBrand.map((doc) => {
                    documents.push(doc);
                });
            }

            for (let index = 0; index < files.length; index++) {
                let file = files[index];
                if (file.size / 1048576 <= sizeLimit) {
                    let reader = new FileReader();
                    reader.onloadend = () => {
                        Request.UploadDocument(file, documentType)
                            .then((res) => {
                                if (res && res.data) {
                                    documents.push(res.data.url);
                                    setBrand({ ...brand, certificatesBrand: documents });
                                } else {
                                    let errMsg =
                                        res.errors && res.errors.length > 0
                                            ? res.errors.reduce((prev, item, idx) => {
                                                  return `${prev}${item.value}`;
                                              }, "")
                                            : "" || CONSTANTS.MSG_ERROR;
                                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
                                }
                            })
                            .catch((err) => {});
                    };
                    reader.readAsDataURL(file);
                } else {
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Vượt quá kích thước quy định");
                }
            }
        }
    }

    return (
        <React.Fragment>
            <Breadcum menu={["Trang chủ", "Quản lý thiết kế", "Đăng thiết kế"]} title="Thêm mới thương hiệu" />
            <Wrapper>
                <BrandWrapper>
                    <div className="brand__detail">
                        <div className="form-group">
                            <label htmlFor="name" className="required">
                                Tên thương hiệu:
                            </label>
                            <Textbox
                                id="name"
                                name="name"
                                required
                                ref={addRef}
                                maxLength={255}
                                className="form-control"
                                onChanged={(name, value) => setBrand({ ...brand, name: value })}
                                onBlur={(event) => setBrand({ ...brand, name: event.target.value.trim() })}
                                value={brand.name}
                                placeholder="Tên thương hiệu"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">Mô tả:</label>
                            <TextArea className="form-control" placeholder="Hãy mô tả đôi điều về thương hiệu của bạn" rows="10" value={brand.description} onChanged={(name, value) => setBrand({ ...brand, description: value })} />
                        </div>
                    </div>
                    <div className="brand__avatar">
                        <label htmlFor="name" className="required">
                            Tải ảnh đại diện:
                        </label>
                        <div className="brand__avatar-instruction">
                            <span style={{ color: "#28B259", fontWeight: 700 }}>*Tips:</span> Nên dùng ảnh có Logo thương hiệu vì nó sẽ được dùng làm hình ảnh đại diện cho thương hiệu của bạn.
                        </div>
                        <div className="brand__avatar-upload">
                            {brand.image ? <img src={brand.image} className="brand__avatar-image" /> : <div className="brand__avatar-image"></div>}
                            <div className="brand__avatar-button">
                                <label htmlFor="uploadBrandAvatar">Tải ảnh đại diện</label>
                                <input
                                    id="uploadBrandAvatar"
                                    type="file"
                                    className="d-none"
                                    onChange={(e) => {
                                        handleImageChange(e);
                                    }}
                                />
                                <p className="sub-title">Định dạng .JPG, .PNG Kích thước 500x500px Tối đa 3Mb</p>
                            </div>
                        </div>
                    </div>
                </BrandWrapper>
                <BrandInfoWrapper>
                    <div className="form-row col-12">
                        <div className="form-group col-md-6">
                            <label className="url" htmlFor="linkWebsite">
                                Link Website:
                            </label>
                            <Textbox
                                name="linkWebsite"
                                ref={addRef}
                                maxLength={500}
                                url
                                className="form-control"
                                onChanged={(name, value) => setBrand({ ...brand, linkWebsite: value })}
                                onBlur={(event) => setBrand({ ...brand, linkWebsite: event.target.value.trim() })}
                                value={brand.linkWebsite}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label className="url" htmlFor="facebookPage">
                                Facebook Page:
                            </label>
                            <Textbox
                                name="facebookPage"
                                ref={addRef}
                                url
                                maxLength={500}
                                className="form-control"
                                onChanged={(name, value) => setBrand({ ...brand, facebookPage: value })}
                                onBlur={(event) => setBrand({ ...brand, facebookPage: event.target.value.trim() })}
                                value={brand.facebookPage}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label className="url" htmlFor="instagram">
                                Instagram:
                            </label>
                            <Textbox
                                name="instagram"
                                ref={addRef}
                                url
                                maxLength={500}
                                className="form-control"
                                onChanged={(name, value) => setBrand({ ...brand, instagram: value })}
                                onBlur={(event) => setBrand({ ...brand, instagram: event.target.value.trim() })}
                                value={brand.instagram}
                            />
                        </div>
                    </div>
                </BrandInfoWrapper>
                <BrandDocumentWrapper>
                    <div className="title">Giấy tờ chứng minh quyền sở hữu thương hiệu</div>
                    <div className="brand__document">
                        {brand.certificatesBrand ? (
                            brand.certificatesBrand.map((item, idx) => {
                                let extension = item.substring(item.lastIndexOf("."), item.length);
                                let isImage = IMAGES.find((x) => x == extension) != null;
                                return <div className="brand__document-item">{isImage ? <img alt="ranus" src={item} /> : <img alt="ranus" src={detectOfficeIcon(extension)} />}</div>;
                            })
                        ) : (
                            <div className="brand__document-item"></div>
                        )}
                        <div className="brand__document-upload">
                            <div className="brand__document-button">
                                <label htmlFor="uploadDocuments">Tải lên tài liệu</label>
                                <input
                                    id="uploadDocuments"
                                    type={"file"}
                                    accept={".pdf,.jpg,.jpeg,.png"}
                                    className="d-none"
                                    onChange={(e) => {
                                        _handleChangeUpload(e);
                                    }}
                                />
                            </div>
                            <div className="brand__document-instruction">Định dạng .PDF, .JPG, .JPEG, .PNG Dung lượng tối đa 3Mb Tối đa 5 tệp tin</div>
                        </div>
                    </div>
                </BrandDocumentWrapper>
                <BrandButton>
                    <div className="brand__button-info">
                        Thông tin thương hiệu sẽ được <span>Ranus xét duyệt trong vòng 24H</span> kể từ lúc gửi yêu cầu.
                    </div>
                    <div className="brand__button-button">
                        <button className="brand__button-close" onClick={(e) => _handleClose()}>
                            Đóng
                        </button>
                        <button className={"brand__button-save " + `${mainloading ? "disabled" : ""}`} onClick={(e) => _handleSave()}>
                            Tạo thương hiệu
                        </button>
                    </div>
                </BrandButton>
            </Wrapper>
        </React.Fragment>
    );
}

CreateBrand.propTypes = {};

export default CreateBrand;

const Wrapper = styled.div`
    width: 100%;
`;
const BrandWrapper = styled.div`
    margin: 0 20px;
    display: flex;
    .brand__detail {
        width: 50%;
        background: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 1px 2px 20px rgb(0 0 0 / 10%);
        .form-group {
            label {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: #444444;
            }
        }
    }

    .brand__avatar {
        width: calc(50% - 20px);
        background: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 1px 2px 20px rgb(0 0 0 / 10%);
        margin-left: 20px;
        label {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #444444;
        }
        .brand__avatar-instruction {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #5d5d5d;
            margin: 20px 0;
        }
        .brand__avatar-upload {
            display: flex;
            .brand__avatar-image {
                width: 180px;
                height: 180px;
                background: #ecf8f0;
                border-radius: 10px;
                margin-right: 60px;
            }
            .brand__avatar-button {
                label {
                    background: #28b259;
                    border: none;
                    color: white;
                    padding: 10px 20px;
                    border-radius: 10px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                    color: #ffffff;
                }
                .sub-title {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #5d5d5d;
                    margin: 20px 0;
                }
            }
        }
    }

    .sub-title {
    }
`;

const BrandInfoWrapper = styled.div`
    margin: 20px;
    display: flex;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 1px 2px 20px rgb(0 0 0 / 10%);
    .form-group {
        label {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #444444;
        }
    }
`;

const BrandDocumentWrapper = styled.div`
    margin: 20px;
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 1px 2px 20px rgb(0 0 0 / 10%);
    .title {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        color: #444444;
    }
    .brand__document {
        display: flex;
        margin: 20px 0;
        .brand__document-item {
            height: 100px;
            width: 100px;
            background: #ecf8f0;
            border-radius: 10px;
            margin: 0 10px;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .brand__document-item:first-of-type {
            margin-left: -5px;
        }
        .brand__document-upload {
        }
        .brand__document-button {
            label {
                border: none;
                background: #28b259;
                color: white;
                padding: 10px 30px;
                border-radius: 10px;
            }
        }
        .brand__document-instruction {
            margin: 10px 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #5d5d5d;
        }
    }
`;

const BrandButton = styled.div`
    margin: 0 20px;
    display: flex;
    .brand__button-info {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #5d5d5d;
        span {
            color: #28b259;
            font-weight: 700;
        }
    }
    .brand__button-button {
        margin-left: auto;
        button {
            margin: 0 20px;
        }
        .brand__button-close {
            border: 1px solid #28b259;
            padding: 10px 40px;
            border-radius: 10px;
            color: 28B259;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #28b259;
        }
        .brand__button-save {
            border: none;
            padding: 10px 40px;
            border-radius: 10px;
            color: 28B259;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: white;
            background: #28b259;
        }
    }
`;
