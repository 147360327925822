import React from 'react'
import styled from 'styled-components'

export const WarningBar = styled.div`
width: 100%;
background: #ffbcaf;
border-radius: 13px;
display: ${({display})=>display===1?'flex':'none'};
flex-direction: row;
justify-content: space-between;
align-items: center;
margin-top: 15px;
border: none;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

.message_warning{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;
    span{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 10px;
        p{
            margin: 0;
            color: blue;
            cursor: pointer;
        }
    }
    i{
        color: #ff6d00;
    }
}
span.exit__icon{
        padding:7px 15px 10px 7px;
        color: #f44336;
        cursor: pointer;
    }

`
export const Loading = styled.div`
    position: relative;
    display: inline-block;
    width: 90%;
    height: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
    border-radius: 4px;
    overflow: hidden;
    background: #f1f1f1;
    &::after{
        content: '';
        position: absolute;
        left: 0;
        width: ${({ ratio }) => ratio ? ratio : 0}%;
        height: 100%;
        border-radius: 4px;
        box-shadow: 0 0 5px rgba(0, 0, 0, .2);
        background: #20c997;
    }
`
const Star = () => (<i className="fa fa-star" aria-hidden="true"></i>)
const HalfStar = () => (<i className="fa fa-star-half-o" aria-hidden="true"></i>)
export const StarRating = ({ rate }) => {

    if (rate) {
        if (Number(rate) > 0 && Number(rate) < 1) return <HalfStar />
        else if (Number(rate) >= 1 && Number(rate) < 1.5) return <React.Fragment><Star /></React.Fragment>
        else if (Number(rate) >= 2 && Number(rate) < 2.5) return (<React.Fragment><Star /><Star /></React.Fragment>)
        else if (Number(rate) >= 2.5 && Number(rate) < 3) return (<React.Fragment><Star /><Star /> <HalfStar /></React.Fragment>)
        else if (Number(rate) >= 3 && Number(rate) < 3.5) return (<React.Fragment><Star /><Star /><Star /></React.Fragment>)
        else if (Number(rate) >= 3.5 && Number(rate) < 4) return (<React.Fragment><Star /><Star /><Star /> <HalfStar /></React.Fragment>)
        else if (Number(rate) >= 4 && Number(rate) < 4.5) return (<React.Fragment><Star /><Star /><Star /><Star /></React.Fragment>)
        else if (Number(rate) >= 4.5 && Number(rate) < 5) return (<React.Fragment><Star /><Star /><Star /><Star /> <HalfStar /></React.Fragment>)
        else if (Number(rate) >= 5) return (<React.Fragment><Star /> <Star /><Star /><Star /><Star /></React.Fragment>)
    }else return ''
}