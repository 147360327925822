import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { INITPAGING, PACKAGE_TYPE, PRINT_TYPE_NAME } from "../../based/Constants";
import CommonModal from "../../based/Modal";
import SetProductServices from "../../based/services/SetProductServices";
import { CreateArtworkContext, PRINT_SIDE } from "../context/CreateArtworkContext";

function ProductVer2Modal({ show, onClose }) {
    const { setModel, setSetModel, setPrintSide } = useContext(CreateArtworkContext);
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING, pageSize: 12 });

    useEffect(() => {
        _getData({ ...paging });
    }, []);

    //Lấy thông tin app set theo paging
    async function _getData(paging) {
        const [err, data] = await SetProductServices.GetPagingCreatorArtwork(paging);
        if (!err && data && data.listObjects) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(paging);
        }
    }

    return (
        <CommonModal
            show={show}
            onClose={() => {
                onClose();
            }}
            size="lg"
            hideClose
        >
            <Wrapper>
                <div className="modal_header">
                    <h3>Đổi dòng sản phẩm khác</h3>
                    <div className="icon">
                        <CloseIcon />
                    </div>
                </div>
                <div className="modal_body">
                    <div className="list-item">
                        {datas &&
                            datas.length > 0 &&
                            datas.map((item, idx) => {
                                let listPrintTypeName = [];
                                if (item.setConfigModel && item.setConfigModel.listPrintType) {
                                    item.setConfigModel.listPrintType.map((item) => {
                                        listPrintTypeName.push(PRINT_TYPE_NAME[item]);
                                    });
                                }
                                return (
                                    <div className="item" key={idx}>
                                        <div className="item-avatar">
                                            <img src={item.setAvatar} alt="" />
                                        </div>
                                        <div className="item-info">
                                            <div className="item-name">{item.title}</div>
                                            <div className="total-item">{item.countProductName} dòng sản phẩm</div>
                                            <div className="upload-info">
                                                <div className="main-upload-info">Yêu cầu thiết kế không có Opacity (Độ mờ)</div>
                                                {item.setConfigModel && (
                                                    <ul className="list-require">
                                                        <li>Định dạng: {item.setConfigModel.format.join(", ")}</li>
                                                        <li>
                                                            Kích thước thiết kế: {item.setConfigModel.widthArtwork}x{item.setConfigModel.heightArtwork}cm, {item.setConfigModel.resolution}dpi
                                                        </li>
                                                        <li>Tối đa: {item.setConfigModel.maxMb}Mb</li>
                                                        {listPrintTypeName.length > 0 && <li>Loại in: {listPrintTypeName.join(", ")}</li>}
                                                    </ul>
                                                )}
                                            </div>
                                            <div className="item-action">
                                                <button
                                                    className="btn-select"
                                                    onClick={(e) => {
                                                        let model = { ...setModel };
                                                        model.appSetId = item.id;
                                                        model.appSetName = item.title;
                                                        model.appSetAvatar = item.setAvatar;
                                                        if (item.setConfigModel) {
                                                            model.printType = item.setConfigModel.printType;
                                                        }
                                                        setSetModel(model);

                                                        //đóng modal
                                                        onClose();
                                                    }}
                                                >
                                                    Chọn
                                                </button>
                                                <button className="btn-detail">Chi tiết</button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </Wrapper>
        </CommonModal>
    );
}

export default ProductVer2Modal;

const Wrapper = styled.div`
    .modal_header {
        display: flex;
        align-items: center;
    }

    .modal_header h3 {
        color: #333;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .modal_header .icon {
        display: flex;
        margin-left: auto;
    }
    .list-item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .item {
        width: calc(100% / 2 - 24px);
        margin: 12px;
        display: flex;
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
        padding: 12px;
    }

    .item-avatar {
        width: 160px;
        height: 160px;
        display: flex;
    }

    .item-avatar img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .item-info {
        margin-left: 12px;
    }

    .item-name {
        color: #333;
        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .total-item {
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 6px 0;
    }
    ul.list-require {
        padding: 0;
        color: #5d5d5d;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        list-style: none;
        margin: 6px 0;
    }

    .item-action {
        margin: 12px 0;
        display: flex;
        flex-wrap: wrap;
    }

    button.btn-select {
        margin: 6px;
        border: none;
        border-radius: 40px;
        background: #ff9027;
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 10px 30.5px;
    }

    button.btn-detail {
        margin: 6px;
        border: none;
        border-radius: 40px;
        background: #f8f8f8;
        color: black;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 10px 30.5px;
    }

    button.btn-select:focus {
        border: none;
    }
`;

const CloseIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
