import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { CARD_COLOR, PROJECT_EDITOR_ITEM_TYPE as ITEM_TYPE } from "../../based/Constants";
import { NOTIFY } from "../../based/Constants";
import { Notify } from "../../based/Notify";
import Modal from "../../based/Modal";
import TextBox from "../../based/inputs/Textbox";
import ImagePicker from "./ImagePicker";

// Redux
import { uploadCoverImage, createProject } from "../../redux/project-editor/actions";
import { selectProjectModel, selectItems } from "../../redux/project-editor/selector";
import BaseModal from "../../based/refactor/BaseModal";
import { BtnRounded } from "../../based/content/ct.elm";
const defaultImage = "/images/no-image.png";
const StatusValid = {
    Name: 1,
    Image: 2,
    Success: 0,
};
const ProjectModal = ({ isShow, project, items, onClose, onSave, uploadCoverImage, createProject }) => {
    const imgRef = useRef(null);
    const [projectModel, setProjectModel] = useState(project);

    useEffect(() => {
        const updatedProject = project;
        if (!updatedProject.coverUrl) {
            updatedProject.coverUrl = defaultImage;
        }
        setProjectModel({ ...projectModel, coverUrl: updatedProject.coverUrl });
    }, [project]);
    const pickImageHandler = () => {
        if (imgRef.current) imgRef.current.click();
    };

    const uploadCoverImageHandler = (imgFile) => {
        uploadCoverImage(imgFile);
    };

    const getTextItemHandler = ({ index, value, align }) => {
        return {
            index: index,
            type: ITEM_TYPE.TEXT,
            data: {
                value: value,
                align: align,
                isFullWidth: false,
            },
        };
    };

    const getImageItemHandler = ({ index, value, isFullWidth }) => {
        return {
            index: index,
            type: ITEM_TYPE.IMAGE,
            data: {
                align: "",
                value: value,
                isFullWidth: isFullWidth,
            },
        };
    };

    const getEmbedItemHandler = ({ index, value, isFullWidth }) => {
        return {
            index: index,
            type: ITEM_TYPE.EMBED,
            data: {
                align: "",
                value: value,
                isFullWidth: isFullWidth,
            },
        };
    };

    const getMockupItemHandler = ({ index, value, isFullWidth }) => {
        const newValue = {
            mainImage: value.mainImage,
            subImages: value.subImages.map((subImg) => {
                if (subImg.url) return { url: subImg.url, objectId: subImg.objectId };
            }),
        };
        return {
            index: index,
            type: ITEM_TYPE.MOCKUP,
            data: {
                align: "",
                value: JSON.stringify(newValue),
                isFullWidth: isFullWidth,
            },
        };
    };

    const getContentHandler = () => {
        return items.map((item, idx) => {
            switch (item.type) {
                case ITEM_TYPE.TEXT:
                    return getTextItemHandler({
                        index: idx,
                        value: item.data.value,
                        align: item.data.align,
                    });
                case ITEM_TYPE.IMAGE:
                    return getImageItemHandler({
                        index: idx,
                        value: item.data.url,
                        isFullWidth: item.data.isFullWidth,
                    });
                case ITEM_TYPE.EMBED:
                    return getEmbedItemHandler({
                        index: idx,
                        value: item.data.content,
                        isFullWidth: item.data.isFullWidth,
                    });
                case ITEM_TYPE.MOCKUP:
                    return getMockupItemHandler({
                        index: idx,
                        value: {
                            mainImage: item.data.mainImage,
                            subImages: item.data.subImages,
                        },
                        isFullWidth: item.data.isFullWidth,
                    });
                default:
                    return;
            }
        });
    };

    const createProjectHandler = async () => {
        const valid = () => {
            if (projectModel.name === "") return StatusValid.Name;
            if (projectModel.coverUrl === defaultImage) return StatusValid.Image;
            return StatusValid.Success;
        };
        switch (valid()) {
            case StatusValid.Name:
                Notify(NOTIFY.ERROR, "Thông tin còn thiếu", "Điền tên bộ sưu tập!");
                break;
            case StatusValid.Image:
                Notify(NOTIFY.ERROR, "Thông tin còn thiếu", "Thêm ảnh bìa!");
                break;
            case StatusValid.Success:
                {
                    const res = await createProject({
                        ...projectModel,
                        content: getContentHandler(),
                    });
                    if (res) {
                        const msg = `${project.id ? "Cập nhật" : "Tạo"} bộ sưu tập thành công.`;
                        Notify(NOTIFY.SUCCESS, "Thành công", msg);
                        onSave();
                    }
                }
                break;
            default:
                Notify(NOTIFY.ERROR, "Lỗi chưa xác định", "Lỗi!");
                break;
        }
    };

    return (
        <BaseModal title='Tạo bộ sưu tập' className='modal-width-40' onClose={onClose} isShow={isShow} onSave={createProjectHandler}>
            <div className='row w-100 d-flex flex-row'>
                <div className='form-group col-md-6 col-lg-6'>
                    <label htmlFor='coverUrl' className='required'>
                        Ảnh bìa
                    </label>
                    <div onClick={pickImageHandler}>
                        <ImagePicker imgRef={imgRef} onChange={uploadCoverImageHandler} disablePreview />
                        <img className='w-100' src={projectModel.coverUrl} alt='coverUrl' />
                    </div>
                </div>
                <div className='form-group col-md-4 col-lg-4'>
                    <label htmlFor='name' className='required'>
                        Tên
                    </label>
                    <TextBox required id='name' name='name' className='form-control' onChanged={(_, value) => setProjectModel({ ...projectModel, name: value })} value={projectModel.name} />
                </div>
            </div>
        </BaseModal>
    );
};

const mapStateToProps = createStructuredSelector({
    items: selectItems,
    project: selectProjectModel,
});

const mapDispatchToProps = (dispatch) => ({
    uploadCoverImage: (imgFile) => {
        return dispatch(uploadCoverImage(imgFile));
    },
    createProject: (model) => {
        return dispatch(createProject(model));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectModal);
