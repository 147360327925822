import axios from "axios";
import React, { Component, Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AffiliateSiteManagement from "./affiliate/affiliate-site/AffiliateSiteManagement";
import CreateArtworkProvider from "./artwork-manage/context/CreateArtworkProvider";
import CreateArtwork from "./artwork-manage/CreateArtwork";
import ManageArtwork from "./artwork-manage/ManageArtwork";
import ConfirmEmail from "./authen/ConfirmEmail";
import ForgetPassword from "./authen/ForgetPassword";
import Login from "./authen/Login";
import RefreshPassword from "./authen/RefreshPassword";
import Register from "./authen/Register";
import NotAcceptable from "./based/406Page";
import Common from "./based/Common";
import LoadingR from "./based/LoadingR";
import RouterConstants from "./based/RouteConstants";
import CommonServices from "./based/services/CommonServices";
import CreateBrand from "./brand/CreateBrand";
import CreateBrandInfo from "./brand/CreateBrandInfo";
import { default as Brand } from "./brand/refactor/Brand";
import CampaignBoosting from "./campaign-boostings/CampaignBoosting";
import CampaignMilestoneManagement from "./campaign-milestone/refactor/CampaignMilestoneManagement";
import ComingSoon from "./comming-soon-page/ComingSoon";
import { ApplicationContext, ApplicationInfoModel } from "./context/ApplicationContext";
import CreatorHomepage from "./creator-homepage/CreatorHomepage";
import CreateSuccess from "./creator-register/CreateSuccess";
import CreatorRegisterPage from "./creator-register/CreatorRegisterPage";
import CreateProfileInfo from "./creator/CreateProfileInfo";
import CreatorFeed from "./creator/CreatorFeed";
import CreatorProfileVer2 from "./creator/CreatorProfileVer2";
import FeedManagement from "./creator/FeedManagement";
import ProjectEditor from "./creator/ProjectEditor";
import ProjectEditorAuto from "./creator/ProjectEditorAuto";
import { Home } from "./dashboard/Home";
import DownloadResource from "./download-resources/DownloadResource";
import FinanceCreatorBrand from "./finance-creator-brand/FinanceCreatorBrand";
import LoyaltyManagement from "./finance-creator-loyalty/refactor/LoyaltyManagement";
import FinanceCreatorOverview from "./finance-creator-overview/FinanceCreatorOverview";
import LayoutLight from "./layout/refactor/Layout/Layout";
import ManageCollections from "./manage-collections/ManageCollections";
import ManageReviewProducts from "./manage-review-products/refactor/ManageReviewProducts";
import BankAccountsRefactor from "./profile/refactor/BankAccountsRefactor";
import ChangePasswordRefactor from "./profile/refactor/ChangePassRefactor";
import UserReferencesRefactor from "./profile/refactor/UserReferencesRefactor";
import { store } from "./redux/store";
import AdminDash from "./refactor/dashboard/AdminDash";
import ViolateProduct from "./violate-product/ViolateProduct";
import VoucherGiftManagement from "./voucher-gift/refactor/VoucherGiftManagement";

export default class App extends Component {
    displayName = App.name;
    constructor(props) {
        super(props);
        this.state = {
            applicationInfo: new ApplicationInfoModel(),
            location: window.location.href,
        };
        this.setApplicationInfo = this.setApplicationInfo.bind(this);
    }

    componentDidMount() {
        var token = Common.GetToken();
        if (window.location.href.indexOf(RouterConstants.ACCESS_DENIED) >= 0) return;
        if (token) {
            let _this = this;
            setTimeout(() => {
                _this.GetMenus();
            }, 1000);
        }
    }

    async GetMenus() {
        const [err, data] = await CommonServices.GetMenus();
        if (!err) {
            let appinfo = this.state.applicationInfo;
            appinfo.menus = data;
            this.setState({
                applicationInfo: appinfo,
            });
        }
    }

    setApplicationInfo(newData) {
        this.setState({ applicationInfo: newData });
    }

    render() {
        let obj = {
            applicationInfo: this.state.applicationInfo,
            setApplicationInfo: this.setApplicationInfo,
        };

        return (
            <ApplicationContext.Provider value={obj}>
                <React.StrictMode>
                    <Provider store={store}>
                        <Suspense fallback={<LoadingR fullScreen />}>
                            <BrowserRouter>
                                <MenuCreator />
                            </BrowserRouter>
                        </Suspense>
                    </Provider>
                </React.StrictMode>
            </ApplicationContext.Provider>
        );
    }
}

const MenuCreator = () => {
    useEffect(() => {
        const handleError = (event) => {
            var content = `creator.ranus.vn - [${new Date()}] - Location: ${window.location.href} - ${event.type}: ${event.message}`;
            if (process.process.env.NODE_ENV === "production") axios.post(`https://api.ranus.vn/api/Home/hook-data-error`, { error: content }).then((res) => {});
        };
        window.addEventListener("error", handleError);
        return () => {
            window.removeEventListener("error", handleError);
        };
    }, []);

    return (
        <Switch>
            {/* Tài khoản */}
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Register} />
            <Route path="/confirm-email" component={ConfirmEmail} />
            <Route path="/refresh-password" component={RefreshPassword} />
            <Route path="/forgot-password" component={ForgetPassword} />
            <Route exact path="/edit-creator-homepage" component={ComingSoon} />
            <Route exact path="/coming-soon" component={ComingSoon} />
            <Route exact path={RouterConstants.NOT_ACCEPTABLE} component={NotAcceptable} />
            <Route path="/creator-register" component={CreatorRegisterPage} />
            <Route path="/creator-register-success" component={CreateSuccess} />
            <LayoutLight>
                {/* Refactor module Creator */}
                <Route exact path="/" component={Home} />
                <Route exact path="/home-refactor" component={AdminDash} />
                {/* Quản lý thiết kế */}
                <Route path="/violate-product" component={ViolateProduct} />
                <Route path="/brand-creator" component={Brand} />
                <Route path="/create-brand/:brandId?" component={CreateBrand} />
                <Route path="/create-brand-info" component={CreateBrandInfo} />
                <Route path="/artworks" component={ManageArtwork} />
                <CreateArtworkProvider>
                    <Route path="/create-artwork/:setId?" component={CreateArtwork} />
                </CreateArtworkProvider>
                {/* Trang chủ nhà sáng tạo */}
                <Route path="/projects" component={ManageCollections} />
                <Route path="/review-product" component={ManageReviewProducts} />
                <Route exact path="/creator-homepage" component={CreatorHomepage} />
                {/* // Third Party */}
                <Route path="/creator/project-editor" exact component={ProjectEditor} />
                <Route path="/creator/project-editor/:id" component={ProjectEditor} />
                <Route path="/creator/feed" component={CreatorFeed} />
                <Route path="/creator/feed-management" component={FeedManagement} />
                <Route path="/creator/project-editor-auto/:id" exact component={ProjectEditorAuto} />
                <Route path="/creator/project-editor-auto/" exact component={ProjectEditorAuto} />
                {/* Khuyến mãi */}
                <Route path="/voucher-gift" component={VoucherGiftManagement} />
                <Route path="/campaign-milestone-management" component={CampaignMilestoneManagement} />
                <Route path="/campaign-boostings" component={CampaignBoosting} />
                {/* Tài chính */}
                <Route path="/finance-creator-overview" component={FinanceCreatorOverview} />
                <Route path="/finance-creator-loyalty" component={LoyaltyManagement} />
                <Route path="/finance-creator-brand" component={FinanceCreatorBrand} />
                {/* Thông tin cá nhân */}
                <Route path="/profile" component={CreatorProfileVer2} />
                <Route path="/profile-info" component={CreateProfileInfo} />
                <Route path="/bank-accounts" component={BankAccountsRefactor} />
                <Route path="/security" component={ChangePasswordRefactor} />
                <Route path="/user-references" component={UserReferencesRefactor} />
                {/* Affiliate */}
                <Route path="/affiliate-site-management" component={AffiliateSiteManagement} />
                {/* Thông tin và hỗ trợ */}
                <Route path="/download-resources" component={DownloadResource} />
            </LayoutLight>
        </Switch>
    );
};
