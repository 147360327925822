import { createSelector } from 'reselect';

const projectEditorSelector = (state) => state.projectEditor;

export const selectItems = createSelector(
  [projectEditorSelector],
  (projectEditor) => projectEditor.items.data
);

export const selectEmbedModal = createSelector(
  [projectEditorSelector],
  (projectEditor) => projectEditor.embedModal
);

export const selectMockupModal = createSelector(
  [projectEditorSelector],
  (projectEditor) => projectEditor.mockupModal
);

export const selectImageMockupModal = createSelector(
  [projectEditorSelector],
  (projectEditor) => projectEditor.mockupImageModal.data
);

export const selectImageMockupLoading = createSelector(
  [projectEditorSelector],
  (projectEditor) => projectEditor.mockupImageModal.isLoading
);

export const selectProjectModel = createSelector(
  [projectEditorSelector],
  (projectEditor) => projectEditor.project.data
);

export const selectProjectLoading = createSelector(
  [projectEditorSelector],
  (projectEditor) => projectEditor.project.isLoading
);
