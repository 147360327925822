import React, { useState } from 'react';
import { NOTIFY } from '../based/Constants';
import TextArea from '../based/inputs/TextArea';
import LoadingR from '../based/LoadingR';
import { Notify } from '../based/Notify';
import BaseServices from '../based/services/BaseServices';
import useFormValidate from '../hooks/useFormValidate';
import Breadcum from '../layout/refactor/Breadcum/Breadcum';
import DownloadHistory from './components/DownloadHistory';
import RESOURCE_TYPE from './components/ResourceType';

const menu = ['Trang chủ', 'Download hình ảnh']

function DownloadResource(props) {
    const [busy, setBusy] = useState(false);
    const [selectedResource, setSelectedResource] = useState(RESOURCE_TYPE[0]);
    const [resourceUrl, setResourceUrl] = useState('');
    const { addRef, isValid, displayError } = useFormValidate();
    function _handleClickResource(resource) {
        setSelectedResource(resource)
    }

    function extractFileName(url) {
        var array = url.split("freepik.com/");
        if (array.length > 1) {

            var name = array[1];
            var routers = name.split('/');
            if (routers.length > 1) {
                let router = routers[1];
                let name = router.split('_');
                if (name.length > 0)
                    return name[0] + '.zip';
            }
        }
        return 'unknown.zip';
    }

    async function _handleDownload() {
        if (isValid()) {
            setBusy(true);
            const [err, data] = await BaseServices.DownloadFile(`/api/downloadresource/download-file?rstype=${selectedResource.resourceType}&url=${resourceUrl}`, extractFileName(resourceUrl), true);
            setBusy(false);
            if (err) {
                Notify(NOTIFY.ERROR, 'Có lỗi xảy ra', 'Có lỗi trong quá trình download. Vui lòng thử lại sau.');
            }
            else {
                setResourceUrl('')
            }
        }
    }
    return (
        <React.Fragment>
            <Breadcum menu={menu} title='Download hình ảnh' />
            <LoadingR fullScreen isShow={busy} />
            <div className='download-resources'>
                <div>
                    <label htmlFor="">Chọn nguồn download:</label>
                </div>
                <div className='resource-items'>
                    {
                        RESOURCE_TYPE.map((item, idx) => {
                            let className = item.name == selectedResource.name ? 'selected' : '';
                            return <div onClick={() => _handleClickResource(item)} key={idx} className={'resource-item ' + className}>
                                <img src={item.logo} />
                            </div>
                        })
                    }
                </div>
                <hr />
                <label>Link ảnh cần download:</label>
                <div className='resource-url'>
                    <TextArea name='resourceUrl' id='resourceUrl' required ref={addRef} value={resourceUrl} onChanged={(name, value) => { setResourceUrl(value) }} />
                </div>
                <div className='m-t-10'>
                    <button className='btn btn-custom' onClick={_handleDownload}>Download</button>
                </div>
                <hr />
                <DownloadHistory fetchData={!busy} />
            </div>
        </React.Fragment>
    );
}

export default DownloadResource;