import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import Textbox from "../based/inputs/Textbox";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import AccountServices from "../based/services/AccountServices";
import BackgroundLogin from "./assets/BackgroundLogin.png";
import RanusCreatorLogo from "./assets/RanusCreatorLogo.png";
var token = Common.GetToken();

const STATUS_CONFIRM = {
    FALED: 0,
    SUCCESSFULLY: 1,
    CONFIRM: 2,
};
export default function ConfirmEmail(props) {
    const [statusConfirm, setStatusConfirm] = useState(STATUS_CONFIRM.CONFIRM);
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        if (window.location.search) confirm();
        else if (!token) props.history.push("/login");
    }, []);

    async function confirm() {
        setIsBusy(true);
        setMsg("Đang xác thực....");
        let [error, result] = await AccountServices.ConfirmEmail(window.location.search);
        if (!error) {
            setIsBusy(false);
            setMsg("Xác thực thành công. Vui lòng đăng nhập để sử dụng và trải nghiệm hệ thống quản trị");
            setStatusConfirm(STATUS_CONFIRM.SUCCESSFULLY);
        } else {
            setIsBusy(false);
            setMsg("Hiện tại link xác thực đã hết giá trị sử dụng. Xin vui lòng đăng ký lại");
            setStatusConfirm(STATUS_CONFIRM.FALED);
        }
    }

    function _handleReSendConfirm() {
        if (email) {
            setIsBusy(true);
            setMsg("Đang gửi xác thực....");
            Request.Post("/api/accounts/re-sendemail-confirm/" + email, null)
                .then((res) => {
                    if (res.success) {
                        setIsBusy(false);
                        setMsg("Gửi thông tin xác thực thành công. Vui lòng kiểm tra email và làm theo hướng dẫn");
                        setStatusConfirm(STATUS_CONFIRM.SUCCESSFULLY);
                    } else {
                        setIsBusy(false);
                        setMsg("Có lỗi xảy ra trong quá trình gửi xác thực. Vui lòng thử lại sau ít phút");
                        setStatusConfirm(STATUS_CONFIRM.FALED);
                    }
                })
                .catch((err) => {
                    setIsBusy(false);
                    setMsg(null);
                    Notify(NOTIFY.INFO, "Thông báo", err.message);
                });
        } else Notify(NOTIFY.INFO, "Thông báo", "Vui lòng nhập địa chỉ email đã đăng ký");
    }

    function _renderContent() {
        switch (statusConfirm) {
            case STATUS_CONFIRM.FALED:
                return <p>{msg} </p>;
            case STATUS_CONFIRM.CONFIRM:
                return (
                    <React.Fragment>
                        <p>Tài khoản của bạn chưa được xác thực. Vui lòng kiểm tra email và làm theo hướng dẫn</p>
                        <p>
                            <b>
                                <i>Nếu không nhận được email. Vui lòng nhập lại email đã đăng ký để thực hiện lại việc xác thực.</i>
                            </b>
                        </p>
                        <Textbox id="email" name="email" value={email} placeholder="Email đã đăng ký" className="form-control" onChanged={(name, value) => setEmail(value)} />
                    </React.Fragment>
                );
            case STATUS_CONFIRM.SUCCESSFULLY:
                return <p className="text-dark"> {msg} </p>;
            default:
                return <p>{msg} </p>;
        }
    }

    function _renderButton() {
        switch (statusConfirm) {
            case STATUS_CONFIRM.FALED:
                return (
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            props.history.push("/");
                        }}
                        className="btn-login"
                    >
                        Trang chủ
                    </a>
                );
            case STATUS_CONFIRM.CONFIRM:
                return (
                    <React.Fragment>
                        <a
                            className="btn-confirm"
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                _handleReSendConfirm();
                            }}
                        >
                            Gửi email xác thực
                        </a>
                        <div className="btn-relogin">
                            <p className="text-muted">
                                Đăng nhập với tài khoản khác?
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.history.push("/login");
                                    }}
                                    className="text-dark"
                                >
                                    <b>Đăng nhập</b>
                                </a>
                            </p>
                        </div>
                    </React.Fragment>
                );
            case STATUS_CONFIRM.SUCCESSFULLY:
                return (
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            props.history.push("/login");
                        }}
                        className="btn-login"
                    >
                        Đăng nhập
                    </a>
                );
        }
    }
    return (
        <Wrapper>
            <section className="creator-background-account">
                <div className="account-content-body">
                    <div className="content-body">
                        <div className="body-left">
                            <img className="background-body" atl="lotus" src={BackgroundLogin} />
                            <div className="header-creator cursor-pointer">
                                <img
                                    alt="ranus"
                                    src={RanusCreatorLogo}
                                    onClick={() => {
                                        window.location.href = "/";
                                    }}
                                />
                            </div>
                        </div>
                        <div className="body-right">
                            <div className="main-account">
                                <h4 className="login-header">Xác thực Email</h4>
                                <div className="account-content">
                                    {_renderContent()}
                                    <div className="form-group text-center">
                                        <div className="col-12">{_renderButton()}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    a.btn-login {
        border-radius: 12px;
        background: var(--Cam-Thng-hiu, #ff9027);
        width: 100%;
        display: block;
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 12px;
    }

    .account-content p {
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 0 10px;
        margin: 24px 0;
    }

    .content-body {
        display: flex;
    }

    .body-left {
        width: 50%;
        height: 100vh;
        position: relative;
        background: #fff5ec;
    }

    img.background-body {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .header-creator.cursor-pointer {
        position: absolute;
        z-index: 1;
        top: 61px;
        left: 55px;
    }

    .body-right {
        flex: 1;
        background: white;
        position: relative;
    }

    .main-account {
        width: 450px;
        display: flex;
        flex-direction: column;
        height: auto;
        position: absolute;
        top: calc(40% - 225px);
        margin-left: 80px;
    }

    h4.login-header {
        color: #303030;
        font-family: Lexend;
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
    }

    .form-group {
        margin-bottom: 24px;
        position: relative;
    }

    .form-group input {
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
        color: #303030 !important;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        padding: 11px 16px;
        height: auto;
        position: relative;
    }

    .login-input {
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
        width: 100%;
        padding: 11px 16px;
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .form-group-login {
        margin: 12px 0;
    }

    .icon {
        display: flex;
        position: absolute;
        top: 11px;
        right: 16px;
        width: 24px;
        height: 24px;
        align-items: center;
    }

    .form-area {
        position: relative;
    }

    button.btn-ranus {
        border-radius: 12px;
        background: var(--Cam-Thng-hiu, #ff9027);
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        border: none;
        padding: 12px;
    }

    .login-section {
        margin: 12px 0;
    }

    button.btn-forgot {
        border: none;
        background: none;
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 12px;
    }

    span.register-section {
        text-align: center;
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 12px 0;
    }

    button.btn-ranus-register {
        border: none;
        background: none;
        color: var(--Cam-Thng-hiu, #ff9027);
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 8px;
    }

    h4.login-header {
        padding: 0 10px;
    }

    .form-input-action input#email {
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
        padding: 11px 16px;
        height: auto;
    }

    .form-input-action {
        margin: 12px 0;
    }

    .btn-action {
        margin: 0 10px;
        border-radius: 12px;
        background: #ff9027;
        color: #fff !important;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        padding: 12px;
    }

    a.btn-reset {
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    p.text-muted {
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .p.text-muted a.text-dark {
        color: var(--Cam-Thng-hiu, #ff9027);
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    p.text-muted b {
        color: #ff9027;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .form-input-action label.required {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .account-content input#email {
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
        padding: 11px 16px;
        height: auto;
    }

    a.btn-confirm {
        border-radius: 12px;
        background: var(--Cam-Thng-hiu, #ff9027);
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        display: block;
        padding: 12px;
        margin: 24px 0;
    }

    .form-group .col-12 {
        padding: 0;
    }

    .text-muted b {
        margin-left: 4px;
    }
`;
