export default class ProductReviewModel {
    constructor() {
        this.createdBy = "";
        this.createdDate = new Date();
        this.id = 0;
        this.replyId = 0;
        this.productId = 0;
        this.orderProductId = 0;
        this.reviewComment = "";
        this.isIncognito = false;
        this.artworkStarRate = 0;
        this.printStarRate = 0;
        this.totalStarRate = 0;
        this.images = [];
        this.userId = 0;
        this.userFirstName = "";
        this.userLastName = "";
        this.userAvatar = "";
        this.productName = "";
        this.productAvatar = "";
        this.userFullName = "";
        this.replyProductReview = {};
        this.attributeValues = [];
        this.printType = 0;
        this.printTypeName = "";
        this.appProductName = "";
        this.replyProductReviewComment = "";
    }
}
