import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { CARD_COLOR, PROJECT_EDITOR_MODAL_TYPE as EMBED_MODAL_TYPE } from "../../based/Constants";
import { BtnRounded } from "../../based/content/ct.elm";
import Modal from "../../based/Modal";
import BaseModal from "../../based/refactor/BaseModal";

// Redux
import { addEmbed, editEmbed, insertEmbed, closeEmbedModal } from "../../redux/project-editor/actions";
import { selectEmbedModal } from "../../redux/project-editor/selector";

const EmbebModal = ({ embedModal, addEmbed, editEmbed, insertEmbed, closeEmbedModal }) => {
    const [content, setContent] = useState(embedModal.content);
    const [isValidContent, setIsValidContent] = useState(true);

    useEffect(() => {
        setContent(embedModal.content);
    }, [embedModal.isShow, embedModal.content]);

    useEffect(() => {
        if (embedModal.type === EMBED_MODAL_TYPE.ERROR) {
            setIsValidContent(false);
        }
    }, [embedModal.type]);

    const checkContentIsValidHandler = () => {
        if (content.includes("iframe")) return true;
        return false;
    };

    const addEmbedHandler = () => {
        addEmbed(content);
        setContent("");
    };

    const editEmbedHandler = () => {
        editEmbed(content);
        setContent("");
    };

    const insertEmbebHandler = () => {
        insertEmbed(content);
        setContent("");
    };

    const saveEmbedHandler = () => {
        const isValid = checkContentIsValidHandler();
        if (isValid) {
            setIsValidContent(true);

            switch (embedModal.type) {
                case EMBED_MODAL_TYPE.ADD:
                    return addEmbedHandler();
                case EMBED_MODAL_TYPE.EDIT:
                    return editEmbedHandler();
                case EMBED_MODAL_TYPE.INSERT:
                    return insertEmbebHandler();
                default:
                    return;
            }
        } else {
            setIsValidContent(false);
        }
    };

    const closeEmbedModalHandler = () => {
        closeEmbedModal();
    };
    const renderFooterModal = () => {
        return (
            <div className="row w-100 modal__footer d-flex justify-content-end m-r-15">
                <BtnRounded width="130px" color={CARD_COLOR.Green} onClick={saveEmbedHandler}>
                    Lưu
                </BtnRounded>
                <BtnRounded width="130px" color={CARD_COLOR.Red} onClick={closeEmbedModalHandler}>
                    Hủy
                </BtnRounded>
            </div>
        );
    };
    return (
        <BaseModal className="modal-width-40" title="Nhúng" onClose={closeEmbedModalHandler} isShow={embedModal.isShow} onSave={saveEmbedHandler}>
            <textarea rows="20" className="w-100" value={content} placeholder="<iframe>...</iframe>" onChange={(e) => setContent(e.target.value)} />
            {!isValidContent && <span style={{ color: "red" }}>Iframe không hợp lệ</span>}
        </BaseModal>
        // <Modal
        //   title="Nhúng"
        //   show={embedModal.isShow}
        //   onClose={closeEmbedModalHandler}
        //   onSave={saveEmbedHandler}
        // >
        // <textarea
        //   rows="10"
        //   className="w-100"
        //   value={content}
        //   placeholder="<iframe>...</iframe>"
        //   onChange={(e) => setContent(e.target.value)}
        // />
        // {!isValidContent && (
        //   <span style={{ color: 'red' }}>Iframe không hợp lệ</span>
        // )}
        // </Modal>
    );
};

const mapStateToProps = createStructuredSelector({
    embedModal: selectEmbedModal,
});

const mapDispatchToProps = (dispatch) => ({
    closeEmbedModal: () => {
        return dispatch(closeEmbedModal());
    },
    addEmbed: (content) => {
        return dispatch(addEmbed(content));
    },
    editEmbed: (newContent) => {
        return dispatch(editEmbed(newContent));
    },
    insertEmbed: (newContent) => {
        return dispatch(insertEmbed(newContent));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmbebModal);
