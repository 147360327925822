import React, { useEffect, useState } from "react";
import NumberFormat from "react-currency-format";
import Common from "../../based/Common";
import { ARTWORK_STATUS } from "../../based/Constants";
import { Box, ButtonBlackRounded } from "../../based/content/ct.elm";
import Loading from "../../based/Loading";
import CommonTable from "../../based/refactor/CommonTable";
import FinanceServices from "../../based/services/FinanceServices";
import Breadcum from "../../layout/refactor/Breadcum/Breadcum";
import AddEditLoyalty from "../AddEditLoyalty";
const menuItem = ["Trang chủ", "Quản lý tiền bản quyền"];
export default function LoyaltyManagement(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState({ ...Common.Paging, status: ARTWORK_STATUS.Online });
    const [datas, setDatas] = useState([]);
    const [tabIndex, setTabIndex] = useState(ARTWORK_STATUS.Online);
    const [isShow, setIsShow] = useState(false);
    const [setId, setSetId] = useState(0);

    useEffect(() => {
        getDatas(paging, tabIndex);
    }, []);

    /** lấy thông tin management */
    async function getDatas(newPaging, tab) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await FinanceServices.GetLoyaltyManagement(newPaging, tab);
        if (!error && data) {
            let pagingUp = { ...data.paging, status: newPaging.status };
            setDatas(data.listObjects);
            setPaging(pagingUp);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    async function _handleChangeTab(tab) {
        if (tabIndex != tab) {
            let pagingUp = { ...paging, status: tab };
            setTabIndex(tab);
            getDatas(pagingUp, tab);
        }
    }
    function _renderHeader() {
        return (
            <tr>
                <th width="50">STT</th>
                <th width="200" className="text-center">
                    Tên thiết kế, ID
                </th>
                <th width="200" className="text-center">
                    Thumbnail mặt trước
                </th>
                <th width="200" className="text-center">
                    Thumbnail mặt sau
                </th>
                <th width="200" className="text-center">
                    Tiền bản quyền (đ)
                </th>
                <th width="200" className="text-center">
                    Các dòng sản phẩm
                </th>
                <th width="100" className="text-center">
                    Số lượng đã bán
                </th>
                <th className="text-center">Voucher áp dụng</th>
                <th width="150" className="text-center">
                    Ngày áp dụng
                </th>
            </tr>
        );
    }
    function _renderBody() {
        return (
            <React.Fragment>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td className="vertical-align-middle text-center">{idx + 1}</td>
                                <td className="vertical-align-middle text-center">
                                    {item.title}
                                    <p>{item.id}</p>
                                </td>
                                <td className="vertical-align-middle text-center">{item.thumbnailFront ? <img src={item.thumbnailFront} alt={item.title} style={{ width: "200px", height: "auto", objectFit: "cover" }} /> : ""}</td>
                                <td className="vertical-align-middle text-center">{item.thumbnailBack ? <img src={item.thumbnailBack} alt={item.title} style={{ width: "200px", height: "auto", objectFit: "cover" }} /> : ""}</td>
                                <td className="vertical-align-middle text-center">
                                    <p>
                                        Bản quyền: <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={item.loyaltyAmount} />
                                    </p>
                                    <p>
                                        BQ nhận được: <NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={item.receivedAmount} />
                                    </p>
                                    <p>
                                        <button
                                            className="btn btn-custom"
                                            onClick={(e) => {
                                                setSetId(item.id);
                                                setIsShow(true);
                                            }}
                                            style={{ borderRadius: "50px" }}
                                        >
                                            Thay đổi &nbsp;
                                            <i className="fa fa-pencil cursor-poiter text-primary" title="Thay đổi"></i>
                                        </button>
                                    </p>
                                </td>
                                <td className="vertical-align-middle text-center">
                                    {item.listAppProductName &&
                                        item.listAppProductName.length > 0 &&
                                        item.listAppProductName.map((item, key) => {
                                            return <p key={key}>{item}</p>;
                                        })}
                                </td>
                                <td className="vertical-align-middle text-center">{item.totalSell}</td>
                                <td className="vertical-align-middle text-center">
                                    {item.vouchers &&
                                        item.vouchers.length > 0 &&
                                        item.vouchers.map((voucher, v) => {
                                            return <p key={v}>{voucher.name}</p>;
                                        })}
                                </td>
                                <td className="vertical-align-middle text-center">
                                    {item.vouchers &&
                                        item.vouchers.length > 0 &&
                                        item.vouchers.map((voucher, v) => {
                                            return (
                                                <p key={v}>
                                                    {voucher.fromDateDisplay} - {voucher.toDateDisplay}
                                                </p>
                                            );
                                        })}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan="10" className="text-center">
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </React.Fragment>
        );
    }
    function _renderAction() {}
    return (
        <React.Fragment>
            <Breadcum menu={menuItem} title="Quản lý tiền bản quyền" />
            <Loading show={isLoading} msg={msg} />
            <Box>
                <div className="box__header">
                    <ButtonBlackRounded className={tabIndex == ARTWORK_STATUS.Online ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangeTab(ARTWORK_STATUS.Online)}>
                        <span>Sản phẩm đang hiển thị</span>
                    </ButtonBlackRounded>
                    <ButtonBlackRounded className={tabIndex == ARTWORK_STATUS.WaitingTotal ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangeTab(ARTWORK_STATUS.WaitingTotal)}>
                        <span>Chờ duyệt</span>
                    </ButtonBlackRounded>
                    <ButtonBlackRounded className={tabIndex == ARTWORK_STATUS.Offline ? "nav-link active" : "nav-link btn-light"} onClick={() => _handleChangeTab(ARTWORK_STATUS.Offline)}>
                        <span>Sản phẩm đang tắt</span>
                    </ButtonBlackRounded>
                </div>
                <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging, tabIndex)} searchBasic={false} />
            </Box>
            <AddEditLoyalty
                isShow={isShow}
                onClose={() => setIsShow(false)}
                setId={setId}
                onSave={() => {
                    setIsShow(false);
                    setSetId(0);
                    getDatas({ ...paging }, tabIndex);
                }}
            />
        </React.Fragment>
    );
}
