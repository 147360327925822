import React, { useEffect, useState } from "react";
import Common from "../based/Common";
import { CONSTANTS, NOTIFY } from "../based/Constants";
import Textbox from "../based/refactor/Textbox";
import TextNumber from "../based/refactor/TextNumber";
import { Notify } from "../based/Notify";
import useFormValidate from "../hooks/useFormValidate";
import RateCardConfigModal from "./RateCardConfigModal";
import PrintServices from "../based/services/PrintServices";
import VoucherServices from "../based/services/VoucherServices";
import TextArea from "../based/refactor/TextArea";
import { Box, BtnRounded } from "../based/content/ct.elm";
import BaseModal from "../based/refactor/BaseModal";

export default function AddOrEdit(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [voucher, setVoucher] = useState({});
    const { addRef, displayError, isValid } = useFormValidate();
    const [showRateCard, setShowRateCard] = useState(false);
    const [readRateCard, setReadRateCard] = useState([]);
    const [codeValid, setCodeValid] = useState({ status: 1, message: "" });
    useEffect(() => {
        setVoucher(props.voucher);
        getReadRateCard();
    }, [props.isShowModal]);
    async function getReadRateCard() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await PrintServices.ReadRateCard();
        if (!error && data) {
            setReadRateCard(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setReadRateCard([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    async function _handleSave(isContinue) {
        if (isValid() && codeValid.status === 1) {
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu..");
            let [err, data] = await VoucherServices.SaveVoucherGift(voucher);
            if (!err) {
                setIsLoading(null);
                setMsg(false);
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                props.onSaveSucceed(isContinue);
                if (isContinue) setVoucher(props.voucher);
                else props.onClose();
            } else {
                setIsLoading(null);
                setMsg(false);
                if (err.errors) {
                    let notifyError = err.errors.find((x) => x.key == 'notifyError');
                    if (notifyError) Notify(NOTIFY.ERROR, "Lỗi", notifyError.value);
                    else displayError(err.errors);
                } else Notify(NOTIFY.ERROR, "Lỗi", err.data);
            }
        }
    }
    function setAmount(amount) {
        let lotusChargeAmount = Common.SetLotusChargeAmount(amount, readRateCard);
        setVoucher({ ...voucher, loyaltyAmount: amount, lotusChargeAmount: lotusChargeAmount });
    }
    const handleRegexCodeVoucher = (value) => {
        setVoucher({ ...voucher, code: value });
        let regex = new RegExp(/[!@# $%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/);
        if (regex.test(value)) setCodeValid({ status: 2, message: CONSTANTS.INVALID_VOUCHER_NAME });
        else if (value.length && value.length < 6) setCodeValid({ status: 2, message: CONSTANTS.MSG_MIN_LENGTH_INVALID.replace("##", "06") });
        else if (value.length && value.length > 15) setCodeValid({ status: 2, message: CONSTANTS.MSG_MAX_LENGTH_INVALID.replace("##", "15") });
        else setCodeValid({ status: 1, message: "" });
    };
    return (
        <BaseModal className='modal-width-40' title='Thêm mới' isShow={props.isShowModal}
            onClose={() => props.onClose()}
            onSave={_handleSave}>
            {showRateCard && <RateCardConfigModal showRateCard={showRateCard} readRateCard={readRateCard} onClose={() => setShowRateCard(false)} />}
            <Box>
                <div className="box__card">
                    <div className='form-group'>
                        <div className='label__group'>
                            <label htmlFor='code' className='required'>
                                Mã Voucher:
                            </label>
                            <div className='suggestions .ext-info'>
                                <i className='fa fa-exclamation-circle' aria-hidden='true'></i>&nbsp;
                            </div>
                        </div>
                        <Textbox name='code' required ref={addRef} maxLength={200} className={`form-control font-weight-bold ${codeValid.status === 2 ? "alert-danger" : ""}`} onChanged={(name, value) => handleRegexCodeVoucher(value.trim())} value={voucher.code} />
                        {codeValid.status === 2 ? <span className='text-danger'>{codeValid.message}</span> : null}
                    </div>
                    <div className='form-group'>
                        <label htmlFor='name' className='required'>
                            Tên Voucher:
                        </label>
                        <Textbox name='name' required ref={addRef} maxLength={200} className='form-control' onChanged={(name, value) => setVoucher({ ...voucher, name: value })} value={voucher.name} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='appliedQuantity'>Số lượng sản phẩm trong giỏ hàng áp dụng:</label>
                        <TextNumber id='appliedQuantity' ref={addRef} name='appliedQuantity' className='form-control' min={1} max={999999} value={voucher.appliedQuantity} onChanged={(name, value) => setVoucher({ ...voucher, appliedQuantity: value })} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='loyaltyAmount' className='required'>
                            Số tiền bản quyền <i className='fa fa-info-circle' onClick={(e) => setShowRateCard(true)} style={{ color: "firebrick", cursor: "pointer" }}></i>:
                        </label>
                        <TextNumber required ref={addRef} id='loyaltyAmount' suffix='đồng' name='loyaltyAmount' className='form-control' value={voucher.loyaltyAmount} onChanged={(name, value) => setAmount(value)} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='lotusChargeAmount'>Phí hoạt động Ranus:</label>
                        <TextNumber ref={addRef} id='lotusChargeAmount' suffix='đồng' name='lotusChargeAmount' className='form-control' readOnly value={voucher.lotusChargeAmount} onChanged={(name, value) => { }} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='description'>Mô tả:</label>
                        <TextArea ref={addRef} name='description' value={voucher.description} onChanged={(name, value) => setVoucher({ ...voucher, description: value })} />
                    </div>
                </div>
            </Box>
        </BaseModal>
    );
}
