import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import Slider from 'react-slick';

import { PROJECT_EDITOR_MODAL_TYPE as MODAL_TYPE } from '../../based/Constants';

// Redux
import {
  openMockupModal,
  removeMockup,
  toggleFullWidth,
} from '../../redux/project-editor/actions';

const SETTINGS = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};

const ProjectMockup = ({
  id,
  isFullWidth,
  mainImage,
  subImages,
  openMockupModal,
  removeMockup,
  toggleFullWidth,
}) => {
  const [settings, setSettings] = useState({ ...SETTINGS });
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const width = window.innerWidth;
    if (width < 991) {
      setSettings({ ...settings, slidesToShow: 1, slidesToScroll: 1 });
    } else if (width > 991) {
      setSettings({ ...settings, slidesToShow: 2, slidesToScroll: 1 });
    }
  }, [window.innerWidth]);

  const toggleFullWidthHandler = () => {
    toggleFullWidth(id);
  };

  const openMockupModalHandler = () => {
    openMockupModal({ itemId: id, type: MODAL_TYPE.EDIT });
  };

  const removeMockupHandler = () => {
    removeMockup(id);
  };

  return (
    <div
      className="project-mockup-wrapper cursor-pointer"
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <div className="row align-items-center justify-content-center px-0 mx-0">
        <div
          className={`project-mockup px-0 ${isFullWidth ? 'col-12' : 'col-10'}`}
        >
          {/* Dropdown Button */}
          <div
            className={`project-mockup__dropdown d-inline-block ${
              isActive ? 'show' : 'hide'
            }`}
          >
            <Dropdown className="shadow rounded-circle">
              <Dropdown.Toggle
                className="rounded-circle btn-sm"
                variant="primary"
              />
              <Dropdown.Menu>
                <Dropdown.Item onClick={openMockupModalHandler}>
                  Chỉnh sửa
                </Dropdown.Item>
                <Dropdown.Item onClick={removeMockupHandler}>Xóa</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {/* Toggle Button */}
          <div
            className={`project-mockup__resize border-rounded bg-dark rounded cursor-pointer shadow ${
              isActive ? 'show' : 'hide'
            }`}
            onClick={toggleFullWidthHandler}
          >
            <i className="fa fa-arrows-h px-2 py-1 text-white" />
          </div>

          <div className="row mx-0">
            {/* Fixed Image */}
            <div className="col-sm-6 col-md-6 col-lg-4 px-0">
              <div className="project-mockup__fixed-img border">
                <img className="w-100" src={mainImage.url} alt="main-image" />
              </div>
            </div>

            {/* Slider Images */}
            <div className="col-sm-6 col-md-6 col-lg-8 px-0">
              <div className="project-mockup__slider">
                <Slider {...settings}>
                  {subImages.map((subImg) => (
                    <div
                      key={subImg.id}
                      className="border d-inline-block overflow-hidden"
                    >
                      <img className="w-100" src={subImg.url} alt="sub-image" />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openMockupModal: ({ itemId, type }) => {
    return dispatch(openMockupModal({ itemId, type }));
  },
  removeMockup: (itemId) => {
    return dispatch(removeMockup(itemId));
  },
  toggleFullWidth: (itemId) => {
    return dispatch(toggleFullWidth(itemId));
  },
});

export default connect(null, mapDispatchToProps)(ProjectMockup);
