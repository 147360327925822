import React, { Component } from "react";
import { CONSTANTS } from "../Constants";

class InputValidation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: true,
            msgInvalid: "",
            value: "",
        };
        this.handleChanged = this.handleChanged.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
    }

    displayError(msg) {
        this.setState({
            isValid: false,
            msgInvalid: msg,
        });
    }

    handleChanged(e) {
        let value = e.target.value;
        console.log("value :>> ", value);
        let msgError = this.validateInput(value);
        if (msgError !== "") {
            this.setState({
                isValid: false,
                msgInvalid: msgError,
            });
        } else {
            this.setState({
                isValid: true,
                msgInvalid: "",
            });
        }
        this.props.onChanged(this.props.name, value);
    }
    handleFocus() {
        if (this.props.onFocus) this.props.onFocus();
    }
    handleKeyUp() {
        if (this.props.onKeyUp) this.props.onKeyUp();
    }
    handleEnter(e) {
        if (this.props.number && (e.key.toLowerCase() == "e" || e.key == "-")) return e.preventDefault();
        if (e.key === "Enter" && this.props.handleEnter) this.props.handleEnter();
    }
    onlySpaces(str) {
        if (str && typeof str === "string") return str.trim().length === 0;
        return false;
    }
    validateInput(value) {
        let msg = "";
        if (value === undefined) value = this.state.value;
        if (value == null || value === "" || this.onlySpaces(value)) {
            if (this.props.required) msg = this.props.requiredMsg ? this.props.requiredMsg : CONSTANTS.MSG_REQUIRED;
        } else {
            if (value.length < this.props.minLength) msg = this.props.minLengthMsg ? this.props.minLengthMsg : CONSTANTS.MSG_MIN_LENGTH_INVALID.replace("##", this.props.minLength);
            else if (value.length > this.props.maxLength) msg = this.props.maxLengthMsg ? this.props.maxLengthMsg : CONSTANTS.MSG_MAX_LENGTH_INVALID.replace("##", this.props.maxLength);
            else if (this.props.email && !this.isValidEmail(value)) msg = CONSTANTS.MSG_EMAIL_INVALID;
            else if (this.props.url && !this.isValidURL(value)) msg = CONSTANTS.MSG_URL_INVALID;
            else if (value < this.props.min) msg = this.props.minMsg ? this.props.minMsg : CONSTANTS.MSG_MIN_INVALID.replace("##", this.props.min.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
            else if (value > this.props.max) msg = this.props.maxMsg ? this.props.maxMsg : CONSTANTS.MSG_MAX_INVALID.replace("##", this.props.max.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
        }
        return msg;
    }

    isValidEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }
    isValidURL(url) {
        var re =
            /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
        return re.test(url.toLowerCase());
    }
}

export default InputValidation;
