import React, { useEffect, useState } from "react";
import Common from "../../based/Common";
import { INITPAGING } from "../../based/Constants";
import { Box, ButtonBlackRounded } from "../../based/content/ct.elm";
import Textbox from "../../based/refactor/Textbox";
import LoadingR from "../../based/LoadingR";
import Button, { ButtonType } from "../../based/refactor/Button";
import ReviewProductsService from "../../based/services/ManageReviewProductServices";
import Breadcum from "../../layout/refactor/Breadcum/Breadcum";
import LayoutLight from "../../layout/refactor/Layout/Layout";
import ProductReviewModel from "../../models/ProductReviewModel";
import AddUpdate from "../AddUpdate";
import ReviewTable from "./ReviewTable";
import "./style.css";


// tất cả, kèm nội dung (1), kèm hình ảnh video (2)
const ContentStatus = {
    All: 0,
    Content: 1,
    ImageVideo: 2,
};
// -1, --tất cả, 5 sao, 4 sao, 3 sao, 2 sao 1 sao
const TotalStarRateStatus = {
    All: -1,
    One: 1,
    Two: 2,
    Three: 3,
    Four: 4,
    Five: 5,
};
// -- tất cả, chưa phản hồi, phản hồi
const ReplyStatus = {
    All: 0,
    NotReply: 1,
};
const TabScreen = {
    CommentDetail: 1,
};

const pagingModel = { 
    pageNumber: 1,
    pageSize: 10,
    orderId: 0,
    keyword: "",
    contentStatus: ContentStatus.All,
    totalStarRateStatus: TotalStarRateStatus.All, 
    replyStatus: ReplyStatus.All,
}

const ManageReviewProducts = () => {
    const [listItemReviewProduct, setListItemReviewProduct] = useState([new ProductReviewModel()]);
    const [isBusy, setIsBusy] = useState(false);
    const [paging, setPaging] = useState({...pagingModel});
    const [isShow, setIsShow] = useState(false);
    const [productReview, setProductReview] = useState({id: 0, reviewComment: ''});
    const [isLoading, setIsLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState(TabScreen.CommentDetail);

    useEffect(() => {
        getReviewProducts(paging);
    }, []);

    /** lấy danh sách review products */
    const getReviewProducts = async (paging) => {
        setIsLoading(true);
        const [err, data] = await ReviewProductsService.GetAllReviewProduct(paging);
        if (!err && data) {
            setIsLoading(false);
            setListItemReviewProduct(data.listObjects);
            setPaging(data.paging);
        } else {
            setIsLoading(false);
            setListItemReviewProduct([]);
            setPaging({ ...pagingModel });
        }
    };

    /** update thuộc tính của page */
    const handleChangePage = (name, value) => {
        let page = { ...paging, pageNumber: 1 };
        page[name] = value;
        getReviewProducts(page);
    };

    /** trả lời */
    const handleAnswer = (item) => {
        setIsShow(true);
        setProductReview({
            id: item.id,
            reviewComment: item.reviewComment,
            orderProductId: item.orderProductId,
            orderProductType: item.orderProductType,
        });
    };

    const renderSortTab = () => {
        return (
            <div className='sort__tab'>
                <div className='sort__by__select'>
                    <div className='sort__row'>
                        <span className='sort__title'>Nội dung:</span>
                        <div className={`sort__item ${paging.contentStatus == ContentStatus.All ? "active" : ""}`} onClick={() => handleChangePage("contentStatus", ContentStatus.All)}>
                            Tất cả
                        </div>
                        <div className={`sort__item ${paging.contentStatus == ContentStatus.Content ? "active" : ""}`} onClick={() => handleChangePage("contentStatus", ContentStatus.Content)}>
                            Kèm nội dung
                        </div>
                        <div className={`sort__item ${paging.contentStatus == ContentStatus.ImageVideo ? "active" : ""}`} onClick={() => handleChangePage("contentStatus", ContentStatus.ImageVideo)}>
                            Kèm hình ảnh/ Video
                        </div>
                    </div>
                    <div className='sort__row'>
                        <span className='sort__title'>Tổng quan:</span>
                        <div className={`sort__item  ${paging.totalStarRateStatus == TotalStarRateStatus.All ? "active" : ""}`} onClick={() => handleChangePage("totalStarRateStatus", TotalStarRateStatus.All)}>
                            Tất cả
                        </div>
                        <div className={`sort__item ${paging.totalStarRateStatus == TotalStarRateStatus.One ? "active" : ""}`} onClick={() => handleChangePage("totalStarRateStatus", TotalStarRateStatus.One)}>
                            1 sao
                        </div>
                        <div className={`sort__item ${paging.totalStarRateStatus == TotalStarRateStatus.Two ? "active" : ""}`} onClick={() => handleChangePage("totalStarRateStatus", TotalStarRateStatus.Two)}>
                            2 sao
                        </div>
                        <div className={`sort__item ${paging.totalStarRateStatus == TotalStarRateStatus.Three ? "active" : ""}`} onClick={() => handleChangePage("totalStarRateStatus", TotalStarRateStatus.Three)}>
                            3 sao
                        </div>
                        <div className={`sort__item ${paging.totalStarRateStatus == TotalStarRateStatus.Four ? "active" : ""}`} onClick={() => handleChangePage("totalStarRateStatus", TotalStarRateStatus.Four)}>
                            4 sao
                        </div>
                        <div className={`sort__item ${paging.totalStarRateStatus == TotalStarRateStatus.Five ? "active" : ""}`} onClick={() => handleChangePage("totalStarRateStatus", TotalStarRateStatus.Five)}>
                            5 sao
                        </div>
                    </div>
                    <div className='sort__row'>
                        <span className='sort__title'>Trạng thái:</span>
                        <div className={`sort__item ${paging.replyStatus == ReplyStatus.All ? "active" : ""}`} onClick={() => handleChangePage("replyStatus", ReplyStatus.All)}>
                            Tất cả
                        </div>
                        <div className={`sort__item ${paging.replyStatus == ReplyStatus.NotReply ? "active" : ""}`} onClick={() => handleChangePage("replyStatus", ReplyStatus.NotReply)}>
                            Chưa phản hồi
                        </div>
                    </div>
                </div>
                <div className='sort__by__properties'>
                    <div className='right'>
                        <div className='search__item'>
                            <Textbox name='orderId' placeholder='Order ID' value={paging.orderId} onChanged={(name, value) => setPaging({ ...paging, orderId: value ? value : 0})} />
                        </div>
                        <Button btnType={ButtonType.Search} onClick={() => getReviewProducts({ ...paging, pageNumber: 1 })} />
                    </div>
                </div>
            </div>
        );
    };
    const _handleChangeTab = (tab) => {
        setTabIndex(tab);
    };
    return (
        <React.Fragment>
            <Breadcum menu={["Trang Chủ", "Quản lý bình luận và đánh giá"]} title='Quản lý bình luận và đánh giá' />
            <LoadingR fullScreen isShow={isLoading} height='100vh' />
            {isShow && <AddUpdate
                isShowModal={isShow}
                review={productReview}
                onClose={() => setIsShow(false)}
                onSaveSucceed={() => {
                    setIsShow(false);
                    getReviewProducts({ ...paging });
                }}
            />}
            <Box>
                <div className='box__header'>
                    <ButtonBlackRounded className={tabIndex === TabScreen.CommentDetail ? "active" : ""} onClick={() => _handleChangeTab(TabScreen.CommentDetail)}>
                        <span>Chi tiết đánh giá sản phẩm</span>
                    </ButtonBlackRounded>
                </div>
            </Box>
            <Box>{renderSortTab()}</Box>
            <Box>
                <ReviewTable listItemReviewProduct={listItemReviewProduct} handleAnswer={(item) => handleAnswer(item)} paging={paging} onFilter={(newPaging) => getReviewProducts(newPaging)} />
            </Box>
        </React.Fragment>
    );
};

export default ManageReviewProducts;

function TableFoot(props) {
    function handleChangePageSize(pageSize) {
        let newPaging = {
            ...props.paging,
            pageNumber: 1,
            pageSize: pageSize,
        };
        props.onFilter(newPaging);
    }

    function handleChangePage(page) {
        let newPaging = {
            ...props.paging,
            pageNumber: page,
        };
        props.onFilter(newPaging);
    }

    let totalRecord = props.paging.totalRecord,
        pageTotal = totalRecord % props.paging.pageSize === 0 ? totalRecord / props.paging.pageSize : (totalRecord - (totalRecord % props.paging.pageSize)) / props.paging.pageSize + 1;

    let from = (props.paging.pageNumber - 1) * props.paging.pageSize + 1;
    let to = props.paging.pageSize * props.paging.pageNumber;

    if (totalRecord == 0) {
        from = 0;
        to = 0;
    } else if (totalRecord < props.paging.pageSize * props.paging.pageNumber) {
        to = totalRecord;
    }
    return (
        <div className='form-inline'>
            <ul className='pagination pagination-split footable-pagination m-b-0'>
                {props.paging.pageNumber > 1 &&
                    (props.paging.pageNumber === 2 ? (
                        <li onClick={() => handleChangePage(1)}>
                            <a className='cursor-pointer'>1</a>
                        </li>
                    ) : (
                        [
                            <li onClick={() => handleChangePage(1)} title={1} key={0}>
                                <a className='cursor-pointer'>«</a>
                            </li>,
                            <li onClick={() => handleChangePage(props.paging.pageNumber - 1)} title={props.paging.pageNumber - 1} key={1}>
                                <a className='cursor-pointer'>‹</a>
                            </li>,
                            <li onClick={() => handleChangePage(props.paging.pageNumber - 1)} key={2}>
                                <a className='cursor-pointer'>{props.paging.pageNumber - 1}</a>
                            </li>,
                        ]
                    ))}
                <li className='active'>
                    <a className='cursor-pointer'>{props.paging.pageNumber}</a>
                </li>
                {pageTotal > props.paging.pageNumber &&
                    (pageTotal === props.paging.pageNumber + 1 ? (
                        <li onClick={() => handleChangePage(props.paging.pageNumber + 1)}>
                            <a className='cursor-pointer'>{props.paging.pageNumber + 1}</a>
                        </li>
                    ) : (
                        [
                            <li onClick={() => handleChangePage(props.paging.pageNumber + 1)} key={0}>
                                <a className='cursor-pointer'>{props.paging.pageNumber + 1}</a>
                            </li>,
                            <li onClick={() => handleChangePage(props.paging.pageNumber + 1)} title={props.paging.pageNumber + 1} key={1}>
                                <a className='cursor-pointer'>›</a>
                            </li>,
                            <li onClick={() => handleChangePage(pageTotal)} title={pageTotal} key={2}>
                                <a className='cursor-pointer'>»</a>
                            </li>,
                        ]
                    ))}
            </ul>
            <span className='table-record'>
                Hiển thị từ {from} đến {to} của {totalRecord} bản ghi
            </span>
            <label className={"form-inline " + props.footerClassName}>
                Hiển thị&nbsp;
                <select className={props.footerClassName ? props.footerClassName : "form-control h-30px"} value={props.paging.pageSize} onChange={(event) => handleChangePageSize(event.target.value)}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
                &nbsp;bản ghi
            </label>
        </div>
    );
}

const sectionAnalysis = () => {
    return (
        <div className='col-sm-12 card-box p-3 row'>
            <div className='col-md-3 flex-vertical'>
                <span className='item__title'>
                    <b>Sản phẩm chờ được đánh giá &gt;</b>
                </span>
                <span className='item__message'>
                    <i className='fa fa-check-circle-o' aria-hidden='true'></i>
                    <p className='message__content'>Xuất sắc, tất cả đơn hàng đề đã được đánh giá.</p>
                </span>
                <span className='count '>
                    Mới: <b>0</b>
                </span>
                <span className='count '>
                    Hết hạn hôm nay: <b>0</b>
                </span>
            </div>
            <div className='col-md-3'>
                <span className='item__title'>
                    <b>Chờ phản hồi &gt;</b>
                </span>
                <span className='item__message'>
                    <i className='fa fa-check-circle-o' aria-hidden='true'></i>
                    <p className='message__content'>Sản phẩm đã có đánh giá.</p>
                </span>
                <span className='count '>
                    Mới: <b>0</b>
                </span>
                <span className='count '>
                    Hết hạn hôm nay: <b>0</b>
                </span>
            </div>
            <div className='col-md-3'>
                <span className='item__title'>
                    <b>Sản phẩm đã có đánh giá &gt;</b>
                </span>
                <span className='item__target '>
                    <h5>100%</h5>
                    <p>
                        Mục tiêu: <b>92%</b>
                    </p>
                </span>
                <LoadingR fullScreen />
            </div>
            <div className='col-md-3'>
                <span className='item__title'>
                    <b>Đánh giá nhà bán hàng tích cực &gt;</b>
                </span>
                <span className='item__target '>
                    <h5>100%</h5>
                    <p>
                        Mục tiêu: <b>92%</b>
                    </p>
                </span>
                <LoadingR fullScreen />
            </div>
        </div>
    );
};
