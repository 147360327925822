import React from "react";
import styled from "styled-components";
import CommonModal from "../../based/Modal";
import VerticalPositionScoll from "../../based/VerticalPositionScoll";
import { handlePositionMockup } from "./DetailVer2";

function MockupOppositeModalVer2({ show, onClose, frameDetail, thumbnail, onScroll }) {
    const result = handlePositionMockup(frameDetail, 300);
    const imageData = frameDetail ? frameDetail.mockup.image : null;
    return (
        <CommonModal
            show={show}
            onClose={() => {
                onClose();
            }}
            size="md"
            hideClose
        >
            <Wrapper>
                <div className="modal_header">
                    <h3>Đổi dòng sản phẩm khác</h3>
                    <div className="icon">
                        <CloseIcon />
                    </div>
                </div>
                <div className="modal_body">
                    <div className="product_base-front-avatar">
                        <img src={imageData.url} />
                        {thumbnail && (
                            <React.Fragment>
                                <div
                                    className="thumbnail"
                                    style={{
                                        position: "absolute",
                                        top: `${result.y}px`,
                                        left: `${result.x}px`,
                                    }}
                                >
                                    <img
                                        src={thumbnail}
                                        style={{
                                            width: `${result.width}px`,
                                            height: `${result.height}px`,
                                        }}
                                    />
                                </div>
                                <div
                                    className="position-scroll"
                                    onMouseMove={(e) => {
                                        e.stopPropagation();
                                    }}
                                    style={{
                                        zIndex: 2,
                                    }}
                                >
                                    <VerticalPositionScoll
                                        value={frameDetail.optionTop}
                                        min={frameDetail.min}
                                        max={frameDetail.max}
                                        onChange={(value) => {
                                            onScroll(value);
                                        }}
                                    />
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </Wrapper>
        </CommonModal>
    );
}

export default MockupOppositeModalVer2;

const Wrapper = styled.div`
    .position-scroll {
        position: absolute;
        left: -115px;
        top: 135px;
    }

    .product_base-front-avatar {
        width: 300px;
        height: 300px;
        position: relative;
    }

    .product_base-front-avatar img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .modal_body {
        display: flex;
        justify-content: center;
    }

    .modal_header {
        display: flex;
        align-items: center;
    }

    .modal_header h3 {
        color: #333;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .modal_header .icon {
        display: flex;
        margin-left: auto;
    }
    .list-item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .item {
        width: calc(100% / 2 - 24px);
        margin: 12px;
        display: flex;
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
        padding: 12px;
    }

    .item-avatar {
        width: 160px;
        height: 160px;
        display: flex;
    }

    .item-avatar img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .item-info {
        margin-left: 12px;
    }

    .item-name {
        color: #333;
        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .total-item {
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 6px 0;
    }
    ul.list-require {
        padding: 0;
        color: #5d5d5d;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        list-style: none;
        margin: 6px 0;
    }

    .item-action {
        margin: 12px 0;
        display: flex;
        flex-wrap: wrap;
    }

    button.btn-select {
        margin: 6px;
        border: none;
        border-radius: 40px;
        background: #ff9027;
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 10px 30.5px;
    }

    button.btn-detail {
        margin: 6px;
        border: none;
        border-radius: 40px;
        background: #f8f8f8;
        color: black;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 10px 30.5px;
    }

    button.btn-select:focus {
        border: none;
    }
`;

const CloseIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
