import React, { useState } from 'react';
import styled from 'styled-components';
import { CARD_COLOR, NOTIFY } from '../../based/Constants';
import { BtnRounded } from '../../based/content/ct.elm';
import { Notify } from '../../based/Notify';
import ColorPicker from '../../based/refactor/ColorPicker';

/*default color tets*/

const ProjectMenu = ({
  items,
  project,
  onAddText,
  onPickImage,
  onOpenEmbedModal,
  onOpenMockupModal,
  onOpenProjectModal,
}) => {
  const [dropdownChangeBg, setDropdownChangeBg] = useState(false)
  const _handleDropdownChangeBg = () => {
    setDropdownChangeBg(!dropdownChangeBg)
  }
  const _handleChangeColor = (value) => {

  }
  const _handleChangeColorBg = (value) => {

  }
  const handleOnOpenProjectModal = () => {
    if (items && items.length > 0) {
      onOpenProjectModal()
    } else {
      Notify(NOTIFY.ERROR, "Bộ sưu tập trống", 'Hãy thêm gì đó vào bộ sưu tập!');
    }
  }
  return (
    <div className="project-editor__tools p-3  mt-4 bg-white rounded shadow d-flex flex-column align-self-start">
      <div
        className="d-flex align-items-center justify-content-start cursor-pointer p-3 mb-2 rounded"
        onClick={onAddText}
      >
        <i className="fa fa-text-width" style={{ fontSize: '1.2rem' }} />
        <p>Thêm chữ</p>
      </div>
      <div
        className="d-flex align-items-center justify-content-start cursor-pointer p-3 mb-2 rounded"
        onClick={onPickImage}
      >
        <i className="fa fa-picture-o" style={{ fontSize: '1.2rem' }} />
        <p>Thêm ảnh</p>
      </div>
      <div
        className="d-flex align-items-center justify-content-start cursor-pointer p-3 mb-2 rounded"
        onClick={onOpenEmbedModal}
      >
        <i className="fa fa-code" style={{ fontSize: '1.2rem' }} />
        <p>Nhúng</p>
      </div>
      <div
        className="d-flex align-items-center justify-content-start cursor-pointer p-3 mb-2 rounded"
        onClick={onOpenMockupModal}
      >
        <i className="fa fa-code" style={{ fontSize: '1.2rem' }} />
        <p >Thêm mẫu sản phẩm</p>
      </div>
      {/* handle change background */}
      {/* <ChangeColorCont className='p-3 mb-2 rounded' drop={dropdownChangeBg}>
        <div className='header d-flex justify-content-between' onClick={() => setDropdownChangeBg(!dropdownChangeBg)}>
          <i className="fa fa-adjust" aria-hidden="true"></i>
          <p>Đổi màu nền</p>
          {dropdownChangeBg ? <i className="fa fa-chevron-up cursor-pointer" aria-hidden="true"></i> : <i className="fa fa-chevron-down cursor-pointer" aria-hidden="true"></i>}
        </div>
        <div className='dropdown__change__color'>
          <ColorPicker onChange={_handleChangeColorBg} />
        </div>

      </ChangeColorCont> */}
      {/* <div className='d-flex justify-content-center mt-1'>
        <BtnRounded width='130px' color={CARD_COLOR.Blue}>Lưu bản nháp</BtnRounded>
      </div> */}
      <div className='d-flex justify-content-center mt-1'>
        <BtnRounded width='130px' color={CARD_COLOR.Green} onClick={handleOnOpenProjectModal}>Tiếp theo</BtnRounded>
      </div>
    </div>
  );
};


const ChangeColorCont = styled.div`
position: relative;
display: flex;
flex-direction:column;
transition: all .5s ease;
.dropdown__change__color{
  transition: all .5s ease;
  display: ${({ drop }) => drop ? 'block' : 'none'};
}
`

export default ProjectMenu;
