import React from "react";
import styled from "styled-components";
import RanusCreator from "./assets/RanusCreator.png";
import TopBar from "../layout/refactor/TopBar/TopBar";
import CreatorSuccess from "./assets/CreatorSuccess.png";
import { useHistory, useLocation } from "react-router-dom";

function CreateSuccess(props) {
    const history = useHistory();

    return (
        <Wrapper>
            {/* <div className="top-bar">
                <div className="logo-ranus">
                    <div className="icon-logo">
                        <img src={RanusCreator} alt="" />
                    </div>
                </div>
                <TopBar />
            </div> */}
            <section className="main-page">
                <div className="main-info">
                    <div className="main-img">
                        <img src={CreatorSuccess} alt="thành công" />
                    </div>
                    <div className="main-instruct">Cảm ơn bạn đã cập nhật thông tin</div>
                    <div className="sub-instruct">
                        <span className="high-light">Ranus sẽ xác thực trong vòng 24h</span> kể từ lúc cập nhật thành công, sau đó bạn sẽ sử dụng được đầy đủ chức năng của Ranus Creator.
                    </div>
                    <div className="list-btn">
                        <div className="edit-information">
                            <button
                                onClick={(e) => {
                                    history.push("/creator-register");
                                }}
                            >
                                Chỉnh sửa thông tin đăng ký
                            </button>
                        </div>
                        <div
                            className="connect-ranus"
                            onClick={(e) => {
                                window.open("https://zalo.me/0898000004", "_blank");
                            }}
                        >
                            <button>Liên hệ hỗ trợ Zalo Ranus</button>
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
}

export default CreateSuccess;

const Wrapper = styled.div`
    .logo-ranus {
        position: relative;
        z-index: 101;
        left: 45px;
        top: 10px;
        display: inline-block;
    }

    .top-bar {
        height: 70px;
    }
    .main-info {
        margin: 0px 30%;
        text-align: center;
        border-radius: 12px 12px 0px 0px;
        border: 1px solid #f3f3f3;
        background: #fff;
        position: relative;
        top: 50px;
        padding: 24px;
    }

    section.main-page {
        height: calc(100vh - 70px);
        background: white;
    }

    .main-instruct {
        color: #444;
        text-align: center;
        font-family: Lexend;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 12px 0;
    }

    .sub-instruct {
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .sub-instruct span.high-light {
        color: #ff9027;
        text-align: center;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    .list-btn {
        display: flex;
        justify-content: space-around;
        margin: 12px 0;
    }

    .edit-information button {
        border-radius: 12px;
        background: #fff5ec;
        border: none;
        color: var(--Cam-Thng-hiu, #ff9027);
        text-align: center;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 11px;
    }

    .connect-ranus button {
        border-radius: 12px;
        background: #e5f0ff;
        border: none;
        padding: 11px;
        color: #0068ff;
        text-align: center;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`;
