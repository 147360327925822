import BaseServices from "./BaseServices";

const AffiliateServices = {
    GetListAffiliateSite: async (paging) => {
        return await BaseServices.Post("/api/Affiliate/get-list-affiliate-site", paging);
    },
    CreateUpdateAffiliateSite: async (model) => {
        return await BaseServices.Post("/api/Affiliate/create-update-affiliate-site", model);
    },
    GetAffiliateSite: async (id) => {
        return await BaseServices.Get("/api/Affiliate/get-affiliate-site/" + id);
    },
    DeleteAffiliateSite: async (id) => {
        return await BaseServices.Get("/api/Affiliate/delete-affiliate-site/" + id);
    },
};

export default AffiliateServices;
