import React, { useState, useEffect } from "react";
import { NOTIFY, CONSTANTS ,IMAGE_SITE, IMAGE_TYPE, LAYOUT_BANNER, BANNER_POSITION } from "../based/Constants";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import BannerServices from "../based/services/BannerServices";
import MultipleImages from "../based/inputs/MultipleImages";
import Request from "../based/Request";
import Breadcum from "../layout/refactor/Breadcum/Breadcum";
import { Block, Box, ButtonBlackRounded } from "../based/content/ct.elm";
import { RequestDomain } from "../based/RequestDomain";
import styled from "styled-components";
import Textbox from "../based/refactor/Textbox";
import { ConfirmDialog } from "../based/Dialog";

const TABS = {
    BANNER: 1,
};
const BANNER_TYPE = {
    DESKTOP: 1,
    MOBILE: 2
}
const BANNER_PAGE = {
    CREATOR: 4
}
const BANNER_POSITION_TYPE = {
    CREATOR_3 : 21,
    CREATOR_2 : 20, 
    CREATOR_1 : 19,

}

export default function CreatorHomepage(props) {
    const [isLoading, setLoading] = useState(false);
    const [msg, setMsg] = useState(null);
    const [tabIndex, setTabIndex] = useState(TABS.BANNER);
    const [banners, setBanners] = useState([]);
    
    useEffect(() => {
        getBanners();
    }, []);

    const getBanners = async () => {
        setLoading(true);
        setMsg("Đang tải dữ liệu....");
        const [err, data] = await BannerServices.GetBannerCreator();
        if (!err && data) {
            setBanners(data);
            setLoading(false);
            setMsg("");
        } else {
            setBanners([]);
            setLoading(false);
            setMsg("");
        }
    };

    function _handleChangeTab(tab) {
        setTabIndex(tab);
    }

    function _handleImageChange(e, position,type) {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadImage(file)
                    .then( async (res) => {
                        if (res && res.data) {
                            var item = {
                                id: 0,
                                redirectUrl: "",
                                imageUrl: res.data.imageUrl,
                                startDate: null,
                                endDate: null,
                                bannerType: type,
                                pageType: BANNER_PAGE.CREATOR,
                                position: position,
                                bannerIndex: 0,
                                creatorId: 0
                            };
                            await handleSaveBanners(item)
                            getBanners()

                        } else {
                            let errMsg =
                                res.errors && res.errors.length > 0
                                    ? res.errors.reduce((prev, item, idx) => {
                                            return `${prev}${item.value}<br/>`;
                                        }, "")
                                    : "" || CONSTANTS.MSG_ERROR;
                            Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
                        }
                    })
                    .catch((err) => {
                    });
            };

            reader.readAsDataURL(file);
        }
    }
    async function _handleRemoveImageBanner(id) {
        ConfirmDialog("Xác nhận xóa?", `Xin vui lòng xác nhận để xóa ảnh banner này?`, () => {
                return new Promise(async (resolve, reject) => {
                    setMsg("Đang tải dữ liệu...");
                    let [err, data] = await BannerServices.DeleteBanner(id);
                    if (!err && data) {
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getBanners()
                    } else {
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
        });
    }
    async function handleSaveUrl(e, image) {
        if(e.charCode === 13){
            const [err, data] = await BannerServices.SaveBannerCreator({...image, redirectUrl : e.target.value});
            if (!err && data) {
                setLoading(false);
                setMsg("");
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công.");
                getBanners()
            } else {
                setLoading(false);
                setMsg("");
                Notify(NOTIFY.ERROR, "Lỗi", err.data ? err.data : err.message);
            }
        }
        
    }
    function handleChangeSelectUrl(e, image) {
        let bannersUp = [...banners];
        bannersUp.map((banner) => {
            if (banner.id == image.id) banner.redirectUrl = e.target.value;
        });
        setBanners(bannersUp);
        
    }
    async function handleSaveBanners(banner) {
        setLoading(true);
        setMsg("Đang lưu dữ liệu...");
        if (!banner) Notify(NOTIFY.WARNING, "Chú ý", "Yêu cầu upload banner trước khi lưu");
        const [err, data] = await BannerServices.SaveBannerCreator(banner);
        if (!err && data) {
            setLoading(false);
            setMsg("");
            Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công.");
        } else {
            setLoading(false);
            setMsg("");
            Notify(NOTIFY.ERROR, "Lỗi", err.data ? err.data : err.message);
        }
    }
    return (
        <React.Fragment>
            <Breadcum menu={["Trang chủ nhà sáng tạo", "Trang chủ"]} title="Trang chủ nhà sáng tạo" />
            <Loading show={isLoading} msg={msg} />
            <Box>
                <div className="box__header">
                    <ButtonBlackRounded className={tabIndex === TABS.BANNER ? "active" : ""} onClick={() => _handleChangeTab(TABS.BANNER)}>
                        <span>Thiết lập Banner</span>
                    </ButtonBlackRounded>
                </div>
                <div className="box__content">
                    <Block flexBasis="49%" style={{ marginTop: "20px", height: "auto" }}>
                        <BoxBanner>
                            <BannerHeader>
                                <h6 className="m-0" style={{ color: "white" }}>
                                    Banner vị trí (1)
                                </h6>
                            </BannerHeader>
                            <div className="full-width p-10">
                                <div className="col-12 card-box">
                                    <h4 className="header-title mb-4 px-2">
                                        Banner desktop
                                        <button
                                            className="btn btn-success btn-xs float-right"
                                            onClick={() => {
                                                document.getElementById("upload-dt-1").click()
                                            }}
                                        >
                                            <span className="fa fa-plus"></span>
                                        </button>
                                    </h4>
                                    <div className="bg-white">
                                        <input type="file" accept="image/x-png, image/gif, image/jpeg, image/bmp , image/svg" className="hide" id="upload-dt-1" onChange={ (e) => {
                                            _handleImageChange(e, BANNER_POSITION_TYPE.CREATOR_1, BANNER_TYPE.DESKTOP)
                                        }} />
                                        <div className="box-images">
                                            {banners && banners.length > 0 && (
                                                <ul className="resCmtImg form-row" style={{ margin: 0 }}>
                                                    {banners.map((image, i) => {
                                                        if(image.position == BANNER_POSITION_TYPE.CREATOR_1 && image.bannerType == BANNER_TYPE.DESKTOP){
                                                            return (
                                                                <li key={i} className="cmt-image-item col-md-4" style={{ marginRight: 0, marginBottom: 20 }}>
                                                                    <div>
                                                                        <img src={image.imageUrl} className="full-width" style={{ maxWidth: "100%", maxHeight: 250 }} />
                                                                        <i className="fbDelImg" style={{ right: 0, padding: 0 }} onClick={() => _handleRemoveImageBanner(image.id)}>
                                                                            x
                                                                        </i>
                                                                    </div>
                                                                    <div>Nhập và nhấn enter để lưu</div>
                                                                    <input type="text" className="input-redirect" placeholder="Đường dẫn điều hướng" value={image.redirectUrl} onChange={(e) => handleChangeSelectUrl(e,image)} onKeyPress={(e)=>{handleSaveUrl(e, image)}} />
                                                                </li>
                                                            );
                                                        }
                                                    })}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 card-box">
                                    <h4 className="header-title mb-4 px-2">
                                        Banner mobile
                                        <button
                                            className="btn btn-success btn-xs float-right"
                                            onClick={() => {
                                                document.getElementById("upload-mb-1").click()
                                            }}
                                        >
                                            <span className="fa fa-plus"></span>
                                        </button>
                                    </h4>
                                    <div className="bg-white">
                                        <input type="file" accept="image/x-png, image/gif, image/jpeg, image/bmp , image/svg"  className="hide" id="upload-mb-1" onChange={ (e) => {
                                            _handleImageChange(e, BANNER_POSITION_TYPE.CREATOR_1, BANNER_TYPE.MOBILE)
                                        }} />
                                        {banners && banners.length > 0 && (
                                                <ul className="resCmtImg form-row" style={{ margin: 0 }}>
                                                    {banners.map((image, i) => {
                                                        if(image.position == BANNER_POSITION_TYPE.CREATOR_1 && image.bannerType == BANNER_TYPE.MOBILE){
                                                            return (
                                                                <li key={i} className="cmt-image-item col-md-4" style={{ marginRight: 0, marginBottom: 20 }}>
                                                                    <div>
                                                                        <img src={image.imageUrl} className="full-width" style={{ maxWidth: "100%", maxHeight: 250 }} />
                                                                        <i className="fbDelImg" style={{ right: 0, padding: 0 }} onClick={() => _handleRemoveImageBanner(image.id)}>
                                                                            x
                                                                        </i>
                                                                    </div>
                                                                    <div>Nhập và nhấn enter để lưu</div>
                                                                    <input type="text" className="input-redirect" placeholder="Đường dẫn điều hướng" value={image.redirectUrl} onChange={(e) => handleChangeSelectUrl(e,image)} onKeyPress={(e)=>{handleSaveUrl(e, image)}} />
                                                                </li>
                                                            );
                                                        }
                                                    })}
                                                </ul>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </BoxBanner>
                        <BoxBanner>
                            <BannerHeader>
                                <h6 className="m-0" style={{ color: "white" }}>
                                    Banner vị trí (2)
                                </h6>
                            </BannerHeader>
                            <div className="full-width p-10">
                                <div className="col-12 card-box">
                                    <h4 className="header-title mb-4 px-2">
                                        Banner desktop
                                        <button
                                            className="btn btn-success btn-xs float-right"
                                            onClick={() => {
                                                document.getElementById("upload-dt-2").click()
                                            }}
                                        >
                                            <span className="fa fa-plus"></span>
                                        </button>
                                    </h4>
                                    <div className="bg-white">
                                        <input type="file" accept="image/x-png, image/gif, image/jpeg, image/bmp , image/svg" className="hide" id="upload-dt-2" onChange={ (e) => {
                                            _handleImageChange(e, BANNER_POSITION_TYPE.CREATOR_2, BANNER_TYPE.DESKTOP)
                                        }} />
                                        <div className="box-images">
                                        {banners && banners.length > 0 && (
                                                <ul className="resCmtImg form-row" style={{ margin: 0 }}>
                                                    {banners.map((image, i) => {
                                                        if(image.position == BANNER_POSITION_TYPE.CREATOR_2 && image.bannerType == BANNER_TYPE.DESKTOP){
                                                            return (
                                                                <li key={i} className="cmt-image-item col-md-4" style={{ marginRight: 0, marginBottom: 20 }}>
                                                                    <div>
                                                                        <img src={image.imageUrl} className="full-width" style={{ maxWidth: "100%", maxHeight: 250 }} />
                                                                        <i className="fbDelImg" style={{ right: 0, padding: 0 }} onClick={() => _handleRemoveImageBanner(image.id)}>
                                                                            x
                                                                        </i>
                                                                    </div>
                                                                    <div>Nhập và nhấn enter để lưu</div>
                                                                    <input type="text" className="input-redirect" placeholder="Đường dẫn điều hướng" value={image.redirectUrl} onChange={(e) => handleChangeSelectUrl(e,image)} onKeyPress={(e)=>{handleSaveUrl(e, image)}} />
                                                                </li>
                                                            );
                                                        }
                                                    })}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 card-box">
                                    <h4 className="header-title mb-4 px-2">
                                        Banner mobile
                                        <button
                                            className="btn btn-success btn-xs float-right"
                                            onClick={() => {
                                                document.getElementById("upload-mb-2").click()
                                            }}
                                        >
                                            <span className="fa fa-plus"></span>
                                        </button>
                                    </h4>
                                    <div className="bg-white">
                                        <input type="file" accept="image/x-png, image/gif, image/jpeg, image/bmp , image/svg"  className="hide" id="upload-mb-2" onChange={ (e) => {
                                            _handleImageChange(e, BANNER_POSITION_TYPE.CREATOR_2, BANNER_TYPE.MOBILE)
                                        }} />
                                        {banners && banners.length > 0 && (
                                                <ul className="resCmtImg form-row" style={{ margin: 0 }}>
                                                    {banners.map((image, i) => {
                                                        if(image.position == BANNER_POSITION_TYPE.CREATOR_2 && image.bannerType == BANNER_TYPE.MOBILE){
                                                            return (
                                                                <li key={i} className="cmt-image-item col-md-4" style={{ marginRight: 0, marginBottom: 20 }}>
                                                                    <div>
                                                                        <img src={image.imageUrl} className="full-width" style={{ maxWidth: "100%", maxHeight: 250 }} />
                                                                        <i className="fbDelImg" style={{ right: 0, padding: 0 }} onClick={() => _handleRemoveImageBanner(image.id)}>
                                                                            x
                                                                        </i>
                                                                    </div>
                                                                    <div>Nhập và nhấn enter để lưu</div>
                                                                    <input type="text" className="input-redirect" placeholder="Đường dẫn điều hướng" value={image.redirectUrl} onChange={(e) => handleChangeSelectUrl(e,image)} onKeyPress={(e)=>{handleSaveUrl(e, image)}} />
                                                                </li>
                                                            );
                                                        }
                                                    })}
                                                </ul>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </BoxBanner>
                        <BoxBanner>
                            <BannerHeader>
                                <h6 className="m-0" style={{ color: "white" }}>
                                    Banner vị trí (3)
                                </h6>
                            </BannerHeader>
                            <div className="full-width p-10">
                                <div className="col-12 card-box">
                                    <h4 className="header-title mb-4 px-2">
                                        Banner desktop
                                        <button
                                            className="btn btn-success btn-xs float-right"
                                            onClick={() => {
                                                document.getElementById("upload-dt-3").click()
                                            }}
                                        >
                                            <span className="fa fa-plus"></span>
                                        </button>
                                    </h4>
                                    <div className="bg-white">
                                        <input type="file" accept="image/x-png, image/gif, image/jpeg, image/bmp , image/svg" className="hide" id="upload-dt-3" onChange={ (e) => {
                                            _handleImageChange(e, BANNER_POSITION_TYPE.CREATOR_3, BANNER_TYPE.DESKTOP)
                                        }} />
                                        <div className="box-images">
                                            {banners && banners.length > 0 && (
                                                <ul className="resCmtImg form-row" style={{ margin: 0 }}>
                                                    {banners.map((image, i) => {
                                                        if(image.position == BANNER_POSITION_TYPE.CREATOR_3 && image.bannerType == BANNER_TYPE.DESKTOP){
                                                            return (
                                                                <li key={i} className="cmt-image-item col-md-4" style={{ marginRight: 0, marginBottom: 20 }}>
                                                                    <div>
                                                                        <img src={image.imageUrl} className="full-width" style={{ maxWidth: "100%", maxHeight: 250 }} />
                                                                        <i className="fbDelImg" style={{ right: 0, padding: 0 }} onClick={() => _handleRemoveImageBanner(image.id)}>
                                                                            x
                                                                        </i>
                                                                    </div>
                                                                    <div>Nhập và nhấn enter để lưu</div>
                                                                    <input type="text" className="input-redirect" placeholder="Đường dẫn điều hướng" value={image.redirectUrl} onChange={(e) => handleChangeSelectUrl(e,image)} onKeyPress={(e)=>{handleSaveUrl(e, image)}} />
                                                                </li>
                                                            );
                                                        }
                                                    })}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 card-box">
                                    <h4 className="header-title mb-4 px-2">
                                        Banner mobile
                                        <button
                                            className="btn btn-success btn-xs float-right"
                                            onClick={() => {
                                                document.getElementById("upload-mb-3").click()
                                            }}
                                        >
                                            <span className="fa fa-plus"></span>
                                        </button>
                                    </h4>
                                    <div className="bg-white">
                                        <input type="file" accept="image/x-png, image/gif, image/jpeg, image/bmp , image/svg"  className="hide" id="upload-mb-3" onChange={ (e) => {
                                            _handleImageChange(e, BANNER_POSITION_TYPE.CREATOR_3, BANNER_TYPE.MOBILE)
                                        }} />
                                        {banners && banners.length > 0 && (
                                                <ul className="resCmtImg form-row" style={{ margin: 0 }}>
                                                    {banners.map((image, i) => {
                                                        if(image.position == BANNER_POSITION_TYPE.CREATOR_3 && image.bannerType == BANNER_TYPE.MOBILE){
                                                            return (
                                                                <li key={i} className="cmt-image-item col-md-4" style={{ marginRight: 0, marginBottom: 20 }}>
                                                                    <div>
                                                                        <img src={image.imageUrl} className="full-width" style={{ maxWidth: "100%", maxHeight: 250 }} />
                                                                        <i className="fbDelImg" style={{ right: 0, padding: 0 }} onClick={() => _handleRemoveImageBanner(image.id)}>
                                                                            x
                                                                        </i>
                                                                    </div>
                                                                    <div>Nhập và nhấn enter để lưu</div>
                                                                    <input type="text" className="input-redirect" placeholder="Đường dẫn điều hướng" value={image.redirectUrl} onChange={(e) => handleChangeSelectUrl(e,image)} onKeyPress={(e)=>{handleSaveUrl(e, image)}} />
                                                                </li>
                                                            );
                                                        }
                                                    })}
                                                </ul>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </BoxBanner>
                    </Block>
                  
                    
                    <Block flexBasis="49%" boxShadow="none">
                        <img className="full-width" src={`${RequestDomain.CDNUrl()}/creator/guide-banner.png`} title="Trang chủ nhà sáng tạo" />
                    </Block>
                </div>
            </Box>
        </React.Fragment>
    );
}

export const BoxBanner = styled.div`
    min-height: 50px;
    border: 1px dashed #36a3f7;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;

    .input-redirect{
        width: 100%;
    }
`;

export const BannerHeader = styled.div`
    background: #74baef;
    color: white;
    padding: 5px 20px;
`;
