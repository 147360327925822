import { actionTypes } from "../types";
import CreatorServices from "../../../based/services/CreatorServices";

// Mockups
const fetchMockupImagesBegin = () => ({
  type: actionTypes.PROJECT_EDITOR_FETCH_IMAGE_MOCKUP_BEGIN,
  payload: null,
});

const fetchMockupImagesSuccess = ({ paging, listObjects }, hasMore) => ({
  type: actionTypes.PROJECT_EDITOR_FETCH_IMAGE_MOCKUP_SUCCESS,
  payload: {
    paging: paging,
    items: listObjects,
    hasMore: hasMore,
  },
});

const fetchMockupImagesFail = (err) => ({
  type: actionTypes.PROJECT_EDITOR_FETCH_IMAGE_MOCKUP_FAIL,
  payload: err,
});

// Artwork
const fetchArtworksBegin = () => ({
  type: actionTypes.PROJECT_EDITOR_FETCH_ARTWORKS_BEGIN,
  payload: null,
});

const fetchArtworksSuccess = ({ paging, listObjects }, hasMore) => ({
  type: actionTypes.PROJECT_EDITOR_FETCH_ARTWORKS_SUCCESS,
  payload: {
    paging: paging,
    items: listObjects,
    hasMore: hasMore,
  },
});

const fetchArtworksFail = (err) => ({
  type: actionTypes.PROJECT_EDITOR_FETCH_ARTWORKS_FAIL,
  payload: err,
});

// Action Creators
const getProjectEditorState = () => (_, getState) => {
  return getState().projectEditor;
};

export const openMockupImageModal = (id) => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_OPEN_IMAGE_MOCKUP_MODAL,
      payload: id,
    };
    dispatch(action);
  };
};

export const closeMockupImageModal = () => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_CLOSE_IMAGE_MOCKUP_MODAL,
      payload: null,
    };
    dispatch(action);
  };
};

export const fetchMockupImages = (paging, artworkId) => {
  return (dispatch) => {
    dispatch(fetchMockupImagesBegin());

    setTimeout(async () => {
      const [err, data] = await CreatorServices.GetMockupImagesByArtworkId(
        paging,
        artworkId
      );
      if (!err && data) {
        let hasMore = true;
        if (data.listObjects.length < paging.pageSize) hasMore = false;

        const updatedState = { ...dispatch(getProjectEditorState()) };
        const updatedItems = updatedState.mockupImageModal.data.items;

        if (paging.pageNumber === 1) {
          hasMore = true;
          dispatch(fetchMockupImagesSuccess(data, hasMore));
        } else {
          const updatedData = {
            paging: data.paging,
            listObjects: [...updatedItems, ...data.listObjects],
          };
          dispatch(fetchMockupImagesSuccess(updatedData, hasMore));
        }
      } else dispatch(fetchMockupImagesFail(err));
    }, 1000);
  };
};

export const fetchArtworks = (paging) => {
  return (dispatch) => {
    dispatch(fetchArtworksBegin());

    setTimeout(async () => {
      const [err, data] = await CreatorServices.GetArtworksByCreator(
        paging
      );
      if (!err && data) {
        let hasMore = true;
        if (data.listObjects.length < paging.pageSize) hasMore = false;

        const updatedState = { ...dispatch(getProjectEditorState()) };
        const updatedItems = updatedState.mockupImageModal.data.items;
        
        if (paging.pageNumber === 1) {
          hasMore = true;
          dispatch(fetchArtworksSuccess(data, hasMore));
        } else {
          const updatedData = {
            paging: data.paging,
            listObjects: [...updatedItems, ...data.listObjects],
          };
          dispatch(fetchArtworksSuccess(updatedData, hasMore));
        }
      } else dispatch(fetchArtworksFail(err));
    }, 1000);
  };
};

export const clearImages = () => {
  return async (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_CLEAR_IMAGES_MOCKUP,
      payload: null,
    };
    dispatch(action);
  };
};

export const selectMockupImage = (imgUrl, objectId) => {
  return async (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_SELECT_IMAGE_MOCKUP,
      payload: { imgUrl, objectId },
    };
    dispatch(action);
  };
};
