import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { CARD_COLOR, PROJECT_EDITOR_MODAL_TYPE as MODAL_TYPE } from '../../based/Constants';
import Modal from '../../based/Modal';
import ImageMockupModal from './ImageMockupModal';

// Redux
import {
  addMockup,
  editMockup,
  insertMockup,
  addSubImageMockup,
  closeMockupModal,
  openMockupImageModal,
} from '../../redux/project-editor/actions';
import { selectMockupModal } from '../../redux/project-editor/selector';
import BaseModal from '../../based/refactor/BaseModal';
import { BtnRounded } from '../../based/content/ct.elm';

const MockupModal = ({
  addMockup,
  editMockup,
  insertMockup,
  mockupModal,
  addSubImageMockup,
  closeMockupModal,
  openMockupImageModal,
}) => {
  const { isShow, mockupId, type, mainImage, subImages } = mockupModal;

  const getMainImageUrlHandler = () => {
    if (mainImage.url) return mainImage.url;
    return '/images/select-collection.png';
  };

  const getSubImageUrlHandler = (img) => {
    if (img.url) return img.url;
    return '/images/150x150.png';
  };

  const closeMockupModalHandler = () => {
    closeMockupModal();
  };

  const pickImageHandler = (id) => {
    openMockupImageModal(id);
  };

  const addSubImageMockupHandler = () => {
    addSubImageMockup();
  };


  const saveMockupHandler = () => {
    switch (type) {
      case MODAL_TYPE.ADD:
        return addMockup();
      case MODAL_TYPE.EDIT:
        return editMockup(mockupId);
      case MODAL_TYPE.INSERT:
        return insertMockup(mockupId);
      default:
        return;
    }
  };
  return (
    <React.Fragment>
      {/* Image Mockup Modal */}
      <ImageMockupModal />

      {/* Mockup Modal */}
      <BaseModal className='modal-width-40' isShow={isShow} title='Thêm mẫu sản phẩm' onClose={closeMockupModalHandler} onSave={saveMockupHandler}>
        <div className="row w-100">
          {/* Main Image */}
          <div className="form-group col-sm-12 col-md-5 col-lg-5 px-0 mr-1">
            <label>Thiết kế</label>
            <div className="row mx-0">
              <div className="col-12 px-0">
                <div
                  className="border px-0 cursor-pointer"
                  onClick={() => pickImageHandler(null)}
                >
                  <img
                    className="w-100"
                    alt="main-img"
                    src={getMainImageUrlHandler()}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Sub Images */}
          <div className="form-group col-sm-12 col-md-12 col-lg-6 px-0">
            <label>Ảnh Mockup</label>
            <div className="row mx-0">
              {/* List Sub Image */}
              {subImages.map((img) => (
                <div
                  key={img.id}
                  className="col-6 border px-0 cursor-pointer"
                  onClick={() => pickImageHandler(img.id)}
                >
                  <img
                    id={img.id}
                    className="w-100"
                    alt={`sub-img`}
                    src={getSubImageUrlHandler(img)}
                  />
                </div>
              ))}

              {/* Button Add Sub Image */}
              <div
                className="col-6 border px-0 cursor-pointer d-flex align-items-center justify-content-center position-relative"
                onClick={addSubImageMockupHandler}
              >
                <img
                  className="w-100"
                  alt="main-img"
                  src="/images/150x150xempty.png"
                />
                <i className="fa fa-plus-circle position-absolute fa-2x text-muted" />
              </div>
            </div>
          </div>

        </div>
      </BaseModal>

    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  mockupModal: selectMockupModal,
});

const mapDispatchToProps = (dispatch) => ({
  addMockup: () => {
    return dispatch(addMockup());
  },
  editMockup: (itemId) => {
    return dispatch(editMockup(itemId));
  },
  insertMockup: (itemId) => {
    return dispatch(insertMockup(itemId));
  },
  addSubImageMockup: () => {
    return dispatch(addSubImageMockup());
  },
  closeMockupModal: () => {
    return dispatch(closeMockupModal());
  },
  openMockupImageModal: (id) => {
    return dispatch(openMockupImageModal(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MockupModal);
