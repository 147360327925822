import * as signalR from "@aspnet/signalr";
import React, { Component } from "react";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import Common from "../../../based/Common";
import Request from "../../../based/Request";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { TopBarWrapper } from "./tb.elm";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

class TopBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownIndex: 0,
            fullName: "",
            avatar: "",
            balance: 0,
            isShowModal: false,
            notificationCount: 0,
            notifications: [],
        };
        this.handleLogout = this.handleLogout.bind(this);
        this.userRef = React.createRef();
        this.notificationRef = React.createRef();
        this.connection = null;
    }

    componentDidMount() {
        //event
        document.addEventListener("click", this.handleDropdown.bind(this, 0));
        if (Common.isMobile()) document.body.classList.toggle("enlarged");

        let token = Common.GetToken();
        this.connection = new signalR.HubConnectionBuilder().withUrl(`${Request.API_URL}/hubs/notification?access_token=${token}`).build();
        this.connection
            .start()
            .then(() => {})
            .catch((err) => {});

        Request.Get("/api/accounts/get-user-info")
            .then((result) => {
                if (result && result.data) {
                    let userInfo = result.data;
                    let roles = userInfo.roles;
                    let isVerified = userInfo.isVerified;
                    this.context.setApplicationInfo({ ...this.context.applicationInfo, userInfo: userInfo });
                    this.connection.send("subscribeRoles", roles);

                    //nếu account của creator chưa được verified thì đẩy sang trang creator-register
                    if (!isVerified) this.props.history.push("/creator-register");
                }
            })
            .catch((err) => {});

        this.connection.on("notify", (content, user) => {
            let notifications = this.state.notifications;
            if (notifications.length >= 5) notifications = notifications.slice(0, 4);
            notifications.push(<div dangerouslySetInnerHTML={{ __html: content }} />);
            this.setState({
                notificationCount: this.state.notificationCount + 1,
                notifications: notifications,
            });
        });
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleDropdown.bind(this, 0));
        this.connection.off("notify");
    }

    handleToggleSideBar() {
        document.body.classList.toggle("enlarged");
    }

    handleDropdown(index, event) {
        if (index > 0) {
            if (index == this.state.dropdownIndex)
                this.setState({
                    dropdownIndex: 0,
                });
            else
                this.setState({
                    dropdownIndex: index,
                });
        } else {
            const { target } = event;
            switch (this.state.dropdownIndex) {
                case 1: //notification
                    if (this.notificationRef.current && !this.notificationRef.current.contains(target)) {
                        this.setState({
                            dropdownIndex: 0,
                        });
                    }
                    break;
                case 2: //user
                    if (this.userRef.current && !this.userRef.current.contains(target)) {
                        this.setState({
                            dropdownIndex: 0,
                        });
                    }
                    break;
            }
        }
    }

    handleLogout() {
        Common.RemoveToken();
        cookie.remove("responseAPI");
        window.location.href = "/login";
    }

    handleRenderLinks() {
        return (
            <React.Fragment>
                <Link to={`/profile`} className="dropdown-item notify-item">
                    <div className="icon">
                        <PersonalIcon />
                    </div>
                    <span>Thông tin cá nhân</span>
                </Link>
                <Link to={`/security`} className="dropdown-item notify-item">
                    <div className="icon">
                        <PersonalIcon />
                    </div>
                    <span>Đổi mật khẩu</span>
                </Link>
                <a className="dropdown-item notify-item" onClick={this.handleLogout}>
                    <div className="icon">
                        <LogoutIcon />
                    </div>
                    <span className="logout">Đăng xuất</span>
                </a>
            </React.Fragment>
        );
    }

    render() {
        return (
            <TopBarWrapper>
                <ul className="list-inline float-right mb-0">
                    <li className="list-inline-item dropdown notification-list">
                        <a className="nav-link dropdown-toggle waves-effect waves-light nav-user" onClick={this.handleDropdown.bind(this, 2)} ref={this.userRef} data-toggle="dropdown" role="button" aria-haspopup="false" aria-expanded="false">
                            <img src={this.context.applicationInfo.userInfo.avatar ? this.context.applicationInfo.userInfo.avatar : "/images/users/no-avatar.png"} id="user-avatar" alt="user" className="rounded-circle obj-fit-contain" />
                        </a>
                        <div className={`dropdown-menu dropdown-menu-right profile-dropdown ${this.state.dropdownIndex == 2 ? "show" : ""}`} aria-labelledby="Preview">
                            {this.handleRenderLinks()}
                        </div>
                    </li>
                </ul>
            </TopBarWrapper>
        );
    }
}

export default withRouter(TopBar);

TopBar.contextType = ApplicationContext;

const PersonalIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3002 16.3C14.8634 14.6604 12.5763 13.6 10.0002 13.6C7.42413 13.6 5.13696 14.6604 3.7002 16.3C4.3002 17.2 6.4002 19 10.0002 19C13.6002 19 15.7002 17.2 16.3002 16.3Z" stroke="#707070" strokeWidth="1.8" />
            <circle cx="10" cy="10" r="9" stroke="#707070" strokeWidth="1.8" />
            <path
                d="M9.9998 11.35C11.491 11.35 12.6998 10.1411 12.6998 8.64995C12.6998 7.15878 11.491 5.94995 9.9998 5.94995C8.50864 5.94995 7.2998 7.15878 7.2998 8.64995C7.2998 10.1411 8.50864 11.35 9.9998 11.35Z"
                stroke="#707070"
                strokeWidth="1.8"
            />
        </svg>
    );
};

const LogoutIcon = () => {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H7"
                stroke="#D70000"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M12 15L17 10L12 5" stroke="#D70000" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17 10H6.5" stroke="#D70000" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
