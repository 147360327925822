import React, { useEffect, useState } from 'react';
import { Box } from '../../based/content/ct.elm';
import LoadingR from '../../based/LoadingR';
import CommonTable from '../../based/refactor/CommonTable';
import TextNumber from '../../based/refactor/TextNumber';
import BaseServices from '../../based/services/BaseServices';
const ORDER_BY = {
    Month: "Month",
}
const INIT_PAGING = {
    pageNumber: 1,
    pageSize: 50,
    orderBy: ORDER_BY.Month,
    orderDirection: 'DESC',
}

function Payments(props) {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(INIT_PAGING);
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        _fetchData(paging);
    }, [])

    async function _fetchData(pagingModel) {
        setBusy(true);
        const [err, resData] = await BaseServices.Post('/api/finance/get-revenues', pagingModel);
        setBusy(false);
        if (!err && resData) {
            setData(resData.listObjects);
            setPaging(resData.paging);
        } else {
            console.log(err)
        }
    }

    function _sortItems(sortBy) {
        let page = { ...paging };
        if (page.orderBy == sortBy) {
            if (page.orderDirection == 'DESC')
                page.orderDirection = 'ASC'
            else
                page.orderDirection = 'DESC'
        } else {
            page.orderBy = sortBy;
            page.orderDirection = 'DESC';
        }
        setPaging(page);
        _fetchData(page);
    }
    function _renderHeader() {
        const icon1 = paging.orderBy == ORDER_BY.Month ? paging.orderDirection == 'DESC' ? <i className="fa fa-sort-desc fa-sort-thead"></i> : <i className="fa fa-sort-asc fa-sort-thead"></i> : null;
        return <tr>
            <th className='cursor-pointer' onClick={() => _sortItems(ORDER_BY.Month)}>Tháng {icon1}</th>
            <th>Tổng số SP bán được</th>
            <th>Tiền bản quyền</th>
            <th>Thuế TNCN</th>
            <th>Thực tế nhận</th>
            <th>Trạng thái</th>
        </tr>
    }
    function _renderBody() {
        return <React.Fragment>
            {data && data.length > 0 ? data.map((item, i) => {
                return <tr key={i}>
                    <td className='text-center'>{item.displayMonth}</td>
                    <td className='text-center'>{item.productCount}</td>
                    <td className='text-center'>
                        <TextNumber value={item.totalLoyalty} text price />
                    </td>
                    <td className='text-center'>
                        <TextNumber value={item.tax} text price />
                    </td>
                    <td className='text-center'>
                        <TextNumber value={item.finallyLoyalty} text price />
                    </td>
                    <td>
                        {item.isPaid ? 'Đã thanh toán' : 'Chưa thanh toán'}
                    </td>
                </tr>
            })
                :
                <tr>
                    <td colSpan={6}>Không có dữ liệu</td>
                </tr>
            }
        </React.Fragment>
    }

    return (
        <Box>
            <LoadingR fullScreen isShow={busy} />
            <CommonTable
                paging={paging}
                renderHeader={_renderHeader}
                renderBody={_renderBody}
            />
        </Box>
    );
}

export default Payments;