import moment from "moment";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-currency-format";
import Common from "../../based/Common";
import { CARD_COLOR, CONSTANTS, NOTIFY } from "../../based/Constants";
import { Block, Box } from "../../based/content/ct.elm";
import { ConfirmDialog } from "../../based/Dialog";
import CheckBox from "../../based/inputs/CheckBox";
import { Notify } from "../../based/Notify";
import Button, { ButtonType } from "../../based/refactor/Button";
import CommonTable from "../../based/refactor/CommonTable";
import InputDatePicker from "../../based/refactor/InputDatePicker";
import Textbox from "../../based/refactor/Textbox";
import Request from "../../based/Request";
import VoucherServices from "../../based/services/VoucherServices";
import Breadcum from "../../layout/refactor/Breadcum/Breadcum";
import LayoutLight from "../../layout/refactor/Layout/Layout";
import { VoucherGiftModel } from "../../models/VoucherModel";
import AddOrEdit from "../AddOrEdit";
import QuickAddOrEdit from "../QuickAddOrEdit";

const VoucherGiftManagement = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState(Common.Paging);
    const [datas, setDatas] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showQuickModal, setShowQuickModal] = useState(false);
    const [currentVoucher, setCurrentVoucher] = useState(null);
    const [dashboard, setDashboard] = useState({});
    const menuItem = ["Trang chủ", "Voucher thưởng từ Ranus"];

    useEffect(() => {
        document.getElementById("code").focus();
        getDatas(paging);
        getDashboard();
    }, []);

    /** Lấy data voucher gift */
    const getDatas = async (newPaging) => {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await VoucherServices.GetVoucherGiftPaging(newPaging);
        if (!error && data) {
            let pagingUp = data.paging;
            if (pagingUp) {
                if (pagingUp.startDate) pagingUp.startDate = moment(pagingUp.startDate).toDate();
                if (pagingUp.endDate) pagingUp.endDate = moment(pagingUp.endDate).toDate();
            }
            setDatas(data.listObjects);
            setPaging(pagingUp);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    };

    /** Lấy thông tin config settings level */
    const getDashboard = async () => {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await VoucherServices.GetVoucherGiftDashboard();
        if (!error && data) {
            setDashboard(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDashboard({});
            setIsLoading(false);
            setMsg(null);
        }
    };

    /** Handle checkbox */
    const _handleChecked = (index, value) => {
        let newDatas = [...datas];
        if (index === -1) newDatas.forEach((m) => (m.selected = value));
        else newDatas[index].selected = value;
        setDatas(newDatas);
    };

    /** Handle thêm voucher */
    const _handleAdd = () => {
        setCurrentVoucher(new VoucherGiftModel());
        setShowModal(true);
    };

    /** Handle thêm nhanh voucher */
    const _handleQuickAdd = () => {
        let model = new VoucherGiftModel();
        model.appliedQuantity = 1;
        model.code = "autoGenerate";
        setCurrentVoucher(model);
        setShowQuickModal(true);
    };

    /** Handle edit voucher */
    const _handleEdit = async () => {
        var selecteds = datas.filter((x) => x.selected);
        if (selecteds && selecteds.length == 1) {
            let selected = selecteds[0];
            if (selected != null) {
                setIsLoading(true);
                setMsg("Đang tải dữ liệu...");
                const [err, data] = await VoucherServices.GetVoucherGiftById(selected.id);
                if (!err && data) {
                    setIsLoading(false);
                    setMsg(null);
                    setCurrentVoucher(data);
                    setShowModal(true);
                } else {
                    setIsLoading(false);
                    setMsg(null);
                    Notify(NOTIFY.INFO, "Thông báo", CONSTANTS.MSG_ERROR);
                }
            }
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn voucher để thực hiện. Vui lòng kiểm tra lại!");
    };

    /** Handle delete voucher */
    const _handleDelete = () => {
        var ids = datas.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa voucher này", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await VoucherServices.RemoveVoucherGift(ids);
                    if (!error && data) {
                        setIsLoading(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Thành công." });
                        getDatas(paging);
                        getDashboard();
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Không có voucher nào được chọn. Vui lòng kiểm tra lại!");
    };

    /** Handle filter */
    const _handleFilter = () => {
        let newPaging = { ...paging, pageNumber: 1 };
        getDatas(newPaging);
        getDashboard();
    };

    /** render header dash board */
    const renderHeaderInfoTier = () => {
        return (
            <tr>
                <th>&nbsp;</th>
                <th>Bán trên 10</th>
                <th>Bán trên 50</th>
                <th>Bán trên 100</th>
                <th>Bán trên 200</th>
            </tr>
        );
    };

    /** render body dashboard */
    const renderBodyInfoTier = () => {
        if (dashboard && dashboard.level) {
            return (
                <tr>
                    <td>
                        <span>{dashboard.level.tierName}</span>
                    </td>
                    <td className='text-center'>
                        <span>{dashboard.level.totalVouchersSellOver10}</span>
                    </td>
                    <td className='text-center'>
                        <span>{dashboard.level.totalVouchersSellOver50}</span>
                    </td>
                    <td className='text-center'>
                        <span>{dashboard.level.totalVouchersSellOver100}</span>
                    </td>
                    <td className='text-center'>
                        <span>{dashboard.level.totalVouchersSellOver200}</span>
                    </td>
                </tr>
            );
        } else
            return (
                <tr>
                    <td>&nbsp;</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            );
    };

    /** render action */
    const renderAction = () => {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className='row mt-10 w-30'>
                <Button btnType={ButtonType.Search} label='Tìm kiếm' onClick={() => _handleFilter()} />
                &ensp;
                <Button btnType={ButtonType.Add} label='Thêm mới' onClick={() => _handleAdd()} />
                &ensp;
                <Button btnType={ButtonType.Add} label='Thêm nhanh' onClick={() => _handleQuickAdd()} />
                &ensp;
                <Button className={dataChecked && dataChecked.length > 0 ? "" : "disabled"} btnType={ButtonType.Delete} onClick={() => (dataChecked && dataChecked.length > 0 ? _handleDelete() : {})} />
                &ensp;
                <Button className={isEnabled ? "" : "disabled"} btnType={ButtonType.Edit} onClick={() => (isEnabled ? _handleEdit() : {})} />
            </div>
        );
    };

    /** render header */
    const renderMainTableHead = () => {
        return (
            <React.Fragment>
                <tr>
                    <th width={40} className='text-center' rowSpan='2'>
                        <CheckBox id='select_all' name='select_all' checked={datas && datas.length > 0 && datas.filter((x) => x.selected).length === datas.length} label='' onCheckedChange={(name, value) => _handleChecked(-1, value)} />
                    </th>
                    <th width='150' rowSpan='2' className='text-center'>
                        Mã Voucher
                    </th>
                    <th width='150' className='text-center' rowSpan='2'>
                        Tên Voucher
                    </th>
                    <th width='150' className='text-center' colSpan='2'>
                        Thời gian áp dụng
                    </th>
                    <th width='150' className='text-center' rowSpan='2'>
                        Số lượng SP áp dụng
                    </th>
                    <th width='200' rowSpan='2' className='text-center'>
                        Số tiền bản quyền (vnđ)
                    </th>
                    <th width='200' rowSpan='2' className='text-center'>
                        Phí hoạt động Ranus (vnđ)
                    </th>
                    <th width='100' rowSpan='2' className='text-center'>
                        Trạng thái Voucher
                    </th>
                    <th width='100' rowSpan='2' className='text-center'>
                        Số lượt thu thập
                    </th>
                    <th width='100' rowSpan='2' className='text-center'>
                        Số lượt sử dụng
                    </th>
                    <th width='150' rowSpan='2' className='text-center'>
                        Chia sẻ
                    </th>
                </tr>
                <tr>
                    <th width='150' className='text-center'>
                        Từ ngày
                    </th>
                    <th width='150' className='text-center'>
                        Đến ngày
                    </th>
                </tr>
            </React.Fragment>
        );
    };

    /** render body */
    const renderMainTableBody = () => {
        return (
            <React.Fragment>
                {datas && datas.length > 0 ? (
                    datas.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td width={40} className='text-center'>
                                    <CheckBox id={i.toString()} name={i.toString()} checked={item.selected ? item.selected : false} label=' ' onCheckedChange={(name, value) => _handleChecked(i, value)} />
                                </td>
                                <td className='text-center'>
                                    <strong>{item.code}</strong>
                                </td>
                                <td className='text-center'> {item.name}</td>
                                <td className='text-center'> {item.fromDateDisplay}</td>
                                <td className='text-center'> {item.toDateDisplay}</td>
                                <td className='text-center'> {item.appliedQuantity}</td>
                                <td className='text-center'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.loyaltyAmount} />
                                </td>
                                <td className='text-center'>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.lotusChargeAmount} />
                                </td>
                                <td className='text-center'> {item.isActive ? "Đang hoạt động" : "Không hoạt động"}</td>
                                <td className='text-center'> {item.collectedCount}</td>
                                <td className='text-center'> {item.usedCount}</td>
                                <td className='text-center d-flex'>
                                    <Textbox id='shareVoucher' name='shareVoucher' readOnly value={`${Request.HOME_URL}/share-voucher/${item.code}`} allowCopy />
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan='12' className='text-center'>
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Breadcum menu={menuItem} title='Voucher thưởng từ Ranus' />
            <Box>
                <div className='box__content'>
                    <Block flexBasis='50%' boxShadow='none'>
                        <div className='header'>
                            <span>Thông tin tier hiện tại</span>
                        </div>
                        <div className='content'>
                            <CommonTable renderHeader={renderHeaderInfoTier} renderBody={renderBodyInfoTier} />
                        </div>
                    </Block>
                    <Block bgColor={CARD_COLOR.Blue} flexBasis='24%'>
                        <div className='header'>
                            <h6 style={{ color: "#fff" }}>Tổng sản phẩm bán được tháng trước</h6>
                        </div>
                        <div className='header'>
                            {" "}
                            <p>Số lượng sản phẩm của Voucher tùy thuộc vào Tier và số lượng này.</p>
                        </div>
                        <div className='content'>
                            <div className='info'>
                                <h2 style={{ color: "#fff" }}>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={dashboard.totalProducts ? dashboard.totalProducts : 0} />
                                </h2>
                            </div>
                        </div>
                    </Block>
                    <Block bgColor={CARD_COLOR.Green} flexBasis='24%'>
                        <div className='header'>
                            <h6 style={{ color: "#fff" }}>Số voucher thưởng tháng này</h6>
                        </div>
                        <div className='header'>
                            <p>Dựa vào số lượng bán được tháng trước và tier hiện tại để xác định.</p>
                        </div>
                        <div className='content'>
                            <div className='info'>
                                <h2 style={{ color: "#fff" }}>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={dashboard.allowQuantity ? dashboard.allowQuantity : 0} />
                                </h2>
                            </div>
                        </div>
                    </Block>
                </div>
            </Box>
            <Box>
                <div className='box__content'>
                    <Block flexBasis='100%' boxShadow='none' style={{ marginTop: "20px" }} height='206px'>
                        <div className='flex__space-between mb-10' style={{ width: "90%" }}>
                            <div className='flex__column flex__basis-30'>
                                <label>Mã voucher:</label>
                                <Textbox placeholder='Mã voucher' id='code' className='input-40' name='code' onChanged={(name, value) => setPaging({ ...paging, code: value })} value={paging.code} />
                            </div>
                            <div className='flex__column flex__basis-30'>
                                <label>Tên voucher:</label>
                                <Textbox placeholder='Tên voucher' id='voucher' className='input-40' name='voucher' onChanged={(name, value) => setPaging({ ...paging, name: value })} value={paging.name} />
                            </div>
                            <div className='flex__column flex__basis-40'>
                                <label>Thời gian áp dụng:</label>
                                <div className='flex__row'>
                                    <InputDatePicker dateFormat='dd/MM/yyyy' name='startDate' maxDate={paging.endDate} value={paging.startDate} placeholder='Từ ngày' onChanged={(newValue) => setPaging({ ...paging, startDate: newValue })} />
                                    <InputDatePicker dateFormat='dd/MM/yyyy' name='endDate' minDate={paging.startDate} value={paging.endDate} placeholder='Đến ngày' onChanged={(newValue) => setPaging({ ...paging, endDate: newValue })} />
                                </div>
                            </div>
                        </div>
                        {renderAction()}
                    </Block>
                </div>
                <div className='box__content flex__column'>
                    <CommonTable renderHeader={renderMainTableHead} renderBody={renderMainTableBody} paging={paging} onFilter={(paging) => getDatas(paging)} />
                </div>
            </Box>
            {showModal && (
                <AddOrEdit
                    isShowModal={showModal}
                    voucher={currentVoucher}
                    onClose={() => setShowModal(false)}
                    onSaveSucceed={() => {
                        getDashboard();
                        getDatas(paging);
                    }}
                />
            )}
            {showQuickModal && (
                <QuickAddOrEdit
                    isShowModal={showQuickModal}
                    voucher={currentVoucher}
                    onClose={() => setShowQuickModal(false)}
                    onSaveSucceed={() => {
                        getDashboard();
                        getDatas(paging);
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default VoucherGiftManagement;
