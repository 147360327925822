import React from 'react';
import TextNumber from '../../based/inputs/TextNumber';

function PendingPayment(props) {
    return (
        <div className="row">
            <div className="col-12 bg-white">
                <h5>Số sản phẩm đang chờ thanh toán</h5>
                <div className="row">
                    <div className=" col-md-6 col-sm-6">
                        <div className="card-box widget-box-two widget-two-custom p-20">
                            <div className="media">
                                <div className="avatar-lg rounded-circle bg-custom widget-two-icon align-self-center">
                                    <i className="mdi mdi-currency-usd avatar-title font-30 text-white" />
                                </div>
                                <div className="wigdet-two-content media-body">
                                    <p className="m-0 text-uppercase font-weight-medium text-truncate" title="Statistics">SP Đã mua, chưa hoàn thành đơn hàng</p>
                                    <h3 className="font-weight-medium my-2">
                                        <span data-plugin="counterup">
                                            <TextNumber price displayType='text' value={props.processingOrderProduct ? props.processingOrderProduct.totalLoyaltyAmount : 0} />
                                        </span>
                                    </h3>
                                    <p className="m-0"><a href='#'>{props.processingOrderProduct ? props.processingOrderProduct.quantity : 0} sản phẩm</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" col-md-6 col-sm-6">
                        <div className="card-box widget-box-two widget-two-custom p-20">
                            <div className="media">
                                <div className="avatar-lg rounded-circle bg-custom widget-two-icon align-self-center">
                                    <i className="mdi mdi-currency-usd avatar-title font-30 text-white" />
                                </div>
                                <div className="wigdet-two-content media-body">
                                    <p className="m-0 text-uppercase font-weight-medium text-truncate" title="Statistics">Sản phẩm đã hoàn thành đơn</p>
                                    <h3 className="font-weight-medium my-2">
                                        <span data-plugin="counterup">
                                            <TextNumber price displayType='text' value={props.succeededOrderProduct ? props.succeededOrderProduct.totalLoyaltyAmount : 0} />
                                        </span>
                                    </h3>
                                    <p className="m-0"><a href='#'>{props.succeededOrderProduct ? props.succeededOrderProduct.quantity : 0} sản phẩm</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PendingPayment;