import BaseServices from "./BaseServices";

const BannerServices = {  
    DeleteBanner: async (id) => {
        return await BaseServices.Get(`/api/Banner/delete-banner/${id}`);
    },
    GetBannerCreator: async () => {
        return await BaseServices.Get(`/api/Banner/get-banner-by-creator`);
    },
    SaveBannerCreator: async (banner) => {
        return await BaseServices.Post("/api/Banner/create-update-banner-creator", banner);
    },
};

export default BannerServices;
