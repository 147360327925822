import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';

import { PROJECT_EDITOR_TEXT_ALIGN_TYPE as TEXT_ALIGN_TYPE } from '../../based/Constants';

// Redux
import { removeText, setText } from '../../redux/project-editor/actions';

const ProjectText = ({ id, value, align, isLast, removeText, setText }) => {
  const itemRef = useRef(null);

  const [isActive, setIsActive] = useState(false);
  const [alignType, setAlignType] = useState(align);
  const [textValue, setTextValue] = useState(value);

  useEffect(() => {
    if (itemRef.current && isLast) {
      itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  const getClassByTextAlignHandler = () => {
    switch (alignType) {
      case TEXT_ALIGN_TYPE.LEFT:
        return 'justify-content-start';
      case TEXT_ALIGN_TYPE.CENTER:
        return 'justify-content-center';
      case TEXT_ALIGN_TYPE.RIGHT:
        return 'justify-content-end';
      default:
        return '';
    }
  };

  const removeTextHandler = () => {
    removeText(id);
  };

  let timer;
  const textChangeHandler = (event) => {
    const value = event.target.value;
    setTextValue(value);

    clearTimeout(timer);
    timer = setTimeout(() => {
      setText({ id, value });
    }, 1000);
  };

  return (
    <div
      ref={itemRef}
      className="project-text__wrapper"
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <div className={`project-text row mx-0 px-0 justify-content-center`}>
        <div className="col-10 px-0">
          <div
            className={`row mx-0 px-0 alignType-items-center ${getClassByTextAlignHandler()}`}
          >
            <div className="project-text__area col-6 px-0">
              {/* Dropdown Button */}
              <div
                className={`project-text__dropdown d-inline-block ${
                  isActive ? 'show' : 'hide'
                }`}
              >
                <Dropdown className="shadow rounded-circle">
                  <Dropdown.Toggle
                    className="rounded-circle btn-sm"
                    variant="primary"
                  />
                  <Dropdown.Menu>
                    <Dropdown.Item>Chỉnh sửa</Dropdown.Item>
                    <Dropdown.Item onClick={removeTextHandler}>
                      Xóa văn bản
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* Align Button */}
              <div
                className={`project-text__align rounded bg-dark shadow p-1 ${
                  isActive ? 'show' : 'hide'
                }`}
              >
                <i
                  className={`fa fa-align-left mx-1 cursor-pointer ${
                    alignType === TEXT_ALIGN_TYPE.LEFT
                      ? 'text-white'
                      : 'text-muted'
                  }`}
                  onClick={() => setAlignType(TEXT_ALIGN_TYPE.LEFT)}
                />
                <i
                  className={`fa fa-align-center mx-1 cursor-pointer ${
                    alignType === TEXT_ALIGN_TYPE.CENTER
                      ? 'text-white'
                      : 'text-muted'
                  }`}
                  onClick={() => setAlignType(TEXT_ALIGN_TYPE.CENTER)}
                />
                <i
                  className={`fa fa-align-right mx-1 cursor-pointer ${
                    alignType === TEXT_ALIGN_TYPE.RIGHT
                      ? 'text-white'
                      : 'text-muted'
                  }`}
                  onClick={() => setAlignType(TEXT_ALIGN_TYPE.RIGHT)}
                />
              </div>
              <textarea
                className="p-2 w-100"
                value={textValue}
                placeholder="Nhập văn bản ở đây"
                onChange={textChangeHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  removeText: (itemId) => {
    return dispatch(removeText(itemId));
  },
  setText: ({ id, value }) => {
    return dispatch(setText({ id, value }));
  },
});

export default connect(null, mapDispatchToProps)(ProjectText);
