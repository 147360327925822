import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Breadcum from "../layout/refactor/Breadcum/Breadcum";

function CreateBrandInfo(props) {
    const history = useHistory();
    return (
        <React.Fragment>
            <Breadcum menu={["Trang chủ", "Quản lý thiết kế", "Đăng thiết kế"]} title="Thêm mới thương hiệu" />
            <Wrapper>
                <BrandWrapper>
                    <div className="main">Cảm ơn bạn đã tạo thương hiệu mới</div>
                    <div className="sub">
                        <p>
                            Thông tin thương hiệu sẽ được <span>Ranus xét duyệt trong vòng 24H</span> kể từ lúc gửi yêu cầu.
                        </p>
                        <p>
                            <span>Sau khi xét duyệt thì bạn có thể đăng tải và bán thiết kế</span> cho thương hiệu của bạn.
                        </p>
                    </div>
                    <div>
                        <button className="button" onClick={(e) => history.push("/brand")}>
                            Quản lý thương hiệu
                        </button>
                    </div>
                </BrandWrapper>
            </Wrapper>
        </React.Fragment>
    );
}

CreateBrandInfo.propTypes = {};

export default CreateBrandInfo;

const Wrapper = styled.div`
    width: 100%;
`;
const BrandWrapper = styled.div`
    margin: 0 20px;
    background: white;
    border-radius: 10px;
    padding: 20px;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    text-align: center;
    .main {
        font-weight: 700;
        font-size: 25px;
        line-height: 20px;
        /* identical to box height, or 80% */

        text-align: center;

        color: #444444;
        margin: 20px 0;
    }
    .sub {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #444444;
        span {
            font-weight: 700;
            color: #28b259;
        }
        margin: 20px 0;
    }
    .button {
        border: none;
        background: #28b259;
        color: white;
        padding: 10px 20px;
        border-radius: 10px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #f3f3f3;
        margin: 20px 0;
    }
`;
