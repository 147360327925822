import React, { useEffect, useState } from "react";
import Breadcum from "../layout/refactor/Breadcum/Breadcum";
import CommonTable from "../based/refactor/CommonTable";
import Loading from "../based/Loading";
import { Box } from "../based/content/ct.elm";
import BaseServices from "../based/services/BaseServices";
import TextNumber from "../based/inputs/TextNumber";
import Textbox from "../based/inputs/Textbox";
import TFUSelect from "../based/inputs/TFUSelect";
import Request from "../based/Request";

const menuItem = ["Trang chủ", "Thông tin phát triển thương hiệu"];
const FILTER_TIME = [
    {
        value: -1,
        label: "Tất cả",
    },
    {
        value: 7,
        label: "7 ngày gần nhất",
    },
    {
        value: 30,
        label: "30 ngày gần nhất",
    },
    {
        value: 90,
        label: "90 ngày gần nhất",
    },
];

const ORDER_BY = {
    ClickCount: "ClickCount",
    ConversionRate: "ConversionRate",
};
const INIT_PAGING = {
    pageNumber: 1,
    pageSize: 20,
    orderBy: ORDER_BY.ClickCount,
    orderDirection: "DESC",
    orderStatus: 2,
    keyword: "",
    dayOffset: FILTER_TIME[0].value,
};

export default function FinanceCreatorBrand(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState(INIT_PAGING);
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        getDatas(paging);
    }, []);

    async function getDatas(paging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await BaseServices.Post("/api/finance/get-creator-insight-skus", paging);
        if (!error && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    function _sortItems(sortBy) {
        let page = { ...paging };
        if (page.orderBy == sortBy) {
            if (page.orderDirection == "DESC") page.orderDirection = "ASC";
            else page.orderDirection = "DESC";
        } else {
            page.orderBy = sortBy;
            page.orderDirection = "DESC";
        }
        setPaging(page);
        getDatas(page);
    }
    function _renderHeader() {
        const icon1 = paging.orderBy == ORDER_BY.ClickCount ? paging.orderDirection == "DESC" ? <i className="fa fa-sort-desc fa-sort-thead"></i> : <i className="fa fa-sort-asc fa-sort-thead"></i> : null;
        const icon2 = paging.orderBy == ORDER_BY.ConversionRate ? paging.orderDirection == "DESC" ? <i className="fa fa-sort-desc fa-sort-thead"></i> : <i className="fa fa-sort-asc fa-sort-thead"></i> : null;
        return (
            <tr>
                <th className="text-center">Tên SP, SKU ID</th>
                <th className="text-center cursor-pointer" onClick={() => _sortItems(ORDER_BY.ClickCount)}>
                    Lượt Click {icon1}
                </th>
                <th className="text-center cursor-pointer" onClick={() => _sortItems(ORDER_BY.ConversionRate)}>
                    Tỉ lệ chuyển đổi (%) {icon2}
                </th>
                <th className="text-center">Giao hàng thành công</th>
                <th className="text-center">Đang giao hàng</th>
                <th className="text-center">Đang trong giỏ hàng</th>
                <th className="text-center">Mặt trước</th>
                <th className="text-center">Mặt sau</th>
                <th className="text-center">Giá bán (đ)</th>
                <th className="text-center">Tiền bản quyền (đ)</th>
                <th className="text-center">Voucher đang được áp dụng</th>
                <th className="text-center">Ngày tạo Artwork</th>
                <th className="text-center">Trạng thái</th>
            </tr>
        );
    }

    const handleFriendlyUrl = (friendlyUrl) => {
        let url = `${Request.HOME_URL}/${friendlyUrl}`;
        window.open(url, "_blank");
    };

    function _renderBody() {
        return (
            <React.Fragment>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td className="vertical-align-middle text-center">
                                    <p>{item.productName}</p>
                                </td>
                                <td className="vertical-align-middle text-center">{item.clickCount}</td>
                                <td className="vertical-align-middle text-center">{item.conversionRate}</td>
                                <td className="vertical-align-middle text-center">{item.shippedCount}</td>
                                <td className="vertical-align-middle text-center">{item.shippingCount}</td>
                                <td className="vertical-align-middle text-center">{item.buyingCount}</td>
                                <td
                                    className="vertical-align-middle text-center cursor-pointer"
                                    onClick={(e) => {
                                        if (item.fullFriendlyUrl) handleFriendlyUrl(item.fullFriendlyUrl);
                                    }}
                                >
                                    {item.backThumbnail ? <img src={item.backThumbnail} alt={item.sku} style={{ width: "200px", height: "auto", objectFit: "cover" }} /> : ""}
                                </td>
                                <td
                                    className="vertical-align-middle text-center cursor-pointer"
                                    onClick={(e) => {
                                        if (item.fullFriendlyUrl) handleFriendlyUrl(item.fullFriendlyUrl);
                                    }}
                                >
                                    {item.frontThumbnail ? <img src={item.frontThumbnail} alt={item.sku} style={{ width: "200px", height: "auto", objectFit: "cover" }} /> : ""}
                                </td>
                                <td className="vertical-align-middle text-center">
                                    <TextNumber value={item.minSellPrice} price text />
                                    -
                                    <TextNumber value={item.maxSellPrice} price text />
                                </td>
                                <td className="vertical-align-middle text-center">
                                    <TextNumber value={item.loyaltyAmount} price text />
                                </td>
                                <td className="vertical-align-middle text-center">
                                    {item.vouchers &&
                                        item.vouchers.length > 0 &&
                                        item.vouchers.map((fc, f) => {
                                            return <p key={f}>{fc.name}</p>;
                                        })}
                                </td>
                                <td className="vertical-align-middle text-center">{item.artworkCreatedTimeText}</td>
                                <td className="vertical-align-middle text-center">{item.statusText}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan="13" className="text-center">
                            <span>Không có dữ liệu</span>
                        </td>
                    </tr>
                )}
            </React.Fragment>
        );
    }
    function _renderAction() {}
    return (
        <React.Fragment>
            <Breadcum menu={menuItem} title="Thông tin phát triển thương hiệu" />
            <Loading show={isLoading} msg={msg} />
            <Box>
                <div className="box__header">
                    <div style={{ width: 200, marginRight: 10 }}>
                        <Textbox value={paging.keyword} onChanged={(name, value) => setPaging({ ...paging, keyword: value })} />
                    </div>
                    <div style={{ width: 200, marginRight: 10 }}>
                        <TFUSelect options={FILTER_TIME} value={paging.dayOffset} onChanged={(value) => setPaging({ ...paging, dayOffset: value ? value : 0 })} />
                    </div>
                    <div style={{ width: 200, marginRight: 10 }}>
                        <button className="btn btn-sm btn-custom" onClick={() => getDatas(paging)}>
                            <i className="fa fa-search"></i>Tìm kiếm
                        </button>
                    </div>
                </div>
                <CommonTable noBoxOuter data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={(paging) => getDatas(paging)} />
            </Box>
        </React.Fragment>
    );
}
