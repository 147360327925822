import React, { useEffect, useState } from "react";
import { SetConfigModel, SetModel } from "../model/PreAddArtworkModel";
import { CreateArtworkContext, PRINT_SIDE } from "./CreateArtworkContext";
import useFormValidate from "../../hooks/useFormValidate";
import SetProductServices from "../../based/services/SetProductServices";
import { IMAGE_TYPE } from "../../based/Constants";
export const StepOptions = [
    {
        value: 1,
        text: "Chọn dòng sản phẩm",
        isActive: true,
        isDisable: false,
    },
    {
        value: 2,
        text: "Chi tiết thiết kế",
        isActive: false,
        isDisable: false,
    },
];

function CreateArtworkProvider(props) {
    const [step, setStep] = useState([...StepOptions]); //ds các màn
    const [setModel, setSetModel] = useState(new SetModel()); //set sản phẩm của creator
    const [artworkModels, setArtworkModels] = useState([]); //danh sách artwork của creator
    const [printSide, setPrintSide] = useState(PRINT_SIDE.Both); //mặt in
    const [detail, setDetail] = useState([]); //ds sản phẩm phôi
    const [frame, setFrame] = useState({}); //thông tin khung in
    const [configModel, setConfigModel] = useState(new SetConfigModel()); //thông tin config của artwork
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(() => {
        if (setModel && setModel.appSetId > 0) _getDetail(setModel.appSetId);
    }, [setModel && setModel.appSetId]);

    async function _getDetail(setId) {
        const [err, data] = await SetProductServices.GetProductDetailCreateArtwork(setId);
        if (!err && data && data.productDetails) {
            let datas = data.productDetails;
            let item = datas[0];
            let colors = item.colors;
            let images = colors[0].images;
            item.checked = true;
            colors[0].checked = true;
            images[0].checked = true;
            setDetail(datas);
            setFrame(item.standardFrame);
            setConfigModel(data.setConfigModel);

            //set printside
            let imageTypeValidate = data.setConfigModel && data.setConfigModel.imageTypeValidate;
            if (imageTypeValidate == IMAGE_TYPE.ArtworkCase) setPrintSide(PRINT_SIDE.Front);
        } else {
            setDetail([]);
            setFrame({});
            setConfigModel(new SetConfigModel());
        }
    }

    return (
        <CreateArtworkContext.Provider
            value={{
                step,
                setStep,
                setModel,
                setSetModel,
                artworkModels,
                setArtworkModels,
                printSide,
                setPrintSide,
                frame,
                setFrame,
                detail,
                setDetail,
                configModel,
                setConfigModel,
                addRef,
                displayError,
                isValid,
            }}
        >
            {props.children}
        </CreateArtworkContext.Provider>
    );
}

export default CreateArtworkProvider;
