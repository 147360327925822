import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ApplicationContext } from "../../../context/ApplicationContext";
import RanusCreator from "./assets/RanusCreator.png";
import { NavbarLeftWrapper, NavBarToggle, NavItem } from "./nbl.elm";

export const NavITem = ({ id, handleClickMenu, currMenuSelect, toggle, item }) => {
    var parent = item.filter((item) => item.parentId === 0);
    var children = item.filter((item) => item.parentId !== 0);
    const [isDropDown, setIsDropDown] = useState(true);
    const handleDropdown = () => {
        handleClickMenu(id);
        setIsDropDown(!isDropDown);
    };
    const setActiveNavId = (id) => {
        localStorage.setItem("navItem", id);
    };
    const location = useLocation();
    return (
        <NavItem toggle={toggle}>
            <div className="main__nav__item">
                <span className="item__icon">{parent[0].icon ? <img src={parent[0].icon} /> : <i className="fa fa-id-card-o" aria-hidden="true"></i>}</span>
                <div className="item__name">{parent[0].name}</div>
                <span className="toggle__icon" onClick={handleDropdown}>
                    {currMenuSelect.includes(id) ? <i className="fa fa-chevron-right" aria-hidden="true"></i> : <i className="fa fa-chevron-down" aria-hidden="true"></i>}
                </span>
            </div>
            <div className={`${toggle && isDropDown ? "dropdown active" : "dropdown"}`}>
                <ul>
                    {children.length &&
                        children.map((item, index) => (
                            <li className={`${location.pathname === item.navigateLink ? "menu__item active" : "menu__item"}`} onClick={() => setActiveNavId(item.id)} key={index}>
                                <Link to={item.navigateLink}>{item.name}</Link>
                            </li>
                        ))}
                </ul>
            </div>
        </NavItem>
    );
};
const NavbarLeft = ({ toggle }) => {
    const [isToggle, setIsToggle] = useState(true);
    const [currMenuSelect, setCurrMenuSelect] = useState([]);
    var context = useContext(ApplicationContext);
    var menus = context.applicationInfo.menus;

    const handleToggleNav = () => {
        setIsToggle(!isToggle);
        toggle();
    };
    const handleClickMenu = (key) => {
        if (!currMenuSelect.includes(key)) {
            setCurrMenuSelect([...currMenuSelect, key]);
        } else {
            var menuSelect = currMenuSelect.filter((value, index) => {
                return value !== key;
            });
            setCurrMenuSelect(menuSelect);
        }
    };
    const _handleMenuItem = (menus) => {
        if (menus && menus.length) {
            var parentItems = menus.filter((item, index) => item.parentId === 0);
            var menulist = [];
            if (parentItems.length) {
                parentItems.map((item) => {
                    var children = menus.filter((childItem) => childItem.parentId === item.id);
                    menulist.push([item, ...children]);
                });
            }
            return menulist.map((item, index) => <NavITem key={index} item={item} id={index} handleClickMenu={handleClickMenu} currMenuSelect={[...currMenuSelect]} toggle={isToggle} />);
        }
    };
    return (
        <Wrapper>
            <NavbarLeftWrapper toggle={isToggle}>
                <div className={`${isToggle ? "navbar__left__container toggle" : "navbar__left__container"}`}>
                    <NavBarToggle onClick={handleToggleNav}>{isToggle ? <i className="fa fa-chevron-left" aria-hidden="true"></i> : <i className="fa fa-chevron-right" aria-hidden="true"></i>}</NavBarToggle>
                    <Link to="/" className="nav__logo">
                        {isToggle ? <img className="logo__full" src={RanusCreator} /> : <img className="logo__sub" src="/images/Logo-04.png" />}
                    </Link>
                    <div className="nav__content__wrapper">{_handleMenuItem(menus)}</div>
                </div>
            </NavbarLeftWrapper>
        </Wrapper>
    );
};

export default NavbarLeft;

const Wrapper = styled.div`
    .item__name {
        color: #303030;
        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }
    .main__nav__item {
        align-items: center;
    }

    .menu__item a {
        color: #707070 !important;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    li.menu__item.active {
        border-radius: 8px;
        background: #fff5ec !important;
        flex: 1;
        width: 100% !important;
    }

    li.menu__item.active a {
        color: #ff9027 !important;
    }

    .dropdown ul {
        padding: 0 10px 0 15%;
    }
`;
