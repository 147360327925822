import { vi } from 'date-fns/locale';
import React, { useContext, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import OrderCommissionContext from '../../affiliate/affiliate-commission/OrderCommissionContext';
import { CARD_COLOR } from '../../based/Constants';
import { Box } from '../../based/content/ct.elm';
import LoadingR from '../../based/LoadingR';
import Button, { ButtonType } from '../../based/refactor/Button';
import CommonTable from '../../based/refactor/CommonTable';
import TextNumber from '../../based/refactor/TextNumber';
import BaseServices from '../../based/services/BaseServices';
const ORDER_BY = {
    CreatedDate: "CreatedDate",
    ModifiedDate: 'ModifiedDate',
    ProductCount: 'ProductCount',
    OrderId: 'OrderId',
    FinallyLoyaltyAmount: 'FinallyLoyaltyAmount'
}
const INIT_PAGING = {
    pageNumber: 1,
    pageSize: 50,
    orderBy: ORDER_BY.ModifiedDate,
    orderDirection: 'DESC',
    orderStatus: 2,
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    totalLoyaltyAmount: 0,
    totalSoldProducts: 0
}

function CompletedOrders(props) {
    const [data, setData] = useState(null);
    const [paging, setPaging] = useState(INIT_PAGING);
    const [busy, setBusy] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [expandedOrderId, setExpandedOrderId] = useState(0);
    const [orderProducts, setOrderProducts] = useState([]);
    const context = useContext(OrderCommissionContext);

    useEffect(() => {
        _fetchData(paging);
    }, [])

    async function _fetchData(pagingModel) {
        setBusy(true);
        const [err, resData] = await BaseServices.Post('/api/finance/get-orders', pagingModel);
        setBusy(false);
        if (!err && resData) {
            setData(resData.listObjects);
            setPaging(resData.paging);
            context.setOrderCount({
                inCompletedOrderCount: resData.paging.inCompletedOrderCount,
                completedOrderCount: resData.paging.completedOrderCount,
            });
        } else {
            console.log(err)
        }
    }

    function _handleFilter() {
        let pageModel = { ...paging, month: selectedDate.getMonth() + 1, year: selectedDate.getFullYear() }
        _fetchData(pageModel);
    }

    function _sortItems(sortBy) {
        let page = { ...paging };
        if (page.orderBy == sortBy) {
            if (page.orderDirection == 'DESC')
                page.orderDirection = 'ASC'
            else
                page.orderDirection = 'DESC'
        } else {
            page.orderBy = sortBy;
            page.orderDirection = 'DESC';
        }
        setPaging(page);
        _fetchData(page);
    }
    async function _handleExpandOrder(orderId) {
        if (orderId > 0) {
            setBusy(true);
            const [err, resData] = await BaseServices.Get('/api/finance/get-order-products/' + orderId);
            setBusy(false);
            if (!err && resData) {
                setOrderProducts(resData);
            } else {
                console.log(err)
            }
        }
        setExpandedOrderId(orderId);
    }
    function _renderHeader() {
        const icon1 = paging.orderBy == ORDER_BY.CreatedDate ? paging.orderDirection == 'DESC' ? <i className="fa fa-sort-desc fa-sort-thead"></i> : <i className="fa fa-sort-asc fa-sort-thead"></i> : null;
        const icon2 = paging.orderBy == ORDER_BY.ModifiedDate ? paging.orderDirection == 'DESC' ? <i className="fa fa-sort-desc fa-sort-thead"></i> : <i className="fa fa-sort-asc fa-sort-thead"></i> : null;
        const icon3 = paging.orderBy == ORDER_BY.ProductCount ? paging.orderDirection == 'DESC' ? <i className="fa fa-sort-desc fa-sort-thead"></i> : <i className="fa fa-sort-asc fa-sort-thead"></i> : null;
        const icon4 = paging.orderBy == ORDER_BY.FinallyLoyaltyAmount ? paging.orderDirection == 'DESC' ? <i className="fa fa-sort-desc fa-sort-thead"></i> : <i className="fa fa-sort-asc fa-sort-thead"></i> : null;
        const icon5 = paging.orderBy == ORDER_BY.OrderId ? paging.orderDirection == 'DESC' ? <i className="fa fa-sort-desc fa-sort-thead"></i> : <i className="fa fa-sort-asc fa-sort-thead"></i> : null;
        return <tr>
            <th className='cursor-pointer' onClick={() => _sortItems(ORDER_BY.OrderId)}>Mã đơn {icon5}</th>
            <th className='cursor-pointer' onClick={() => _sortItems(ORDER_BY.CreatedDate)}>Thời gian đặt hàng {icon1}</th>
            <th className='cursor-pointer' onClick={() => _sortItems(ORDER_BY.ModifiedDate)}>Thời gian hoàn thành {icon2}</th>
            <th>Trạng thái đơn</th>
            <th className='cursor-pointer' onClick={() => _sortItems(ORDER_BY.ProductCount)}>Số sản phẩm trong đơn {icon3}</th>
            <th className='cursor-pointer' onClick={() => _sortItems(ORDER_BY.FinallyLoyaltyAmount)}>Tiền bản quyền nhận được (trước thuế) {icon4}</th>
        </tr>
    }
    function _renderBody() {
        return <React.Fragment>
            {data && data.length > 0 ? data.map((item, i) => {
                return <React.Fragment key={i}>
                    <tr>
                        <td className='text-center'>
                            {
                                expandedOrderId == item.orderId ? <i className='fa fa-minus cursor-pointer text-custom float-left' onClick={() => _handleExpandOrder(0)}></i>
                                    :
                                    <i className='fa fa-plus cursor-pointer text-custom float-left' onClick={() => _handleExpandOrder(item.orderId)}></i>
                            }
                            *****{item.orderId}
                        </td>
                        <td className='text-center'>
                            {item.createdDateText}
                        </td>
                        <td className='text-center'>
                            {item.modifiedDateText}
                        </td>
                        <td className='text-center'>
                            {
                                item.orderStatus == 2 ? 'Hoàn thành' : 'Chưa hoàn thành'
                            }
                        </td>
                        <td>
                            {item.productCount}
                        </td>
                        <td>
                            <TextNumber value={item.finallyLoyaltyAmount} text price />
                        </td>
                    </tr>
                    {
                        expandedOrderId == item.orderId && <tr>
                            <td></td>
                            <td colSpan={5}>
                                {_renderOrderProducts()}
                            </td>
                        </tr>
                    }
                </React.Fragment>
            })
                :
                <tr>
                    <td colSpan={6}>Không có dữ liệu</td>
                </tr>
            }
        </React.Fragment>
    }

    function _renderOrderProducts() {
        if (orderProducts && orderProducts.length > 0) {
            return <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th>Hình Ảnh SP</th>
                        <th>Tên SP</th>
                        <th>Số lượng</th>
                        <th>Tiền bản quyền trước thuế</th>
                        <th>Tổng tiền bản quyền trước thuế</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        orderProducts.map((product, idx) => {
                            return <tr key={idx}>
                                <td>
                                    <img src={product.productAvatar} />
                                </td>
                                <td>{product.productName}</td>
                                <td>{product.quantity}</td>
                                <td>
                                    <TextNumber value={product.creatorLoyaltyEachProduct} text price />
                                </td>
                                <td>
                                    <TextNumber value={product.finallyLoyaltyAmount} text price />
                                </td>
                            </tr>
                        })
                    }
                    <tr></tr>
                </tbody>
            </table>
        }
        return null;
    }
    function _renderAction() {
        let commission = paging.totalLoyaltyAmount ? Number(paging.totalLoyaltyAmount) : 0;
        let tax = commission >= 2000000 ? commission / 10 : 0;
        let remaining = commission - tax;
        return <div className='box__header d-flex justify-content-between' style={{ alignItems: 'baseline' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Tổng số SP trong tháng: <strong>{paging.totalSoldProducts}</strong></label>
                <label>Tổng tiền bản quyền nhận được: <strong><TextNumber value={commission} displayType='text' price /></strong></label>
                <label>Thuế TNCN: <strong><TextNumber value={tax} displayType='text' price /></strong></label>
                <label>Tổng tiền thực lĩnh: <strong><TextNumber value={remaining} displayType='text' price /></strong></label>
            </div>
            <div className='form-group form-inline'>
                <ReactDatePicker dateFormat='MM/yyyy' name='filterDate' selected={selectedDate} className='form-control'
                    locale={vi}
                    showMonthYearPicker
                    onChange={(value) => setSelectedDate(value)} />
                <Button btnType={ButtonType.Rounded} className='ml-4' title='Lọc dữ liệu' color={CARD_COLOR.Green} onClick={_handleFilter} />
            </div>
        </div>
    }

    return (
        <Box>
            <LoadingR fullScreen isShow={busy} />
            <CommonTable
                paging={paging}
                renderHeader={_renderHeader}
                renderBody={_renderBody}
                onFilter={(paging) => _fetchData(paging)}
                renderAction={_renderAction}
            />
        </Box>
    );
}

export default CompletedOrders;