import BaseServices from "./BaseServices";

const AccountServices = {
    GetListUser: async (paging) => {
        return await BaseServices.Post("/api/Accounts/get-list-user", paging);
    },
    GetAgencyUserByUserId: async () => {
        return await BaseServices.Get(`/api/Accounts/get-agency-user-by-user-id`);
    },
    UpdateAgencyUser: async (model) => {
        return await BaseServices.Post(`/api/Accounts/update-agency-user`, model);
    },
    ConfirmEmail: async (url) => {
        return await BaseServices.Post(`/api/accounts/confirm-email` + url, null);
    },
    GetRolesByUser: async () => {
        return await BaseServices.Get("/api/accounts/get-roles");
    },
    UpdateIdentificationImage: async (name, value) => {
        return await BaseServices.Post(`/api/accounts/update-identification-image?name=${name}&value=${value}`, null);
    },
    OAuthDialogSocial: async (social) => {
        return await BaseServices.Get(`/api/accounts/oauth-dialog-social?social=${social}`);
    },
    LoginSocial: async (param, social) => {
        return await BaseServices.Get(`/api/accounts/login-social` + param + "&social=" + social);
    },
    UpdateCreatorPersonalInfo: async (model) => {
        return await BaseServices.Post(`/api/accounts/update-creator-personal-info`, model);
    },
    GetPersonalCreatorInfo: async () => {
        return await BaseServices.Get(`/api/accounts/get-personal-creator-info`);
    },
    UpdatePersonalInfo: async (model) => {
        return await BaseServices.Post(`/api/accounts/update-personal-info`, model);
    },
    UpdateBrandConnect: async (model) => {
        return await BaseServices.Post(`/api/accounts/update-brand-connect`, model);
    },
    UpdateBankAccounts: async (model) => {
        return await BaseServices.Post(`/api/accounts/update-bank-accounts`, model);
    },
};

export default AccountServices;
