import axios from "axios";
import React from "react";
import cookie from "react-cookies";
import styled from "styled-components";
import Common from "../based/Common";
import FormValidate from "../based/FormValidate";
import Request from "../based/Request";
import Textbox from "../based/inputs/Textbox";
import { ApplicationContext } from "../context/ApplicationContext";
import BackgroundLogin from "./assets/BackgroundLogin.png";
import RanusCreatorLogo from "./assets/RanusCreatorLogo.png";

class Login extends FormValidate {
    constructor(props) {
        super(props);
        this.state = {
            isBusy: true,
            data: {
                errorMsg: "",
                username: "",
                password: "",
                isRemember: false,
            },
            errorMsg: "",
            username: "",
            password: "",
            isRemember: false,
            showPassword: false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }
    componentDidMount() {
        this.setState({
            isBusy: false,
        });
    }
    handleInputChange(key, value) {
        let obj = this.state.data;
        obj.errorMsg = "";
        obj[key] = value;
        this.setState(obj);
    }

    handleLogin(e) {
        e.preventDefault();
        if (this.isFormValid()) {
            this.setState({ isBusy: true });
            let redirect = this.props.location.search;
            Request.Post("/api/accounts/login" + redirect, { ...this.state.data, rUUID: Common.GetRUUID() })
                .then((res) => {
                    // Save token to cookie
                    let today = new Date();
                    let expireDay = new Date();
                    expireDay.setDate(today.getDate() + 365);
                    cookie.save("token", res.data.token, { path: "/", expires: expireDay });
                    this.getMenus(res.data.token);
                    window.location.href = res.data.redirect;
                })
                .catch((err) => {
                    this.setState({ isBusy: false });
                    var oldNotify = document.querySelectorAll(".ui-pnotify-closer");
                    for (var i = 0; i < oldNotify.length; i++) oldNotify[i].click();
                    this.displayError(err);
                });
        }
    }

    getMenus(token) {
        const instance = axios.create({
            baseURL: Request.API_URL,
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        instance.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8";
        instance
            .get("/api/menu/get-menus")
            .then((res) => {
                let data = res.data;
                if (data.success) {
                    let { applicationInfo, setApplicationInfo } = this.context;
                    applicationInfo.menus = data.data;
                    setApplicationInfo(applicationInfo);
                }
            })
            .catch((err) => {});
    }

    render() {
        const isDesktop = Common.isDesktop();
        return (
            <Wrapper>
                <section className="creator-background-account">
                    <div className="account-content-body">
                        <div className="content-body">
                            <div className="body-left">
                                <img className="background-body" atl="lotus" src={BackgroundLogin} />
                                <div className="header-creator cursor-pointer">
                                    <img
                                        alt="ranus"
                                        src={RanusCreatorLogo}
                                        onClick={() => {
                                            window.location.href = "/";
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="body-right">
                                <div className={isDesktop ? "main-account" : "main-account-mobile"}>
                                    <h4 className="login-header">Đăng nhập</h4>
                                    <div className="form-group-login">
                                        <div className="form-area">
                                            <Textbox
                                                placeholder="Tên đăng nhập"
                                                ref={(ins) => this.refSets.set("username", ins)}
                                                id="username"
                                                name="username"
                                                value={this.state.username}
                                                required
                                                email
                                                className="login-input"
                                                onChanged={this.handleInputChange}
                                                handleEnter={this.handleLogin}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group-login">
                                        <div className="form-area">
                                            <Textbox
                                                placeholder="Mật khẩu"
                                                id="password"
                                                name="password"
                                                value={this.state.password}
                                                required
                                                ref={(ins) => this.refSets.set("password", ins)}
                                                password={!this.state.showPassword}
                                                className="login-input"
                                                onChanged={this.handleInputChange}
                                                handleEnter={this.handleLogin}
                                            />
                                            <div
                                                className="icon"
                                                onClick={(e) => {
                                                    this.setState({ showPassword: !this.state.showPassword });
                                                }}
                                            >
                                                {this.state.showPassword ? <EyeOn /> : <EyeOff />}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="login-section">
                                        <button className="btn-ranus" onClick={this.handleLogin}>
                                            Đăng nhập
                                        </button>
                                        <button
                                            className="btn-forgot"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.history.push("/forgot-password");
                                            }}
                                        >
                                            Quên mật khẩu?
                                        </button>
                                    </div>

                                    <span className="register-section">
                                        Bạn chưa có tài khoản
                                        <button
                                            className="btn-ranus-register"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.history.push("/signup");
                                            }}
                                        >
                                            Đăng ký ngay
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Wrapper>
        );
    }
}
Login.contextType = ApplicationContext;
export default Login;

const Wrapper = styled.div`
    .content-body {
        display: flex;
    }

    .body-left {
        width: 50%;
        height: 100vh;
        position: relative;
        background: #fff5ec;
    }

    img.background-body {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .header-creator.cursor-pointer {
        position: absolute;
        z-index: 1;
        top: 61px;
        left: 55px;
    }

    .body-right {
        flex: 1;
        background: white;
        position: relative;
    }

    .main-account {
        width: 450px;
        display: flex;
        flex-direction: column;
        height: auto;
        position: absolute;
        top: calc(40% - 225px);
        margin-left: 80px;
    }

    h4.login-header {
        color: #303030;
        font-family: Lexend;
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
    }

    .form-group {
        margin-bottom: 24px;
        position: relative;
    }

    .form-group input {
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
        color: #303030 !important;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        padding: 11px 16px;
        height: auto;
        position: relative;
    }

    .login-input {
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
        width: 100%;
        padding: 11px 16px;
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .form-group-login {
        margin: 12px 0;
    }

    .icon {
        display: flex;
        position: absolute;
        top: 11px;
        right: 16px;
        width: 24px;
        height: 24px;
        align-items: center;
    }

    .form-area {
        position: relative;
    }

    button.btn-ranus {
        border-radius: 12px;
        background: var(--Cam-Thng-hiu, #ff9027);
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        border: none;
        padding: 12px;
        cursor: pointer;
    }

    .login-section {
        margin: 12px 0;
    }

    button.btn-forgot {
        border: none;
        background: none;
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 12px;
    }

    span.register-section {
        text-align: center;
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 12px 0;
    }

    button.btn-ranus-register {
        border: none;
        background: none;
        color: var(--Cam-Thng-hiu, #ff9027);
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 8px;
    }
`;

const EyeOn = () => {
    return (
        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 9C1 9 5 1 12 1C19 1 23 9 23 9C23 9 19 17 12 17C5 17 1 9 1 9Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const EyeOff = () => {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.6 15.4C7.15401 16.5846 9.04627 17.2408 11 17.2728C17.3636 17.2728 21 10 21 10C19.8692 7.89268 18.3008 6.05151 16.4 4.60005M12.9091 2.9455C12.2833 2.79903 11.6427 2.72581 11 2.72732C4.63636 2.72732 0.999999 10 0.999999 10C1.55184 11.0324 2.20995 12.0043 2.96364 12.9M9.07273 11.9273C9.32241 12.1953 9.6235 12.4102 9.95804 12.5592C10.2926 12.7083 10.6537 12.7885 11.0199 12.7949C11.3861 12.8014 11.7499 12.734 12.0894 12.5969C12.429 12.4597 12.7375 12.2555 12.9965 11.9965C13.2555 11.7376 13.4596 11.4291 13.5968 11.0895C13.734 10.7499 13.8013 10.3862 13.7949 10.02C13.7884 9.65377 13.7083 9.29263 13.5592 8.95809C13.4101 8.62355 13.1952 8.32245 12.9273 8.07277"
                stroke="#303030"
                stroke-width="1.8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M19.1816 1.81836L2.818 18.182" stroke="#303030" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
