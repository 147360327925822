import React, { Component } from "react";
import Request from "../../based/Request";
import { Editor } from "@tinymce/tinymce-react";
import { CONSTANTS } from "../Constants";
import Common from "../Common";

class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.state = {
            isValid: true,
            inValidMsg: "",
        };
    }
    handleEditorChange(content) {
        if (this.props.onChanged) {
            this.props.onChanged(this.props.name, content);
        }
    }
    getContent() {
        return this.props.html;
    }
    onlySpaces(str) {
        if (str && typeof str === "string") {
            let convertValue = Common.stripHtml(str);
            return convertValue.trim().length === 0;
        }
        return false;
    }
    validateInput(value) {
        if ((!this.props.html || this.onlySpaces(this.props.html)) && this.props.required) return CONSTANTS.MSG_REQUIRED;
        this.setState({
            isValid: true,
            msgInvalid: "",
        });
        return "";
    }
    displayError(msg) {
        this.setState({ isValid: false, inValidMsg: msg });
    }
    render() {
        return (
            <React.Fragment>
                <Editor
                    value={this.props.html}
                    onEditorChange={this.handleEditorChange}
                    init={{
                        language: "vi",
                        plugins: "link image code media",
                        toolbar: "undo redo | bold italic | alignleft aligncenter alignright | code",
                        images_upload_handler: function (blobInfo, success, failure) {
                            Request.UploadImage(blobInfo.blob())
                                .then((res) => {
                                    success(res.data.imageUrl);
                                })
                                .catch((err) => failure(err));
                        },
                        height: "480px",
                        branding: false,
                    }}
                    disabled={this.props.readOnly}
                />
                {!this.state.isValid && <label className="text-danger full-width">{this.state.inValidMsg}</label>}
            </React.Fragment>
        );
    }
}

export default TextEditor;
