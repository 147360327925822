import React, { useEffect, useState } from "react";
import Common from "../../based/Common";
import CommonTable from "../../based/CommonTable";
import { CONSTANTS, NOTIFY } from "../../based/Constants";
import { ConfirmDialog } from "../../based/Dialog";
import CheckBox from "../../based/inputs/CheckBox";
import LoadingR from "../../based/LoadingR";
import { Notify } from "../../based/Notify";
import AffiliateServices from "../../based/services/AffiliateServices";
import Breadcum from "../../layout/refactor/Breadcum/Breadcum";
import PluginTutorial from "./PluginTutorial";
const INIT_PAGING = {
    pageNumber: 1,
    pageSize: 10,
};
const menu = ["Trang chủ", "Quản lý Affiliate Plugin"];
function AffiliateSiteManagement(props) {
    const [busy, setBusy] = useState(false);
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(INIT_PAGING);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isShow, setIsShow] = useState(false);
    const [id, setId] = useState(0);

    useEffect(() => {
        _fetchData(paging);
    }, []);

    async function _fetchData(pagingModel) {
        setBusy(true);
        const [err, data] = await AffiliateServices.GetListAffiliateSite(pagingModel);
        if (!err && data && data.listObjects) {
            setData(data.listObjects);
            setPaging(data.paging);
        } else {
            setData([]);
            setPaging(Common.Paging);
        }
        setBusy(false);
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th></th>
                    <th className='text-center'>Mã Affiliate Code</th>
                    <th className='text-center'>Trang web</th>
                    <th className='text-center'>Ngày tạo</th>
                    <th className='text-center'>Layout</th>
                    <th className='text-center'>Từ khoá</th>
                    <th className='text-center'>Ghi chú</th>
                    <th className='text-center'>Copy Code</th>
                </tr>
            </thead>
        );
    }

    function _handleSelectItem(item, checked) {
        if (checked) setSelectedItem(item);
        else setSelectedItem(null);
    }

    const renderCodePaste = (layout, keyword, afcode) => {
        return `<!-- Affiliate Plugin Code -->
        <div id="ranus_affiliate_container" class="ranus_affiliate_container" data-layout="${layout}" data-keyword="${keyword}" data-afcode="${afcode}"></div>
        <!-- Your Plugin Code -->
        <script src="https://cdn.ranus.vn/cdn-ranus-affiliate/js/bundle.737652dc139a8c37728b.js"></script>
        `;
    };

    function _renderBody() {
        return (
            <tbody>
                {data && data.length > 0 ? (
                    data.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className='text-center' width={50}>
                                    <CheckBox name='voucher' checked={selectedItem && item.voucherCode == selectedItem.voucherCode} onCheckedChange={(name, value) => _handleSelectItem(item, value)} />
                                </td>
                                <td className='text-center'>{item.hashCode}</td>
                                <td className='text-center'>{item.siteUrl}</td>
                                <td className='text-center'>{Common.formatDate(new Date(item.createdDate))}</td>
                                <td className='text-center'>{item.layoutName}</td>
                                <td className='text-center'>{item.keyword ? item.keyword.join(",") : ""}</td>
                                <td className='text-center'>{item.note}</td>
                                <td className='text-center'>
                                    <span
                                        className='text-primary cursor-pointer'
                                        onClick={() => {
                                            navigator.clipboard.writeText(renderCodePaste(item.layout, item.keyword ? item.keyword.join(",") : "", item.hashCode));
                                            Notify(NOTIFY.SUCCESS, "Thành công", "Đã sao chép vào bộ nhớ tạm");
                                        }}
                                    >
                                        Copy Code
                                    </span>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={10}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    function _renderAction() {
        return (
            <div className='flex-row m-b-10'>
                <button className={selectedItem ? "btn btn-danger  btn-sm m-r-5" : "btn btn-danger  btn-sm m-r-5 disable"} onClick={_handleDelete}>
                    <i className='fa fa-remove m-r-5' />
                    Xóa
                </button>
                <button className={"btn btn-primary"} onClick={_handleAdd}>
                    Thêm mới
                </button>
            </div>
        );
    }

    function _handleDelete() {
        ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
            return new Promise(async (resolve, reject) => {
                setBusy(true);
                let [err, data] = await AffiliateServices.DeleteAffiliateSite(selectedItem.id);
                if (!err && data) {
                    _fetchData(paging);
                    setSelectedItem(null);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                } else {
                    Notify(NOTIFY.WARNING, "Có lỗi", CONSTANTS.MSG_ERROR);
                }
                setBusy(false);
            });
        });
    }

    function _handleAdd() {
        setIsShow(true);
        setId(0);
    }
    return (
        <React.Fragment>
            <Breadcum menu={menu} title='Quản lý Affiliate Plugin' />
            <LoadingR fullScreen isShow={busy} />
            <div style={{ width: "99%" }}>
                <CommonTable paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={(paging) => _fetchData(paging)} renderAction={_renderAction} />
            </div>
            {
                isShow && <PluginTutorial
                isShow={isShow}
                id={id}
                onClose={() => {
                    setIsShow(false);
                }}
                onSave={()=>{
                    setIsShow(false)
                    _fetchData({ ...paging });
                }}
            />
            }
            
        </React.Fragment>
    );
}

export default AffiliateSiteManagement;
