import React, { useEffect, useState } from "react";
import TagsInput from "react-tagsinput";
import styled from "styled-components";
import { NOTIFY } from "../../based/Constants";
import Radio from "../../based/inputs/Radio";
import TextArea from "../../based/inputs/TextArea";
import Textbox from "../../based/inputs/Textbox";
import { Notify } from "../../based/Notify";
import AffiliateServices from "../../based/services/AffiliateServices";
import { AffiliateSiteModel } from "../../models/AffiliateSiteModel";
const STEP = {
    SET_URL: 1,
    COPY_CODE: 2,
    PASTE_CODE: 3,
    DONE: 4,
};

const PluginTutorial = (props) => {
    const [step, setStep] = useState(1);
    const [copy, setCopy] = useState(false);
    const [model, setModel] = useState(new AffiliateSiteModel());
    const { isShow, onClose, id, onSave } = props;

    useEffect(() => {
        if (id > 0) getAffiliateSiteModel(id);
        else setModel(new AffiliateSiteModel());
        return () => {
            setModel(new AffiliateSiteModel());
        };
    }, []);

    const getAffiliateSiteModel = async (id) => {
        let [err, data] = await AffiliateServices.GetAffiliateSite(id);
        if (!err && data) {
            setModel(data);
        } else {
            setModel(new AffiliateSiteModel());
        }
    };

    const handleSaveSiteModel = async (dto) => {
        let [err, data] = await AffiliateServices.CreateUpdateAffiliateSite(dto);
        if (!err && data) {
            setModel({ ...model, id: data.id, hashCode: data.hashCode });
            setStep(STEP.COPY_CODE);
        } else {
        }
    };

    const copyClipboard = () => {
        navigator.clipboard.writeText(renderCodePaste(model.layout, model.keyword ? model.keyword.join(",") : "", model.hashCode));
        setCopy(true);
        Notify(NOTIFY.SUCCESS, "Thành công", "Đã sao chép vào bộ nhớ tạm");
    };

    const renderCodePaste = (layout, keyword, afcode) => {
        return `<!-- Affiliate Plugin Code -->
        <div id="ranus_affiliate_container" class="ranus_affiliate_container" data-layout="${layout}" data-keyword="${keyword}" data-afcode="${afcode}"></div>
        <!-- Your Plugin Code -->
        <script src="https://cdn.ranus.vn/cdn-ranus-affiliate/js/bundle.737652dc139a8c37728b.js"></script>
        `;
    };

    function validURL(str) {
        var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator
        return !!pattern.test(str);
    }

    const Valid = (step) => {
        switch (step) {
            case 1:
                if (validURL(model.siteUrl)) {
                    return true;
                } else return false;
            case 2:
                if (copy) {
                    return true;
                } else return false;
            case 3:
                if (copy) {
                    return true;
                } else return false;
        }
    };

    const handleSetStep = (step) => {
        switch (step) {
            case 1:
                setStep(STEP.SET_URL);
                break;
            case 2:
                if (validURL(model.siteUrl) && model.layout > 0 && model.keyword.length > 0) {
                    handleSaveSiteModel(model);
                }
                break;
            case 3:
                if (copy) setStep(STEP.PASTE_CODE);
                break;
        }
    };

    const _renderStep = (step) => {
        switch (step) {
            case 1:
                return (
                    <StepTwo>
                        <i onClick={() => onClose()} className='fa fa-times ' aria-hidden='true'></i>
                        <h4>Bước 1: Thêm miền, thông tin khung</h4>
                        <p>Thêm miền, thông tin khung vào mọi trang web mà bạn muốn hiển thị Plugin Affiliate.</p>
                        <div className='form-group'>
                            <label className='col-form-label required'>Tên miền</label>
                            <Textbox placeholder='https://example.com' className='text' value={model.siteUrl} onChanged={(name, value) => setModel({ ...model, siteUrl: value })} required />
                        </div>
                        <div className='form-group'>
                            <label className='col-form-label required'>Layout</label>
                            <div className='form-row col-md-12'>
                                <Radio name='layout' isInline checked={model.layout == 1} value={model.layout} onCheckedChange={(name, value) => setModel({ ...model, layout: 1 })} label='Mobile Bottom' />
                                <Radio name='layout' isInline checked={model.layout == 2} value={model.layout} onCheckedChange={(name, value) => setModel({ ...model, layout: 2 })} label='Desktop Side Right' />
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='col-form-label required'>Keyword</label>
                            <TagsInput
                                addKeys={[13]}
                                addOnPaste
                                maxTags={10}
                                inputProps={{ placeholder: "" }}
                                value={model.keyword || []}
                                onChange={(tags) => {
                                    let newTags = tags.reduce((prev, val, idx) => (prev.indexOf(val) == -1 ? [...prev, val] : [...prev]), []);
                                    setModel({ ...model, keyword: newTags });
                                }}
                            />
                        </div>
                        <div className='step-footer'>
                            <span className={validURL(model.siteUrl) && model.layout > 0 && model.keyword.length > 0 ? "next" : "next unactive"} onClick={() => handleSetStep(STEP.COPY_CODE)}>
                                {"Lưu & tiếp tục"}
                            </span>
                        </div>
                    </StepTwo>
                );
            case 2:
                return (
                    <StepThree>
                        <i onClick={() => onClose()} className='fa fa-times ' aria-hidden='true'></i>
                        <h4>Bước 2: Copy code</h4>
                        <p>Sao chép và dán đoạn mã vào trang web của bạn</p>
                        <div className='title'>
                            <div className='icon'></div>
                            <span>
                                Bạn gặp sự cố khi thêm mã vào trang web? Hãy xem <a>hướng dẫn chi tiết</a> này để biết cách thêm Plugin Affiliate vào công cụ tạo trang web như WordPress, Shopify, Wix, Squarespace và các công cụ khác.
                            </span>
                        </div>
                        <TextArea height={"100px"} className='text' value={renderCodePaste(model.layout, model.keyword ? model.keyword.join(",") : "", model.hashCode)} readOnly />
                        <span className='copy' onClick={copyClipboard}>
                            Sao chép mã
                        </span>
                        <div className='step-footer'>
                            <p>Email hướng dẫn</p>
                            <div>
                                <span className='back' onClick={() => setStep(STEP.SET_URL)}>
                                    Quay lại
                                </span>
                                <span
                                    className={copy ? "next" : "next unactive"}
                                    onClick={() => {
                                        console.log("1 :>> ");
                                        handleSetStep(STEP.PASTE_CODE);
                                    }}
                                >
                                    Tiếp tục
                                </span>
                            </div>
                        </div>
                    </StepThree>
                );
            case 3:
                return (
                    <StepFour>
                        <i onClick={() => onClose()} className='fa fa-times ' aria-hidden='true'></i>
                        <h4>Bước 3: Dán mã vào trang web</h4>
                        <p>{"Hãy dán trực tiếp đoạn mã sau trước thẻ <body> vị trí mà bạn muốn hiển thị Plugin Affiliate."}</p>
                        <div className='title'>
                            <div className='icon'></div>
                            <span>
                                Bạn gặp sự cố khi thêm mã vào trang web? Hãy xem <a>hướng dẫn chi tiết</a> này để biết cách thêm Plugin Affiliate vào công cụ tạo trang web như WordPress, Shopify, Wix, Squarespace và các công cụ khác.
                            </span>
                        </div>
                        <div className='canvas-container'>
                            <div className='canvas-section'>
                                <canvas></canvas>
                            </div>
                        </div>
                        <div className='step-footer'>
                            <p>Email hướng dẫn</p>
                            <div>
                                <span className='back' onClick={() => setStep(STEP.COPY_CODE)}>
                                    Quay lại
                                </span>
                                <span
                                    className='next'
                                    onClick={() => {
                                        setStep(STEP.DONE);
                                    }}
                                >
                                    Hoàn tất
                                </span>
                            </div>
                        </div>
                    </StepFour>
                );
            case 4:
                return (
                    <StepFive>
                        <i onClick={() => onClose()} className='fa fa-times ' aria-hidden='true'></i>
                        <h4>Plugin Affiliate của bạn đã sẳn sàng!</h4>
                        <p>Bạn có thể vào phần Plugin Affiliate trong Menu Quản lý Affiliate Plugin để thay đổi Plugin Affiliate bất cứ lúc nào.</p>
                        <div className='step-footer'>
                            <span className='back' onClick={() => setStep(STEP.PASTE_CODE)}>
                                Quay lại
                            </span>
                            <span className='next' onClick={() => onSave()}>
                                Xong
                            </span>
                        </div>
                    </StepFive>
                );
        }
    };
    return (
        <Wrapper isShow={isShow}>
            <div className='dialog-container'>
                <div className='container-left'>
                    <h4>Thiết lặp plugin Affiliate</h4>
                    <div className='container-left-section'>
                        <div className={step == STEP.SET_URL ? "container-left-item active" : "container-left-item"}>
                            <i className={Valid(STEP.SET_URL) ? "done" : step == STEP.SET_URL ? "select" : ""} />
                            <h4 className={step == STEP.SET_URL ? "active" : ""}>Bước 1: Thêm miền</h4>
                        </div>
                        <div className={step == STEP.COPY_CODE ? "container-left-item active" : "container-left-item"}>
                            <i className={Valid(STEP.COPY_CODE) ? "done" : step == STEP.COPY_CODE ? "select" : ""} />
                            <h4 className={step == STEP.COPY_CODE ? "active" : ""}>Bước 2: Copy code</h4>
                        </div>
                        <div className={step == STEP.PASTE_CODE ? "container-left-item active" : "container-left-item"}>
                            <i className={Valid(STEP.PASTE_CODE) ? "done" : step == STEP.PASTE_CODE ? "select" : ""} />
                            <h4 className={step == STEP.PASTE_CODE ? "active" : ""}>Bước 3: Dán mã</h4>
                        </div>
                    </div>
                </div>
                <div className='container-right'>{_renderStep(step)}</div>
            </div>
        </Wrapper>
    );
};

export default PluginTutorial;
const Wrapper = styled.div`
    width: 100vw;
    min-height: 100vh;
    top: 0;
    right: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.2);
    display: ${({ isShow }) => (isShow ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    z-index: 1001;
    .dialog-container {
        width: 740px;
        height: 460px;
        background: white;
        display: flex;
        border-radius: 15px;
        .container-left {
            background: rgba(0, 0, 0, 0.1);
            width: 200px;
            height: 100%;
            h4 {
                font-size: 16px;
                color: black;
                margin-top: 16px;
                margin-left: 16px;
                font-weight: 500;
            }
            .container-left-section {
                margin-top: 16px;
                .container-left-item {
                    display: flex;
                    margin-left: 10px;
                    margin-right: 10px;
                    height: 40px;
                    align-items: center;
                    h4 {
                        font-size: 14px;
                        margin: 0;
                        color: rgba(0, 0, 0, 0.85);
                        margin-left: 10px;
                    }
                    .active {
                        color: rgba(20, 97, 204, 1);
                        background: none;
                    }
                    i {
                        margin-left: 6px;
                        background-image: url("https://static.xx.fbcdn.net/rsrc.php/v3/y3/r/TtHMgDSfRE-.png");
                        background-position: 0 -205px;
                        height: 20px;
                        width: 20px;
                    }
                    .select {
                        background-position: 0 -142px;
                    }
                    .done {
                        background-position: 0 -121px;
                    }
                }
                .active {
                    border-radius: 5px;
                    background: rgba(24, 119, 242, 0.1);
                }
            }
        }
        .container-right {
            border-radius: 15px;
            width: 540px;
            height: 100%;
        }
    }
`;
const StepOne = styled.div`
    width: 100%;
    height: 100%;
    padding: 16px;
    position: relative;
    i {
        position: absolute;
        right: 16px;
        top: 16px;
        color: black;
    }
    h4 {
        font-weight: 500;
        font-size: 16px;
        color: black;
        margin: 0;
    }
    p {
        padding: 0;
        color: rgba(0, 0, 0, 0.75);
        font-size: 14px;
    }
    .select {
        width: 100%;
        margin-top: 20px;
    }
    .step-footer {
        position: absolute;
        justify-content: end;
        padding: 0 16px;
        right: 0;
        bottom: 16px;
        border: none;
        .back {
            padding: 8px 16px;
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 6px;
            margin-right: 5px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            cursor: pointer;
        }
        .next {
            padding: 8px 16px;
            background-color: rgba(24, 119, 242, 1);
            color: rgba(255, 255, 255, 1);
            border-radius: 6px;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
        }
        .unactive {
            background-color: rgba(24, 119, 242, 0.5);
            color: rgba(255, 255, 255, 0.6);
        }
    }
`;
const StepTwo = styled.div`
    width: 100%;
    height: 100%;
    padding: 16px;
    position: relative;
    i {
        position: absolute;
        right: 16px;
        top: 16px;
        color: black;
    }
    h4 {
        font-weight: 500;
        font-size: 16px;
        color: black;
        margin: 0;
    }
    p {
        padding: 0;
        color: rgba(0, 0, 0, 0.75);
        font-size: 14px;
    }
    .text {
        width: 100%;
        margin-top: 10px;
    }
    .step-footer {
        position: absolute;
        justify-content: end;
        padding: 0 16px;
        right: 0;
        bottom: 16px;
        border: none;
        .back {
            padding: 8px 16px;
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 6px;
            margin-right: 5px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            cursor: pointer;
        }
        .next {
            padding: 8px 16px;
            background-color: rgba(24, 119, 242, 1);
            color: rgba(255, 255, 255, 1);
            border-radius: 6px;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
        }
        .unactive {
            background-color: rgba(24, 119, 242, 0.5);
            color: rgba(255, 255, 255, 0.6);
        }
    }
`;
const StepThree = styled.div`
    width: 100%;
    height: 100%;
    padding: 16px;
    position: relative;
    i {
        position: absolute;
        right: 16px;
        top: 16px;
        color: black;
    }
    h4 {
        color: rgba(0, 0, 0, 0.75);
        font-size: 16px;
        margin: 0;
    }
    p {
        color: rgba(0, 0, 0, 0.75);
        font-size: 14px;
    }
    .title {
        border-radius: 6px;
        padding: 12px;
        align-self: stretch;
        background-color: rgba(0, 0, 0, 0.03);
        display: flex;
        .icon {
            width: 45px;
            height: 16px;
            margin-top: 5px;
            -webkit-mask-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y5/r/X2EssJ-60Jt.png);
            -webkit-mask-size: 25px 1480px;
            -webkit-mask-position: 0px -672px;
            background-color: currentColor;
            color: rgba(0, 0, 0, 0.75);
            font-size: 12px;
        }
        span {
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            margin-left: 5px;
            font-weight: 400;
            a {
                color: #385898;
                cursor: pointer;
                text-decoration: none;
            }
        }
    }
    .text {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 100px;
    }
    .copy {
        padding: 8px 16px;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        cursor: pointer;
    }
    .step-footer {
        position: absolute;
        justify-content: space-between;
        display: flex;
        padding: 0 16px;
        right: 0;
        bottom: 16px;
        width: 100%;
        border: none;
        p {
            color: rgba(20, 97, 204, 1);
            font-size: 14px;
        }
        .back {
            padding: 8px 16px;
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 6px;
            margin-right: 5px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            cursor: pointer;
        }
        .next {
            padding: 8px 16px;
            background-color: rgba(24, 119, 242, 1);
            color: rgba(255, 255, 255, 1);
            border-radius: 6px;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
        }
        .unactive {
            background-color: rgba(24, 119, 242, 0.5);
            color: rgba(255, 255, 255, 0.6);
        }
    }
`;
const StepFour = styled.div`
    width: 100%;
    height: 100%;
    padding: 16px;
    position: relative;
    i {
        position: absolute;
        right: 16px;
        top: 16px;
        color: black;
    }
    h4 {
        color: rgba(0, 0, 0, 0.75);
        font-size: 16px;
        margin: 0;
    }
    p {
        color: rgba(0, 0, 0, 0.75);
        font-size: 14px;
    }
    .title {
        border-radius: 6px;
        padding: 12px;
        align-self: stretch;
        background-color: rgba(0, 0, 0, 0.03);
        display: flex;
        .icon {
            width: 45px;
            height: 16px;
            margin-top: 5px;
            -webkit-mask-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y5/r/X2EssJ-60Jt.png);
            -webkit-mask-size: 25px 1480px;
            -webkit-mask-position: 0px -672px;
            background-color: currentColor;
            color: rgba(0, 0, 0, 0.75);
            font-size: 12px;
        }
        span {
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            margin-left: 5px;
            font-weight: 400;
            a {
                color: #385898;
                cursor: pointer;
                text-decoration: none;
            }
        }
    }
    .canvas-container {
        display: flex;
        min-height: 0;
        justify-content: center;
        margin-top: 8px;
        min-width: 0;
        pointer-events: all;
        .canvas-section {
            display: inline-block;
            line-height: 0;
            font-size: 0px;
            pointer-events: all;
            canvas {
                width: 316px;
                height: 214px;
                aspect-ratio: auto 632 / 428;
                line-height: 0;
                font-size: 0px;
                pointer-events: all;
            }
        }
    }
    .step-footer {
        position: absolute;
        justify-content: space-between;
        display: flex;
        padding: 0 16px;
        right: 0;
        bottom: 16px;
        width: 100%;
        border: none;
        p {
            color: rgba(20, 97, 204, 1);
            font-size: 14px;
        }
        .back {
            padding: 8px 16px;
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 6px;
            margin-right: 5px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            cursor: pointer;
        }
        .next {
            padding: 8px 16px;
            background-color: rgba(24, 119, 242, 1);
            color: rgba(255, 255, 255, 1);
            border-radius: 6px;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
        }
        .unactive {
            background-color: rgba(24, 119, 242, 0.5);
            color: rgba(255, 255, 255, 0.6);
        }
    }
`;
const StepFive = styled.div`
    width: 100%;
    height: 100%;
    padding: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i {
        position: absolute;
        right: 16px;
        top: 16px;
        color: black;
    }
    h4 {
        color: rgba(0, 0, 0, 0.75);
        font-size: 16px;
        margin: 0;
    }
    p {
        color: rgba(0, 0, 0, 0.75);
        font-size: 14px;
        text-align: center;
    }
    .step-footer {
        position: absolute;
        justify-content: end;
        padding: 0 16px;
        right: 0;
        bottom: 16px;
        border: none;
        .back {
            padding: 8px 16px;
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 6px;
            margin-right: 5px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            cursor: pointer;
        }
        .next {
            padding: 8px 16px;
            background-color: rgba(24, 119, 242, 1);
            color: rgba(255, 255, 255, 1);
            border-radius: 6px;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
        }
    }
`;
