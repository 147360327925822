import React from 'react'
import Common from '../../based/Common';
import TableFooter from '../../based/content/TableFooter';
import { StarRating } from '../mrp.elm';
import styled from "styled-components";


const defaultImg = "/images/no-image.png";
const ReviewTable = ({ listItemReviewProduct, handleAnswer, paging, onFilter, itemToDisplay }) => {
    return (
        <Wapper className='table__main__content'>
            <div className='table__head mt-3'>
                <ul className='table'>
                    <li className=''>Nội dung</li>
                    <li className=''>Đánh giá</li>
                    <li className=''>Sản phẩm</li>
                    <li className=''>Phản hồi</li>
                </ul>
            </div>
            {/* table section */}
            <div className='table__body'>
                {listItemReviewProduct.length ? (
                    listItemReviewProduct.map((item, idx) => {
                        return (
                            <div className='table__item mt-2' key={idx}>
                                <div className='table__item__title'>
                                    <div className='title__left'>
                                        <span className='order'>
                                            Đơn hàng:
                                            <p>
                                                {item.orderId} <i className='fa fa-clone' aria-hidden='true'></i>
                                            </p>
                                        </span>
                                    </div>
                                    <div className='title__right'>
                                        <span>{item.fullName}</span>
                                    </div>
                                </div>
                                <div className='main__review'>
                                    <ul className='table'>
                                        <li className='box-comment'>
                                            <div className='review__title'>
                                                <span>Thời gian:</span>
                                                <p>{Common.formatDate(new Date(item.createdDate))}</p>
                                            </div>
                                            <div className='review__title'>
                                                <span>Nội dung:</span>
                                                <p>{item.reviewComment ? item.reviewComment : 'Không để lại đánh giá'}</p>
                                            </div>
                                            <div className='review__title'>
                                                <span>Tag:</span>
                                                <div className='list-tag'>{item.tagComment ? 
                                                    JSON.parse(item.tagComment).map((item , idx)=>{
                                                        return <span>{item}</span>
                                                    })
                                                : 'Không để lại tag'}</div>
                                            </div>
                                            <div className='review__title'>
                                                <span>Hình ảnh:</span>
                                                <p>{item.images && item.images.length > 0  ? "" : 'Không để lại hình ảnh/video'}</p>
                                            </div>
                                            {item.images && item.images.length > 0 && (
                                                <div className='list-image'>
                                                    {item.images.map((item) => {
                                                        return <img
                                                            src={item.url}
                                                            alt='image'
                                                        />
                                                    })}
                                                </div>
                                            )}
                                        </li>
                                        <li className='box-review'>
                                            <div className='rating__item'>
                                                <span>Thiết kế:</span>
                                                <div className="rating__list">
                                                    {[...Array(item.artworkStarRate)].map((_, idx)=>{
                                                        return <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M17.4701 1.73501L20.0114 8.62313L27.4168 8.90111C27.924 8.92045 28.413 9.09354 28.8177 9.39703C29.2224 9.70053 29.5233 10.1198 29.6797 10.5981C29.8362 11.0765 29.8406 11.5909 29.6924 12.0718C29.5442 12.5527 29.2506 12.977 28.8512 13.2872L23.0203 17.8124L25.0471 24.8703C25.1863 25.3535 25.1726 25.8673 25.0078 26.3424C24.8429 26.8176 24.535 27.2312 24.1254 27.5275C23.7158 27.8238 23.2243 27.9885 22.7173 27.9994C22.2102 28.0103 21.712 27.8669 21.2898 27.5885L15.0536 23.4958L8.91098 27.5885C8.4874 27.8633 7.98945 28.0038 7.4833 27.9915C6.97715 27.9792 6.48675 27.8147 6.07729 27.5197C5.66782 27.2247 5.35869 26.8132 5.19093 26.34C5.02317 25.8668 5.00473 25.3542 5.13809 24.8703L7.16486 17.8124L1.34962 13.2872C0.950206 12.977 0.656549 12.5527 0.508368 12.0718C0.360187 11.5909 0.364623 11.0765 0.521048 10.5981C0.677473 10.1198 0.978355 9.70053 1.38305 9.39703C1.78774 9.09354 2.27674 8.92045 2.78394 8.90111L10.1894 8.62313L12.7306 1.73501C12.8863 1.23272 13.2005 0.793136 13.627 0.480838C14.0536 0.16854 14.57 0 15.1004 0C15.6308 0 16.1472 0.16854 16.5738 0.480838C17.0003 0.793136 17.3145 1.23272 17.4701 1.73501Z" fill="#F8993F"/>
                                                        </svg>                                                        
                                                    })}
                                                </div>
                                            </div>
                                            <div className='rating__item'>
                                                <span>Chất lượng in ấn:</span>
                                                <div className="rating__list">
                                                    {[...Array(item.printStarRate)].map((_, idx)=>{
                                                        return <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M17.4701 1.73501L20.0114 8.62313L27.4168 8.90111C27.924 8.92045 28.413 9.09354 28.8177 9.39703C29.2224 9.70053 29.5233 10.1198 29.6797 10.5981C29.8362 11.0765 29.8406 11.5909 29.6924 12.0718C29.5442 12.5527 29.2506 12.977 28.8512 13.2872L23.0203 17.8124L25.0471 24.8703C25.1863 25.3535 25.1726 25.8673 25.0078 26.3424C24.8429 26.8176 24.535 27.2312 24.1254 27.5275C23.7158 27.8238 23.2243 27.9885 22.7173 27.9994C22.2102 28.0103 21.712 27.8669 21.2898 27.5885L15.0536 23.4958L8.91098 27.5885C8.4874 27.8633 7.98945 28.0038 7.4833 27.9915C6.97715 27.9792 6.48675 27.8147 6.07729 27.5197C5.66782 27.2247 5.35869 26.8132 5.19093 26.34C5.02317 25.8668 5.00473 25.3542 5.13809 24.8703L7.16486 17.8124L1.34962 13.2872C0.950206 12.977 0.656549 12.5527 0.508368 12.0718C0.360187 11.5909 0.364623 11.0765 0.521048 10.5981C0.677473 10.1198 0.978355 9.70053 1.38305 9.39703C1.78774 9.09354 2.27674 8.92045 2.78394 8.90111L10.1894 8.62313L12.7306 1.73501C12.8863 1.23272 13.2005 0.793136 13.627 0.480838C14.0536 0.16854 14.57 0 15.1004 0C15.6308 0 16.1472 0.16854 16.5738 0.480838C17.0003 0.793136 17.3145 1.23272 17.4701 1.73501Z" fill="#F8993F"/>
                                                        </svg>                                                        
                                                    })}
                                                </div>
                                            </div>
                                            <div className='rating__item'>
                                                <span>Tổng quan:</span>
                                                <div className="rating__list">
                                                    {[...Array(item.totalStarRate)].map((_, idx)=>{
                                                        return <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M17.4701 1.73501L20.0114 8.62313L27.4168 8.90111C27.924 8.92045 28.413 9.09354 28.8177 9.39703C29.2224 9.70053 29.5233 10.1198 29.6797 10.5981C29.8362 11.0765 29.8406 11.5909 29.6924 12.0718C29.5442 12.5527 29.2506 12.977 28.8512 13.2872L23.0203 17.8124L25.0471 24.8703C25.1863 25.3535 25.1726 25.8673 25.0078 26.3424C24.8429 26.8176 24.535 27.2312 24.1254 27.5275C23.7158 27.8238 23.2243 27.9885 22.7173 27.9994C22.2102 28.0103 21.712 27.8669 21.2898 27.5885L15.0536 23.4958L8.91098 27.5885C8.4874 27.8633 7.98945 28.0038 7.4833 27.9915C6.97715 27.9792 6.48675 27.8147 6.07729 27.5197C5.66782 27.2247 5.35869 26.8132 5.19093 26.34C5.02317 25.8668 5.00473 25.3542 5.13809 24.8703L7.16486 17.8124L1.34962 13.2872C0.950206 12.977 0.656549 12.5527 0.508368 12.0718C0.360187 11.5909 0.364623 11.0765 0.521048 10.5981C0.677473 10.1198 0.978355 9.70053 1.38305 9.39703C1.78774 9.09354 2.27674 8.92045 2.78394 8.90111L10.1894 8.62313L12.7306 1.73501C12.8863 1.23272 13.2005 0.793136 13.627 0.480838C14.0536 0.16854 14.57 0 15.1004 0C15.6308 0 16.1472 0.16854 16.5738 0.480838C17.0003 0.793136 17.3145 1.23272 17.4701 1.73501Z" fill="#F8993F"/>
                                                        </svg>                                                        
                                                    })}
                                                </div>
                                            </div>
                                        </li>
                                        <li className='box-product'>
                                            <div className='product__image'>
                                                <img style={{ width: '100px', height: '100px', borderRadius: 10 }} src={item.productAvatar || defaultImg} alt='product avatar' />
                                            </div>
                                            <div className='product__info'>
                                                <div>{item.productVariantName}</div>
                                                <div>{item.productName}</div>
                                                <div>{item.sizeName}</div>
                                                <div>{item.colorName}</div>
                                            </div>
                                        </li>
                                        <li className=''>
                                            <div
                                                className='reply cursor-pointer'
                                                onClick={() => {
                                                    handleAnswer(item);
                                                }}
                                            >
                                                <i className='fa fa-pencil-square-o' aria-hidden='true'></i>
                                                Trả lời
                                            </div>
                                            <div>{item.reviewCommentReply}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <span className='w-100 d-flex justify-content-center mt-10'>Hiện tại không có dữ liệu</span>
                )}
            </div>
            {paging && <TableFooter itemToDisplay={itemToDisplay || 2} onFilter={(paging) => onFilter(paging)} paging={paging} />}
            {/* <div className='table__footer row col-md-12 mt-3'>{<TableFoot paging={paging} onFilter={(newPaging) => getReviewProducts(newPaging)} />}</div> */}
        </Wapper>

    )
}

export default ReviewTable


const Wapper = styled.div`

    .table__head{
        .table{

            li{
                width: 25%;
            }
        }
    }
    
    .table{
        align-items: stretch;

        li{
            width: 25%;
            height: unset !important;

            &.box-comment{
                text-align: left;

                .review__title{
                    display: flex;

                    span{
                        color: #000;
                        width: 80px;
                        display: block;
                        text-align: left;
                    }

                    p{
                        margin: 0 0 0 10px;
                    }


                    .list-tag{
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0 0 0 10px;


                        span{
                            margin-right: 10px;
                            display: block;
                            padding: 2px 5px;
                            border-radius: 10px;
                            width: auto !important;
                            color: #28a745;
                            white-space: nowrap;
                            border: 2px solid #28a745;
                        }
                    }
                }


                .review__content{
                    padding-left: 10px;
                    text-align: left;
                }

                .list-image{
                    display: flex;
                    flex-wrap: wrap;


                    img{
                        border-radius: 10px;
                        width: 30%;
                        aspect-ratio: 1/1;
                        object-fit: cover;
                        margin-right: 3%;
                        margin-top: 8px;
                    }
                }
            }

            &.box-product{
                display: flex;


                .product__info{
                    padding-left: 12px;
                    text-align: left;
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }


            &.box-review{
                text-align: left;

                .rating__item{
                    display: flex;
                    margin-bottom: 8px;

                    span{
                        display: block;
                        width: 150px;
                        text-align: left;
                    }


                    .rating__list{
                        display: flex;
                    }
                }
               
            }
        }
    }
    
`