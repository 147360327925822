import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BaseModal from '../based/refactor/BaseModal'
import ProductReviewModel from "../models/ProductReviewModel";
import Textbox from "../based/inputs/Textbox";
import ManageReviewProductServices from "../based/services/ManageReviewProductServices";
import useFormValidate from "../hooks/useFormValidate";
import TextArea from "../based/inputs/TextArea";
import { Notify } from "../based/Notify";
import { NOTIFY } from "../based/Constants";
import CommonModal from "../based/Modal";

function AddUpdate(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [model, setModel] = useState(new ProductReviewModel());
    const { addRef, displayError, isValid } = useFormValidate();
    const { review } = props;

    useEffect(() => {
        if (review.id > 0) {
            getProductReviewId(review.id);
        }
    }, [review]);

    /** lấy thông tin product review theo id */
    const getProductReviewId = async (id) => {
        let [err, data] = await ManageReviewProductServices.GetProductReviewReplyByReviewId(id);
        if (!err && data) {
            setModel({...data , replyId: id});
        } else {
            setModel({
                id: 0,
                replyId: id,
                reviewComment: '',
                orderProductId: review.orderProductId,
                orderProductType: review.orderProductType,
                tagComment: '',
                isIncognito: false,
                artworkStarRate: 0,
                printStarRate: 0,
                totalStarRate: 0,
                images: []
            });
        }
    };

    /** thay đổi thông tin model */
    const handleChangeValue = (name, value) => {
        let data = { ...model };
        data[name] = value;
        setModel(data);
    };

    /** save thong tin */
    const saveReplyModel = async () => {
        let [err, data] = await ManageReviewProductServices.AddOrUpdateReplyProductReview({...model});
        if (!err && data) {
            props.onSaveSucceed();
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thât bại");
        }
    };

    return (
        <CommonModal show={props.isShowModal} isBusy={isLoading} busyMsg={msg} title='Trả lời' onClose={() => props.onClose()} onSave={() => saveReplyModel()}>
            <div className='form-group w-100'>
                <div className='label__group'>
                    <label htmlFor='code' className='required'>
                        Review:
                    </label>
                    <div className='suggestions .ext-info'>
                        <i className='fa fa-exclamation-circle' aria-hidden='true'></i>&nbsp;
                    </div>
                </div>
                <TextArea name='code' readOnly required ref={addRef} className={`form-control font-weight-bold`} value={review.reviewComment ? review.reviewComment : "Không để lại đánh giá"} />
            </div>
            <div className='form-group w-100'>
                <div className='label__group'>
                    <label htmlFor='code' className='required'>
                        Trả lời:
                    </label>
                    <div className='suggestions .ext-info'>
                        <i className='fa fa-exclamation-circle' aria-hidden='true'></i>&nbsp;
                    </div>
                </div>
                <TextArea name='replyProductReviewComment' required ref={addRef} maxLength={500} className={`form-control font-weight-bold`} onChanged={(name, value) => handleChangeValue('reviewComment', value)} value={model.reviewComment} />
            </div>
        </CommonModal>
    );
}

AddUpdate.propTypes = {};

export default AddUpdate;
