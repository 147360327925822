import PropTypes from "prop-types";
import React from "react";
import { Box, Table } from "../content/ct.elm";
import TableFooter from "../content/TableFooter";
import { CommonTableProps } from "../PropsDefined";

/**
 * Common table
 * @param {CommonTableProps} props common table properties
 * @returns 
 */
function CommonTable(props) {
    return (
        <Box minHeight={props.minHeight}>
            {props.renderTab ? <div className='box__header'>{props.renderTab && props.renderTab()}</div> : ''}
            {props.renderAction ? props.renderAction && props.renderAction() : ''}
            {props.fixedTable ? (
                <div className='fixTableHead '>
                    <Table>
                        <thead>{props.renderHeader && props.renderHeader()}</thead>
                        <tbody>{props.renderBody && props.renderBody()}</tbody>
                    </Table >
                </div>
            ) : (
                <Table>
                    <thead>{props.renderHeader && props.renderHeader()}</thead>
                    <tbody>{props.renderBody && props.renderBody()}</tbody>
                </Table >
            )
            }

            {props.paging && <TableFooter itemToDisplay={props.itemToDisplay || 2} onFilter={(paging) => props.onFilter(paging)} paging={props.paging} />}
        </Box >
    );
}

CommonTable.propTypes = {
    renderTab: PropTypes.func,
    renderAction: PropTypes.func,
    renderHeader: PropTypes.func,
    renderBody: PropTypes.func,
};

export default CommonTable;
