import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import PhoneNumber from "../based/inputs/PhoneNumber";
import TFUDatetime from "../based/inputs/TFUDatetime";
import Textbox from "../based/inputs/Textbox";
import TFUSelect from "../based/refactor/TFUSelect";
import AccountServices from "../based/services/AccountServices";
import CommonServices from "../based/services/CommonServices";
import useFormValidate from "../hooks/useFormValidate";
import TopBar from "../layout/refactor/TopBar/TopBar";
import Discord from "./assets/Discord.svg";
import Facebook from "./assets/Facebook.svg";
import Instagram from "./assets/Instagram.svg";
import NimoTV from "./assets/NimoTV.svg";
import Personal from "./assets/Personal.svg";
import RanusCreator from "./assets/RanusCreator.png";
import Reddit from "./assets/Reddit.svg";
import Skype from "./assets/Skype.svg";
import SoundCloud from "./assets/SoundCloud.svg";
import Spotify from "./assets/Spotify.svg";
import TikTok from "./assets/TikTok.svg";
import Twitch from "./assets/Twitch.svg";
import Twitter from "./assets/Twitter.svg";
import WhatsApp from "./assets/WhatsApp.svg";
import Youtube from "./assets/Youtube.svg";
import Zalo from "./assets/Zalo.svg";
const ThirdPartyBankAccountModel = {
    bankAccountName: "", // Required, equivalent to public string BankAccountName { get; set; }
    bankAccountNumber: "", // Required, equivalent to public string BankAccountNumber { get; set; }
    bankCode: null, // Equivalent to public long BankCode { get; set; }
    isActive: false, // Equivalent to public bool IsActive { get; set; }
    bankAccountType: 0, // Equivalent to public BankAccountType BankAccountType { get; set; }
};

const ThirdPartyVerificationStatus = {
    Undefined: -1,
    Processing: 0,
    Verified: 1,
    Declined: 2,
};

const BankAccountType = {
    Bank: 1,
    MoMo: 2,
};

const PersonalInformationModel = {
    //thông tin cá nhân
    avatar: "",
    fullName: "",
    displayName: "",
    phoneNumber: "",
    email: "",
    birthDate: new Date(),
    provinceCode: "",
    districtCode: "",
    wardCode: "",
    street: "",
    identificationNumber: "",
    identificationCreatedTime: null, // Assuming DateTime, use null for nullable fields
    identificationProvinceCode: "",
    identificationFrontUrl: "",
    identificationBackUrl: "",
    isVerified: false,
    updatedDate: new Date(), // Assuming DateTime
    hoverFront: "",
    hoverBack: "",
    verificationStatus: ThirdPartyVerificationStatus.Undefined,
    verificationStatusName: "",
};

const SocialModel = {
    socialType: 0,
    socialName: "",
    socialUrl: "",
};

const PersonalBrandModel = {
    //thông tin thương hiệu
    brandName: "",
    listDocument: [],
};

const Step = {
    Detail: 1,
    Brand: 2,
    Payment: 3,
};

const SocialOptions = [
    { value: 1, text: "Facebook", label: "Facebook", url: Facebook },
    { value: 3, text: "Zalo", label: "Zalo", url: Zalo },
    { value: 4, text: "Tiktok", label: "Tiktok", url: TikTok },
    { value: 5, text: "Youtube", label: "Youtube", url: Youtube },
    { value: 6, text: "Skype", label: "Skype", url: Skype },
    { value: 7, text: "WhatsApp", label: "WhatsApp", url: WhatsApp },
    { value: 8, text: "Twitter", label: "Twitter", url: Twitter },
    { value: 9, text: "Twitch", label: "Twitch", url: Twitch },
    { value: 10, text: "Discord", label: "Discord", url: Discord },
    { value: 11, text: "NimoTV", label: "NimoTV", url: NimoTV },
    { value: 12, text: "Spotify", label: "Spotify", url: Spotify },
    { value: 13, text: "SoundCloud", label: "SoundCloud", url: SoundCloud },
    { value: 14, text: "Reddit", label: "Reddit", url: Reddit },
    { value: 15, text: "Personal", label: "Personal", url: Personal },
    { value: 16, text: "Instagram", label: "Instagram", url: Instagram },
];

function CreatorProfileVer2(props) {
    const [currentStep, setCurrentStep] = useState(Step.Detail); //vị trí edit hiện tại
    const [personalInfo, setPersonalInfo] = useState(PersonalInformationModel); //thông tin cá nhân
    const [brandInfo, setBrandInfo] = useState(PersonalBrandModel); //thông tin thương hiệu
    const [listBankAccount, setListBankAccount] = useState([ThirdPartyBankAccountModel]); //thông tin thanh toán
    const [listSocial, setListSocial] = useState([SocialModel]);
    const { addRef, displayError, isValid } = useFormValidate();
    const history = useHistory();

    //danh sách tỉnh/thành phố
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);
    const [bankList, setBankList] = useState([]);

    useEffect(() => {
        getProvinces();
        getBanks();
        initListBankAccount();
        getPersonalCreatorInfo();
    }, []);

    useEffect(() => {
        getDistricts(personalInfo.provinceCode);
    }, [personalInfo.provinceCode]);

    useEffect(() => {
        getWards(personalInfo.districtCode);
    }, [personalInfo.districtCode]);

    const getPersonalCreatorInfo = async () => {
        const [err, data] = await AccountServices.GetPersonalCreatorInfo();
        if (!err && data) {
            setPersonalInfo(data.personalCreatorInfo);
            setBrandInfo(data.creatorBrandInfo);
            setListBankAccount(data.bankAccounts);
            setListSocial(data.listSocial);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Có lỗi xảy ra, vui lòng thử lại sau");
        }
    };

    function initListBankAccount() {
        let listBankAccount = [];
        let md = { ...ThirdPartyBankAccountModel };
        md.bankAccountType = BankAccountType.Bank;
        listBankAccount.push(md);

        let mm = { ...ThirdPartyBankAccountModel };
        mm.bankAccountType = BankAccountType.MoMo;
        listBankAccount.push(mm);

        setListBankAccount(listBankAccount);
    }

    async function getProvinces() {
        const [err, data] = await CommonServices.GetProvinces();
        const formatedList = data && data.length > 0 ? data.map((i) => ({ value: i.id, label: i.name })) : [];
        if (!err) setProvinces(formatedList);
    }

    async function getDistricts(provinceCode) {
        if (provinceCode) {
            const [err, data] = await CommonServices.GetDistricts(provinceCode);
            const formatedList = data && data.length > 0 ? data.map((i) => ({ value: i.id, label: i.name })) : [];
            if (!err) setDistricts(formatedList);
            else setDistricts([]);
        } else setDistricts([]);
    }

    async function getWards(districtCode) {
        if (districtCode) {
            const [err, data] = await CommonServices.GetWards(districtCode);
            const formatedList = data && data.length > 0 ? data.map((i) => ({ value: i.id, label: i.name })) : [];
            if (!err) setWards(formatedList);
            else setWards([]);
        } else setWards([]);
    }

    const getBanks = async () => {
        const [err, data] = await CommonServices.GetBanks();
        if (!err && data) {
            var bankList = data.map((item) => {
                item.value = item.value;
                item.label = item.text;
                return item;
            });
            setBankList(bankList);
        } else {
            setBankList([]);
        }
    };

    function isStepActiveOrComplete(currentStep, currentPosition) {
        //nếu vị trí hiện tại bằng với step hiện tại thì là active
        if (currentPosition == currentStep) return "active";
        //nếu vị trí hiện tại nhỏ hơn step hiện tại thì là completed
        else if (currentPosition < currentStep) return "completed";
        else return "";
    }

    async function handleSave() {
        let total = {
            personalCreatorInfo: personalInfo,
            creatorBrandInfo: brandInfo,
            bankAccounts: listBankAccount,
            listSocial: listSocial,
        };

        console.log("total", total);
        const [err, data] = await AccountServices.UpdateCreatorPersonalInfo(total);
        if (!err && data) {
            history.push("/creator-register-success");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Có lỗi xảy ra, vui lòng thử lại sau");
        }
    }

    function handleAvatar(e) {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
            Request.UploadImage(file).then((res) => {
                if (res && res.data) {
                    setPersonalInfo({
                        ...personalInfo,
                        avatar: res.data.imageUrl,
                    });
                }
            });
        };
    }

    function handleFrontIdentification(e) {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
            Request.UploadImage(file).then((res) => {
                if (res && res.data) {
                    setPersonalInfo({
                        ...personalInfo,
                        identificationFrontUrl: res.data.imageUrl,
                    });
                }
            });
        };
    }

    function handleBackIdentification(e) {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
            Request.UploadImage(file).then((res) => {
                if (res && res.data) {
                    setPersonalInfo({
                        ...personalInfo,
                        identificationBackUrl: res.data.imageUrl,
                    });
                }
            });
        };
    }

    function handleBrand(e) {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
            Request.UploadImage(file).then((res) => {
                if (res && res.data) {
                    setBrandInfo({
                        ...brandInfo,
                        listDocument: [...brandInfo.listDocument, { url: res.data.imageUrl }],
                    });
                }
            });
        };
    }

    // Các step
    function StepSection() {
        return (
            <div className="body_step">
                <div className="stepper-wrapper">
                    <div
                        className={`stepper-item ${isStepActiveOrComplete(currentStep, Step.Detail)}`}
                        onClick={(e) => {
                            setCurrentStep(Step.Detail);
                        }}
                    >
                        <div className="step-counter"></div>
                        <div className="step-name">Thông tin cá nhân và xác thực</div>
                    </div>
                    <div
                        className={`stepper-item ${isStepActiveOrComplete(currentStep, Step.Brand)}`}
                        onClick={(e) => {
                            setCurrentStep(Step.Brand);
                        }}
                    >
                        <div className="step-counter"></div>
                        <div className="step-name">Thông tin thương hiệu và liên kết</div>
                    </div>
                    <div
                        className={`stepper-item ${isStepActiveOrComplete(currentStep, Step.Payment)}`}
                        onClick={(e) => {
                            setCurrentStep(Step.Payment);
                        }}
                    >
                        <div className="step-counter"></div>
                        <div className="step-name">Thông tin thanh toán</div>
                    </div>
                </div>
            </div>
        );
    }

    function ButtonSection() {
        return (
            <div className="section-btn">
                <div className="list-action">
                    <button
                        className={`action-item ${isStepActiveOrComplete(currentStep, Step.Detail)}`}
                        onClick={(e) => {
                            setCurrentStep(Step.Detail);
                        }}
                    >
                        Thông tin cá nhân và xác thực
                    </button>
                    <button
                        className={`action-item ${isStepActiveOrComplete(currentStep, Step.Brand)}`}
                        onClick={(e) => {
                            setCurrentStep(Step.Brand);
                        }}
                    >
                        Thông tin thương hiệu và liên kết
                    </button>
                    <button
                        className={`action-item ${isStepActiveOrComplete(currentStep, Step.Payment)}`}
                        onClick={(e) => {
                            setCurrentStep(Step.Payment);
                        }}
                    >
                        Thông tin thanh toán
                    </button>
                </div>
            </div>
        );
    }

    // Thông tin cá nhân
    function PersonalDetailSection() {
        return (
            <div className="personal_information-main">
                <h6>Thông tin cá nhân</h6>
                <div className="row-form">
                    <div className="form-avatar">
                        <div className="avatar">{personalInfo.avatar ? <img src={personalInfo.avatar} alt="avatar" /> : <img src="/images/users/no-avatar.png" alt="avatar" />}</div>
                        <div className="btn-avatar-action">
                            <label className="required">Ảnh đại diện</label>
                            <button
                                className="btn-upload"
                                onClick={(e) => {
                                    document.getElementById("avatar-creator").click();
                                }}
                            >
                                <div className="icon">
                                    <UploadAvatar />
                                </div>
                                Tải lên
                            </button>
                            <input
                                type="file"
                                className="d-none"
                                id="avatar-creator"
                                onChange={(e) => {
                                    handleAvatar(e);
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-group-input">
                        <label className="required" htmlFor="fullName">
                            Họ và Tên
                        </label>
                        <Textbox
                            ref={addRef}
                            className="form-input"
                            placeholder="Nhập họ và tên"
                            name="fullName"
                            id="fullName"
                            value={personalInfo.fullName}
                            onChanged={(name, value) => {
                                setPersonalInfo({
                                    ...personalInfo,
                                    fullName: value,
                                });
                            }}
                            required
                        />
                    </div>
                    <div className="form-group-input">
                        <label className="required" htmlFor="displayName">
                            Nghệ danh
                        </label>
                        <Textbox
                            ref={addRef}
                            className="form-input"
                            placeholder="Nhập nghệ danh"
                            name="displayName"
                            id="displayName"
                            value={personalInfo.displayName}
                            onChanged={(name, value) => {
                                setPersonalInfo({
                                    ...personalInfo,
                                    displayName: value,
                                });
                            }}
                            required
                        />
                    </div>
                    <div className="form-group-input">
                        <label className="required" htmlFor="phoneNumber">
                            Số điện thoại
                        </label>
                        <div className="mobile form-input">
                            <PhoneNumber
                                addRef={addRef}
                                placeholder="Số điện thoại"
                                name="phoneNumber"
                                id="phoneNumber"
                                required
                                value={personalInfo.phoneNumber}
                                onChanged={(name, value) => {
                                    setPersonalInfo({
                                        ...personalInfo,
                                        phoneNumber: value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-group-input">
                        <label className="required" htmlFor="email">
                            Email
                        </label>
                        <Textbox
                            ref={addRef}
                            className="form-input"
                            name="email"
                            placeholder="Nhập số điện thoại"
                            value={personalInfo.email}
                            onChanged={(name, value) => {
                                setPersonalInfo({
                                    ...personalInfo,
                                    email: value,
                                });
                            }}
                            required
                        />
                    </div>
                    <div className="form-group-input">
                        <label className="required" htmlFor="birthDate">
                            Ngày sinh
                        </label>
                        <div className="birthday form-input">
                            <TFUDatetime
                                name="birthDate"
                                placeholder="Nhập ngày sinh"
                                value={new Date(personalInfo.birthDate)}
                                showDateOnly
                                onChanged={(value, name) => {
                                    setPersonalInfo({
                                        ...personalInfo,
                                        birthDate: value,
                                    });
                                }}
                                addRef={addRef}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group-input address">
                        <label className="required" htmlFor="provinceCode">
                            Địa chỉ
                        </label>
                        <div className="form-address">
                            <TFUSelect
                                placeholder="Tỉnh/Thành phố"
                                name="provinceCode"
                                value={personalInfo.provinceCode}
                                onChanged={(value) => {
                                    setPersonalInfo({
                                        ...personalInfo,
                                        provinceCode: value,
                                        districtCode: 0,
                                        wardCode: 0,
                                    });
                                }}
                                options={provinces}
                                required
                                addRef={addRef}
                            />
                        </div>
                        <div className="form-address">
                            <TFUSelect
                                placeholder="Quận/Huyện"
                                value={personalInfo.districtCode}
                                name="districtCode"
                                onChanged={(value) => {
                                    setPersonalInfo({
                                        ...personalInfo,
                                        districtCode: value,
                                        wardCode: 0,
                                    });
                                }}
                                options={districts}
                                required
                                addRef={addRef}
                            />
                        </div>
                        <div className="form-address">
                            <TFUSelect
                                placeholder="Xã/Phường"
                                value={personalInfo.wardCode}
                                name="wardCode"
                                onChanged={(value) => {
                                    setPersonalInfo({
                                        ...personalInfo,
                                        wardCode: value,
                                    });
                                }}
                                options={wards}
                                required
                                addRef={addRef}
                            />
                        </div>
                        <div className="form-street">
                            <Textbox
                                ref={addRef}
                                className="form-input"
                                name="street"
                                placeholder="Địa chỉ (Số nhà/Tòa nhà/Tổ/Ấp)"
                                value={personalInfo.street}
                                onChanged={(name, value) => {
                                    setPersonalInfo({
                                        ...personalInfo,
                                        street: value,
                                    });
                                }}
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // Căn cước công dân
    function IdentificationSection() {
        return (
            <section className="identification">
                <div className="row-form">
                    <div className="form-group-input">
                        <label className="required" htmlFor="identificationNumber">
                            Số Căn cước/CCCD/CMND
                        </label>
                        <Textbox
                            ref={addRef}
                            className="form-input"
                            name="identificationNumber"
                            placeholder="Nhập số Căn cước/CCCD/CMND *"
                            value={personalInfo.identificationNumber}
                            onChanged={(name, value) => {
                                setPersonalInfo({
                                    ...personalInfo,
                                    identificationNumber: value,
                                });
                            }}
                            required
                        />
                    </div>
                    <div className="form-group-input">
                        <label className="required" htmlFor="identificationCreatedTime">
                            Ngày cấp
                        </label>
                        <div className="form-input">
                            <TFUDatetime
                                name="identificationCreatedTime"
                                placeholder="Ngày cấp"
                                showDateOnly
                                onChanged={(value, name) => {
                                    setPersonalInfo({
                                        ...personalInfo,
                                        identificationCreatedTime: value,
                                    });
                                }}
                                value={new Date(personalInfo.identificationCreatedTime)}
                                ref={addRef}
                            />
                        </div>
                    </div>
                </div>

                <div className="identification-upload">
                    <div className="header-form">
                        <label className="required">Ảnh Căn cước/CMND mặt trước và sau</label>
                        <span className="instruction">(Định dạng .JPG hoặc .PNG, dung lượng tối đa 5Mb)</span>
                    </div>
                    <div className="body-form">
                        <div className="form-upload-identification">
                            <div
                                className="form-image"
                                onMouseEnter={(e) => {
                                    setPersonalInfo({
                                        ...personalInfo,
                                        hoverFront: true,
                                    });
                                }}
                                onMouseLeave={(e) => {
                                    setPersonalInfo({
                                        ...personalInfo,
                                        hoverFront: false,
                                    });
                                }}
                            >
                                {personalInfo.identificationFrontUrl ? <img src={personalInfo.identificationFrontUrl} alt="front" /> : <img src="https://via.placeholder.com/150" alt="avatar" />}
                                {personalInfo.hoverFront && (
                                    <div className="form-action">
                                        <div
                                            className="upload-btn"
                                            onClick={(e) => {
                                                document.getElementById("upload-btn-front").click();
                                            }}
                                        >
                                            <div className="icon">
                                                <UploadAvatar />
                                            </div>
                                            <span>Tải ảnh mặt trước</span>
                                        </div>
                                        <input
                                            type="file"
                                            id="upload-btn-front"
                                            className="d-none"
                                            onChange={(e) => {
                                                handleFrontIdentification(e);
                                            }}
                                        ></input>
                                        <div
                                            className="remove-btn"
                                            onClick={(e) => {
                                                setPersonalInfo({
                                                    ...personalInfo,
                                                    identificationFrontUrl: null,
                                                });
                                            }}
                                        >
                                            <div className="icon">
                                                <RemoveIcon />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="form-upload-identification">
                            <div
                                className="form-image"
                                onMouseEnter={(e) => {
                                    setPersonalInfo({
                                        ...personalInfo,
                                        hoverBack: true,
                                    });
                                }}
                                onMouseLeave={(e) => {
                                    setPersonalInfo({
                                        ...personalInfo,
                                        hoverBack: false,
                                    });
                                }}
                            >
                                {personalInfo.identificationBackUrl ? <img src={personalInfo.identificationBackUrl} alt="front" /> : <img src="https://via.placeholder.com/150" alt="avatar" />}
                                {personalInfo.hoverBack && (
                                    <div className="form-action">
                                        <div
                                            className="upload-btn"
                                            onClick={(e) => {
                                                document.getElementById("upload-btn-back").click();
                                            }}
                                        >
                                            <div className="icon">
                                                <UploadAvatar />
                                            </div>
                                            <span>Tải ảnh mặt sau</span>
                                        </div>
                                        <input
                                            type="file"
                                            id="upload-btn-back"
                                            className="d-none"
                                            onChange={(e) => {
                                                handleBackIdentification(e);
                                            }}
                                        />
                                        <div
                                            className="remove-btn"
                                            onClick={(e) => {
                                                setPersonalInfo({
                                                    ...personalInfo,
                                                    identificationBackUrl: null,
                                                });
                                            }}
                                        >
                                            <div className="icon">
                                                <RemoveIcon />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    //Thông tin thương hiệu/liên kết
    function BrandSection() {
        return (
            <section className="brand">
                <h6>Thông tin thương hiệu</h6>
                <div className="row-form">
                    <div className="form-group-input">
                        <label className="required">Tên thương hiệu</label>
                        <Textbox
                            ref={addRef}
                            className="form-input"
                            name="brandName"
                            placeholder="Nhập tên thương hiệu"
                            value={brandInfo.brandName}
                            onChanged={(name, value) => {
                                setBrandInfo({
                                    ...brandInfo,
                                    brandName: value,
                                });
                            }}
                            required
                        />
                    </div>
                </div>
                <div className="upload-document">
                    <label>Giấy tờ chứng minh sở hữu thương hiệu (Nếu có)</label>
                    <div className="instruction">Định dạng .PDF, .JPG, .JPEG, .PNG Dung lượng tối đa 3Mb Tối đa 5 tệp tin</div>
                    <div className="list-document">
                        <div
                            className="document-item-upload"
                            onClick={(e) => {
                                document.getElementById("brand-creator").click();
                            }}
                        >
                            <div className="icon">
                                <UploadAvatar />
                            </div>
                            <span>Tải lên</span>
                        </div>
                        <input
                            type="file"
                            className="d-none"
                            onChange={(e) => {
                                handleBrand(e);
                            }}
                            id="brand-creator"
                        />
                        {brandInfo &&
                            brandInfo.listDocument &&
                            brandInfo.listDocument.map((item, idx) => {
                                return (
                                    <div className="document-item" key={idx}>
                                        <div className="img-icon">
                                            <img src={item.url} alt="certificate brand" />
                                        </div>
                                        <div
                                            className="remove-icon"
                                            onClick={(e) => {
                                                let lst = [...brandInfo.listDocument];
                                                lst.splice(idx, 1);
                                                setBrandInfo({
                                                    ...brandInfo,
                                                    listDocument: lst,
                                                });
                                            }}
                                        >
                                            <div className="icon">
                                                <RemoveIcon />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </section>
        );
    }

    //Liên kết
    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderColor: "#E5E5E5",
            background: "#F3F3F3",
            borderRadius: "8px 0 0 8px",
            boxShadow: "none",
            height: "42px",
            "&:hover": {
                borderColor: "#E5E5E5",
            },
        }),
        option: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
        }),
        singleValue: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
        }),
    };

    const formatOptionLabel = ({ label, url }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
            <img src={url} alt="" style={{ width: 20, height: 20, marginRight: 10 }} />
            {label}
        </div>
    );

    function ConnectSection() {
        return (
            <section className="connection">
                <h6>Liên kết</h6>
                <div className="instruction">Chia sẻ đường liên kết bên ngoài với fan hoặc khách hàng. Các đường liên kết này sẽ hiển thị tại gian hàng của bạn.</div>
                <div className="list-connection">
                    {listSocial.map((item, index) => {
                        return (
                            <div className="connect-item">
                                <div className="remove-connect">
                                    <div className="icon">
                                        <RemoveConnect />
                                    </div>
                                </div>
                                <div className="select-connect">
                                    <div className="select-area">
                                        <Select
                                            options={SocialOptions}
                                            styles={customStyles}
                                            formatOptionLabel={formatOptionLabel}
                                            value={SocialOptions.find((x) => x.value == item.socialType)}
                                            onChange={(e) => {
                                                let lst = [...listSocial];
                                                lst[index].socialType = e.value;
                                                setListSocial(lst);
                                            }}
                                        />
                                    </div>
                                    <div className="input-area">
                                        <input
                                            type="text"
                                            placeholder="Nhập link"
                                            value={item.socialUrl}
                                            onChange={(e) => {
                                                let lst = [...listSocial];
                                                lst[index].socialUrl = e.target.value;
                                                setListSocial(lst);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                    <div className="add-connection">
                        <button
                            onClick={(e) => {
                                let lst = [...listSocial];
                                lst.push({ socialType: 0, socialUrl: "" });
                                setListSocial(lst);
                            }}
                        >
                            Thêm liên kết
                            <div className="icon">
                                <PlusIcon />
                            </div>
                        </button>
                    </div>
                </div>
            </section>
        );
    }

    //Thông tin thanh toán
    function PaymentMethodSection() {
        return (
            <section className="payment">
                <h6>Thông tin thanh toán</h6>
                <div className="sub-instruct">Đây là tài khoản Ranus sẽ chuyển tiền hoa hồng về mỗi tháng.</div>
                <div className="list-payment">
                    {listBankAccount.map((item, index) => {
                        let clsName = item.bankAccountType == BankAccountType.Bank ? "bank" : "momo";
                        return (
                            <div className={`payment-item ${clsName}`}>
                                <div className="header-payment">
                                    <div className="icon">{item.bankAccountType == BankAccountType.Bank ? <BankIcon /> : <MomoIcon />}</div>
                                    <span>Tài khoản ngàn hàng</span>
                                </div>
                                <div className="body-payment">
                                    {item.bankAccountType == BankAccountType.Bank && (
                                        <div className="form-payment">
                                            <label className="required">Chọn ngân hàng</label>
                                            <div className="form-bank">
                                                <TFUSelect
                                                    placeholder="Chọn ngân hàng"
                                                    onChanged={(value) => {
                                                        let lst = [...listBankAccount];
                                                        lst[index].bankCode = value;
                                                        setListBankAccount(lst);
                                                    }}
                                                    options={bankList}
                                                    value={item.bankCode}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className="form-payment">
                                        <label className="required">Tên tài khoản</label>
                                        <Textbox
                                            ref={addRef}
                                            className="form-input"
                                            name="bankAccountName"
                                            placeholder="Nhập tên tài khoản"
                                            value={item.bankAccountName}
                                            onChanged={(name, value) => {
                                                let lst = [...listBankAccount];
                                                lst[index].bankAccountName = value;
                                                setListBankAccount(lst);
                                            }}
                                            required
                                        />
                                    </div>
                                    <div className="form-payment">
                                        <label className="required">Số tài khoản</label>
                                        <Textbox
                                            ref={addRef}
                                            className="form-input"
                                            name="bankAccountNumber"
                                            placeholder="Nhập số tài khoản"
                                            value={item.bankAccountNumber}
                                            onChanged={(name, value) => {
                                                let lst = [...listBankAccount];
                                                lst[index].bankAccountNumber = value;
                                                setListBankAccount(lst);
                                            }}
                                            required
                                        />
                                    </div>
                                    <div className="radio-default">
                                        <div className="radio-box">
                                            <input
                                                type="radio"
                                                id={`default_${index}`}
                                                name={`default_${index}`}
                                                checked={item.isActive}
                                                onClick={(e) => {
                                                    let lst = [...listBankAccount];
                                                    lst.map((i) => (i.isActive = false));

                                                    lst[index].isActive = e.target.checked;
                                                    setListBankAccount(lst);
                                                }}
                                            />
                                            <label htmlFor={`default_${index}`}>Đăt làm mặc định</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </section>
        );
    }

    //update thông tin cá nhân
    async function handleUpdatePersonalInfo() {
        const [err, data] = await AccountServices.UpdatePersonalInfo(personalInfo);
        if (!err && data) {
            getPersonalCreatorInfo();
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Cập nhật thông tin thành công");
        } else Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Có lỗi xảy ra, vui lòng thử lại sau");
    }

    //update thông tin thương hiệu và liên kết
    async function handleUpdateBrandInfo() {
        const [err, data] = await AccountServices.UpdateBrandConnect({ creatorBrandInfo: brandInfo, listSocial: listSocial });
        if (!err && data) {
            getPersonalCreatorInfo();
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Cập nhật thông tin thành công");
        } else Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Có lỗi xảy ra, vui lòng thử lại sau");
    }

    //update thông tin thanh toán
    async function handleUpdatePaymentInfo() {
        const [err, data] = await AccountServices.UpdateBankAccounts({ bankAccounts: listBankAccount });
        if (!err && data) {
            getPersonalCreatorInfo();
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Cập nhật thông tin thành công");
        } else Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Có lỗi xảy ra, vui lòng thử lại sau");
    }

    function BottomAction() {
        return (
            <section className="action">
                <div className="list-btn">
                    <div className="notify-left">
                        <span className="high-light">Lưu ý:</span> Thông tin cá nhân sau khi chỉnh sửa sẽ được Ranus kiểm duyệt trong 24h làm việc. Nếu kiểm duyệt không thành công thì sẽ trở về thông tin ban đầu.
                    </div>
                    <button
                        className="complete"
                        onClick={(e) => {
                            switch (currentStep) {
                                case Step.Detail:
                                    handleUpdatePersonalInfo();
                                    break;
                                case Step.Brand:
                                    handleUpdateBrandInfo();
                                    break;
                                case Step.Payment:
                                    handleUpdatePaymentInfo();
                                    break;

                                default:
                                    break;
                            }
                        }}
                    >
                        Lưu
                    </button>
                </div>
            </section>
        );
    }

    if (personalInfo == null) return null;
    return (
        <Wrapper>
            <section className="main-page">
                <section className="header">
                    {personalInfo.verificationStatus != ThirdPartyVerificationStatus.Undefined && personalInfo.verificationStatus != ThirdPartyVerificationStatus.Verified && (
                        <p className="admin-notify">
                            <span>Trạng thái: {personalInfo.verificationStatusName}</span>
                        </p>
                    )}
                </section>
                <section className="body">
                    <section
                        className="personal_information"
                        style={{
                            borderRadius: currentStep == Step.Payment ? "12px 12px 0px 0px" : "12px",
                        }}
                    >
                        {/* Các step */}
                        <ButtonSection />

                        {/* Thông tin cá nhân */}
                        {currentStep == Step.Detail && PersonalDetailSection()}
                        {/* Thôn tin thương hiệu */}
                        {currentStep == Step.Brand && BrandSection()}
                        {/* Phương thức */}
                        {currentStep == Step.Payment && PaymentMethodSection()}
                    </section>

                    {/* Căn cước công dân */}
                    {currentStep == Step.Detail && IdentificationSection()}
                    {/* Liên kết */}
                    {currentStep == Step.Brand && ConnectSection()}

                    {/* Tiếp theo/Hoàn thành */}
                    <BottomAction />
                </section>
            </section>
        </Wrapper>
    );
}

export default CreatorProfileVer2;

const Wrapper = styled.div`
    width: 100%;

    span.high-light {
        color: #ff9027;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    .list-btn {
        align-items: center;
    }

    .notify-left {
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        width: 50%;
    }
    .section-btn {
        padding: 10px 24px;
        border-bottom: 1px solid #f3f3f3;
    }

    .list-action {
        display: flex;
    }

    button.action-item {
        border: none;
        background: none;
        padding: 12px 18px;
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    button.action-item.active {
        border-radius: 8px;
        background: #fff5ec;
        color: var(--Cam-Thng-hiu, #ff9027);
        text-align: center;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    p.admin-notify {
        border-radius: 12px;
        background: #ffe4e4;
        color: #d70000;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 16px 24px;
    }

    .img-icon {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .img-icon img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    /* Style for required fields if left blank */
    .input-group {
        height: 100%;
    }

    input#phoneNumber {
        height: 100%;
    }
    .birthday.form-input {
        height: 45px;
    }

    .react-datepicker-wrapper {
        height: 40px;
    }

    .react-datepicker__input-container {
        height: 100%;
    }

    .react-datepicker__input-container input.form-control {
        height: 100%;
    }

    .mobile.form-input {
        height: 45px;
    }

    .input-phone-number {
        height: 100%;
    }

    .input-group-phone.valid {
        height: 100%;
    }

    .input-group-prepend {
        height: 100%;
    }

    /* Trở về/Hoàn thành */
    section.action {
        padding: 24px;
        background: white;
        border-radius: 0 0 24px 24px;
    }

    .list-btn {
        display: flex;
    }

    button.next {
        border-radius: 20px;
        background: #f3f3f3;
        border: none;
        color: #303030;
        text-align: center;
        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 11px;
        width: 140px;
    }

    button.complete {
        border-radius: 20px;
        background: #ff9027;
        color: #fff;
        text-align: center;
        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: none;
        padding: 11px;
        width: 140px;
        margin-left: auto;
    }

    /* Phương thức thanh toán */
    .payment h6 {
        margin: 12px 24px;
        color: #303030;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .sub-instruct {
        margin: 12px 24px;
        color: #a5a5a5;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .list-payment {
        display: flex;
    }

    .payment-item {
        width: calc(50% - 24px);
        margin: 12px 24px;
        border-radius: 8px;
        border: 1px solid #e5e5e5;
        background: #fff;
    }

    .header-payment {
        padding: 12px 16px;
        display: flex;
        align-items: center;
        height: 48px;
    }

    .payment-item.bank {
    }

    .payment-item.bank .header-payment {
        background: #fff5ec;
    }

    .payment-item.momo .header-payment {
        background: #ffdef2;
    }

    .payment-item.bank .header-payment span {
        color: var(--Cam-Thng-hiu, #ff9027);
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        margin-left: 8px;
    }

    .payment-item.momo .header-payment span {
        color: #a40064;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        margin-left: 8px;
    }

    .body-payment {
        padding: 12px 16px;
        display: flex;
        flex-direction: column;
        height: calc(100% - 48px);
    }

    .form-payment label {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        width: 100%;
    }

    .form-payment {
        display: flex;
        flex-wrap: wrap;
        margin: 12px 0;
    }

    .react-select__control {
        height: 45px !important;
    }

    .form-bank {
        width: 100%;
    }

    .form-payment input {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #e5e5e5;
        background: #fff;
        padding: 11px 12px;
    }

    .radio-box label {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin: 0 0 0 8px;
    }

    .radio-box {
        display: flex;
        align-items: center;
    }

    .radio-default {
        margin-top: auto;
    }
    /* Liên kết */
    section.connection {
        border-radius: 12px 12px 0px 0px;
        border: 1px solid #f3f3f3;
        background: #fff;
        margin: 12px 0 0 0;
    }

    .connection h6 {
        color: #303030;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 12px 24px;
    }

    .connection .instruction {
        margin: 0 24px;
        color: #a5a5a5;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .add-connection button {
        border-radius: 40px;
        background: var(--Cam-Thng-hiu, #ff9027);
        display: flex;
        align-items: center;
        color: #fff;
        text-align: center;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: none;
        align-items: center;
        padding: 12px;
    }

    .add-connection button .icon {
        margin-left: 6px;
    }

    .add-connection {
        margin-top: 30px;
    }

    .connect-item {
        display: flex;
        align-items: center;
    }

    .select-connect {
        display: flex;
        align-items: center;
        margin-left: 18px;
        height: 42px;
        width: 100%;
        flex: 1;
    }

    .list-connection {
        margin: 12px 24px;
    }

    .input-area {
        height: 100%;
        width: 50%;
    }

    .select-area {
        width: 15%;
    }

    .input-area input[type="text"] {
        border-radius: 0px 8px 8px 0px;
        border: 1px solid #e5e5e5;
        background: #fff;
        height: 100%;
        width: 100%;
    }

    .select-area > div {
    }

    /* Thông tin thương hiệu */
    .brand h6 {
        color: #303030;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 12px 24px;
    }

    .upload-document {
        margin: 12px 24px;
    }

    .upload-document label {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    .upload-document .instruction {
        color: #a5a5a5;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .list-document {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
    }

    .list-document > div {
        width: 74px;
        height: 74px;
        border-radius: 8px;
        border: 1px solid #e5e5e5;
        background: #fff;
        padding: 9px 14px;
        display: flex;
        margin: 0 12px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
    }

    .remove-icon {
        position: absolute;
        top: 5px;
        right: 5px;
    }

    .document-item-upload {
        color: #303030;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .document-item-upload span {
        margin-top: 8px;
    }

    /* Căn cước công dân */
    section.identification {
        border-radius: 12px 12px 0px 0px;
        border: 1px solid #f3f3f3;
        background: #fff;
        margin: 12px 0 0 0;
    }

    .body-form {
        display: flex;
        width: 100%;
    }

    .header-form {
        margin: 0 24px;
    }

    .header-form label.required {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    .header-form span.instruction {
        color: #a5a5a5;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .form-upload-identification {
        width: calc(100% / 3 - 48px);
        margin: 12px 24px;
        height: 150px;
    }

    .form-image {
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
    }

    .form-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .form-action {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(34, 34, 34, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .remove-btn {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .upload-btn {
        display: flex;
        align-items: center;
        border-radius: 40px;
        background: #f8f8f8;
        padding: 13px 12px;
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .upload-btn .icon {
        margin-right: 8px;
    }

    .form-group-input.address {
        flex-wrap: wrap;
        margin: 12px 0;
    }

    .logo-ranus {
        position: relative;
        z-index: 101;
        left: 45px;
        top: 10px;
        display: inline-block;
    }

    .top-bar {
        height: 70px;
    }

    section.main-page {
        padding: 20px 20px;
    }

    section.header h1 {
        color: #303030;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    p.instruction {
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 10px 0;
    }

    .stepper-wrapper {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .stepper-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        cursor: pointer;

        @media (max-width: 768px) {
            font-size: 12px;
        }
    }

    .stepper-item::before {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 7px;
        left: -50%;
        z-index: 2;
    }

    .stepper-item::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 7px;
        left: 50%;
        z-index: 2;
    }

    .stepper-item .step-counter {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #ccc;
        margin-bottom: 6px;
    }

    .stepper-item.active {
        font-weight: bold;
    }

    .stepper-item.completed .step-counter {
        background-color: #ff9027;
    }
    .stepper-item.active .step-counter {
        background-color: #ff9027;
    }

    .stepper-item.completed::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ff9027;
        width: 100%;
        top: 7px;
        left: 50%;
        z-index: 3;
    }

    .stepper-item:first-child::before {
        content: none;
    }
    .stepper-item:last-child::after {
        content: none;
    }

    .stepper-item.completed .step-name {
        color: var(--Cam-Thng-hiu, #ff9027);
        text-align: center;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .stepper-item.active .step-name {
        color: var(--Cam-Thng-hiu, #ff9027);
        text-align: center;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .stepper-item .step-name {
        color: #303030;
        text-align: center;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    section.personal_information {
        border-radius: 12px;
        border: 1px solid #f3f3f3;
        background: #fff;
    }

    .body_step {
        padding: 16px;
        border-bottom: 1px solid #f3f3f3;
    }

    .personal_information-main {
        padding: 24px 0;
    }

    .personal_information-main h6 {
        color: #303030;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-left: 102px;
    }

    .row-form {
        display: flex;
        flex-wrap: wrap;
    }

    .form-group-input {
        width: calc(100% / 3 - 48px);
        height: 90px;
        margin: 12px 24px;
        display: flex;
        flex-direction: column;
    }

    .form-avatar {
        width: calc(100% / 3 - 48px);
        margin: 12px 24px;
        display: flex;
    }

    .btn-avatar-action {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        align-items: baseline;
    }

    .form-avatar .avatar {
        width: 90px;
        height: 90px;
        border-radius: 100%;
        overflow: hidden;
    }

    .form-avatar .avatar img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .btn-avatar-action label.required {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    button.btn-upload {
        display: flex;
        border: none;
        border-radius: 40px;
        background: #f8f8f8;
        padding: 13px 12px;
        align-items: center;
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        align-items: center;
    }

    .icon {
        display: flex;
    }

    .btn-upload .icon {
        margin-right: 8px;
    }

    .form-group-input label.required {
        width: 100%;
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    .form-group-input input.form-input {
        border-radius: 8px;
        border: 1px solid #e5e5e5;
        background: #fff;
        padding: 11px 16px;
        height: 45px;
    }

    .form-group-input.address {
        width: 100%;
        flex-direction: row;
        height: auto !important;
    }
    .form-address {
        width: calc(100% / 3 - 48px);
        margin: 12px 24px;
    }

    .form-group-input.address label.required {
        margin: 0px 24px;
    }

    .form-street {
        width: 100%;
        margin: 12px 24px;
    }

    .form-street input[type="text"] {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #e5e5e5;
        background: #fff;
        padding: 11px 16px;
    }
`;

const UploadAvatar = () => {
    return (
        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 3C1 1.89543 1.89543 1 3 1H10L17 8V18.8462C17 19.9507 16.1046 20.8462 15 20.8462H3C1.89543 20.8462 1 19.9507 1 18.8462V3Z" stroke="#303030" stroke-width="1.8" stroke-linejoin="round" />
            <path d="M8.59977 11.3599L6.67977 9.43994L4.75977 11.3599" stroke="#303030" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.67969 9.43994V15.1999" stroke="#303030" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3.80078 17.1201H9.56078" stroke="#303030" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10 8V1L17 8H10Z" stroke="#303030" stroke-width="1.8" stroke-linejoin="round" />
        </svg>
    );
};

const RemoveIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill="#D9D9D9" />
            <path d="M12 6L6 12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 6L12 12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const PlusIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 1V13" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13 7L1 7" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const RemoveConnect = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill="#D70000" />
            <path d="M13.2426 8.99996H4.75736" stroke="#F3F3F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const BankIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8398 1.73926L2.83984 8.73926H20.8398L11.8398 1.73926Z" stroke="#FF9027" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.83984 8.73926V16.7393" stroke="#FF9027" stroke-width="2" stroke-linecap="round" />
            <path d="M4.83984 8.73926V16.7393" stroke="#FF9027" stroke-width="2" stroke-linecap="round" />
            <path d="M14.8398 8.73926V16.7393" stroke="#FF9027" stroke-width="2" stroke-linecap="round" />
            <path d="M18.8398 8.73926V16.7393" stroke="#FF9027" stroke-width="2" stroke-linecap="round" />
            <rect x="2.83984" y="16.7393" width="18" height="4" stroke="#FF9027" stroke-width="2" stroke-linejoin="round" />
        </svg>
    );
};

const MomoIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8398 1.73926L2.83984 8.73926H20.8398L11.8398 1.73926Z" stroke="#A40064" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.83984 8.73926V16.7393" stroke="#A40064" stroke-width="2" stroke-linecap="round" />
            <path d="M4.83984 8.73926V16.7393" stroke="#A40064" stroke-width="2" stroke-linecap="round" />
            <path d="M14.8398 8.73926V16.7393" stroke="#A40064" stroke-width="2" stroke-linecap="round" />
            <path d="M18.8398 8.73926V16.7393" stroke="#A40064" stroke-width="2" stroke-linecap="round" />
            <rect x="2.83984" y="16.7393" width="18" height="4" stroke="#A40064" stroke-width="2" stroke-linejoin="round" />
        </svg>
    );
};
