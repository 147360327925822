import React, { useEffect } from "react";
import styled from "styled-components";
import { CARD_COLOR } from "../Constants";
import { BtnRounded } from "../content/ct.elm";
const BaseModal = (props) => {
    useEffect(() => {
        const escFunction = (e) => {
            if (e.keyCode === 27 && props.onClose) {
                props.onClose();
            }
        };
        window.addEventListener("keydown", escFunction);
        return () => {
            window.removeEventListener("keydown", escFunction);

            document.body.className = "scroll";
        };
    }, []);
    useEffect(() => {
        if (props.isShow) {
            document.body.className = "hidden";
        } else {
            document.body.className = "scroll";
        }
    }, [props.isShow]);
    var cls = props.className ? props.className : "";
    return (
        <Wrapper>
            <div className={`${props.isShow ? "modal__extent__layout active" : "modal__extent__layout"}`}>
                <div className={` ${cls} modal__container`} style={{ width: `${props.width ? props.width : "1200px"}` }}>
                    <div className="modal__header">
                        <h4>{props.title ? props.title : ""}</h4>
                        <div className="icon" onClick={props.onClose}>
                            <CloseIcon />
                        </div>
                    </div>
                    <div className="modal__body">{props.children}</div>
                    <div className="modal__footer">
                        {props.customButton ? props.customButton : null}
                        {props.onSave ? (
                            <BtnRounded className="mr-3" width="130px" color={CARD_COLOR.Green} onClick={props.onSave}>
                                Lưu
                            </BtnRounded>
                        ) : (
                            ""
                        )}
                        {props.onConfirm ? (
                            <BtnRounded className="mr-3" width="130px" color={CARD_COLOR.Green} onClick={props.onConfirm}>
                                Xác nhận
                            </BtnRounded>
                        ) : (
                            ""
                        )}
                        {props.nextStep ? (
                            <BtnRounded className="mr-3" width="130px" color={CARD_COLOR.Green} onClick={props.nextStep}>
                                Tiếp theo
                            </BtnRounded>
                        ) : (
                            ""
                        )}
                        {props.onClose && !props.hideClose ? (
                            <BtnRounded width="130px" color={CARD_COLOR.Red} onClick={props.onClose} className="btn-close">
                                Đóng
                            </BtnRounded>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default BaseModal;

const Wrapper = styled.div`
    .modal__header {
        color: #303030 !important;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .modal__header h4 {
        color: black;
    }

    .btn-close {
        border-radius: 40px;
        background: #f8f8f8;
        color: #000;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: none;
        padding: 8px 26px;
        height: auto;
    }
`;

const CloseIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
