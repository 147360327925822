import React, { useState, useRef } from "react";
import { connect } from "react-redux";

import { PROJECT_EDITOR_MODAL_TYPE as MODAL_TYPE } from "../../based/Constants";
import ImagePicker from "./ImagePicker";

// Redux
import { insertText, insertImage, openEmbedModal, openMockupModal } from "../../redux/project-editor/actions";

const InsertBar = ({ itemId, insertText, insertImage, openEmbedModal, openMockupModal }) => {
    const imgRef = useRef(null);
    const [isActive, setIsActive] = useState(false);

    const pickImageHandler = () => {
        if (imgRef.current) imgRef.current.click();
    };

    const insertImageHandler = (imgFile) => {
        insertImage(imgFile, itemId);
    };

    const insertTextHandler = () => {
        insertText(itemId);
    };

    const openEmbedModalHandler = () => {
        openEmbedModal({ itemId: itemId, type: MODAL_TYPE.INSERT });
    };

    const openMockupModalHandler = () => {
        openMockupModal({ itemId: itemId, type: MODAL_TYPE.INSERT });
    };

    return (
        <div className={`insert-bar-wrapper row align-items-center justify-content-center ${isActive ? "active" : "inactive"}`} onMouseEnter={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)}>
            <div className='col-10'>
                <div className='insert-bar p-2'>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className='insert-bar__content d-flex align-items-center justify-content-center px-3 py-1 rounded'>
                            <div className='text-light mx-1'>
                                <span>Chèn:</span>
                            </div>
                            <ImagePicker imgRef={imgRef} onChange={insertImageHandler} disablePreview />
                            <div className='d-flex align-items-center justify-content-center'>
                                <i className='fa fa-picture-o text-light mx-1 p-2 rounded cursor-pointer' onClick={pickImageHandler} />
                                <i className='fa fa-text-width text-light mx-1 p-2 rounded cursor-pointer' onClick={insertTextHandler} />
                                <i className='fa fa-code text-light mx-1 p-2 rounded cursor-pointer' onClick={openEmbedModalHandler} />
                                <i className='fa fa-window-maximize text-light mx-1 p-2 rounded cursor-pointer' onClick={openMockupModalHandler} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    insertImage: (imgFile, itemId) => {
        return dispatch(insertImage(imgFile, itemId));
    },
    insertText: (itemId) => {
        return dispatch(insertText(itemId));
    },
    openEmbedModal: ({ itemId, type }) => {
        return dispatch(openEmbedModal({ itemId, type }));
    },
    openMockupModal: ({ itemId, type }) => {
        return dispatch(openMockupModal({ itemId, type }));
    },
});

export default connect(null, mapDispatchToProps)(InsertBar);
