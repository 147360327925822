import BaseServices from "./BaseServices";

const BrandServices = {
    GetById: async (id) => {
        return await BaseServices.Get("/api/brand/get/" + id);
    },
    GetListBrand: async (model, tabIndex, status) => {
        return await BaseServices.Post(`/api/brand/get-list-brand?tab=${tabIndex}&status=${status}`, model);
    },
    SaveBrand: async (model) => {
        return await BaseServices.Post("/api/brand/save", model);
    },
    ApprovedBrand: async (model) => {
        return await BaseServices.Post("/api/brand/approved-brand", model);
    },
    GetDropdownApprovedBrand: async (paging) => {
        return await BaseServices.Post("/api/brand/get-dropdown-approved", paging);
    },

    GetDropdownsAllBrands: async () => {
        return await BaseServices.Get("/api/brand/get-dropdown-all-brands");
    },
    GetHandleBrands: async (model, statusHandle) => {
        return await BaseServices.Post(`/api/brand/get-handle-brands?status=${statusHandle}`, model);
    },
    DownloadLogo: async (url, fileName) => {
        return await BaseServices.DownloadFile(`/api/brand/download-logo?url=${url}`, fileName);
    },
    HandleLogo: async (model) => {
        return await BaseServices.Post(`/api/brand/handle-logo`, model);
    },
    GetBrandConfigByTier: async (userName) => {
        return await BaseServices.Get(`/api/brand/get-brand-config-tier?userName=${userName}`);
    },
    Deletes: async (ids) => {
        return await BaseServices.Post("/api/brand/delete", ids);
    },
};
export default BrandServices;
