import React, { useEffect, useState } from "react";
import Common from "../based/Common";
import { ROLES } from "../based/Constants";
import Request from "../based/Request";
import AccountServices from "../based/services/AccountServices";
import TFUErrorBoundaries from "../based/TFUErrorBoundaries";
import LastRevenue from "./components/LastRevenue";
import PendingPayment from "./components/PendingPayment";

export const Home = (props) => {
    const [data, setData] = useState({});
    const [busy, setBusy] = useState(false);
    const [roles, setRoles] = useState(null);

    useEffect(() => {
        checkShowDashboard();
    }, []);
    async function checkShowDashboard() {
        setBusy(true);
        let [error, data] = await AccountServices.GetRolesByUser();
        if (!error && data) {
            setRoles(data);
            setBusy(false);
            if (Common.IsAdmin() || data.find((x) => x.toUpperCase() == ROLES.CREATOR)) getDashboard();
        } else {
            setRoles(null);
            setBusy(false);
            window.location.href = "/access-denied";
        }
    }

    const getDashboard = () => {
        setBusy(true);
        Request.Get("/api/finance/dashboard")
            .then((res) => {
                setBusy(false);
                if (res.success) setData(res.data);
            })
            .catch((err) => {
                setBusy(false);
            });
    };
    return (
        <React.Fragment>
            <TFUErrorBoundaries>
                <div className="container-fluid" style={{ marginBottom: "60px" }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <h4 className="page-title float-left">Dashboard</h4>
                                <ol className="breadcrumb float-right">
                                    <li className="breadcrumb-item">
                                        <a>Ranus</a>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <a>Thống kê</a>
                                    </li>
                                </ol>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                    <React.Fragment>
                        <PendingPayment processingOrderProduct={data.processingOrderProduct} succeededOrderProduct={data.succeededOrderProduct} />
                        <LastRevenue lastRevenues={data.lastRevenues} />
                        {Common.IsAdmin() ||
                            (roles && roles.length > 0 && roles.find((x) => x.toUpperCase() == ROLES.CREATOR) && (
                                <React.Fragment>
                                    {/* <SoldProducts dashboard />
                                        <SoldProductsLoyalty /> */}
                                </React.Fragment>
                            ))}
                    </React.Fragment>
                </div>
                <footer className="footer text-right">{new Date().getFullYear()} - Ranus ©</footer>
            </TFUErrorBoundaries>
        </React.Fragment>
    );
};
