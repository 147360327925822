import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import styled from "styled-components";
import { CONSTANTS, NOTIFY, USER_TYPE } from "../based/Constants";
import PhoneNumber from "../based/inputs/PhoneNumber";
import Textbox from "../based/inputs/Textbox";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import { RequestDomain } from "../based/RequestDomain";
import useFormValidate from "../hooks/useFormValidate";
import BackgroundLogin from "./assets/BackgroundLogin.png";
import BackgroundRegister from "./assets/BackgroundRegister.png";
import RanusCreatorLogo from "./assets/RanusCreatorLogo.png";

const UserModel = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    userType: USER_TYPE.CREATOR,
};

export default function Register(props) {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const [isConfirmEmail, setIsConfirmEmail] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const { addRef, displayError, isValid } = useFormValidate();
    const [user, setUser] = useState(UserModel);

    useEffect(() => {
        let paramUrl = window.location.search;
        if (paramUrl && paramUrl.length > 0 && paramUrl.indexOf("ref=")) {
            var url_string = window.location.href;
            var url = new URL(url_string);
            var sellerId = url.searchParams.get("ref");
            var today = new Date();
            var expiresRef = new Date();
            expiresRef.setDate(today.getDate() + 30);
            cookie.save(CONSTANTS.COOKIE_USER_REFERENCE, sellerId, { expires: expiresRef });
        }
    }, []);

    function _handleSignUp() {
        if (isValid()) {
            setIsBusy(true);
            setMsg("Đang lưu dữ liệu...");
            let sellerId = cookie.load(CONSTANTS.COOKIE_USER_REFERENCE);
            Request.Post("/api/accounts/register", { ...user, userReferencesId: sellerId ? sellerId.toString() : "" })
                .then((res) => {
                    if (res.success) {
                        setIsBusy(false);
                        setMsg(null);
                        setIsConfirmEmail(true);
                    } else {
                        Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                        setIsBusy(false);
                        setMsg(null);
                    }
                })
                .catch((err) => {
                    setIsBusy(false);
                    setMsg(null);
                    if (err.errors) displayError(err.errors);
                });
        }
    }

    function renderConfirmContent() {
        return (
            <WrapperConfirmContent>
                <section className="creator-background-account">
                    <div className="account-content-body">
                        <div className="content-body">
                            <div className="body-left">
                                <img className="background-body" atl="lotus" src={BackgroundLogin} />
                                <div className="header-creator cursor-pointer">
                                    <img
                                        alt="ranus"
                                        src={RanusCreatorLogo}
                                        onClick={() => {
                                            window.location.href = "/";
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="body-right">
                                <div className="account-content">
                                    <div className="col-right">
                                        <div className="main-account">
                                            <h4 className="login-header">Xác thực Email</h4>
                                            <div className="account-content">
                                                <p>Một email xác thực đã được gửi đến email của bạn. Vui lòng kiểm tra email và nhấp vào liên kết đi kèm để thực hiện việc xác thực!</p>
                                                <div className="form-input-action">
                                                    <div className="btn-action">
                                                        <a
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                props.history.push("/");
                                                            }}
                                                            className="btn-reset"
                                                        >
                                                            Trang chủ
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </WrapperConfirmContent>
        );
    }

    function renderSignUp() {
        return (
            <Wrapper>
                <section className="background-account">
                    <div className="body-left">
                        <img className="background-body" atl="lotus" src={BackgroundRegister} />
                        <div className="header-creator cursor-pointer">
                            <img
                                alt="ranus"
                                src={RanusCreatorLogo}
                                onClick={() => {
                                    window.location.href = "/";
                                }}
                            />
                        </div>
                    </div>
                    <div className="body-right">
                        <div className="signup">
                            <div className="main-account">
                                <h4 className="header-register">Đăng ký</h4>
                                <div className="body-register">
                                    <div className="form-input-control">
                                        <div className="col-6">
                                            <Textbox
                                                placeholder="Họ"
                                                id="firstName"
                                                name="firstName"
                                                required
                                                value={user.firstName}
                                                ref={(ref) => addRef(ref, { pattern: CONSTANTS.NAME_REGEX, invalidMsg: CONSTANTS.INVALID_NAME })}
                                                maxLength={255}
                                                className="form-control"
                                                onChanged={(name, value) => setUser({ ...user, firstName: value })}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <Textbox
                                                placeholder="Tên"
                                                id="lastName"
                                                name="lastName"
                                                required
                                                value={user.lastName}
                                                ref={(ref) => addRef(ref, { pattern: CONSTANTS.NAME_REGEX, invalidMsg: CONSTANTS.INVALID_NAME })}
                                                maxLength={255}
                                                className="form-control"
                                                onChanged={(name, value) => setUser({ ...user, lastName: value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-input-control">
                                        <div className="col-12">
                                            <PhoneNumber placeholder="Số điện thoại" id="phoneNumber" name="phoneNumber" addRef={addRef} required value={user.phoneNumber} onChanged={(name, value) => setUser({ ...user, phoneNumber: value })} />
                                        </div>
                                    </div>
                                    <div className="form-input-control">
                                        <div className="col-12">
                                            <Textbox
                                                placeholder="Email"
                                                id="email"
                                                name="email"
                                                value={user.email}
                                                required
                                                email
                                                ref={addRef}
                                                maxLength={255}
                                                className="form-control"
                                                onChanged={(name, value) => setUser({ ...user, email: value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-input-control">
                                        <div className="col-12 position-relative">
                                            <div className="register-input">
                                                <Textbox
                                                    placeholder="Mật khẩu"
                                                    id="password"
                                                    name="password"
                                                    value={user.password}
                                                    required
                                                    password={!showPassword}
                                                    ref={(ref) => addRef(ref, { pattern: CONSTANTS.REGEX_PASSWORD, invalidMsg: CONSTANTS.INVALID_PASSWORD })}
                                                    maxLength={255}
                                                    minLength={6}
                                                    className="form-control"
                                                    onChanged={(name, value) => setUser({ ...user, password: value })}
                                                />
                                                <div className="icon" onClick={() => setShowPassword(!showPassword)}>
                                                    {showPassword ? <EyeOn /> : <EyeOff />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-input-control">
                                        <div className="col-12 position-relative">
                                            <div className="register-input">
                                                <Textbox
                                                    placeholder="Nhập lại mật khẩu"
                                                    id="confirmPassword"
                                                    name="confirmPassword"
                                                    value={user.confirmPassword}
                                                    required
                                                    password={!showConfirmPass}
                                                    ref={(ref) => addRef(ref, { pattern: CONSTANTS.REGEX_PASSWORD, invalidMsg: CONSTANTS.INVALID_PASSWORD })}
                                                    maxLength={255}
                                                    minLength={6}
                                                    className="form-control"
                                                    onChanged={(name, value) => setUser({ ...user, confirmPassword: value })}
                                                />
                                                <div className="icon" onClick={() => setShowConfirmPass(!showConfirmPass)}>
                                                    {showConfirmPass ? <EyeOn /> : <EyeOff />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-input-control">
                                        <span className="btn-lotus btn-dark ml-2" onClick={(e) => _handleSignUp()}>
                                            Đăng ký
                                        </span>
                                    </div>
                                    <div className="form-group instruct">
                                        Bằng việc đăng ký, bạn đã đồng ý với Ranus về
                                        <a
                                            className="text-danger cursor-pointer"
                                            onClick={(e) => {
                                                window.open(`${RequestDomain.HomeUrl()}/html/TermOfService.html`, "_blank");
                                            }}
                                        >
                                            Điều khoản dịch vụ
                                        </a>{" "}
                                        &{" "}
                                        <a
                                            className="text-danger cursor-pointer"
                                            onClick={(e) => {
                                                window.open(`${RequestDomain.HomeUrl()}/html/PrivacyPolicy.html`, "_blank");
                                            }}
                                        >
                                            Chính sách bảo mật
                                        </a>
                                    </div>
                                    <div className="form-input-control-center">
                                        <span className="color_gray">Bạn đã có tài khoản?</span>
                                        <span
                                            className="font-weight-bold text-dark cursor-pointer"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                props.history.push("/login");
                                            }}
                                        >
                                            Đăng nhập ngay
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Wrapper>
        );
    }
    return isConfirmEmail ? renderConfirmContent() : renderSignUp();
}

const WrapperConfirmContent = styled.div`
    .account-content p {
        padding: 0 10px;
    }

    .content-body {
        display: flex;
    }

    .body-left {
        width: 50%;
        height: 100vh;
        position: relative;
        background: #fff5ec;
    }

    img.background-body {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .header-creator.cursor-pointer {
        position: absolute;
        z-index: 1;
        top: 61px;
        left: 55px;
    }

    .body-right {
        flex: 1;
        background: white;
        position: relative;
    }

    .main-account {
        width: 450px;
        display: flex;
        flex-direction: column;
        height: auto;
        position: absolute;
        top: calc(40% - 225px);
        margin-left: 80px;
    }

    h4.login-header {
        color: #303030;
        font-family: Lexend;
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
    }

    .form-group {
        margin-bottom: 24px;
        position: relative;
    }

    .form-group input {
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
        color: #303030 !important;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        padding: 11px 16px;
        height: auto;
        position: relative;
    }

    .login-input {
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
        width: 100%;
        padding: 11px 16px;
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .form-group-login {
        margin: 12px 0;
    }

    .icon {
        display: flex;
        position: absolute;
        top: 11px;
        right: 16px;
        width: 24px;
        height: 24px;
        align-items: center;
    }

    .form-area {
        position: relative;
    }

    button.btn-ranus {
        border-radius: 12px;
        background: var(--Cam-Thng-hiu, #ff9027);
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        border: none;
        padding: 12px;
    }

    .login-section {
        margin: 12px 0;
    }

    button.btn-forgot {
        border: none;
        background: none;
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 12px;
    }

    span.register-section {
        text-align: center;
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 12px 0;
    }

    button.btn-ranus-register {
        border: none;
        background: none;
        color: var(--Cam-Thng-hiu, #ff9027);
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 8px;
    }

    h4.login-header {
        padding: 0 10px;
    }

    .form-input-action input#email {
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
        padding: 11px 16px;
        height: auto;
    }

    .form-input-action {
        margin: 12px 0;
    }

    .btn-action {
        margin: 0 10px;
        border-radius: 12px;
        background: #ff9027;
        color: #fff !important;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        padding: 12px;
    }

    a.btn-reset {
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    p.text-muted {
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .p.text-muted a.text-dark {
        color: var(--Cam-Thng-hiu, #ff9027);
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .p.text-muted a {
    }

    p.text-muted b {
        color: #ff9027;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .form-input-action label.required {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;

const Wrapper = styled.div`
    .background-account {
        display: flex;
    }

    .body-left {
        width: 50%;
        position: relative;
        height: 100vh;
        background: #fff5ec;
    }

    img.background-body {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .header-creator {
        position: absolute;
        top: 61px;
        left: 55px;
    }

    .signup.container {
        width: 450px;
        display: flex;
        flex-direction: column;
        height: auto;
        position: absolute;
        top: calc(40% - 225px);
        margin-left: 80px;
    }

    h4.header-register {
        color: #303030;
        font-family: Lexend;
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        padding: 0 10px;
        margin-bottom: 40px;
    }

    .body-right {
        width: 50%;
        background: white;
    }

    .signup {
        width: 450px;
        display: flex;
        flex-direction: column;
        height: auto;
        position: absolute;
        top: calc(40% - 225px);
        margin-left: 80px;
    }

    .form-input-control {
        display: flex;
        align-items: center;
        margin: 12px 0;
    }

    .form-input-control input {
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
        padding: 11px 16px;
        height: auto;
        position: relative;
    }

    .input-group-prepend {
        height: auto;
    }

    .icon {
        display: flex;
        position: absolute;
        top: 11px;
        right: 24px;
        width: 24px;
        height: 24px;
        align-items: center;
    }

    span.btn-lotus.btn-dark.ml-2 {
        width: 100%;
        border-radius: 12px;
        background: var(--Cam-Thng-hiu, #ff9027);
        padding: 12px;
    }

    .form-group.instruct {
        padding: 0 10px;
    }

    .form-input-control-center {
        text-align: center;
        margin-top: 24px;
    }

    .form-input-control-center span.color_gray {
        color: #707070;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .form-input-control-center span.font-weight-bold.text-dark.cursor-pointer {
        color: #ff9027 !important;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 10px;
    }

    .form-group.instruct a.text-danger.cursor-pointer {
        color: #ff9027 !important;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 4px;
    }
`;

const EyeOn = () => {
    return (
        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 9C1 9 5 1 12 1C19 1 23 9 23 9C23 9 19 17 12 17C5 17 1 9 1 9Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const EyeOff = () => {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.6 15.4C7.15401 16.5846 9.04627 17.2408 11 17.2728C17.3636 17.2728 21 10 21 10C19.8692 7.89268 18.3008 6.05151 16.4 4.60005M12.9091 2.9455C12.2833 2.79903 11.6427 2.72581 11 2.72732C4.63636 2.72732 0.999999 10 0.999999 10C1.55184 11.0324 2.20995 12.0043 2.96364 12.9M9.07273 11.9273C9.32241 12.1953 9.6235 12.4102 9.95804 12.5592C10.2926 12.7083 10.6537 12.7885 11.0199 12.7949C11.3861 12.8014 11.7499 12.734 12.0894 12.5969C12.429 12.4597 12.7375 12.2555 12.9965 11.9965C13.2555 11.7376 13.4596 11.4291 13.5968 11.0895C13.734 10.7499 13.8013 10.3862 13.7949 10.02C13.7884 9.65377 13.7083 9.29263 13.5592 8.95809C13.4101 8.62355 13.1952 8.32245 12.9273 8.07277"
                stroke="#303030"
                stroke-width="1.8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M19.1816 1.81836L2.818 18.182" stroke="#303030" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
