import React, { useState } from 'react';
import TextNumber from '../../based/inputs/TextNumber';
import Loading from '../../based/Loading';
import Request from '../../based/Request';

function LastRevenue(props) {

    const [busy, setBusy] = useState(false);
    const [revenueActiveTabIndex, setRevenueActiveTabIndex] = useState(0);
    const [lastRevenues, setLastRevenues] = useState({});

    const _getProperty = (index, propName) => {
        return props.lastRevenues.length > index ? props.lastRevenues[index][propName] : 0
    }
    const _renderTableRevenue = () => {
        if (!props.lastRevenues)
            return null;
        return <React.Fragment>
            <Loading show={busy} />
            <table className='table table-bordered mb-0'>
                <thead>
                    <tr>
                        <th></th>
                        {
                            props.lastRevenues && props.lastRevenues.map((item, idx) => {
                                return <th className='text-center' key={idx}>{item.timeRange.fromDate} - {item.timeRange.toDate}</th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Tổng số áo bán thành công</td>{
                            props.lastRevenues.map((item, idx) => {
                                return <td className='text-right' key={idx}>{item.quantity}</td>
                            })
                        }
                    </tr>
                    <tr>
                        <td>Tổng số tiền bản quyền nhận được</td>{
                            props.lastRevenues.map((item, idx) => {
                                return <td className='text-right' key={idx}><TextNumber displayType='text' price value={item.totalLoyaltyAmount} /></td>
                            })
                        }
                    </tr>
                    <tr>
                        <td>Thuế TNCN của Creator</td>{
                            props.lastRevenues.map((item, idx) => {
                                return <td className='text-right' key={idx}><TextNumber displayType='text' price value={item.personalTax} /></td>
                            })
                        }
                    </tr>
                    <tr>
                        <td>Số tiền Creator nhận được sau Thuế TNCN</td>{
                            props.lastRevenues.map((item, idx) => {
                                return <td className='text-right' key={idx}><TextNumber displayType='text' price value={item.summaryLoyaltyAmount} /></td>
                            })
                        }
                    </tr>
                    <tr>
                        <td>Số tiền Lotus đã thanh toán</td>
                        {
                            props.lastRevenues.map((item, idx) => {
                                return <td className='text-right' key={idx}><TextNumber displayType='text' price value={item.paidAmount} /></td>
                            })
                        }
                    </tr>
                    <tr>
                        <td>Số tiền Lotus chưa thanh toán</td>
                        {
                            props.lastRevenues.map((item, idx) => {
                                return <td className='text-right' key={idx}><TextNumber displayType='text' price value={item.remainingAmount} /></td>
                            })
                        }
                    </tr>
                </tbody>
            </table>
        </React.Fragment>
    }

    return (
        <div className="row">
            <div className="col-12 bg-white">
                <h5>Doanh thu 6 tuần gần đây</h5>
                <div className='table-responsive'>
                    {_renderTableRevenue()}
                </div>
            </div>
        </div>
    );
}

export default LastRevenue;