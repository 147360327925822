import React, { useContext, useEffect, useState } from 'react'
import { INITPAGING, NOTIFY } from '../based/Constants'
import { Box, ButtonBlackRounded } from '../based/content/ct.elm'
import LoadingR from '../based/LoadingR'
import { Notify } from '../based/Notify'
import CreatorServices from '../based/services/CreatorServices'
import { ApplicationContext } from '../context/ApplicationContext'
import Breadcum from '../layout/refactor/Breadcum/Breadcum'
import CardCollection from './CardCollection'
const TAB_SCREEN = [
    {
        label: 'Đã hoàn thành',
        value: 1
    }, {
        label: 'Bản nháp',
        value: 2
    }]
const ManageCollections = () => {
    const [tabIndex, setTabIndex] = useState(1)
    const appCtx = useContext(ApplicationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState({ ...INITPAGING });
    const [collectionPaging, setCollectionPaging] = useState({ ...INITPAGING });
    const [projects, setProjects] = useState([]);
    const [autoProjects, setAutoProjects] = useState([]);

    useEffect(() => {
        getAutoProjectsByUserId();
    }, [])

    const getProjectsByUserId = async (agencyId) => {
        setIsLoading(true)
        const [err, data] = await CreatorServices.GetCollectionsByCreatorId(paging, agencyId);
        if (!err && data) {
            setIsLoading(false)
            setPaging(data.paging);
            setProjects(data.listObjects);
        } else {
            setIsLoading(false)
            setProjects([]);
        }
    };

    const getAutoProjectsByUserId = async () => {
        setIsLoading(true)
        const [err, data] = await CreatorServices.GetListCollection(collectionPaging);
        if (!err && data) {
            setIsLoading(false)
            setCollectionPaging(data);
            setAutoProjects(data);
        } else {
            setIsLoading(false)
            setAutoProjects([]);
        }
    }
    const handleDeleteCollection = async (project) => {
        setIsLoading(true)
        const [err, data] = await CreatorServices.DeleteCollection(project.id)
        if (!err && data) {
            setIsLoading(false)
            const userId = appCtx.applicationInfo.userInfo.id;
            if (userId) {
                getProjectsByUserId(userId);
            }
            Notify(NOTIFY.SUCCESS, "Xóa thành công", `Xóa bộ sưu tập ${project.name}!`);
        }
    }

    const handleDeleteAutoCollection = async (autoProject) => {
        setIsLoading(true)
        const [err, data] = await CreatorServices.DeleteAutoCollection(autoProject.id)
        if (!err && data) {
            setIsLoading(false);
            Notify(NOTIFY.SUCCESS, "Xóa thành công", `Xóa bộ sưu tập ${autoProject.name}!`);
            window.location.reload();
        } 
    }

    const renderCollectionsHandler = () => {
        if (projects && projects.length > 0) return projects.map((project) => (
            <CardCollection key={project.id} project={project} isDone={tabIndex === 1 ? true : false} onDelete={() => handleDeleteCollection(project)} />
        ));
    };

    const renderAutoCollectionsHandler = () => {
        if (autoProjects && autoProjects.length > 0) return autoProjects.map((autoProject) => (
            <CardCollection key={autoProject.id} autoProject={autoProject} isDone={tabIndex === 1 ? true : false} onDelete={() => handleDeleteAutoCollection(autoProject)} />
        ));
    };
    const _handleChangeTab = () => {

    }
    const _renderTab = () => {
        return TAB_SCREEN.map((item, index) => (
            <ButtonBlackRounded key={index} className={tabIndex === item.value ? "active" : ""} onClick={() => _handleChangeTab(item.value)}>
                <span>{item.label}</span>
            </ButtonBlackRounded>
        ))
    }
    return (
        <React.Fragment>
            <Breadcum menu={['Trang Chủ', 'Quản lý bộ sưu tập']} title='Quản lý bộ sưu tập' />
            <LoadingR fullScreen isShow={isLoading} height='100vh' />
            <Box>
                <div className='box__header'>
                    {_renderTab()}
                </div>
                <div className='box__content flex-wrap'>
                    <CardCollection addNewAuto />
                    {renderAutoCollectionsHandler()}
                </div>
            </Box>
        </React.Fragment>
    )
}

export default ManageCollections
