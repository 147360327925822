import React, { useEffect, useState } from "react";
import Common from "../based/Common";
import { CONSTANTS, NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import PrintServices from "../based/services/PrintServices";
import VoucherServices from "../based/services/VoucherServices";
import useFormValidate from "../hooks/useFormValidate";
import RateCardConfigModal from "./RateCardConfigModal";
import Textbox from "../based/refactor/Textbox";
import TextNumber from "../based/refactor/TextNumber";
import CommonModal from "../based/refactor/CommonModal";
import TextArea from "../based/refactor/TextArea";
import { Box, BtnRounded } from "../based/content/ct.elm";
import { CARD_COLOR, PROJECT_EDITOR_MODAL_TYPE as EMBED_MODAL_TYPE } from '../based/Constants';
import BaseModal from "../based/refactor/BaseModal";

export default function QuickAddOrEdit(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [voucher, setVoucher] = useState({});
    const { addRef, displayError, isValid } = useFormValidate();
    const [showRateCard, setShowRateCard] = useState(false);
    const [readRateCard, setReadRateCard] = useState([]);
    const [codeValid, setCodeValid] = useState({ status: 1, message: "" });

    useEffect(() => {
        setVoucher(props.voucher);
        getReadRateCard();
    }, [props.isShowModal]);

    /** lấy thông tin ratecard */
    async function getReadRateCard() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await PrintServices.ReadRateCard();
        if (!error && data) {
            setReadRateCard(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setReadRateCard([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    /** tiến hành save */
    async function _handleSave(isContinue) {
        if (isValid() && codeValid.status === 1) {
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu..");
            let [err, data] = await VoucherServices.QuickSaveVoucherGift(voucher);
            if (!err) {
                setIsLoading(null);
                setMsg(false);
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                props.onSaveSucceed(isContinue);
                props.onClose();
            } else {
                setIsLoading(null);
                setMsg(false);
                if (err.errors) displayError(err.errors);
                else Notify(NOTIFY.ERROR, "Lỗi", err.data);
            }
        }
    }

    /** set số tiền */
    function setAmount(amount) {
        let lotusChargeAmount = Common.SetLotusChargeAmount(amount, readRateCard);
        setVoucher({ ...voucher, loyaltyAmount: amount, lotusChargeAmount: lotusChargeAmount });
    }
    const _handleOnSave = () => {
        _handleSave(false);
    };
    const _handleSaveAndClose = () => {
        _handleSave(true);
    };
    const _handleOnClose = () => {
        props.onClose();
    };
    const closeOpenMockupModalHandler = () => {
        props.onClose();
    };
    return (
        <BaseModal className='modal-width-40' title='Thêm nhanh' isShow={props.isShowModal}
            onClose={() => props.onClose()}
            onSave={_handleSave}
            >
            {showRateCard && <RateCardConfigModal showRateCard={showRateCard} readRateCard={readRateCard} onClose={() => setShowRateCard(false)} />}
            <Box>
                <div className="box__card">
                    {showRateCard && <RateCardConfigModal showRateCard={showRateCard} readRateCard={readRateCard} onClose={() => setShowRateCard(false)} />}
                    <div className='form-group'>
                        <div className='label__group'>
                            <label htmlFor='code' className='required'>
                                Mã Voucher:
                            </label>
                            <div className='suggestions .ext-info'>
                                <i className='fa fa-exclamation-circle' aria-hidden='true'></i>&nbsp;
                            </div>
                        </div>
                        <Textbox name='code' required maxLength={200} className={`form-control font-weight-bold`} readOnly value={"AutoGenerate"} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='name' className='required'>
                            Tên Voucher:
                        </label>
                        <Textbox name='name' required ref={addRef} maxLength={200} className='form-control' onChanged={(name, value) => setVoucher({ ...voucher, name: value })} value={voucher.name} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='quickQuantityVoucherCreate'>Số Voucher cần tạo:</label>
                        <TextNumber
                            id='quickQuantityVoucherCreate'
                            ref={addRef}
                            name='quickQuantityVoucherCreate'
                            className='form-control'
                            max={999999}
                            value={voucher.quickQuantityVoucherCreate}
                            onChanged={(name, value) => setVoucher({ ...voucher, quickQuantityVoucherCreate: +value })}
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='appliedQuantity'>Số lượng SP áp dụng:</label>
                        <TextNumber id='appliedQuantity' name='appliedQuantity' ref={addRef} className='form-control' max={999999} value={1} readOnly />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='loyaltyAmount' className='required'>
                            Số tiền bản quyền <i className='fa fa-info-circle' onClick={(e) => setShowRateCard(true)} style={{ color: "firebrick", cursor: "pointer" }}></i>:
                        </label>
                        <TextNumber required ref={addRef} id='loyaltyAmount' suffix='đồng' name='loyaltyAmount' className='form-control' value={voucher.loyaltyAmount} onChanged={(name, value) => setAmount(value)} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='lotusChargeAmount'>Phí hoạt động Ranus:</label>
                        <TextNumber ref={addRef} id='lotusChargeAmount' suffix='đồng' name='lotusChargeAmount' className='form-control' readOnly value={voucher.lotusChargeAmount} onChanged={(name, value) => { }} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='description'>Mô tả:</label>
                        <TextArea ref={addRef} name='description' value={voucher.description} onChanged={(name, value) => setVoucher({ ...voucher, description: value })} />
                    </div>
                </div>
            </Box>
        </BaseModal>
    );
}
