import React, { useEffect, useState } from "react";
import NumberFormat from "react-currency-format";
import Common from "../based/Common";
import { ARTWORK_STATUS, ARTWORK_STATUS_OPTIONS, NOTIFY } from "../based/Constants";
import { Block, Box, ButtonBlackRounded } from "../based/content/ct.elm";
import { ConfirmDialog } from "../based/Dialog";
import TFUSelect from "../based/inputs/TFUSelect";
import { Notify } from "../based/Notify";
import Button, { ButtonType } from "../based/refactor/Button";
import CommonTable from "../based/refactor/CommonTable";
import Textbox from "../based/refactor/Textbox";
import Request from "../based/Request";
import ArtworkServices from "../based/services/ArtworkServices";
import Breadcum from "../layout/refactor/Breadcum/Breadcum";

const ViolateProduct = () => {
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState({ ...Common.Paging, status: ARTWORK_STATUS.Cancelled, orderBy: "id", orderDirection: "desc" });
    const [datas, setDatas] = useState([]);
    const [artworkQuota, setArtworkQuota] = useState({});
    const [tabIndex, setTabIndex] = useState(ARTWORK_STATUS.Cancelled);
    const [setId, setSetId] = useState(0);
    const [artworkCurrent, setArtworkCurrent] = useState({});
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    useEffect(() => {
        getDatas(paging, tabIndex);
        getArtworkQuota();
    }, []);

    //lấy thông tin artwork
    const getArtworkQuota = async () => {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await ArtworkServices.GetArtworkQuota();
        if (!error && data) {
            setArtworkQuota(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setArtworkQuota({});
            setIsLoading(false);
            setMsg(null);
        }
    };

    /** Lấy dữ liệu của set */
    const getDatas = async (newPaging, tab) => {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await ArtworkServices.GetSetPaging(newPaging, newPaging.status, tab);
        if (!error && data) {
            let pagingUp = { ...data.paging, status: newPaging.status };
            setDatas(data.listObjects);
            setPaging(pagingUp);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    };
    /** Xoá set */
    const handleDelete = (item) => {
        let id = item.id;
        ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa set sản phẩm này", () => {
            return new Promise(async (resolve, reject) => {
                setIsLoading(true);
                setMsg("Đang xóa dữ liệu...");
                let [error, data] = await ArtworkServices.DeleteSet(id);
                if (!error && data) {
                    setIsLoading(false);
                    setMsg(null);
                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                    getDatas(paging, tabIndex);
                    getArtworkQuota();
                } else {
                    setIsLoading(false);
                    setMsg(null);
                    Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                }
            });
        });
    };
    const handleOpenPdp = (e, item) => {
        e.preventDefault();
        window.open(`${Request.HOME_URL}/product-detail-page/${item.ageSetId}/${item.ageVariantId}`, "_blank");
    };
    const handleDisplayModal = () => {
        setIsModal(!isModal);
    };
    const renderHeaderTableTierInfo = () => {
        return (
            <React.Fragment>
                <tr>
                    <th colSpan={2}>
                        <span>
                            Creator FIFO: {artworkQuota ? artworkQuota.tierName : ""} - Tổng sản phẩm đã bán: {artworkQuota ? artworkQuota.totalSell : ""}
                        </span>
                    </th>
                </tr>
                <tr>
                    <th className="text-left">  </th>
                    <th className="text-left">Vi phạm</th>
                </tr>
            </React.Fragment>
        );
    };
    const renderBodyTableTierInfo = () => {
        if (artworkQuota)
            return (
                <React.Fragment>
                    <tr>
                        <td>Số lượng thiết kế &#60; 25MB</td>
                        <td>{artworkQuota.totalArtworkUnder25Used}</td>
                    </tr>
                    <tr>
                        <td>Số lượng thiết kế &#60; 50MB</td>
                        <td>{artworkQuota.totalArtworkUnder50}</td>
                    </tr>
                    <tr>
                        <td>Số lượng thiết kế &#60; 100MB</td>
                        <td>{artworkQuota.totalArtworkUnder100Used}</td>
                    </tr>
                </React.Fragment>
            );
        return null;
    };
    const renderMainTableHeader = () => {
        return (
            <tr>
                <th>
                    STT &nbsp;
                    {/* {paging.orderBy === "id" && paging.orderDirection.toUpperCase() === "DESC" ? <i className='fa fa-sort-desc fa-sort-thead'></i> : paging.orderBy === "id" ? <i className='fa fa-sort-asc fa-sort-thead'></i> : icon} */}
                </th>
                <th>Tên set thiết kế</th>
                <th>
                    Trạng thái
                </th>
                <th>
                    Thumbnail Thiết kế
                </th>
                <th>Thông tin thiết kế</th>
                <th>Ghi chú</th>
                <th>Trạng thái kiểm duyệt</th>
                <th></th>
            </tr>
        );
    };
    const renderMainTableBody = () => {
        let content = [];
        if (datas && datas.length > 0) {
            datas.map((item, k) => {
                let rowSpan = (item.artworks && item.artworks.length) > 0 ? item.artworks.length : 1;
                let count = 0;
                let disableBtn = item.status == ARTWORK_STATUS.Cancelled || item.status == ARTWORK_STATUS.Waiting ? true : false;
                if (item.artworks && item.artworks.length > 0) {
                    item.artworks.map((art, a) => {
                        count = count + 1;
                        let arr = [];
                        let no = paging.pageSize * (paging.pageNumber - 1) + k + 1;
                        if (count <= 1) {
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle text-center'>
                                    {no}
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle'>
                                    {item.title}
                                </td>
                            );
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle text-center'>
                                    {item.statusName}
                                </td>
                            );
                        }
                        arr.push(
                            <td className='vertical-align-middle text-center'>
                                {art.thumbnail ? (
                                    <a className='iconImageDefault full-width' href='#' onClick={(e) => handleOpenPdp(e, art)}>
                                        <img src={art.thumbnail} alt='Thumbnail Thiết kế' className='variant-avatar' />
                                    </a>
                                ) : (
                                    <strong>Thiết kế đang được xử lý...</strong>
                                )}
                            </td>
                        );
                        arr.push(
                            <td className='vertical-align-middle'>
                                <p className='mb-0'>
                                    <span className='m-r-5'>Kích thước in:</span>
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} suffix=' cm' value={art.widthCm} /> x{" "}
                                    <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} suffix=' cm' value={art.heightCm} />
                                </p>
                                <p className='mb-0'>
                                    <span className='m-r-5'>Kích thước ảnh:</span> {art.width} x {art.height} pixel
                                </p>
                                <p className='mb-0'>
                                    <span className='m-r-5'>{art.dpi} DPi</span>
                                </p>
                                <p className='mb-0'>
                                    <span className='m-r-5'>Dung lượng:</span> {art.size}MB
                                </p>
                            </td>
                        )
                        arr.push(<td rowSpan={rowSpan} className='vertical-align-middle'>{art.remark}</td>)
                        arr.push(
                            <td rowSpan={rowSpan} className='vertical-align-middle text-center'>
                                <div class={`rounded text-white status label-warning`}>{item.manualReviewStatusName}</div>
                            </td>
                        );
                        if (count <= 1) {
                            arr.push(
                                <td rowSpan={rowSpan} className='vertical-align-middle text-center'>
                                    <React.Fragment>
                                        &nbsp;<i title='Xóa' className='mdi mdi-24px mdi-delete text-danger cursor-pointer' onClick={() => handleDelete(item)}></i>
                                    </React.Fragment>
                                </td>
                            );
                        }

                        content.push(
                            <tr key={k + "_" + a}>
                                {arr.map((ar, iiii) => {
                                    return <React.Fragment key={iiii}>{ar}</React.Fragment>;
                                })}
                            </tr>
                        );
                    });
                }
            });
            return content;
        } else {
            return (
                <tr>
                    <td colSpan='100' className='vertical-align-middle text-center'>
                        Không có dữ liệu
                    </td>
                </tr>
            );
        }
    };
    return (
        <React.Fragment>
            <Breadcum title='Quản lý vi phạm' />
            <Box>
                <div className='box__content'>
                    <Block flexBasis='39%' style={{ marginTop: "20px" }} height='206px'>
                        <div className='flex__space'>
                            <div className='flex__column'>
                                <label>Tên set thiết kế:</label>
                                <Textbox
                                    placeholder='Tên set thiết kế'
                                    className={"w-90"}
                                    value={paging.keyword}
                                    onChanged={(name, value) => {
                                        setPaging({ ...paging, keyword: value });
                                    }}
                                    handleEnter={() => getDatas({ ...paging }, tabIndex)}
                                />
                            </div>
                        </div>
                        <div className='flex__row__start mt-10 w-30'>
                            <Button btnType={ButtonType.Search} label='Tìm kiếm' className='mx-1' onClick={() => getDatas({ ...paging }, tabIndex)} />

                        </div>
                    </Block>
                    <Block flexBasis='59%' boxShadow='none'>
                        <CommonTable renderHeader={renderHeaderTableTierInfo} renderBody={renderBodyTableTierInfo} />
                    </Block>
                </div>
                <Box boxShadow='none'>
                    <CommonTable renderHeader={renderMainTableHeader} renderBody={renderMainTableBody} paging={paging} onFilter={(paging) => getDatas({ ...paging }, tabIndex)} itemToDisplay={3} />
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default ViolateProduct;
