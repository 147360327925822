import Request from '../../../based/Request';
import { actionTypes } from '../types';

// Add Image
const addImageBegin = () => ({
  type: actionTypes.PROJECT_EDITOR_ADD_IMAGE_BEGIN,
  payload: null,
});

const addImageSuccess = (imgUrl) => ({
  type: actionTypes.PROJECT_EDITOR_ADD_IMAGE_SUCCESS,
  payload: imgUrl,
});

const addImageFail = (err) => ({
  type: actionTypes.PROJECT_EDITOR_ADD_IMAGE_FAIL,
  payload: err,
});

// Remove Image
const removeImageBegin = () => ({
  type: actionTypes.PROJECT_EDITOR_REMOVE_IMAGE_BEGIN,
  payload: null,
});

const removeImageSuccess = (itemId) => ({
  type: actionTypes.PROJECT_EDITOR_REMOVE_IMAGE_SUCCESS,
  payload: itemId,
});

const removeImageFail = (err) => ({
  type: actionTypes.PROJECT_EDITOR_REMOVE_IMAGE_FAIL,
  payload: err,
});

// Replace Image
const replaceImageBegin = () => ({
  type: actionTypes.PROJECT_EDITOR_REPLACE_IMAGE_BEGIN,
  payload: null,
});

const replaceImageSuccess = (newUrl, oldItemId) => ({
  type: actionTypes.PROJECT_EDITOR_REPLACE_IMAGE_SUCCESS,
  payload: {
    newUrl,
    oldItemId,
  },
});

const replaceImageFail = (err) => ({
  type: actionTypes.PROJECT_EDITOR_REPLACE_IMAGE_FAIL,
  payload: err,
});

// Insert Image
const insertImageBegin = () => ({
  type: actionTypes.PROJECT_EDITOR_INSERT_IMAGE_BEGIN,
  payload: null,
});

const insertImageSuccess = (insertImgUrl, itemId) => ({
  type: actionTypes.PROJECT_EDITOR_INSERT_IMAGE_SUCCESS,
  payload: {
    insertImgUrl,
    itemId,
  },
});

const insertImageFail = (err) => ({
  type: actionTypes.PROJECT_EDITOR_INSERT_IMAGE_FAIL,
  payload: err,
});

export const addImage = (imgFile) => {
  return (dispatch) => {
    dispatch(addImageBegin());

    Request.UploadImage(imgFile)
      .then((res) => {
        dispatch(addImageSuccess(res.data.imageUrl));
      })
      .catch((err) => {
        dispatch(addImageFail(err));
      });
  };
};

export const removeImage = ({ id, url }) => {
  const fileName = url.split('/').pop();

  return (dispatch) => {
    dispatch(removeImageBegin());

    Request.DeleteImage(fileName)
      .then((res) => {
        const { success, errors } = res;
        if (success) dispatch(removeImageSuccess(id));
        else dispatch(removeImageFail(errors));
      })
      .catch((err) => {
        dispatch(removeImageFail(err));
      });
  };
};

export const replaceImage = (newImgFile, oldItem) => {
  const { id, url } = oldItem;
  const fileName = url.split('/').pop();

  return (dispatch) => {
    dispatch(replaceImageBegin());

    // Replace Image
    Request.UploadImage(newImgFile)
      .then((res) => {
        if (res.success) {
          dispatch(replaceImageSuccess(res.data.imageUrl, id));
          // Remove Old Image
          Request.DeleteImage(fileName).catch((err) => {
            dispatch(replaceImageFail(err));
          });
        }
      })
      .catch((err) => {
        dispatch(replaceImageFail(err));
      });
  };
};

export const insertImage = (insertImgFile, itemId) => {
  return (dispatch) => {
    dispatch(insertImageBegin());

    Request.UploadImage(insertImgFile)
      .then((res) => {
        dispatch(insertImageSuccess(res.data.imageUrl, itemId));
      })
      .catch((err) => {
        dispatch(insertImageFail(err));
      });
  };
};
