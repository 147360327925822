import { actionTypes } from '../types';

export const addText = () => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_ADD_TEXT,
      payload: null,
    };
    dispatch(action);
  };
};

export const removeText = (itemId) => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_REMOVE_TEXT,
      payload: itemId,
    };
    dispatch(action);
  };
};

export const insertText = (itemId) => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_INSERT_TEXT,
      payload: itemId,
    };
    dispatch(action);
  };
};

export const setText = ({ id, value }) => {
  return (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_SET_TEXT,
      payload: { id, value },
    };
    dispatch(action);
  };
};
