import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import ImagePicker from "./ImagePicker";

// Redux
import { removeImage, replaceImage, toggleFullWidth } from "../../redux/project-editor/actions";

const ProjectImage = ({ id, url, contentWidth, isFullWidth, isLast, removeImage, replaceImage, toggleFullWidth }) => {
    const imgRef = useRef(null);
    const itemRef = useRef(null);
    const [isActive, setIsActive] = useState(false);
    const [actualWidth, setActualWidth] = useState(0);

    useEffect(() => {
        if (itemRef.current && isLast) {
            itemRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, []);

    useEffect(() => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
            setActualWidth(img.width);
        };
    }, [url]);

    useEffect(() => {
        if (actualWidth) renderImageHandler();
    }, [actualWidth, isFullWidth]);

    const pickImageHandler = () => {
        if (imgRef.current) imgRef.current.click();
    };

    const replaceImageHandler = (imgFile) => {
        replaceImage(imgFile, { id, url });
    };

    const removeImageHandler = () => {
        removeImage({ id, url });
    };

    const toggleFullWidthHandler = () => {
        if (actualWidth > contentWidth) toggleFullWidth(id);
    };

    const renderImageHandler = () => {
        console.log("renderImageHandler");
        let className = "";
        if (actualWidth > contentWidth) className = "w-100";
        return <img src={url} alt='image' className={className} />;
    };

    return (
        <div ref={itemRef} className='project-image' onMouseEnter={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)}>
            <div className='row align-items-center justify-content-center px-0 mx-0'>
                <div className={`px-0 ${isFullWidth ? "col-12" : "col-10"}`}>
                    {/* Dropdown Button */}
                    <div className={`project-image__dropdown d-inline-block ${isActive ? "show" : "hide"}`}>
                        <ImagePicker imgRef={imgRef} onChange={replaceImageHandler} disablePreview/>
                        <Dropdown className='shadow rounded-circle'>
                            <Dropdown.Toggle className='rounded-circle btn-sm' variant='primary' />
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={pickImageHandler}>Đổi ảnh</Dropdown.Item>
                                <Dropdown.Item onClick={removeImageHandler}>Xóa ảnh</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    {/* Toggle Button */}
                    <div className={`project-image__resize border-rounded bg-dark rounded cursor-pointer shadow ${isActive ? "show" : "hide"}`} onClick={toggleFullWidthHandler}>
                        <i className='fa fa-arrows-h px-2 py-1 text-white' />
                    </div>

                    {/* Image */}
                    <div id={`wrapper-image-${id}`} className='project-image__img d-flex align-items-center justify-content-center'>
                        {renderImageHandler()}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    removeImage: ({ id, url }) => {
        return dispatch(removeImage({ id, url }));
    },
    replaceImage: (newImgFile, oldItem) => {
        return dispatch(replaceImage(newImgFile, oldItem));
    },
    toggleFullWidth: (itemId) => {
        return dispatch(toggleFullWidth(itemId));
    },
});

export default connect(null, mapDispatchToProps)(ProjectImage);
