import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import CommonModal from "../based/Modal";
import PrintServices from "../based/services/PrintServices";
import NumberFormat from "react-currency-format";
import TextNumber from "../based/inputs/TextNumber";
import useFormValidate from "../hooks/useFormValidate";
import ArtworkServices from "../based/services/ArtworkServices";
import { ConfirmDialog } from "../based/Dialog";
import { Notify } from "../based/Notify";
import { NOTIFY } from "../based/Constants";
import BaseModal from "../based/refactor/BaseModal";
import { Box } from "../based/content/ct.elm";
import { BtnRounded } from "../based/content/ct.elm";
import { CARD_COLOR, PROJECT_EDITOR_MODAL_TYPE as EMBED_MODAL_TYPE } from '../based/Constants';

function AddEditLoyalty(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [readRateCard, setReadRateCard] = useState([]);
    const { setId, readOnly } = props;
    const { addRef, displayError, isValid } = useFormValidate();
    const [artwork, setArtwork] = useState({});

    useEffect(() => {
        if (setId > 0) {
            getReadRateCard();
            getDatas(setId);
        }
    }, [setId]);

    /** lấy table rate card */
    async function getReadRateCard() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await PrintServices.ReadRateCard();
        if (!error && data) {
            setReadRateCard(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setReadRateCard([]);
            setIsLoading(false);
            setMsg(null);
        }
    }

    /** lấy thông tin của set */
    async function getDatas(id) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await ArtworkServices.GetInfoToComplement(id);
        if (!error && data) {
            setArtwork({ ...data, totalLoyaltyPrice: data.loyaltyAmount - data.lotusChargeAmount });
            setIsLoading(false);
            setMsg(null);
        } else {
            setArtwork({});
            setIsLoading(false);
            setMsg(null);
        }
    }

    /** save thông tin artwork */
    async function handleSave() {
        setIsLoading(true);
        setMsg("Đang lưu dữ liệu...");
        let [error, data] = await ArtworkServices.AdditionalInfo(artwork);
        if (!error && data) {
            setIsLoading(false);
            setMsg(null);
            props.onSave();
            Notify(NOTIFY.SUCCESS, "Thành công", "Cập nhật thông tin thành công");
        } else {
            setIsLoading(false);
            setMsg(null);
            Notify(NOTIFY.ERROR, "Lỗi", error.data);
        }
    }

    /** render table rate card */
    const RateCardConfig = useMemo(() => (
        <div className='table-responsive m-b-10'>
            <table className='table table-bordered mb-0'>
                <thead>
                    <tr style={{ background: "#e4e4e4" }}>
                        <th className='text-center' width='50'>
                            Mức Charge
                        </th>
                        <th className='text-center vertical-align-middle'>Mức tiền bản quyền</th>
                        <th className='text-center vertical-align-middle'>Ranus Operation Fee (%)</th>
                    </tr>
                </thead>
                <tbody>
                    {readRateCard && readRateCard.length > 0 ? (
                        readRateCard.map((item, k) => {
                            return (
                                <tr key={k}>
                                    <td className='text-center'>{k + 1}</td>
                                    <td className='text-center'>
                                        <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} value={item.loyaltyAmount} />
                                    </td>
                                    <td className='text-center'>{item.lotusChargePercent}</td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan='3'>
                                <span>Chưa có cấu hình</span>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    ));

    /** thay đổi tiền bản quyền */
    function setAmount(amount) {
        if (amount > 0 && readRateCard && readRateCard.length > 0) {
            let total = 0;
            let tmp = 0;
            for (let index = 0; index < readRateCard.length; index++) {
                if (amount >= readRateCard[index].loyaltyAmount) {
                    total += (readRateCard[index].loyaltyAmount - tmp) * (1 - readRateCard[index].lotusChargePercent * 0.01);
                    tmp = readRateCard[index].loyaltyAmount;
                } else {
                    total += (amount - tmp) * (1 - readRateCard[index].lotusChargePercent * 0.01);
                    break;
                }
            }
            setArtwork({ ...artwork, totalLoyaltyPrice: total, lotusChargeAmount: amount - total, loyaltyAmount: amount });
        } else {
            setArtwork({ ...artwork, totalLoyaltyPrice: 0, lotusChargeAmount: 0, loyaltyAmount: 0 });
        }
    }

    return (
        <BaseModal className='modal-width-40' title='Thay đổi tiền bản quyền' isShow={props.isShow}
            onClose={() => props.onClose()}
            onSave={handleSave}
            >
            <Box>
                <div className="box__card">
                    <div className='col-md-12'>
                        <div className='form-group col-md-12'>
                            <label className='col-form-label' htmlFor='config'>
                                Thông tin cấu hình mức Charge tiền bản quyền
                            </label>
                            {RateCardConfig}
                        </div>
                        <div className='form-group col-md-12'>
                            <label className='col-form-label required' htmlFor='loyaltyAmount'>
                                Tiền bản quyền
                            </label>
                            <TextNumber readOnly={readOnly} required ref={addRef} name='loyaltyAmount' className='form-control' suffix=' đ' value={artwork.loyaltyAmount} min={0} max={999999999} onChanged={(name, value) => setAmount(value)} />
                        </div>
                        <div className='form-group col-md-12'>
                            <label className='col-form-label' htmlFor='lotusChargeAmount'>
                                Tiền Ranus Operation Fee
                            </label>
                            <NumberFormat thousandSeparator=',' displayType={"text"} decimalSeparator='.' decimalScale={2} suffix=' đ' value={artwork.lotusChargeAmount} className='form-control' style={{ background: "#e9ecef" }} />
                        </div>
                        <div className='form-group col-md-12'>
                            <label className='col-form-label' htmlFor='totalLoyaltyPrice'>
                                Tiền bản quyền thực lĩnh
                            </label>
                            <NumberFormat
                                thousandSeparator=','
                                displayType={"text"}
                                decimalSeparator='.'
                                decimalScale={2}
                                suffix=' đ'
                                value={artwork.totalLoyaltyPrice > 0 ? artwork.totalLoyaltyPrice : 0}
                                className='form-control'
                                style={{ background: "#e9ecef" }}
                            />
                        </div>
                    </div>
                </div>
            </Box>
        </BaseModal>
    );
}

AddEditLoyalty.propTypes = {};

export default AddEditLoyalty;
