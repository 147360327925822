import React, { Component } from "react";

class TFUErrorBoundaries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            info: null,
        };
    }
    static getDerivedStateFromError(error) {
        return { error: true };
    }

    componentDidCatch(error, info) {
        console.error("Component xảy ra lỗi:", info.componentStack);
        this.setState({
            error: error,
            info: info,
        });
    }
    render() {
        if (this.state.error) {
            return (
                <React.Fragment>
                    <h5>Đã có lỗi xảy ra!</h5>
                    <details style={{ whiteSpace: "pre-wrap" }}>{this.state.info.componentStack}</details>
                </React.Fragment>
            );
        }
        return this.props.children;
    }
}
export default TFUErrorBoundaries;
