import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { INITPAGING, NOTIFY } from "../../based/Constants";
import TopicServices from "../../based/services/TopicServices";
import BaseModal from "../../based/refactor/BaseModal";
import Textbox from "../../based/inputs/Textbox";
import { Notify } from "../../based/Notify";

export function DesignType(props) {
    const [popular, setPopular] = useState([]);
    const [listData, setListData] = useState([]);
    const [pagingData, setPagingData] = useState({ ...INITPAGING });
    const [isLoading, setIsLoading] = useState(false);
    const [keyword, setKeyword] = useState("");
    const { topics, setTopics } = props;
    let timer = null;

    useEffect(() => {
        getTopTopic();
        getAllTopic({ ...INITPAGING, pageSize: 20 });
    }, []);

    async function getTopTopic() {
        setIsLoading(true);
        let [err, data] = await TopicServices.GetTop10Topic();
        if (!err && data && data.length > 0) setPopular(data);
        else setPopular([]);
        setIsLoading(false);
    }

    async function getAllTopic(paging) {
        setIsLoading(true);
        let [err, data] = await TopicServices.GetTopics(paging);
        if (!err && data && data.listObjects) setListData(data.listObjects);
        else setListData([]);
        setIsLoading(false);
    }

    async function _fetchKeyword(keyword) {
        setKeyword(keyword);
        clearTimeout(timer);
        timer = setTimeout(() => {
            let paging = { ...INITPAGING, pageNumber: 1, pageSize: 30, keyword };
            getAllTopic(paging);
        }, 500);
    }

    async function _onClickTopicName(name, value) {
        let topic = topics;
        //nếu true, add vào topic
        //tối đa được 3 tag
        if (value) {
            let lengthTopic = topic.length;
            if (lengthTopic >= 2) Notify(NOTIFY.INFO, NOTIFY.INFO, "Chọn tối đa 2 phong cách thiết kế");
            else {
                topic.push(name);
                setTopics(topic);
            }
        }
        //nếu false, remove ra khỏi topic
        else {
            let indexOf = topic.indexOf(name);
            if (indexOf >= 0) topic.splice(indexOf, 1);
            setTopics(topic);
        }
    }

    return (
        <BaseModal isShow={props.isModal} title="Chọn phong cách thiết kế (Tối đa 2)" onClose={props.onClose} width={"564px"}>
            <Wrapper>
                <div className="form-row col-md-12">
                    <div className="col-md-4">
                        <label className="col-form-label artwork-info-popular-label">Phổ biến</label>
                        <div className="artwork-info-popular-topic">
                            {popular.map((item, idx) => {
                                let isActive = topics ? topics.includes(item.topicName) : false;
                                return (
                                    <span
                                        key={idx}
                                        className={`cursor-pointer topic-item ${isActive ? "active" : ""}`}
                                        onClick={(e) => {
                                            _onClickTopicName(item.topicName, !isActive);
                                        }}
                                    >
                                        {item.topicName}
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-md-8 position-relative all-topic">
                        <div className="d-flex">
                            <label className="col-form-label artwork-info-all-label">Tất cả</label>
                            <Textbox name="keyword" value={keyword} onChanged={(name, value) => _fetchKeyword(value)} placeholder={"Tìm kiếm"} className="form-control w-50" />
                        </div>
                        <div className="artwork-info-all-topic">
                            {listData.map((item, idx) => {
                                let isActive = topics ? topics.includes(item.topicName) : false;
                                return (
                                    <span
                                        className={`cursor-pointer topic-item ${isActive ? "active" : ""}`}
                                        key={idx}
                                        onClick={(e) => {
                                            _onClickTopicName(item.topicName, !isActive);
                                        }}
                                    >
                                        {item.topicName}
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Wrapper>
        </BaseModal>
    );
}

const Wrapper = styled.div`
    width: 100%;
    label.col-form-label.artwork-info-popular-label {
        color: #303030;
        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    span.cursor-pointer.topic-item {
        color: #303030;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px; /* 250% */
        padding: 5px 10px;
        margin: 2px 0;
    }

    span.cursor-pointer.topic-item.active {
        color: #ff9027;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
        border-radius: 8px;
        background: #fff5ec;
        border: none;
    }

    label.col-form-label.artwork-info-all-label {
        color: #303030;
        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    input.form-control.w-50 {
        border-radius: 8px;
        border: 1.5px solid #f1f1f1;
        background: #fff;
        margin-left: auto;
    }
`;
