import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { CONSTANTS, NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import ArtworkServices from "../based/services/ArtworkServices";
import Breadcum from "../layout/refactor/Breadcum/Breadcum";
import DetailVer2 from "./component/DetailVer2";
import ProductVer2 from "./component/ProductVer2";
import { CreateArtworkContext, PRINT_SIDE } from "./context/CreateArtworkContext";
import { StepOptions } from "./context/CreateArtworkProvider";
import { SetModel } from "./model/PreAddArtworkModel";

const CreateArtwork = (props) => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { setId } = props.match.params;
    const { step, setStep, setModel, setSetModel, artworkModels, setArtworkModels, printSide, setPrintSide, frame, setFrame, addRef, detail, setDetail } = useContext(CreateArtworkContext);
    const stepActive = useMemo(() => {
        return [...step].find((x) => x.isActive);
    }, [step]);

    useEffect(() => {
        if (setId > 0) {
            handleStepUpdate();
            handleFetchUpdate(setId);
        }
        return () => {
            setStep([]);
            setArtworkModels([]);
            setSetModel(new SetModel());
            setFrame({});
            setDetail([]);
        };
    }, []);

    const handleFetchUpdate = async (setId) => {
        const [err, data] = await ArtworkServices.GetPreAddArtwork(setId);
        if (!err && data) {
            const { artworkModels, setModel } = data;
            if (!artworkModels || !setModel) return;
            setArtworkModels(artworkModels);
            setModel.loyaltyMethod = 1;
            setSetModel(setModel);
            setPrintSide(PRINT_SIDE.Both);
        }
    };

    const handleStepUpdate = () => {
        let stepOptions = [...StepOptions];
        stepOptions.map((item) => {
            if (item.value == 2) {
                item.isActive = true;
                item.isDisable = false;
            } else {
                item.isActive = false;
                item.isDisable = true;
            }
        });
        setStep(stepOptions);
    };

    const handleSave = async () => {
        setLoading(true);
        let artworks = [...artworkModels];

        artworks = artworks.filter((item) => item.url != "");
        artworks.map((item) => {
            item.appSetId = setModel.appSetId;
            item.optionTop = item.optionTop.toString();
            item.optionTopOpposite = item.optionTopOpposite.toString();
            item.isDefaultAvatar = item.isDefaultAvatar == 1 ? true : false;
        });
        let model = {
            artworkModels: artworks,
            setModel: setModel,
        };
        let [err, data] = await ArtworkServices.CreateUpdateListArtwork(model);
        if (!err) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Thêm thành công!");
            history.push("/artworks");
        } else {
            let errArr = err ? err.errors : [];
            let msg =
                errArr && errArr.length != 0
                    ? errArr.reduce((prev, item, idx) => {
                          return (prev += item.value + " ");
                      }, "")
                    : CONSTANTS.MSG_ERROR;

            Notify(NOTIFY.ERROR, NOTIFY.ERROR, msg);
        }
        setLoading(false);
    };

    async function handleUpdate(set) {
        setLoading(true);
        let [error, data] = await ArtworkServices.AdditionalInfo(set);
        if (!error && data) {
            setLoading(false);
            Notify(NOTIFY.SUCCESS, "Thành công", "Cập nhật thông tin thành công");
            history.push("/artworks");
        } else {
            setLoading(false);
            Notify(NOTIFY.ERROR, "Lỗi", error.data);
        }
    }

    return (
        <React.Fragment>
            <Breadcum menu={["Trang chủ", "Quản lý thiết kế", "Thêm thiết kế mới"]} title="Thêm thiết kế mới" />
            <Wrapper>
                {stepActive && (
                    <React.Fragment>
                        <div className={`${stepActive.value == 1 ? "" : "d-none"}`}>
                            <ProductVer2 setId={setId} />
                        </div>
                        <div className={`${stepActive.value == 2 ? "" : "d-none"}`}>
                            <DetailVer2
                                onBack={() => {
                                    let st = [...step];
                                    st.map((item) => {
                                        if (item.value == 1) {
                                            item.isActive = true;
                                        } else {
                                            item.isActive = false;
                                        }
                                    });

                                    setStep(st);
                                }}
                                onSave={() => {
                                    if (setModel.id > 0) handleUpdate(setModel);
                                    else handleSave();
                                }}
                            />
                        </div>
                    </React.Fragment>
                )}
            </Wrapper>
        </React.Fragment>
    );
};

export default CreateArtwork;

const Wrapper = styled.div`
    width: 100%;
    .button-bottom {
        z-index: 101;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .detail-section {
        position: relative;
    }
`;
