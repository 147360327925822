import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';

import { PROJECT_EDITOR_MODAL_TYPE as EMBED_MODAL_TYPE } from '../../based/Constants';

// Redux
import {
  removeEmbed,
  openEmbedModal,
  toggleFullWidth,
} from '../../redux/project-editor/actions';

const ProjectEmbed = ({
  id,
  content,
  isFullWidth,
  isLast,
  removeEmbed,
  openEmbedModal,
  toggleFullWidth,
}) => {
  const itemRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (itemRef.current && isLast) {
      itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  useEffect(() => {
    checkIframeValidHandler();
  }, [content]);

  const checkIframeValidHandler = () => {
    const iframeWrapper = document.getElementById(`iframe-wrapper-${id}`);
    if (!iframeWrapper) return;

    const iframe = iframeWrapper.childNodes[0];
    if (!iframe || iframe.nodeName !== 'IFRAME') {
      openEmbedModal({ itemId: id, type: EMBED_MODAL_TYPE.ERROR });
      return;
    }

    const iframeDoc = iframe.contentWindow.document;
    if (iframeDoc.readyState !== 'complete') {
      openEmbedModal({ itemId: id, type: EMBED_MODAL_TYPE.ERROR });
      return;
    }
  };

  const toggleFullWidthHandler = () => {
    toggleFullWidth(id);
  };

  const removeEmbedHandler = () => {
    removeEmbed(id);
  };

  const openEmbedModalHandler = () => {
    openEmbedModal({ itemId: id, type: EMBED_MODAL_TYPE.EDIT });
  };

  return (
    <div
      ref={itemRef}
      className="project-embed-wrapper"
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <div
        className={`project-embed d-flex align-items-center justify-content-center ${
          isFullWidth ? 'full-width' : ''
        }`}
      >
        {/* Dropdown Button */}
        <div
          className={`project-embed__dropdown d-inline-block ${
            isActive ? 'show' : 'hide'
          }`}
        >
          <Dropdown className="shadow rounded-circle">
            <Dropdown.Toggle
              className="rounded-circle btn-sm"
              variant="primary"
            />
            <Dropdown.Menu>
              <Dropdown.Item onClick={openEmbedModalHandler}>
                Chỉnh sửa
              </Dropdown.Item>
              <Dropdown.Item onClick={removeEmbedHandler}>Xóa</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Resize Button */}
        <div
          className={`project-embed__resize border-rounded bg-dark rounded cursor-pointer shadow ${
            isActive ? 'show' : 'hide'
          }`}
          onClick={toggleFullWidthHandler}
        >
          <i className="fa fa-arrows-h px-2 py-1 text-white" />
        </div>

        {/* Iframe */}
        <div
          id={`iframe-wrapper-${id}`}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispacth) => ({
  removeEmbed: (itemId) => {
    return dispacth(removeEmbed(itemId));
  },
  openEmbedModal: ({ itemId, type }) => {
    return dispacth(openEmbedModal({ itemId, type }));
  },
  toggleFullWidth: (itemId) => {
    return dispacth(toggleFullWidth(itemId));
  },
});

export default connect(null, mapDispatchToProps)(ProjectEmbed);
