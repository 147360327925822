import React from 'react';
import styled from 'styled-components';


const CreatorFeed = () => {
    return (
        <Wrapper>

            <div className="header">
                <h2>New Post (Product List)</h2>
            </div>

            <div className="row">
                <div className="column-left">
                    <div className="reward">
                        <h2><i>500 Exposure reward for post High Quality Video</i></h2>
                        <b style={{ color: "#1a71ff" }}
                        ><i>what's the High Quality video? Click to check the examples {`>>`} </i></b>
                    </div>
                    <div className="column-center">
                        <div className="input">
                            <span style={{ color: "red" }}>*</span>
                            <label for="Description"> Description</label>
                            <br />
                            <textarea
                                placeholder="What do you want to say to your fans (store follower)"
                                maxlength="2000"
                                id="story"
                                name="story"
                                rows="9"
                                cols="33"
                            ></textarea>
                        </div>
                        <div className="input">
                            <p><span style={{ color: "red" }}>*</span> Product</p>
                            <div className="product">
                                <button>+ Add Product</button>
                                <br />
                                <ul>
                                    <li>You can add up to 9 products</li>
                                    <li>Vouchers and hashtags will be displayed for selection based on the products selected</li>
                                </ul>
                            </div>
                            <div className="voucher">
                                <p>Voucher</p>
                                <button>+ Add voucher</button>
                                <button>Create New</button>
                            </div>
                            <div className="hashtag">
                                <label for="hashtag"># Hashtag</label>
                                <select id="hashtag" required>
                                    <option value="" disabled selected>Please Select</option>
                                    <option value="volvo">Volvo</option>
                                    <option value="saab">Saab</option>
                                    <option value="vw">VW</option>
                                    <option value="audi" selected>Audi</option>
                                </select>
                            </div>
                            <div className="post">
                                <p>Pulish this post</p>
                                <input type="radio" id="now" name="time" value="Now" />
                                <label for="now">Now</label>
                                <input type="radio" id="scheduletime" name="time" value="scheduletime" />
                                <label for="scheduletime">Scheduletime</label>
                                <input type="datetime-local" id="birthdaytime" name="birthdaytime" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column-right">
                    <p>Preview</p>
                    <div className="container-left">
                        <div className="profile-top">
                            <div className="avatar">
                                <img
                                    className="avatar"
                                    src="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTF_erFD1SeUnxEpvFjzBCCDxLvf-wlh9ZuPMqi02qGnyyBtPWdE-3KoH3s"
                                    alt="Ash"
                                />
                            </div>
                            <div className="info">
                                <div className="name"><strong>Ranus</strong></div>
                                <div className="follow"><button>follow</button></div>
                            </div>
                        </div>
                        <div className="profile-bottom">
                            <div className="view"><p>0 view</p></div>
                            <div className="interact">
                                <div className="icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-heart"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"
                                        />
                                    </svg>
                                    <div className="count">9</div>
                                </div>
                                <div className="icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-chat"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"
                                        />
                                    </svg>
                                    <div className="count">9</div>
                                </div>
                                <div className="icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-arrow-up-right-circle"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.854 10.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z"
                                        />
                                    </svg>
                                </div>
                                <div className="icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-three-dots-vertical"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="policy">
                            <strong>Content policy</strong>
                            <br />
                            <div className="donts">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-x-circle"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path
                                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                                    />
                                </svg>
                                DON'Ts - Highly probihited
                            </div>
                            <ol>
                                <li>Sexy cover or image</li>
                                <li>Indecent contents</li>
                            </ol>
                            <p>
                                Such content may result in traffic restrictions and affecting long-term traffic acquistion of your
                                store.
                            </p>
                            <div className="dos">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-check-circle"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path
                                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                                    />
                                </svg>
                                DOs - Highly recommend
                            </div>
                            <ol>
                                <li>The New Arrivals items and Unique offering products</li>
                                <li>Customer reviews</li>
                                <li>Daily real image of you and your team, warehouse, your feelings when doing e-commerce</li>
                            </ol>
                            <p>
                                Feed is not only a way to promote items, but also a place for you to build trust with customers.
                                Sharing daily life of you and your team's hard working, as well as the feeling you have when
                                fighting in e-commerce battle are also good way to build trust ad bond your store and customer.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1600px;
    * {
    box-sizing: border-box;
    border-radius: 5px;
    }
    .header {
        text-align: left;
        font-size: 15px;
        font-family: "Times New Roman", Times, serif;
    }
    .row {
        display: -webkit-flex;
        display: flex;
    }

    .column-left {
        -webkit-flex: 3;
        -ms-flex: 3;
        flex: 3;
        padding: 5px;
    }
    .column-center {
        padding: 20px;
        background-color: white;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .column-right {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 5px;
        height: 80%;
        background-color: white;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 10px;
    }
    .reward {
        width: 100%;
        background-image: linear-gradient(to right, rgba(178, 207, 255), rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255));
        height: 100%px;
        margin-bottom: 10px;
        padding: 20px;
        background-color: white;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .input {
        textarea {
            width: 100%;
            border-style: solid;
            border-color: rgb(197, 197, 197);
            resize: none;
        }
        button {
            border: none;
            padding: 8px 32px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            transition-duration: 0.4s;
            cursor: pointer;
            background-color: white;
            color: #3281fe;
            border: solid 0.5px #3281fe;
        }
    }
    .product {
        padding: 5px 20px;
        background-color: #f8f9fb;
    }
    .voucher {
        padding: 5px 20px;
    }
    ul {
        padding: 2px 20px;
    }
    ol {
        padding: 2px 20px;
    }
    .hashtag {
        padding: 20px;
        select {
            width: 100%;
            border-style: solid;
            border-color: rgb(197, 197, 197);
            height: 30px;
        }
    }
    .post {
        padding: 20px;
        background-color: #f8f9fb;
        label {
            padding-right: 10px;
        }
    }
    .profile-top {
        display: flex;
        justify-content: space-between;
    }
    .avatar {
        flex: 1;
        margin-right: 2px;
        img {
            border-radius: 50%;
            height: 50px;
        }
    }
    .info {
        flex: 5;
        display: flex;
        justify-content: space-between;
    }
    .follow {
        button {
            background-image: linear-gradient(to right, #eb3349 0%, #f45c43 51%, #eb3349 100%);
            padding: 2px 20px;
            text-align: center;
            background-size: 200% auto;
            color: white;
            display: block;
            border: none;
        }
    }
    .profile-bottom {
        display: flex;
        justify-content: space-between;
        .view {
            flex: 1;
        }
        p {
            color: #bbb;
            font-size: small;
        }
        .interact {
            flex: 1;
            display: flex;
            justify-content: space-around;
        }
    }
    .donts {
        background-color: #fde7e7;
        width: 100%;
        height: 40px;
        padding-left: 10px;
        margin: auto;
        padding: 10px;
    }
    .dos {
        background-color: #d8f3de;
        width: 100%;
        height: 40px;
        padding-left: 10px;
        margin: auto;
        padding: 10px;
    }
    hr.solid {
        border-top: 3px solid #bbb;
    }
    .container-left {
        padding: 5px;
    }
    .icon {
        position: relative;
    }
    .count {
        position: absolute;
        top: -10px;
        right: -12px;
        background-repeat: no-repeat;
        background-size: cover;
        width: 17px;
        height: 17px;
        flex-shrink: 0;
        color: gray;
        text-align: center;
        font-weight: 700;
        font-size: 0.9rem;
        border-radius: 50%;
    }
    @media (max-width: 1100px) {
        .row {
            -webkit-flex-direction: column;
            flex-direction: column;
        }
    }
}`;


export default CreatorFeed