import styled from "styled-components";
export const BreadcumWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 20px;
    .breadcum {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
    }
`;
export const Title = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    h4 {
        text-transform: uppercase;
        color: #303030;
        font-family: Lexend;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
`;
