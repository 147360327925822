import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import CreatorServices from "../../src/based/services/CreatorServices";
import CommonTablePagination from "../based/CommonTablePagination";
import { ARTWORK_STATUS, INITPAGING, NOTIFY } from "../based/Constants";
import { Block, Box } from "../based/content/ct.elm";
import TFUSelect from "../based/inputs/TFUSelect";
import { Notify } from "../based/Notify";
import TextArea from "../based/refactor/TextArea";
import Textbox from "../based/refactor/Textbox";
import ArtworkServices from "../based/services/ArtworkServices";
import TemplateTypeServices from "../based/services/TemplateTypeServices";
import useFormValidate from "../hooks/useFormValidate";
import Breadcum from "../layout/refactor/Breadcum/Breadcum";
const FILTER_OPTIONS = [
    {
        label: "1",
        value: "1",
    },
    {
        label: "2",
        value: "2",
    },
    {
        label: "3",
        value: "3",
    },
    {
        label: "4",
        value: "4",
    },
];

const initCollection = {
    id: 0,
    name: "",
    description: "",
    templateTypeId: 0,
    status: 0,
    isDelete: false,
    creatorArtworkCollections: [],
    url: "",
};

function ProjectEditorAuto(props) {
    const [artwork, setArtwork] = useState({});
    const [select, setSelect] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [collection, setCollection] = useState(initCollection);
    const { addRef, isValid } = useFormValidate();
    const [paging, setPaging] = useState(INITPAGING);
    const [pagingArtwork, setPagingArtwork] = useState(INITPAGING);
    const [templateType, setTemplateType] = useState([]);
    const [titleCount, setTitleCount] = useState(0);
    const [descriptionCount, setDescriptionCount] = useState(0);
    const [topics, setTopics] = useState([]);
    const [brands, setBrands] = useState([]);
    const history = useHistory();
    const id = props.match.params.id;

    useEffect(() => {
        getArtwork(pagingArtwork);
        getTemplateType();
        getBrand();
        getTopic();
        if (id > 0) getData();
        return () => {
            setArtwork({});
            setCollection(initCollection);
            setSelect([]);
        };
    }, []);

    const getData = async () => {
        let [error, data] = await CreatorServices.GetAutoCollectionById(id);
        if (!error && data) {
            for (const item of data.creatorArtworkCollections) {
                item.artworkUrl = item.artworkThumbnail;
            }
            setCollection(data);
            setIsLoading(false);
            setSelect(data.creatorArtworkCollections);
        } else {
            setIsLoading(false);
        }
    };

    const getTemplateType = async () => {
        let [err, data] = await TemplateTypeServices.GetTemplateTypes(paging);
        if (!err && data && data.listObjects) {
            let datas = [];
            data.listObjects.map((set) => {
                let modelTemp = {
                    text: set.name,
                    label: set.name,
                    value: set.id,
                };
                datas.push(modelTemp);
            });
            setTemplateType(datas);
        }
    };

    const getTopic = async () => {
        let [err, data] = await TemplateTypeServices.GetListTopic({ ...INITPAGING, pageSize: 50 });
        if (!err && data) setTopics(data.listObjects);
        else setTopics([]);
    };

    const getBrand = async () => {
        let [err, data] = await TemplateTypeServices.GetListBrand({ ...INITPAGING, pageSize: 50 });
        if (!err && data) setBrands(data.listObjects);
        else setBrands([]);
    };

    const getArtwork = async (paging) => {
        setIsLoading(true);
        const list = [];
        let [error, data] = await ArtworkServices.GetSetPaging(paging, ARTWORK_STATUS.Online, ARTWORK_STATUS.Online);
        if (!error && data) {
            for (const element of data.listObjects) {
                for (const artwork of element.artworks) {
                    let collectionId = 0;
                    let artworkId = artwork.id;
                    let artworkUrl = artwork.thumbnail;
                    let posterArtworkCount = artwork.posterArtworkCount;
                    list.push({ collectionId, artworkId, artworkUrl, posterArtworkCount });
                }
            }
            setArtwork(list);
            setIsLoading(false);
            setPagingArtwork(data.paging);
        } else {
            setArtwork([]);
            setIsLoading(false);
        }
    };
    function selectHandle(item) {
        let list = [...select, item];
        setSelect(list);
        setCollection({ ...collection, creatorArtworkCollections: list });
    }

    function unselectHandle(item) {
        if (select.includes(item)) {
            const array = select.filter((x) => x !== item);
            setSelect(array);
            setCollection({ ...collection, creatorArtworkCollections: array });
        }
    }

    const handleCreate = async () => {
        if (isValid()) {
            if (select.length <= 2 || select.length > 20) {
                Notify(NOTIFY.WARNING, "Có lỗi", "Vui lòng chọn Tối thiểu 3, Tối đa 20 artwork");
            } else {
                let [err, data] = await CreatorServices.CreateUpdateCollection(collection);
                if (!err && data) {
                    Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                    history.push("/projects");
                }
            }
        }
    };

    function renderSelect() {
        return (
            <React.Fragment>
                {select && select.length > 0
                    ? select.map((item, index) => {
                          return (
                              <div className="select-item" key={index}>
                                  <img src={item.artworkUrl}></img>
                                  <span
                                      className="fa fa-times"
                                      onClick={(e) => {
                                          unselectHandle(item);
                                      }}
                                  ></span>
                              </div>
                          );
                      })
                    : ""}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Breadcum menu={["Trang Chủ", "Quản lý bộ sưu tập"]} title="Tạo bộ sưu tập tự động" />
            <Box>
                <div className="box__content">
                    <Block flexBasis="30%" style={{ marginTop: "20px", height: "auto" }}>
                        <Wrapper>
                            <div className="flex__space">
                                <div className="flex__column">
                                    <label htmlFor="name" className="required">
                                        Tên Bộ sưu tập(Tối đa 50 ký tự)<span style={{ color: titleCount <= 50 ? "#4caf50" : "red" }}>{titleCount} Ký tự</span>
                                    </label>
                                    <Textbox
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        required
                                        maxLength={50}
                                        value={collection.name}
                                        ref={addRef}
                                        onChanged={(name, value) => {
                                            setCollection({ ...collection, name: value });
                                            setTitleCount(value.length);
                                        }}
                                    />
                                </div>
                                <div className="flex__column m-t-30">
                                    <label className="required">
                                        Mô tả ngắn gọn(Tối đa 250 ký tự)<span style={{ color: descriptionCount <= 250 ? "#4caf50" : "red" }}>{descriptionCount} Ký tự</span>
                                    </label>
                                    <TextArea
                                        required
                                        maxLength={250}
                                        ref={addRef}
                                        className="form-control"
                                        id="code"
                                        name="code"
                                        value={collection.description}
                                        onChanged={(name, value) => {
                                            setCollection({ ...collection, description: value });
                                            setDescriptionCount(value.length);
                                        }}
                                    />
                                </div>
                                <div className="flex__column m-t-30">
                                    <label>Phong cách</label>
                                    <TFUSelect
                                        name="planApplied"
                                        id="planApplied"
                                        required
                                        ref={addRef}
                                        options={templateType}
                                        placeholder="Phong cách bộ sưu tập"
                                        value={collection.templateTypeId}
                                        onChanged={(newValue) => setCollection({ ...collection, templateTypeId: newValue })}
                                    />
                                </div>
                                <div className="flex__column m-t-30">
                                    {select && select.length > 0 ? <label style={{ color: "#4caf50" }}>Đã chọn {select.length} artwork</label> : ""}
                                    <div className="select-container">{renderSelect()}</div>
                                </div>
                                <div className="flex__column m-t-30">
                                    <button className="create-button" onClick={handleCreate}>
                                        Bắt đầu tạo
                                    </button>
                                </div>
                            </div>
                        </Wrapper>
                    </Block>

                    <Block flexBasis="70%" style={{ marginTop: "20px", height: "auto" }}>
                        <Wrapper>
                            <label className="required">Lựa chọn Artwork bạn muốn tạo bộ sưu tập (Tối thiểu 3, Tối đa 20)</label>
                            <div className="card-box p-3">
                                <div className="row">
                                    <label>Trạng thái:</label>
                                    <div className="col-sm-2">
                                        <TFUSelect
                                            name="status"
                                            options={topics}
                                            placeholder="Phong cách"
                                            value={pagingArtwork.setTopicName}
                                            onChanged={(newValue) => {
                                                getArtwork({ ...pagingArtwork, pageNumber: 1, setTopicName: newValue ? newValue : "" });
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-2">
                                        <TFUSelect
                                            name="status"
                                            options={brands}
                                            placeholder="Thương hiệu"
                                            value={pagingArtwork.setBrandId}
                                            onChanged={(newValue) => {
                                                getArtwork({ ...pagingArtwork, pageNumber: 1, setBrandId: newValue ? newValue : "" });
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                            <label className={`btn btn-outline-dark ${pagingArtwork.posterArtworkCount == 1 ? "active" : ""}`} onClick={(e) => getArtwork({ ...pagingArtwork, pageNumber: 1, posterArtworkCount: 1 })}>
                                                <input type="radio" name="options" id="option1" autoComplete="off" /> Chưa dùng
                                            </label>
                                            <label className={`btn btn-outline-dark  ${pagingArtwork.posterArtworkCount == 0 ? "active" : ""}`} onClick={(e) => getArtwork({ ...pagingArtwork, pageNumber: 1, posterArtworkCount: 0 })}>
                                                <input type="radio" name="options" id="option2" autoComplete="off" /> Tất cả
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <input
                                            type="text"
                                            className="form-control txt-search"
                                            value={pagingArtwork.keyword}
                                            onChange={(e) => {
                                                setPagingArtwork({ ...pagingArtwork, pageNumber: 1, keyword: e.target.value });
                                            }}
                                        />

                                        <div className="btn-search" onClick={(e) => getArtwork({ ...pagingArtwork })}>
                                            Tìm kiếm
                                        </div>
                                    </div>
                                </div>
                                <div className={`artwork-container `}>
                                    {artwork && artwork.length > 0
                                        ? artwork.map((item, index) => {
                                              let isChoose = "";
                                              if (select.includes(item)) isChoose = " choose";
                                              return (
                                                  <div
                                                      key={index}
                                                      className={`artwork-item ${item.posterArtworkCount > 0 ? "disabled" : ""}` + isChoose}
                                                      onClick={(e) => {
                                                          if (item.posterArtworkCount <= 0) {
                                                              if (!select.includes(item)) {
                                                                  selectHandle(item);
                                                              } else {
                                                                  unselectHandle(item);
                                                              }
                                                          }
                                                      }}
                                                  >
                                                      <div className="artwork-image">
                                                          <img src={item.artworkUrl}></img>
                                                          {item.posterArtworkCount > 0 ? <div className="artwork-selected">Đã được dùng cho {item.posterArtworkCount} bộ sưu tập </div> : ""}
                                                          <div className="artwork-title">{item.title}</div>
                                                      </div>
                                                  </div>
                                              );
                                          })
                                        : ""}
                                    <CommonTablePagination
                                        paging={pagingArtwork}
                                        onChangePage={(page) => {
                                            getArtwork({ ...pagingArtwork, pageNumber: page });
                                        }}
                                        onChangePageSize={(value) => {
                                            getArtwork({ ...pagingArtwork, pageSize: value });
                                        }}
                                    />
                                </div>
                            </div>
                        </Wrapper>
                    </Block>
                </div>
            </Box>
        </React.Fragment>
    );
}

export default ProjectEditorAuto;

const Wrapper = styled.div`
    textarea {
        width: 100%;
        border-style: solid;
        border-color: rgb(197, 197, 197);
        resize: none;
        height: auto;
    }
    .artwork-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        overflow-y: scroll;
        justify-content: start;
        height: 510px;
    }
    .artwork-item {
        display: flex;
        height: 240px;
        margin: 5px;
        border-radius: 5px;
        border: unset;
        align-items: center;
        justify-content: center;
        border: solid 3px white;
    }
    .choose {
        border: solid 3px #4caf50;
    }
    .artwork-image {
        background-color: black;
        width: 94%;
        height: 98%;
        border-radius: 5px;
        aspect-ratio: 3/4;
        position: relative;
    }
    .artwork-image img {
        object-fit: contain;
        width: 100%;
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .select-container {
        display: flex;
        flex-wrap: wrap;
    }
    .select-item {
        background-color: black;
        height: 100px;
        width: 80px;
        margin: 5px;
        position: relative;
        span {
            position: absolute;
            top: 0;
            right: 0;
            color: white;
        }
        img {
            object-fit: contain;
            width: 100%;
        }
    }
    .txt-search {
        border-radius: 20px;
        padding: 0 20px;
        border: solid 0.5px #000;
    }
    .btn-search {
        cursor: pointer;
        border: 1px solid;
        padding: 1px 10px;
        border-radius: 20px;
        color: #fff;
        position: absolute;
        right: 3px;
        top: 2.3px;
        background: black;
        right: 13px;
    }
    .artwork-title {
        position: absolute;
        bottom: 0;
        color: white;
        padding: 5px;
    }
    .artwork-selected {
        position: absolute;
        top: 0;
        color: white;
        padding: 5px;
    }
    .selected {
        background-color: white;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(to bottom, #363636, #6b6a6a, #666666bf, #666666);
            opacity: 0.6;
            border-radius: 5px;
        }
    }
    .create-button {
        background-color: #4caf50;
        border-radius: 20px;
        color: white;
        border: none;
        width: 100px;
        margin: auto;
    }
    label {
        span {
            float: right;
        }
    }
`;
