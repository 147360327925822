import React, { forwardRef, useImperativeHandle, useState } from "react";
import Select from "react-select";
import { TFUSelectProps } from "../PropsDefined";
import { CONSTANTS } from "../Constants";

/**
 * Tfu select
 * @param {TFUSelectProps} props the tfu select props
 * @param {Ref} ref reference
 */
const TFUSelect = (props, ref) => {
    useImperativeHandle(ref, () => ({ displayError, validateInput, props }));

    const [valid, setValid] = useState({ isValid: true, msgInvalid: "" });

    const displayError = (msg) => {
        setValid({ isValid: false, msgInvalid: msg });
    };
    const validateInput = (value) => {
        if (!value && props.required) return CONSTANTS.MSG_REQUIRED;
        return "";
    };
    const handleChange = (selected) => {
        var newValue = selected ? selected.value : null;
        if (props.required && !newValue)
            setValid({
                isValid: false,
                msgInvalid: props.requiredMsg ? props.requiredMsg : CONSTANTS.MSG_REQUIRED,
            });
        else setValid({ isValid: true, msgInvalid: "" });
        if (props.onChanged) props.onChanged(newValue);
        if (props.onChangedObject) props.onChangedObject(selected);
    };

    const handleInputChange = (value) => {
        if (props.handleInputChange) props.handleInputChange(value);
    };
    return (
        <React.Fragment>
            <Select
                id={props.id}
                name={props.name}
                className={props.className ? props.className : ""}
                classNamePrefix={`${valid.isValid ? "" : "alert-danger "}react-select`}
                value={props.options && props.options.length && (props.value >= -1 || typeof props.value == "string") ? props.options.find((x) => x.value == props.value) : null}
                options={props.options}
                noOptionsMessage={() => (props.noOptionsMessage ? props.noOptionsMessage : "Không có dữ liệu")}
                placeholder={props.placeholder ? props.placeholder : "--Chọn--"}
                onChange={handleChange}
                isClearable={props.isClearable == false ? false : true}
                isDisabled={props.isDisabled ? props.isDisabled : false}
                styles={
                    props.styles
                        ? props.styles
                        : {
                              option: (provided, state) => ({
                                  ...provided,
                                  paddingLeft: state.data.level ? state.data.level * 20 : 10,
                              }),
                          }
                }
                onInputChange={handleInputChange}
            />
            {valid.isValid ? (
                ""
            ) : (
                <label className={"text-danger full-width " + props.invalidBoxClassName} htmlFor={props.id}>
                    {valid.msgInvalid}
                </label>
            )}
        </React.Fragment>
    );
};
export default forwardRef(TFUSelect);
