import * as React from "react";
import { useEffect } from "react";
import styled from "styled-components";

const VerticalPositionScoll = ({ value, min, max, onChange }) => {
    useEffect(() => {
        let userAgent = navigator.userAgent;
        let tiktokInApp = userAgent.includes("Bytedance");
        function handlePreventDefault(e) {
            if (tiktokInApp) {
                e.preventDefault();
            }
        }

        let printBodyDocument = document.getElementById("vertical-scroll");

        if (printBodyDocument) {
            printBodyDocument.addEventListener("touchstart", handlePreventDefault, { passive: false });
            printBodyDocument.addEventListener("touchmove", handlePreventDefault, { passive: false });
        }

        return () => {
            if (printBodyDocument) {
                printBodyDocument.removeEventListener("touchstart", handlePreventDefault);
                printBodyDocument.removeEventListener("touchmove", handlePreventDefault);
            }
        };
    }, []);

    const convertValuePercentage = (currentValue) => {
        let percentageSubstract = ((currentValue - min) * 100) / (max - min);
        return 100 - percentageSubstract;
    };

    const handleChangeValue = (e) => {
        let val = +e.target.value;
        let number = ((100 - val) * (max - min)) / 100;
        let actualVal = min + number;
        onChange(actualVal);
        let target = e.target;
        target.style.backgroundSize = val + "% 100%";
        e.stopPropagation();
        e.preventDefault();
    };

    if (min < max)
        return (
            <WrapperVerticalPositionScoll id="vertical-scroll">
                <input type="range" orient="vertical" value={convertValuePercentage(value)} min={0} max={100} onChange={(e) => handleChangeValue(e)} />
            </WrapperVerticalPositionScoll>
        );
    return "";
};

export default VerticalPositionScoll;

const WrapperVerticalPositionScoll = styled.div`
    input[type="range"] {
        -webkit-appearance: none;
        width: 250px;
        height: 7px;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 5px;
        background-image: linear-gradient(#f20e45, #f20e45);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transform: rotate(270deg);
    }

    /* Input Thumb */
    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        padding: 10px;
        border-radius: 50%;
        background: #f20e45;
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
    }

    input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        padding: 10px;
        border-radius: 50%;
        background: #f20e45;
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
    }

    input[type="range"]::-ms-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        padding: 10px;
        border-radius: 50%;
        background: #f20e45;
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
    }

    input[type="range"]::-webkit-slider-thumb:hover {
        background: #f20e45;
    }

    input[type="range"]::-moz-range-thumb:hover {
        background: #f20e45;
    }

    input[type="range"]::-ms-thumb:hover {
        background: #f20e45;
    }

    /* Input Track */
    input[type="range"]::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }

    input[type="range"]::-moz-range-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }

    input[type="range"]::-ms-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }
`;
