import React from "react";
const INIT_ORDER_COUNT = {
    completedOrderCount: 0,
    inCompletedOrderCount: 0
}
const INIT_VALUE = {
    orderCount: INIT_ORDER_COUNT,
    setOrderCount: (v = INIT_ORDER_COUNT) => { }
}
const OrderCommissionContext = React.createContext(INIT_VALUE);
export default OrderCommissionContext;