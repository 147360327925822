import { v4 as uuid } from 'uuid';

import { actionTypes } from '../types';
import { PROJECT_EDITOR_ITEM_TYPE as ITEM_TYPE } from '../../../based/Constants';
import CreatorServices from '../../../based/services/CreatorServices';
import Request from '../../../based/Request';

// Cover Image
const uploadCoverImageBegin = () => ({
  type: actionTypes.PROJECT_EDITOR_UPLOAD_COVER_IMAGE_BEGIN,
  payload: null,
});

const uploadCoverImageSuccess = (imgUrl) => ({
  type: actionTypes.PROJECT_EDITOR_UPLOAD_COVER_IMAGE_SUCCESS,
  payload: imgUrl,
});

const uploadCoverImageFail = (err) => ({
  type: actionTypes.PROJECT_EDITOR_UPLOAD_COVER_IMAGE_FAIL,
  payload: err,
});

// Create
const createProjectBegin = () => ({
  type: actionTypes.PROJECT_EDITOR_CREATE_BEGIN,
  payload: null,
});

const createProjectSuccess = (model) => ({
  type: actionTypes.PROJECT_EDITOR_CREATE_SUCCESS,
  payload: model,
});

const createProjectFail = (err) => ({
  type: actionTypes.PROJECT_EDITOR_CREATE_FAIL,
  payload: err,
});

// Fetch
const fetchProjectBegin = () => ({
  type: actionTypes.PROJECT_EDITOR_FETCH_BEGIN,
  payload: null,
});

const fetchProjectSuccess = (updatedState) => ({
  type: actionTypes.PROJECT_EDITOR_FETCH_SUCCESS,
  payload: updatedState,
});

const fetchProjectFail = (err) => ({
  type: actionTypes.PROJECT_EDITOR_FETCH_FAIL,
  payload: err,
});

// Action Creator
const getProjectEditorState = () => (_, getState) => {
  return getState().projectEditor;
};

export const uploadCoverImage = (imgFile) => {
  return (dispatch) => {
    dispatch(uploadCoverImageBegin());

    Request.UploadImage(imgFile)
      .then((res) => {
        if (res.success) {
          dispatch(uploadCoverImageSuccess(res.data.imageUrl));
        } else {
          dispatch(uploadCoverImageFail(res.errors));
        }
      })
      .catch((err) => {
        dispatch(uploadCoverImageFail(err));
      });
  };
};

export const createProject = (model) => {
  return async (dispatch) => {
    dispatch(createProjectBegin());

    const [err, data] = await CreatorServices.CreateCollection(model);
    if (!err && data) {
      dispatch(createProjectSuccess(data));
      return true;
    } else {
      dispatch(createProjectFail(err));
      return false;
    }
  };
};

export const clearProject = () => {
  return async (dispatch) => {
    const action = {
      type: actionTypes.PROJECT_EDITOR_CLEAR,
      payload: null,
    };
    dispatch(action);
  };
};

export const fetchProject = (projectId) => {
  return async (dispatch) => {
    dispatch(fetchProjectBegin());

    const [err, data] = await CreatorServices.GetCollectionById(projectId);
    if (!err && data) {
      const { id, agencyId, name, coverUrl, content } = data;

      const updatedState = { ...dispatch(getProjectEditorState()) };
      updatedState.items.data = getItems(content);
      updatedState.project.data = { id, agencyId, name, coverUrl, content: '' };

      dispatch(fetchProjectSuccess(updatedState));
    } else {
      dispatch(fetchProjectFail(err));
    }
  };
};

// Utils
const getTextItem = ({ value, align }) => {
  return {
    id: uuid(),
    type: ITEM_TYPE.TEXT,
    data: {
      value: value,
      align: align,
    },
  };
};

const getImageItem = ({ value, isFullWidth }) => {
  return {
    id: uuid(),
    type: ITEM_TYPE.IMAGE,
    data: {
      url: value,
      isFullWidth: isFullWidth,
    },
  };
};

const getEmbedItem = ({ value, isFullWidth }) => {
  return {
    id: uuid(),
    type: ITEM_TYPE.EMBED,
    data: {
      content: value,
      isFullWidth: isFullWidth,
    },
  };
};

const getMockupItem = ({ value, isFullWidth }) => {
  const valueJSON = JSON.parse(value);
  const { mainImage, subImages } = valueJSON;

  return {
    id: uuid(),
    type: ITEM_TYPE.MOCKUP,
    data: {
      isFullWidth: isFullWidth,
      mainImage: mainImage,
      subImages: subImages.map((subImg) => ({ id: uuid(), url: subImg.url })),
    },
  };
};

const getItems = (items) => {
  return items.map((item) => {
    switch (item.type) {
      case ITEM_TYPE.TEXT:
        return getTextItem(item.data);
      case ITEM_TYPE.IMAGE:
        return getImageItem(item.data);
      case ITEM_TYPE.EMBED:
        return getEmbedItem(item.data);
      case ITEM_TYPE.MOCKUP:
        return getMockupItem(item.data);
      default:
        return;
    }
  });
};
